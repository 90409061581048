import React from 'react';
import styled from 'styled-components';
import { isTeamDeath, sortByPlayerStatus } from '../../../../../../util/CommonUtil';

const TeamRow = styled.div`
  text-transform: uppercase;
  width: ${props => props.width}px;
  height: 41px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  background: url('/assets/img/predator/body.png');
  color: white;

  margin-top: ${props => props.rank === 1 ? '0' : '-2'}px;


  .td {
    width: ${props => props.width/5}px;
    height: 37px;
    align-items: center;
    display: flex;
  }

  .team-rank {
    color: #00b4ff;
    padding-right: 12px;
    padding-left: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    text-align: right !important;
    font-family: Predator-Regular;
  }

  .team-initial {
    padding: 0 3px;
    width: 48px;
  }

  p {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 5px;
  }

  .team-points {
    justify-content: center;
    text-align: center;
  }

  .team-alive {
    padding: 0 12px;
    td {
      background: none;
      padding: 3px 2px;
      div {
        width: 4px;
        height: 25px;
        margin-top: 5px;
      }
    }

    .alive {
      background: #00b4ff;
    }
    .death {
      background: #646464;
    }
    .knocked {
      background: #e60046;
    }
  }

  .team-death-overlay {
    position: absolute;
    width: 242px;
    height: 38px;
    opacity: 0.8;
    background: url('/assets/img/predator/out-overlay.png');
  }
`;

function LeaderboardBody(props) {
  const { team, rank, width } = props;

  return (
    <TeamRow
      className={isTeamDeath(team) ? 'team-death' : ''}
      rank={rank}
      width={width}
    >
      {
        isTeamDeath(team) ?
        <div className="team-death-overlay"></div>
        :
        null
      }
      <div className={`td team-rank text-right ${isTeamDeath(team) ? 'team-death' : ''}`}>
          <p>#{ rank }</p>
      </div>
      <div className={`td team-initial text-left ${isTeamDeath(team) ? 'team-death' : ''}`}><p>{ team.initial }</p></div>
      <div className={`td team-alive ${isTeamDeath(team) ? 'team-death' : ''}`}>
        <table>
          <tbody>
            <tr>
              {
                team.players.sort(sortByPlayerStatus).map(i => {
                  return (
                    <td>
                      <div className={i.status}></div>
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className={`td team-points ${isTeamDeath(team) ? 'team-death' : ''}`}><p>{ team.totalKills }</p></div>
      <div className={`td team-points ${isTeamDeath(team) ? 'team-death' : ''}`}><p>{ team.totalPoints }</p></div>
    </TeamRow>
  );
}

export default LeaderboardBody;