import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-transform: uppercase;
  
  &.component-show {
    animation: fadeIn 0.3s ease 0s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: fadeOut 0.3s ease 0s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }

  table {
    width: 100%;

    td {
      width: 50%;
    }
  }

  .title {
    font-size: 90px;
  }

  .group-title p {
    font-size: 50px;
    line-height: 50px;
  }

  .match-title p {
    font-size: 35px;
    line-height: 35px;
    margin-top: -30px;
  }
`;

function LeaderboardHeader(props) {
  const { show, opts, exportOverlay } = props;
  const matchNameSplit = (opts.matchName || '').split('Match');
  const matchNumber = matchNameSplit.length > 1 ? matchNameSplit[1].replaceAll(' ', '') : null;
  const groupName = matchNumber ? parseInt(matchNumber / 6) + (matchNumber % 6 === 0 ? 0 : 1) : null;

  return (
    <td colSpan={2}>
      <Wrapper className={ exportOverlay ? '' : show ? 'component-show' : 'component-hide' }>
        <table>
          <tbody>
            <tr>
              <td rowSpan="2" className="title">Leaderboard</td>
              <td className="group-title text-right"><p>{ groupName ? `Day ${groupName}` : '' }</p></td>
            </tr>
            <tr>
              <td className="match-title text-right"><p>{ matchNumber ? `Match ${matchNumber}` : '' }</p></td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    </td>
  );
}

export default LeaderboardHeader;