import pubgComponents from './pubg';
import csgoComponents from './csgo';
import valorantComponents from './valorant';
import {
  PUBG_GAME,
  CSGO_GAME,
  VALORANT_GAME,
} from '../../util/Games';

export default {
  [PUBG_GAME]: pubgComponents,
  [CSGO_GAME]: csgoComponents,
  [VALORANT_GAME]: valorantComponents,
}