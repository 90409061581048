import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row
} from 'reactstrap';
import {
  Button,
  Card,
  CardHeader,
  Input,
  InputGroupAddon
} from '../../StyledCoreUI';

class TextSearch extends Component {
  state = {
    value: ''
  };

  /**
   * Handles any input changes
   */
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  /**
   * Handles form submit
   */
  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state.value);
  }

  render() {
    const { title, placeholder } = this.props;

    return (
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <strong>{ title }</strong>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleOnSubmit}>
                <FormGroup row>
                  <Col lg="6" className="offset-lg-3 offset-0">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <Button color="primary" type="submit" onClick={this.handleOnSubmit}><i className="fa fa-search"></i> Search</Button>
                      </InputGroupAddon>
                      <Input type="text" name="value" value={this.state.value} onChange={this.handleChange} placeholder={placeholder} />
                    </InputGroup>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

TextSearch.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func
};

export default TextSearch;