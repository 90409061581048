import {
  subscribeToPlayerStats,
  subscribeToTeamStats,
  subscribeToPlayerHeadToHead,
  subscribeToTeamHeadToHead,
  subscribeToPlayerLeaderboard,
  subscribeToTeamLeaderboard,
  subscribeToLiveTeamLeaderboard,
  subscribeToLiveMapTeams,
  subscribeToLiveSurvivalStats,
  subscribeToLiveTeamStats,
  subscribeToLivePlayerStats,
  subscribeToLivePlayerKills,
  subscribeToLivePlayerKillsDamage,
  subscribeToLiveTeamHeadToHead,
  subscribeToLivePlayerLeaderboard,
  subscribeToCurrentMatch,
  subscribeToTodaysWinners,
  subscribeToScoreboard,
  subscribeToMapVeto,
  subscribeToChangeComponent,
} from './componentSubscriptions';
import {
  PLAYER_STATS_COMPONENT_TYPE,
  TEAM_STATS_COMPONENT_TYPE,
  PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
  TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  PLAYER_LEADERBOARD_COMPONENT_TYPE,
  TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_MAP_TEAMS_COMPONENT_TYPE,
  LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE,
  LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE,
  CURRENT_MATCH_COMPONENT_TYPE,
  TODAYS_WINNERS_COMPONENT_TYPE,
  SCOREBOARD_COMPONENT_TYPE,
  MAP_VETO_COMPONENT_TYPE,
} from '../../views/Components/constants';

export const overlayDataSubscriptions = {
  [PLAYER_STATS_COMPONENT_TYPE]: subscribeToPlayerStats,
  [TEAM_STATS_COMPONENT_TYPE]: subscribeToTeamStats,
  [PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE]: subscribeToPlayerHeadToHead,
  [TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: subscribeToTeamHeadToHead,
  [PLAYER_LEADERBOARD_COMPONENT_TYPE]: subscribeToPlayerLeaderboard,
  [TEAM_LEADERBOARD_COMPONENT_TYPE]: subscribeToTeamLeaderboard,
  [LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE]: subscribeToLiveTeamLeaderboard,
  [LIVE_MAP_TEAMS_COMPONENT_TYPE]: subscribeToLiveMapTeams,
  [LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE]: subscribeToLiveSurvivalStats,
  [LIVE_TEAM_STATS_COMPONENT_TYPE]: subscribeToLiveTeamStats,
  [LIVE_PLAYER_STATS_COMPONENT_TYPE]: subscribeToLivePlayerStats,
  [LIVE_PLAYER_KILLS_COMPONENT_TYPE]: subscribeToLivePlayerKills,
  [LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE]: subscribeToLivePlayerKillsDamage,
  [LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: subscribeToLiveTeamHeadToHead,
  [LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE]: subscribeToLivePlayerLeaderboard,
  [CURRENT_MATCH_COMPONENT_TYPE]: subscribeToCurrentMatch,
  [TODAYS_WINNERS_COMPONENT_TYPE]: subscribeToTodaysWinners,
  [SCOREBOARD_COMPONENT_TYPE]: subscribeToScoreboard,
  [MAP_VETO_COMPONENT_TYPE]: subscribeToMapVeto,
}

export const changeComponentSubscriptions = {
  [PLAYER_STATS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [TEAM_STATS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [PLAYER_LEADERBOARD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [TEAM_LEADERBOARD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_MAP_TEAMS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_TEAM_STATS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_PLAYER_STATS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_PLAYER_KILLS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [CURRENT_MATCH_COMPONENT_TYPE]: subscribeToChangeComponent,
  [TODAYS_WINNERS_COMPONENT_TYPE]: subscribeToChangeComponent,
  [SCOREBOARD_COMPONENT_TYPE]: subscribeToChangeComponent,
  [MAP_VETO_COMPONENT_TYPE]: null,
}