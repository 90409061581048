import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const IMAGE_WRAPPER_WIDTH = 230;

const TitleWrapper = styled.div`
  text-transform: uppercase;
  width: ${props => props.headerWidth}px;
  margin-left: ${props => (props.rowWidth - props.headerWidth) / 2}px;
  margin-top: 35px;
  margin-bottom: 35px !important;
  background: ${styles.pslColor};
  height: 180px;

  p {
    padding: 0;
    margin: 0;
  }

  &.title-header-show {
    display: block;
    width: 0px;
    animation: headerSlideDown 0.5s ease 0.2s forwards;

    @keyframes headerSlideDown {
      from {width: 0px;}
      to {width: ${props => props.headerWidth}px;}
    }

    p {
      opacity: 0;
      animation: headerFadeIn 0.6s ease 0.7s forwards;

      @keyframes headerFadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }

    img {
      opacity: 0;
      animation: headerFadeInImg 0.3s ease 0s forwards;

      @keyframes headerFadeInImg {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  }

  &.title-header-hide {
    width: ${props => props.headerWidth}px;
    animation: headerSlideUp 0.5s ease 2.7s forwards;

    @keyframes headerSlideUp {
      from {width: ${props => props.headerWidth}px;}
      to {width: 0px;}
    }

    p {
      opacity: 1;
      animation: headerFadeOut 0.6s ease 2.2s forwards;

      @keyframes headerFadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }

    img {
      opacity: 1;
      animation: headerFadeOutImg 0.3s ease 3s forwards;

      @keyframes headerFadeOutImg {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }

  img {
    height: 250px;
    position: absolute;
    top: 0px;
  }

  .image-wrapper {
    max-width: ${IMAGE_WRAPPER_WIDTH}px !important;
    width: ${IMAGE_WRAPPER_WIDTH}px !important;
  }

  .title-wrapper {
    width: ${props => props.headerWidth - IMAGE_WRAPPER_WIDTH}px !important;
    max-width: ${props => props.headerWidth - IMAGE_WRAPPER_WIDTH}px !important;
  }

  .image-wrapper, .title-wrapper {
    float: left;
    height: 180px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .subtitle {
    vertical-align: middle;
    font-size: 35px;
    letter-spacing: 8px;
    filter: drop-shadow(0px 0px 4px #00000040);
    line-height: 1;
    margin-left: 5px;
  }

  .title {
    vertical-align: middle;
    font-size: 130px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 4px #00000080);
    line-height: 1;
  }
`;

function LeaderboardHeader(props) {
  const { rowWidth, headerWidth, show } = props;

  return (
    <TitleWrapper
      rowWidth={rowWidth}
      headerWidth={headerWidth}
      className={'mb-2 ' + (show ? 'title-header-show' : 'title-header-hide')}
    >
      <img
        src={`/assets/img/psl/logo-winter.png`}
        alt={`PSL logo`}
      />
      <div>
        <div className="image-wrapper">
        </div>
        <div className="title-wrapper text-center">
          <p className="subtitle">{props.groupName && props.groupName !== 'undefined' ? props.groupName : 'Lower Bracket'}</p>
          <p className="title">Top Kills & Damage</p>
        </div>
      </div>
    </TitleWrapper>
  );
}

export default LeaderboardHeader;