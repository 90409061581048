import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${ props => props.rowWidth / 5.5 }px;
  margin-left: ${ props => 2 * props.rowWidth / 4.93 }px;
  overflow: hidden;
  background: #000000;
  border: 1px solid #ff0050;

  img {
    margin-top: 2px;
    height: 45px;
  }
`;

function LeaderboardFooter(props) {
  const { rowWidth, show } = props;

  return (
    <Wrapper
      rowWidth={rowWidth}
      className={ 'text-center mt-3 ' + (show ? 'footer-show' : 'footer-hide') }
    >
      <img
        src="/assets/img/twire/powered-by-twire-no-background.png"
        alt="tournament logo"
      />
    </Wrapper>
  );
}

export default LeaderboardFooter;