import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const StyledHeaderCol = styled.div`
  width: 910px;
  background: #ff0050;
  margin: 0 20px !important;
  padding: 5px 20px;
  color: #000000;

  span {
    font-size: 33px;
    line-height: 47px;
    font-weight: 600;
  }
`;

const StyledTeamCol = styled.div`
  width: 910px;
  padding: 0 15px;
  margin: 0 20px !important;
  color: #FFFFFF;

  img {
    height: 40px;
  }
`;

const StyledTeamRow = styled(Row)`
  background: #000000;
  padding: 5px 10px;
  margin: -7px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border: 1px solid #ff0050;

  span {
    text-transform: uppercase;
    line-height: 45px;
    font-size: 30px;
    font-weight: 600;
  }
`;

function LeaderboardBody(props) {
  const { leaderboard } = props;
  const firstHalfTeams = leaderboard.slice(0, 8);
  const secondHalfTeams = leaderboard.slice(8, leaderboard.length);
  
  const HeaderCol = () => {
    return (
      <StyledHeaderCol xs="5">
        <Row>
          <Col xs="1" className="text-center">
            <span className="font-sony">#</span>
          </Col>
          <Col xs="1">
            
          </Col>
          <Col xs="6">
            <span className="font-sony">TEAM</span>
          </Col>
          <Col xs="2" className="text-center">
            <span>KILLS</span>
          </Col>
          <Col xs="2" className="text-center">
            <span>TOTAL</span>
          </Col>
        </Row>
      </StyledHeaderCol>
    );
  }

  const TeamCol = (team, rank) => {
    return (
      <StyledTeamRow>
        <Col xs="1" className="text-center">
          <span className="font-sony">{rank}</span>
        </Col>
        <Col xs="1" className="text-center">
          <img 
            className="mt-1"
            src={getTeamLogo(team.name)} 
            alt="team logo" 
            onError={(e) => e.target.src = getTeamLogo('default')}
          />
        </Col>
        <Col xs="6">
          <span>{team.name}</span>
        </Col>
        <Col xs="2" className="text-center">
          <span>{team.totalKills}</span>
        </Col>
        <Col xs="2" className="text-center">
          <span>{team.totalPoints}</span>
        </Col>
      </StyledTeamRow>
    );
  }

  return (
    <>
      <Row>
        <HeaderCol/>
        <HeaderCol/>
      </Row>
      <Row>
        <StyledTeamCol xs="5">
          {
            firstHalfTeams.map((team, i) => {
              return TeamCol(team, i + 1);
            })
          }
        </StyledTeamCol>
        <StyledTeamCol xs="5">
        {
          secondHalfTeams.map((team, i) => {
            return TeamCol(team, i + 9);
          })
        }
        </StyledTeamCol>
      </Row>
    </>
  );
}

export default LeaderboardBody;