import styled from 'styled-components';
import styles from '../../../scss/style.scss';
import Datetime from 'react-datetime';

export default styled(Datetime)`
  .rdtPicker {
    display: none;
  }

  .rdtActive {
    background: ${styles.primary};
  }

  .rdtOld, .rdtNew {
    opacity: 0.3;
  }

  .rdtTimeToggle {
    background: ${styles.primary};
  }

  input, input:focus {
    background: ${styles.tertiary};
    color: ${styles.secondary};
    border: none;
  }

  &.rdtOpen {
    .rdtPicker {
      display: block;
      position: absolute;
      background: ${styles.tertiaryDark};
      border: 1px solid ${styles.primary};
      border-radius: 5px;
      padding: 15px;
      z-index: 10;

      table {
        text-align: center;
      }

      td, th {
        height: 40px;
        cursor: pointer;

        &:hover {
          background: ${styles.tertiary};
        }
      }

      td {
        width: 40px;
      }
    }

    .rdtTime td {
      width: 100px;
    }
    .rdtCounters > div {
      float: left;
      margin-right: 10px;
    }
  }
`;