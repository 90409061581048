import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TodaysWinnersHeader from './TodaysWinnersHeader';
import TodaysWinnersBody from './TodaysWinnersBody';
import TodaysWinnersFooter from './TodaysWinnersFooter';

const width = 1800;

const LastWinnersWrapper = styled.div`
  width: ${width}px;
  overflow: hidden;
  font-family: 'agency_fb';

  p {
    padding: 0;
    margin: 0;
  }
`;

class PslLastWinners extends Component {
  render() {
    const { loaded, currentMatchNumber, matches, mapRotation, startTime, timeBetweenMatches, show } = this.props;

    if (!loaded) return null;

    const numOfMatches = (mapRotation || '').length;
    const nextMap = mapRotation[matches.length];

    return (
      <LastWinnersWrapper>
        <TodaysWinnersHeader
          rowWidth={width}
          show={show}
          nextMap={nextMap}
        />
        {
          Array.from(Array(numOfMatches).keys()).map(i => {
            let matchTime = moment.duration(startTime);
            Array.from(Array(i).keys()).forEach(() => {
              matchTime = matchTime.add(timeBetweenMatches);
            });
            return (
              <TodaysWinnersBody
                colWidth={width / numOfMatches}
                index={i}
                matchTime={moment.utc(matchTime.asMilliseconds()).format("HH:mm")}
                numOfMatches={numOfMatches}
                show={show}
                map={mapRotation.substring(i, i + 1)}
                matches={matches}
                currentMatchNumber={currentMatchNumber}
              />
            )
          })
        }
        <TodaysWinnersFooter
          rowWidth={width}
          show={show}
        />
      </LastWinnersWrapper>
    );
  }
}

export default PslLastWinners;
