import gql from 'graphql-tag';

export const createComponent = gql`mutation CreateComponent($shard: String!, $args: InputArguments!) {
  createComponent(shard: $shard, args: $args) {
    token
    show
    name
    opts
    args {
      type
      shard_info
      match
      game
    }
    matches {
      id
      name
      startAt
    }
    teams {
      id
      name
      players {
        id
        firstName
        lastName
        ign
      }
    }
    availableStatistics {
      key
      value
    }
  }
}`;

export const createMapVetoComponent = gql`mutation CreateMapVetoComponent($shard: String!, $args: InputArguments!) {
  createMapVetoComponent(shard: $shard, args: $args) {
    token
    args {
      shard_info
      match
      game
      type
    }
    opts
    name
    show
    availableMaps {
      key
      value
    }
    teams {
      id
      name
    }
    availableRules {
      key
      value
    }
    vetoStatus
    logs {
      map
      type
      mapVoter
      attackers
      defenders
    }
    leftToken
    rightToken
  }
}`;

export const createComponentsBulk = gql`mutation CreateComponentsBulk($shard: String!, $args: [InputArguments!]!) {
  createComponentsBulk(shard: $shard, args: $args) {
    token
    show
    name
    opts
    args {
      type
      shard_info
      match
      game
    }
    matches {
      id
      name
      startAt
    }
    teams {
      id
      name
      players {
        id
        firstName
        lastName
        ign
      }
    }
    availableStatistics {
      key
      value
    }
  }
}`;

export const changeComponent = gql`mutation ChangeComponent($token: String!, $name: String, $show: Boolean, $opts: String, $args: InputArguments) {
  changeComponent(token: $token, name: $name, show: $show, opts: $opts, args: $args) {
    token
    name
    show
    opts
    args {
      type
      shard_info
      match
      game
    }
    matches {
      id
      name
      startAt
    }
    teams {
      id
      name
      players {
        id
        firstName
        lastName
        ign
      }
    }
    availableStatistics {
      key
      value
    }
  }
}`;

export const changeMapVetoComponent = gql`mutation ChangeMapVetoComponent($token: String!, $name: String, $show: Boolean, $opts: String, $vetoStatus: String) {
  changeMapVetoComponent(token: $token, name: $name, show: $show, opts: $opts, vetoStatus: $vetoStatus) {
    token
    args {
      shard_info
      match
      game
      type
    }
    opts
    name
    show
    availableMaps {
      key
      value
    }
    teams {
      id
      name
    }
    availableRules {
      key
      value
    }
    vetoStatus
    logs {
      map
      type
      mapVoter
      attackers
      defenders
    }
    leftToken
    rightToken
  }
}`;

export const changeComponentsShardInfo = gql`mutation ChangeComponentsShardInfo($tokens: [String!]!, $shardInfo: String!) {
  changeComponentsShardInfo(tokens: $tokens, shardInfo: $shardInfo) {
    token
    name
    show
    opts
    args {
      type
      shard_info
      match
      game
    }
    matches {
      id
      name
      startAt
    }
    teams {
      id
      name
      players {
        id
        firstName
        lastName
        ign
      }
    }
    availableStatistics {
      key
      value
    }
  }
}`;

export const removeComponent = gql`mutation RemoveComponent($token: String!) {
  removeComponent(token: $token)
}`;

export const removeMapVetoComponent = gql`mutation RemoveMapVetoComponent($token: String!) {
  removeComponent(token: $token)
}`;

export const mapVetoTeamReady = gql`mutation TeamReady($token: String!, $overlayToken: String!) {
  teamReady(token: $token, overlayToken: $overlayToken) {
    token
    tokenOverlay
    status
    pickValues
    timer
    logs {
      map
      type
      mapVoter
      attackers
      defenders
    }
  }
}`;

export const mapVetoMakePick = gql`mutation MakePick($token: String!, $overlayToken: String!, $status: String!, $value: String!) {
  makePick(token: $token, overlayToken: $overlayToken, status: $status, value: $value) {
    token
    tokenOverlay
    status
    pickValues
    timer
    logs {
      map
      type
      mapVoter
      attackers
      defenders
    }
  }
}`;