import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { COMPONENT_STATE_RULE } from '../../../../constants';
import Multiselect from '../../../../../../components/common/multiselect/Multiselect';

function MapSelect(props) {
  const { component, requestChangeComponent } = props;

  return (
    <FormGroup>
      <Label>Map Pool</Label>
      <Multiselect
        options={component.availableRules}
        selectedValues={component.state[COMPONENT_STATE_RULE]}
        selectionLimit={1}
        onSelect={s => requestChangeComponent(component, { state: { [COMPONENT_STATE_RULE]: s } })}
        onRemove={s => requestChangeComponent(component, { state: { [COMPONENT_STATE_RULE]: s.length ? s : null } })}
        color={styles.tertiaryDark}
        displayValue='value'
        warning={!component.state[COMPONENT_STATE_RULE] && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(MapSelect);
