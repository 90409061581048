// Action for requesting team ready
export const REQUEST_TEAM_READY = 'REQUEST_TEAM_READY';
export const REQUEST_TEAM_READY_PENDING = 'REQUEST_TEAM_READY_PENDING';
export const REQUEST_TEAM_READY_SUCCESS = 'REQUEST_TEAM_READY_SUCCESS';
export const REQUEST_TEAM_READY_ERROR = 'REQUEST_TEAM_READY_ERROR';
// Action for requesting subscription to map veto data
export const SUBSCRIBE_TO_MAP_VETO_DATA = 'SUBSCRIBE_TO_MAP_VETO_DATA';
export const SUBSCRIBE_TO_MAP_VETO_DATA_PENDING = 'SUBSCRIBE_TO_MAP_VETO_DATA_PENDING';
export const SUBSCRIBE_TO_MAP_VETO_DATA_SUCCESS = 'SUBSCRIBE_TO_MAP_VETO_DATA_SUCCESS';
export const SUBSCRIBE_TO_MAP_VETO_DATA_ERROR = 'SUBSCRIBE_TO_MAP_VETO_DATA_ERROR';
// Action for requesting pick
export const REQUEST_MAP_VETO_PICK = 'REQUEST_MAP_VETO_PICK';
export const REQUEST_MAP_VETO_PICK_PENDING = 'REQUEST_MAP_VETO_PICK_PENDING';
export const REQUEST_MAP_VETO_PICK_SUCCESS = 'REQUEST_MAP_VETO_PICK_SUCCESS';
export const REQUEST_MAP_VETO_PICK_ERROR = 'REQUEST_MAP_VETO_PICK_ERROR';
// Action for receiving map veto data
export const SET_MAP_VETO_DATA = 'SET_MAP_VETO_DATA';