import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { ListGroupItem } from 'reactstrap';

export default styled(ListGroupItem)`
  ${props => props.border ? 'border-color: ' + props.border + ';' : ''}

  strong {
    color: ${styles.secondary};
  }
`;