import React from 'react';
import OnslaughtTeamHeadToHead from './OnslaughtLeague/TeamHeadToHead';

const ValorantTeamHeadToHeadOverlay = (props) => {
  const { shard } = props;

  return (
    shard.includes('fs') ? 
    <OnslaughtTeamHeadToHead {...props} />
    :
    shard.includes('ol') ? 
    <OnslaughtTeamHeadToHead {...props} />
    :
    null
  );
}

export default ValorantTeamHeadToHeadOverlay
