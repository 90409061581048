import React from 'react'
import AEL from './acesEsports/TeamLeaderboard'
import OSL from './OnslaughtLeague/TeamLeaderboard'
import SDP from './sdp/TeamLeaderboard'

const ValorantTeamLeaderboardOverlay = (props) => {
  const { shard } = props

  return (
    shard.includes('ael-conq') || shard.includes('test-att') ? 
    <AEL {...props} />
    :
    shard.includes('ol') ? 
    <OSL {...props} />
    :
    shard.includes('sdp') ? 
    <SDP {...props} />
    :
    null
  );
}

export default ValorantTeamLeaderboardOverlay
