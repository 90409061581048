/**
 * ONLY COMPONENTS THAT SUPPORT LIVE DATA CAN HAVE 'LIVE' IN TYPE
 */

// Component types constants
export const PLAYER_STATS_COMPONENT_TYPE = 'player-stats';
export const PLAYER_LEADERBOARD_COMPONENT_TYPE = 'player-leaderboard';
export const PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE = 'player-head-to-head';
export const TEAM_STATS_COMPONENT_TYPE = 'team-stats';
export const TEAM_LEADERBOARD_COMPONENT_TYPE = 'team-leaderboard';
export const LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE = 'live-team-leaderboard';
export const LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE = 'live-survival-leaderboard';
export const LIVE_MAP_TEAMS_COMPONENT_TYPE = 'live-map-teams';
export const LIVE_TEAM_STATS_COMPONENT_TYPE = 'live-team-stats';
export const LIVE_PLAYER_STATS_COMPONENT_TYPE = 'live-player-stats';
export const LIVE_PLAYER_KILLS_COMPONENT_TYPE = 'live-player-kills';
export const LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE = 'live-player-kills-damage';
export const LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE = 'live-team-head-to-head';
export const LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE = 'live-player-leaderboard';
export const TEAM_HEAD_TO_HEAD_COMPONENT_TYPE = 'team-head-to-head';
export const CURRENT_MATCH_COMPONENT_TYPE = 'current-match';
export const TODAYS_WINNERS_COMPONENT_TYPE = 'todays-winners';
export const SCOREBOARD_COMPONENT_TYPE = 'scoreboard';
export const MAP_VETO_COMPONENT_TYPE = 'map-veto';

// Component names constants
export const PLAYER_STATS_COMPONENT_NAME = 'Player Stats';
export const PLAYER_LEADERBOARD_COMPONENT_NAME = 'Player Leaderboard';
export const PLAYER_HEAD_TO_HEAD_COMPONENT_NAME = 'Player Head To Head';
export const TEAM_STATS_COMPONENT_NAME = 'Team Stats';
export const TEAM_LEADERBOARD_COMPONENT_NAME = 'Team Leaderboard';
export const LIVE_TEAM_LEADERBOARD_COMPONENT_NAME = 'Live Team Leaderboard';
export const LIVE_SURVIVAL_LEADERBOARD_COMPONENT_NAME = 'Live Survival Leaderboard';
export const LIVE_MAP_TEAMS_COMPONENT_NAME = 'Live Map Teams';
export const LIVE_TEAM_STATS_COMPONENT_NAME = 'Live Team Stats';
export const LIVE_PLAYER_STATS_COMPONENT_NAME = 'Live Player Stats';
export const LIVE_PLAYER_KILLS_COMPONENT_NAME = 'Live Player Kills';
export const LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_NAME = 'Live Player Kills & Damage';
export const LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_NAME = 'Live Team Head To Head';
export const LIVE_PLAYER_LEADERBOARD_COMPONENT_NAME = 'Live Player Leaderboard';
export const TEAM_HEAD_TO_HEAD_COMPONENT_NAME = 'Team Head To Head';
export const CURRENT_MATCH_COMPONENT_NAME = 'Current Match';
export const TODAYS_WINNERS_COMPONENT_NAME = 'Todays Winners';
export const SCOREBOARD_COMPONENT_NAME = 'Scoreboard';
export const MAP_VETO_COMPONENT_NAME = 'Map Veto';

// Component state variables
export const COMPONENT_STATE_STATS = 'stats';
export const COMPONENT_STATE_MATCH = 'match';
export const COMPONENT_STATE_TEAM = 'team';
export const COMPONENT_STATE_PLAYER = 'player';
export const COMPONENT_STATE_LEFT_TEAM = 'leftTeam';
export const COMPONENT_STATE_RIGHT_TEAM = 'rightTeam';
export const COMPONENT_STATE_LEFT_PLAYER = 'leftPlayer';
export const COMPONENT_STATE_RIGHT_PLAYER = 'rightPlayer';
export const COMPONENT_STATE_MAPS = 'maps';
export const COMPONENT_STATE_RULE = 'rule';
export const COMPONENT_STATE_MAP_ROTATION = 'mapRotation';
export const COMPONENT_STATE_START_TIME = 'startTime';
export const COMPONENT_STATE_TIME_BETWEEN_MATCHES = 'timeBetweenMatches';
export const COMPONENT_STATE_SUBSCRIPTION = 'subscription';
export const COMPONENT_STATE_OVERALL_STATS = 'overall';
export const COMPONENT_STATE_OVERLAY_TYPE = 'overlayType';
export const COMPONENT_STATE_GREEN_BACKGROUND = 'greenBackground';

// Opts state variables
export const OPTS_ID = 'id';
export const OPTS_STATS = 'stats';
export const OPTS_HEAD_TO_HEAD_LEFT = 'leftId';
export const OPTS_HEAD_TO_HEAD_RIGHT = 'rightId';
export const OPTS_MAPS = 'maps';
export const OPTS_RULE = 'rule';
export const OPTS_OTHER_OPTS = 'other_opts';
export const OPTS_SUBSCRIPTION = 'subscription';
export const OPTS_OVERALL_STATS = 'overall';
export const OPTS_OVERLAY_TYPE = 'overlayType';
export const OPTS_GREEN_BACKGROUND = 'greenBackground';

// Default values for state
export const CURRENT_MATCH_DATA = 'current';
export const OVERALL_DATA = 'overall';
export const OVERLAY_TYPE_FULL_SCREEN = 'full-screen';
export const OVERLAY_TYPE_CARD = 'card';
export const OVERLAY_TYPE_MVP = 'mvp';
export const DEFAULT_STATE_VALUES = {
  [COMPONENT_STATE_OVERALL_STATS]: true,
  [COMPONENT_STATE_GREEN_BACKGROUND]: false,
}

// Player stats component state to opts mapping
export const PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_PLAYER]: OPTS_ID,
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Info used to retain state between different dashboard instances
  [COMPONENT_STATE_TEAM]: COMPONENT_STATE_TEAM,
  [COMPONENT_STATE_OVERLAY_TYPE]: OPTS_OVERLAY_TYPE,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Player leadeboard component state to opts mapping
export const PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Team stats component state to opts mapping
export const TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_TEAM]: OPTS_ID,
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Team leadeboard component state to opts mapping
export const TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Team leadeboard component state to opts mapping
export const LIVE_TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  [COMPONENT_STATE_OVERALL_STATS]: OPTS_OVERALL_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Survival leadeboard component state to opts mapping
export const LIVE_SURVIVAL_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  [COMPONENT_STATE_OVERALL_STATS]: OPTS_OVERALL_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Map Teams component state to opts mapping
export const LIVE_MAP_TEAMS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Team Stats component state to opts mapping
export const LIVE_TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  [COMPONENT_STATE_TEAM]: OPTS_ID,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Player Stats component state to opts mapping
export const LIVE_PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  [COMPONENT_STATE_PLAYER]: OPTS_ID,
  [COMPONENT_STATE_TEAM]: COMPONENT_STATE_TEAM,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Player Kills component state to opts mapping
export const LIVE_PLAYER_KILLS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Live Player Kills and Damage component state to opts mapping
export const LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Head to head component state to opts mapping
export const LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  [COMPONENT_STATE_LEFT_TEAM]: OPTS_HEAD_TO_HEAD_LEFT,
  [COMPONENT_STATE_RIGHT_TEAM]: OPTS_HEAD_TO_HEAD_RIGHT,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Player leadeboard component state to opts mapping
export const LIVE_PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_SUBSCRIPTION]: OPTS_SUBSCRIPTION,
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Head to head component state to opts mapping
export const TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_LEFT_TEAM]: OPTS_HEAD_TO_HEAD_LEFT,
  [COMPONENT_STATE_RIGHT_TEAM]: OPTS_HEAD_TO_HEAD_RIGHT,
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};
export const PLAYER_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_LEFT_PLAYER]: OPTS_HEAD_TO_HEAD_LEFT,
  [COMPONENT_STATE_RIGHT_PLAYER]: OPTS_HEAD_TO_HEAD_RIGHT,
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Info used to retain state between different dashboard instances
  [COMPONENT_STATE_LEFT_TEAM]: COMPONENT_STATE_LEFT_TEAM,
  [COMPONENT_STATE_RIGHT_TEAM]: COMPONENT_STATE_RIGHT_TEAM,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Current match component state to opts mapping
export const CURRENT_MATCH_COMPONENT_STATE_TO_OPTS_MAPPING = {
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Todays Winners component state to opts mapping
export const TODAYS_WINNERS_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_MAP_ROTATION]: COMPONENT_STATE_MAP_ROTATION,
  [COMPONENT_STATE_START_TIME]: COMPONENT_STATE_START_TIME,
  [COMPONENT_STATE_TIME_BETWEEN_MATCHES]: COMPONENT_STATE_TIME_BETWEEN_MATCHES,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Scoreboard state to opts mapping
export const SCOREBOARD_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_LEFT_TEAM]: OPTS_HEAD_TO_HEAD_LEFT,
  [COMPONENT_STATE_RIGHT_TEAM]: OPTS_HEAD_TO_HEAD_RIGHT,
  [COMPONENT_STATE_STATS]: OPTS_STATS,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Map veto state to opts mapping
export const MAP_VETO_COMPONENT_STATE_TO_OPTS_MAPPING = {
  [COMPONENT_STATE_MAPS]: OPTS_MAPS,
  [COMPONENT_STATE_RULE]: OPTS_RULE,
  [COMPONENT_STATE_LEFT_TEAM]: OPTS_HEAD_TO_HEAD_LEFT,
  [COMPONENT_STATE_RIGHT_TEAM]: OPTS_HEAD_TO_HEAD_RIGHT,
  // Green background
  [COMPONENT_STATE_GREEN_BACKGROUND]: OPTS_GREEN_BACKGROUND,
};

// Map veto statuses
export const MAP_VETO_STATUS_PICK_MAP = 'pick-map';
export const MAP_VETO_STATUS_BAN_MAP = 'ban-map';
export const MAP_VETO_STATUS_PICK_SIDE = 'pick-side';
export const MAP_VETO_STATUS_WAIT = 'wait';
export const MAP_VETO_STATUS_TO_STRING_MAPPING = {
  [MAP_VETO_STATUS_PICK_MAP]: 'Pick a map',
  [MAP_VETO_STATUS_BAN_MAP]: 'Ban a map',
  [MAP_VETO_STATUS_PICK_SIDE]: 'Pick a starting side',
  [MAP_VETO_STATUS_WAIT]: 'Wait for the opponent team',
}

// Map veto sides
export const MAP_VETO_SIDE_ATTACKERS = 'attackers';
export const MAP_VETO_SIDE_DEFENDERS = 'defenders';
export const MAP_VETO_SIDE_COLORS = {
  [MAP_VETO_SIDE_DEFENDERS]: '#00ffb9',
  [MAP_VETO_SIDE_ATTACKERS]: '#ff4655',
};
export const MAP_VETO_SIDE_MAPPING = {
  left: MAP_VETO_SIDE_ATTACKERS,
  right: MAP_VETO_SIDE_DEFENDERS,
};

// Additional component parameters
export const MAP_VETO_VETO_STATUS = 'vetoStatus';