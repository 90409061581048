import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { ButtonGroup } from 'reactstrap';

export default styled(ButtonGroup)`
  button, button:focus {
    box-shadow: none;
  }

  .dropdown-item, button:hover, i:hover {
    color: ${styles.primary};
  }

  .dropdown-item {
    border-color: ${styles.infoDark};
  }

  .dropdown-item:hover {
    background: ${styles.primary};
    color: ${styles.secondary};
  }

  .dropdown-menu {
    background: ${styles.tertiaryDark};
    border-color: ${styles.infoDark};
    color: ${styles.secondary};
  }
`;