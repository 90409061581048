import React, { Component } from 'react';
import styled from 'styled-components';
import HeadToHeadBody from './HeadToHeadBody';
import HeadToHeadHeader from './HeadToHeadHeader';
import HeadToHeadFooter from './HeadToHeadFooter';

const width = 900;
const margins = 0;

const HeadToHeadWrapper = styled.div`
  width: ${props => props.rowWidth}px;
  overflow: hidden;

  &.head-to-head-show {
    display: block;
    height: 220px;
    animation: fadeIn 0.6s ease 0s forwards, slideDown 1s ease 0.7s forwards;
  
    @keyframes slideDown {
      from {height: 220px;}
      to {height: calc(100vh);}
    }
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {}
    }
  }

  &.head-to-head-hide {
    height: calc(100vh);
    animation: slideUp 1s ease 0s forwards, fadeOut 0.3s ease 1.4s forwards;
  
    @keyframes slideUp {
      from {height: calc(100vh);}
      to {height: 220px;}
    }
  
    @keyframes fadeOut {
      from {}
      to {opacity: 0;}
    }
  }
`;

class HeadToHeadPlayers extends Component {

  render() {
    const { loaded, left, right, opts, show } = this.props;

    if (!loaded || !left || !right) return null;

    return (
      <HeadToHeadWrapper
        rowWidth={width}
        className={ show ? 'head-to-head-show' : 'head-to-head-hide head-to-head-fade-out' }
      >
        <HeadToHeadHeader
          show={show}
          rowWidth={width}
          margins={margins}
        />
        <HeadToHeadBody
          rowWidth={width}
          margins={margins}
          left={left}
          right={right}
          stats={opts.stats || []}
        />
        <HeadToHeadFooter
          rowWidth={width}
          show={show}
        />
      </HeadToHeadWrapper>
    );
  }
}

export default HeadToHeadPlayers;
