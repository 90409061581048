import {
  // Component Names
  PLAYER_STATS_COMPONENT_NAME,
  PLAYER_LEADERBOARD_COMPONENT_NAME,
  // Component Types
  PLAYER_STATS_COMPONENT_TYPE,
  PLAYER_LEADERBOARD_COMPONENT_TYPE,
  // State to opts mappings
  PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
} from '../constants';


import PlayerStatsController from './playerStats/controller/CSGOPlayerStatsController';
import PlayerStatsOverlay from './playerStats/overlay/CSGOPlayerStatsOverlay';
import PlayerLeaderboardController from './playerLeaderboard/controller/CSGOPlayerLeaderboardController';
import PlayerLeaderboardOverlay from './playerLeaderboard/overlay/CSGOPlayerLeaderboardOverlay';

export default {
  PlayerStats: {
    width: 4,
    name: PLAYER_STATS_COMPONENT_NAME,
    type: PLAYER_STATS_COMPONENT_TYPE,
    controller: PlayerStatsController,
    overlay: PlayerStatsOverlay,
    maxStatistics: 5,
    stateOptsMapping: PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  PlayerLeaderboard: {
    width: 4,
    name: PLAYER_LEADERBOARD_COMPONENT_NAME,
    type: PLAYER_LEADERBOARD_COMPONENT_TYPE,
    controller: PlayerLeaderboardController,
    overlay: PlayerLeaderboardOverlay,
    maxStatistics: 1,
    stateOptsMapping: PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
};