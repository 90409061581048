import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const width = 300;

const StyledLogoCol = styled.div`
  background: ${styles.tertiary};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  width: ${width}px;
  margin-left: ${props => props.marginLeft}px;

  img {
    height: 45px;
    margin-bottom: 5px;
  }
`;

function HeadToHeadFooter(props) {

  return (
    <div>
      <StyledLogoCol 
        xs="2" 
        className="text-center"
        marginLeft={props.rowWidth/2 - width/2}
      >
        <img
          src="/assets/img/twire/powered-by-twire-no-background.png"
          alt="tournament logo"
        />
      </StyledLogoCol>
    </div>
  );
}

export default HeadToHeadFooter;