import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { Select } from '../../../../../../components/StyledCoreUI';
import { isInt } from '../../../../../../util/CommonUtil';

function PlayerSelect(props) {
  const { component, label, stateAttribute, parentTeamStateAttribute, requestChangeComponent, playerId } = props;

  return (
    <FormGroup>
      <Label>{ label }</Label>
      <Select
        name={stateAttribute}
        color={styles.tertiaryDark}
        value={component.state[stateAttribute] ? component.state[stateAttribute] : 0}
        onChange={id => {
          requestChangeComponent(component, { state: { [stateAttribute]: (isInt(id) && parseInt(id)) || id } })}
        }
        disabledOptionText='-- select player --'
        options={
          component.state[parentTeamStateAttribute] ?
            (component.teams.find(t => t.id === component.state[parentTeamStateAttribute]) || { players: [] }).players.map(p => {
              return { key: p[playerId || 'id'], value: p.ign };
            })
            :
            []
        }
        warning={!component.state[stateAttribute] && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(PlayerSelect);
