import { 
  REQUEST_ALL_TOURNAMENT_PENDING,
  REQUEST_ALL_TOURNAMENT_ERROR,
  REQUEST_ALL_TOURNAMENT_SUCCESS,
} from './types';
import {
  PUBG_GAME,
  PUBGC_GAME,
  CSGO_GAME,
  VALORANT_GAME,
} from '../../util/Games';
import { REQUEST_CREATE_TOURNAMENT_SUCCESS } from '../tournament/types';

const INITIAL_STATE = {
  loaded: false,
  [PUBG_GAME]: [],
  [PUBGC_GAME]: [],
  [CSGO_GAME]: [],
  [VALORANT_GAME]: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request all tournament pending or error
    case REQUEST_ALL_TOURNAMENT_PENDING:
    case REQUEST_ALL_TOURNAMENT_ERROR:
      return INITIAL_STATE;

    // On request all tournament success
    case REQUEST_ALL_TOURNAMENT_SUCCESS:
      return {
        ...state,
        loaded: true,
        [PUBG_GAME]: action.payload.pubgTournaments,
        [PUBGC_GAME]: action.payload.pubgcTournaments,
        [CSGO_GAME]: action.payload.csgoTournaments,
        [VALORANT_GAME]: action.payload.valorantTournaments,
      };

    // On tournament create success
    case REQUEST_CREATE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        [action.payload.game]: [ action.payload, ...state[action.payload.game] ],
      }

    default:
      return state;
  }
}

export default reducer;