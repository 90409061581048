import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';
import styles from '../../../../../../scss/style.scss';

const StyledHeaderCol = styled.div`
  width: 775px;
  background: ${ styles.pslColor };
  margin: 0 20px;
  padding: 5px 20px;

  span {
    font-size: 33px;
    font-weight: 600;
    line-height: 40px;
  }
`;

const StyledTeamCol = styled.div`
  width: 775px;
  padding: 0 15px;
  margin: 0 20px;
  color: #FFFFFF;

  img {
    height: 40px;
  }
`;

const StyledTeamRow = styled(Row)`
  background: #3e3e3e;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;

  span {
    text-transform: uppercase;
    line-height: 40px;
    font-size: 33px;
    font-weight: 600;
  }
`;

function LeaderboardBody(props) {
  const { leaderboard } = props;
  const firstHalfTeams = leaderboard.slice(0, 8);
  const secondHalfTeams = leaderboard.slice(8, leaderboard.length);
  
  const HeaderCol = () => {
    return (
      <StyledHeaderCol xs="5">
        <Row>
          <Col xs="1">
            <span>#</span>
          </Col>
          <Col xs="2">
            
          </Col>
          <Col xs="5">
            <span>TEAM</span>
          </Col>
          <Col xs="2" className="text-center">
            <span>KILLS</span>
          </Col>
          <Col xs="2" className="text-center">
            <span>TOTAL</span>
          </Col>
        </Row>
      </StyledHeaderCol>
    );
  }

  const TeamCol = (team, rank) => {
    return (
      <StyledTeamRow>
        <Col xs="1">
          <span>{rank}</span>
        </Col>
        <Col xs="2" className="text-center">
          <img 
            src={getTeamLogo(team.name)} 
            alt="team logo" 
            onError={(e) => e.target.src = getTeamLogo('default')}
          />
        </Col>
        <Col xs="5">
          <span>{team.name}</span>
        </Col>
        <Col xs="2" className="text-center">
          <span>{team.totalKills}</span>
        </Col>
        <Col xs="2" className="text-center">
          <span>{team.totalPoints}</span>
        </Col>
      </StyledTeamRow>
    );
  }

  return (
    <>
      <Row>
        <HeaderCol/>
        <HeaderCol/>
      </Row>
      <Row>
        <StyledTeamCol xs="5">
          {
            firstHalfTeams.map((team, i) => {
              return TeamCol(team, i + 1);
            })
          }
        </StyledTeamCol>
        <StyledTeamCol xs="5">
        {
          secondHalfTeams.map((team, i) => {
            return TeamCol(team, i + 9);
          })
        }
        </StyledTeamCol>
      </Row>
    </>
  );
}

export default LeaderboardBody;