import { all, takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {  
  REQUEST_CREATE_COMPONENT,
  REQUEST_CREATE_COMPONENT_PENDING,
  REQUEST_CREATE_COMPONENT_SUCCESS,
  REQUEST_CREATE_COMPONENT_ERROR,
  REQUEST_CREATE_COMPONENTS_BULK,
  REQUEST_CREATE_COMPONENTS_BULK_PENDING,
  REQUEST_CREATE_COMPONENTS_BULK_SUCCESS,
  REQUEST_CREATE_COMPONENTS_BULK_ERROR,
  REQUEST_CHANGE_COMPONENT,
  REQUEST_CHANGE_COMPONENT_PENDING,
  REQUEST_CHANGE_COMPONENT_SUCCESS,
  REQUEST_CHANGE_COMPONENT_ERROR,
  REQUEST_CHANGE_COMPONENT_WITH_VALIDATION,
  REQUEST_REMOVE_COMPONENT,
  REQUEST_REMOVE_COMPONENT_PENDING,
  REQUEST_REMOVE_COMPONENT_SUCCESS,
  REQUEST_REMOVE_COMPONENT_ERROR,
  REQUEST_SHARD_INFO_CHANGE_COMPONENT,
  REQUEST_SHARD_INFO_CHANGE_COMPONENT_PENDING,
  REQUEST_SHARD_INFO_CHANGE_COMPONENT_SUCCESS,
  REQUEST_SHARD_INFO_CHANGE_COMPONENT_ERROR,
  REQUEST_REMOVE_ALL_COMPONENT,
  REQUEST_REMOVE_ALL_COMPONENT_PENDING,
  REQUEST_REMOVE_ALL_COMPONENT_SUCCESS,
  REQUEST_REMOVE_ALL_COMPONENT_ERROR,
  SUBSCRIBE_TO_CHANGE_COMPONENT,
  SUBSCRIBE_TO_CHANGE_COMPONENT_PENDING,
  SUBSCRIBE_TO_CHANGE_COMPONENT_SUCCESS,
  SUBSCRIBE_TO_CHANGE_COMPONENT_ERROR,
} from './types';
import {
  createComponentsBulk,
  changeComponentsShardInfo,
} from '../../graphql/apollo/componentMutations';
import { CREATE_COMPONENT_ERROR, CHANGE_COMPONENT_ERROR, REMOVE_COMPONENT_ERROR } from '../../util/ErrorMessages';
import { DEFAULT_STATE_VALUES } from '../../views/Components/constants';
import { isInt } from '../../util/CommonUtil';
import {
  createComponentMutations,
  changeComponentMutations,
  removeComponentMutations,
} from '../../graphql/apollo/componentTypeMutations';
import {
  changeComponentSubscriptions,
} from '../../graphql/apollo/componentTypeSubscriptions';
import { displayErrorNotification } from '../notification/actions';
import { setComponentState, requestChangeComponent as requestChangeComponentAction } from './actions';

/**
 * Makes a request to server to create new component
 * 
 * @param {*} action 
 */
function* requestCreateComponent(action) {
  yield put({ type: REQUEST_CREATE_COMPONENT_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: createComponentMutations[action.payload.component.type],
      variables: { shard: action.payload.shard, args: action.payload.args }
    })).data;
    const data = result[Object.keys(result)[0]];
    // TODO: fix when endpoint is completed
    data.opts = data.opts ? JSON.parse(data.opts) : null;
    data.name = data.name ? data.name : action.payload.component.name;
    
    yield put({ type: REQUEST_CREATE_COMPONENT_SUCCESS, payload: { ...action.payload.component, ...data, state: {} } });
  } catch (err) {
    yield put({ type: REQUEST_CREATE_COMPONENT_ERROR });
    yield put(displayErrorNotification(CREATE_COMPONENT_ERROR));
  }
}

/**
 * Makes a request to server to create new component
 * 
 * @param {*} action 
 */
function* requestCreateComponentsBulk(action) {
  yield put({ type: REQUEST_CREATE_COMPONENTS_BULK_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: createComponentsBulk,
      variables: { shard: action.payload.shard, args: action.payload.args }
    })).data.createComponentsBulk;
    
    const components = Object.keys(action.payload.components)
      .map(k => action.payload.components[k])
      .filter(c => result.find(rc => rc.args.type === c.type));
    
    yield put({ type: REQUEST_CREATE_COMPONENTS_BULK_SUCCESS, payload: result.map(resultComponent => {
      const componentTemplate = components.find(c => resultComponent.args.type === c.type);
      if (!componentTemplate) return null;
      const state = mapOptsToState(JSON.parse(resultComponent.opts || '{}'), componentTemplate.stateOptsMapping);
      if (resultComponent.args.match) {
        state.match = resultComponent.args.match;
      }
      return {
        ...componentTemplate,
        ...resultComponent,
        // TODO: fix when endpoint is completed
        opts: JSON.parse(resultComponent.opts),
        name: resultComponent.name ? resultComponent.name : componentTemplate.name,
        state: state,
      }
    }).filter(c => c)});
  } catch (err) {
    yield put({ type: REQUEST_CREATE_COMPONENTS_BULK_ERROR });
    yield put(displayErrorNotification(CREATE_COMPONENT_ERROR));
  }
}

/**
 * Makes a request to server to change component
 * 
 * @param {*} action 
 */
function* requestChangeComponent(action) {
  yield put({ type: REQUEST_CHANGE_COMPONENT_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);
    const component = action.payload.component;
    const tournaments = yield select(state => state.tournaments[component.args.game]);

    const result = (yield call([client, 'mutate'], {
      mutation: changeComponentMutations[component.type],
      variables: {
        token: component.token,
        ...action.payload.componentParameters,
      }
    })).data;
    const data = result[Object.keys(result)[0]];
    // TODO: fix when endpoint is completed
    data.opts = data.opts ? JSON.parse(data.opts) : {};
    // TODO: move to server
    data.opts.groupName = getTournamentGroupName(tournaments.find(t => component.args.shard_info.startsWith(t.shard)), component.args.shard_info);
    data.opts.matchName = getMatchName(component, action.payload.state);
    
    yield put({ type: REQUEST_CHANGE_COMPONENT_SUCCESS, payload: {
      ...action.payload.component,
      ...data,
      name: component.name,
      state: { ...component.state, ...action.payload.state },
    } });
  } catch (err) {
    yield put({ type: REQUEST_CHANGE_COMPONENT_ERROR });
    yield put(displayErrorNotification(CHANGE_COMPONENT_ERROR));
  }
}

/**
 * Makes a request to server to change component with state validation
 * 
 * @param {*} action 
 */
function* requestChangeComponentWithValidation(action) {
  const component = action.payload.component;
  addDefaultValues(component);
  const stateValid = Object.keys(component.stateOptsMapping).every(opt => component.state[opt] !== null && component.state[opt] !== undefined) || !Object.keys(component.stateOptsMapping).length;
  if (!stateValid) {
    yield put(setComponentState(component, { showEmptyFieldWarning: true }));
    yield new Promise(resolve => { setTimeout(resolve, 500); });
    yield put(setComponentState(component, { showEmptyFieldWarning: false }));
  } else {
    yield put(requestChangeComponentAction(component, action.payload.changes));
  }
}

/**
 * Makes a request to server to change component with state validation
 * 
 * @param {*} action 
 */
function* subscribeToChangeComponent(action) {
  if (!changeComponentSubscriptions[action.payload.component.type]) return;

  yield put({ type: SUBSCRIBE_TO_CHANGE_COMPONENT_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    
    const result = (yield call([client, 'subscribe'], {
      query: changeComponentSubscriptions[action.payload.component.type],
      variables: { token: action.payload.component.token },
    }));
    result.subscribe({
      next: (result) => action.payload.callback(result.data[Object.keys(result.data)[0]]),
      error: () => {},
    });
    
    yield put({ type: SUBSCRIBE_TO_CHANGE_COMPONENT_SUCCESS });
  } catch (err) {
    yield put({ type: SUBSCRIBE_TO_CHANGE_COMPONENT_ERROR });
  }
}

/**
 * Makes a request to server to remove component
 * 
 * @param {*} action 
 */
function* requestRemoveComponent(action) {
  yield put({ type: REQUEST_REMOVE_COMPONENT_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);

    yield call([client, 'mutate'], {
      mutation: removeComponentMutations[action.payload.type],
      variables: { token: action.payload.token }
    });
    
    yield put({ type: REQUEST_REMOVE_COMPONENT_SUCCESS, payload: action.payload.token});
  } catch (err) {
    yield put({ type: REQUEST_REMOVE_COMPONENT_ERROR });
    yield put(displayErrorNotification(REMOVE_COMPONENT_ERROR));
  }
}

/**
 * Makes a request to server to remove component
 * 
 * @param {*} action 
 */
function* requestRemoveAllComponents(action) {
  yield put({ type: REQUEST_REMOVE_ALL_COMPONENT_PENDING });
  try {
    // Calling the server
    // TODO: remove all the components from server if necessary
    
    yield put({ type: REQUEST_REMOVE_ALL_COMPONENT_SUCCESS });
  } catch (err) {
    yield put({ type: REQUEST_REMOVE_ALL_COMPONENT_ERROR });
    yield put(displayErrorNotification(REMOVE_COMPONENT_ERROR));
  }
}

/**
 * Makes a request to server to change shard
 * 
 * @param {*} action 
 */
function* requestShardInfoChangeComponent(action) {
  yield put({ type: REQUEST_SHARD_INFO_CHANGE_COMPONENT_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);
    const components = yield select(state => state.dashboard.components);

    const result = (yield call([client, 'mutate'], {
      mutation: changeComponentsShardInfo,
      variables: {
        tokens: components.map(c => c.token),
        shardInfo: action.payload,
      }
    })).data.changeComponentsShardInfo;

    yield put({ type: REQUEST_SHARD_INFO_CHANGE_COMPONENT_SUCCESS, payload: components.map(c => {
      const resultComponent = result.find(rc => rc.token === c.token);
      const state = mapOptsToState(JSON.parse(resultComponent.opts || '{}'), c.stateOptsMapping);
      return {
        ...c,
        ...resultComponent,
        // TODO: fix when endpoint is completed
        opts: JSON.parse(resultComponent.opts),
        name: resultComponent.name ? resultComponent.name : c.name,
        // TODO: fix ugly code
        state: {...(resultComponent.args.type.toLowerCase().includes('live') ? state : {})},
      }
    })});

  } catch (err) {
    yield put({ type: REQUEST_SHARD_INFO_CHANGE_COMPONENT_ERROR });
    yield put(displayErrorNotification(CHANGE_COMPONENT_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_CREATE_COMPONENT, requestCreateComponent),
    takeLatest(REQUEST_CREATE_COMPONENTS_BULK, requestCreateComponentsBulk),
    takeLatest(REQUEST_CHANGE_COMPONENT, requestChangeComponent),
    takeLatest(REQUEST_CHANGE_COMPONENT_WITH_VALIDATION, requestChangeComponentWithValidation),
    takeEvery(SUBSCRIBE_TO_CHANGE_COMPONENT, subscribeToChangeComponent),
    takeLatest(REQUEST_REMOVE_COMPONENT, requestRemoveComponent),
    takeLatest(REQUEST_REMOVE_ALL_COMPONENT, requestRemoveAllComponents),
    takeLatest(REQUEST_SHARD_INFO_CHANGE_COMPONENT, requestShardInfoChangeComponent),
  ]);
}

/**
 * Mapps component opts to state according to mapping rules
 * 
 * @param {*} state 
 * @param {*} stateOptsMapping 
 */
function mapOptsToState(opts, stateOptsMapping) {
  const optsStateMapping = _.invert(stateOptsMapping);
  const state = {};
  Object.keys(opts || {}).forEach(key => {
    if (optsStateMapping[key]) {
      state[optsStateMapping[key]] = opts[key];
    }
  });
  return state;
}

/**
 * Returns group name
 * 
 * @param {*} tournament 
 * @param {*} shardInfo 
 */
function getTournamentGroupName(tournament, shardInfo) {
  const ESCAPE_FILTERS = ['year', 'season', 'region'];
  let groupName = '';
  if (tournament.filter) {
    const filters = tournament.filter;
    const filterPhases = shardInfo.substring(tournament.shard.length + 1).split('-');
    filters.forEach((f, i) => {
      const filter = i === 0 ? f : f[filterPhases.slice(0, i).join('-')];
      if (filter && !ESCAPE_FILTERS.includes(filter.name.toLowerCase())) {
        groupName += filter.fields[filterPhases[i]] + ' ';
      }
    })
  }

  return groupName.trim();
}

/**
 * Returns match name
 * 
 * @param {*} component 
 * @param {*} state 
 */
function getMatchName(component, state) {
  const matchId = (state || {}).match;
  if (matchId && isInt(matchId)) {
    return component.matches.find(m => m.id === matchId).name;
  } else if (component.matches && component.matches.length) {
    return component.matches[0].name;
  }
  return null;
}

/**
 * Adds default values to component state if they are missing
 * 
 * @param {*} component 
 */
function addDefaultValues(component) {
  Object.keys(component.stateOptsMapping).forEach(opt => {
    if (component.state[opt] === null || component.state[opt] === undefined) {
      component.state[opt] = DEFAULT_STATE_VALUES[opt];
    }
  });
}