import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo, numberForDisplay } from '../../../../../../util/CommonUtil';
import TeamStat from './TeamStat';
import moment from 'moment';

const TEAM_MAPPING = {
  "Toxic Players (RUS)": "Toxic Players",
  "BigBossLayf": "BBL Esports",
  "Natus Vincere": "NAVI",
  "Orgless (EU)": "Orgless",
}

const LeaderboardTable = styled.table`
  background: url('/assets/img/esl/IG_10_In_Game_Stats_Team_Stats_Background.png');
  background-repeat: no-repeat;
  width: 220px;
  height: 571px;
  font-family: 'ESL Legend';
  font-weight: 600;
  border-collapse: separate;

  &.bc-show {
    margin-left: -240px;
    animation: slideRight 0.3s ease 0.5s forwards;
  
    @keyframes slideRight {
      from {margin-left: -240px;}
      to {margin-left: 32px;}
    }
  }

  &.bc-hide {
    margin-left: 32px;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 32px;}
      to {margin-left: -240px;}
    }
  }

  td {
    &.team-logo {
      padding: 30px 20px 0px;
      height: 190px;

      div {
        background: #1d1d1b;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -10px;
      }

      img {
        height: 150px;
      }
    }

    &.team-name {
      padding: 10px 20px 0px;
      color: #000;
      font-size: 40px;
      line-height: 36px;
      height: 80px;
      vertical-align: initial;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 215px;
    }

    &.team-stats {
      padding: 0px 20px 0px;
      height: 50px;
    }
  }
`;

class EslLiveTeamStats extends Component {
  render() {
    const { team, show } = this.props;

    return (
      team ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <tr>
            <td className="team-logo text-center">
              <div>
                <img
                  src={getTeamLogo(`${team.name}`)}
                  onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                  alt="team-logo"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="team-name text-center">
              { TEAM_MAPPING[team.name] ? TEAM_MAPPING[team.name] : team.name }
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Chicken Dinners'
                value={team.wins}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Avg. Kills'
                value={numberForDisplay((team.totalKills - team.currentKills) / (team.numberOfMatches - 1 || 1))}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Avg. Dmg'
                value={parseInt((team.totalDamage - team.currentDamage) / (team.numberOfMatches - 1 || 1))}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Avg. Time Survived'
                value={
                  moment().startOf('day')
                    .seconds(team.timeSurvived / team.numberOfMatches)
                    .format('mm:ss')
                }
                last={true}
              />
            </td>
          </tr>
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default EslLiveTeamStats;