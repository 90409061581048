import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  background: rgba(0, 0, 0, 0.5);

  td {
    background: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    text-transform: uppercase;
    font-family: 'ESL Legend';
    font-weight: 400;
    height: 43px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    vertical-align: bottom;
    padding-bottom: 9px;

    &:last-child {
      padding-right: 10px;
    }
  }
`;

function LeaderboardHeader(props) {
  return (
    <Wrapper>
      <td></td>
      <td>Kill</td>
      <td>Damage</td>
    </Wrapper>
  );
}

export default LeaderboardHeader;