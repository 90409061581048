import React, { Component } from 'react';
import { getCsgoPlayerImage, getCsgoTeamLogo } from '../../../../../../../util/CommonUtil';
import { STATS_NAME_MAP } from '../constants';

import './ElisaPlayerStatsCard.scss';

class ElisaPlayerStatsCard extends Component {
  render() {
    const { show, player, opts } = this.props;

    return (
      <div 
        className={`player-stats-card ${show ? 'bc-open' : 'bc-close'}`}
        teamName={player.teamName}
      >
        <div className="team_logo">
          <img
            src={getCsgoTeamLogo(player.teamName)}
            onError={e => e.target.src = getCsgoTeamLogo('default')}
            alt="team-logo"
          />
        </div>
        <table>
          <tbody>
            <tr>
              <td rowSpan="3" className="text-center player">
                <img
                  className="player_image" 
                  src={getCsgoPlayerImage(player.teamName + '-' + player.name)}
                  onError={e => e.target.src = getCsgoPlayerImage('default')}
                  alt="player"
                />
                <div className="player_image_gradient">
                  <p className="player_name">{player.firstName} {player.lastName}</p>
                  <p className="player_nickname">{player.name}</p>
                </div>
              </td>
              <td className="stat_wrapper">
                <h1 className="stat_title">{STATS_NAME_MAP[opts.stats[0].key]}</h1>
              </td>
              <td className="line_wrapper">
                <div className="line"/>
              </td>
              <td className="stat_value_wrapper">
                <div className="points_wrapper">
                  <div className="points_container">
                    <div className="points"><p className="point_title">{
                      opts.stats[0].key === 'hsPercentage' ? 
                      `${Math.round(player[opts.stats[0].key] * 100)} %`
                      :
                      Math.round(player[opts.stats[0].key])
                    }</p></div>
                  </div>
                </div>
              </td>
            </tr>
            {
              opts.stats.slice(1, 3).map(s => {
                return (
                  <tr>
                    <td className="stat_wrapper">
                      <h1 className="stat_title">{ STATS_NAME_MAP[s.key] }</h1>
                    </td>
                    <td className="line_wrapper">
                      <div className="line"/>
                    </td>
                    <td className="stat_value_wrapper">
                      <div className="points_wrapper">
                        <div className="points_container">
                          <div className="points"><p className="point_title">{
                            s.key === 'hsPercentage' ?
                            `${Math.round(parseFloat(player[s.key]) * 100)} %`
                            :
                            s.key === 'kD' || s.key === 'kR' ?
                            `${player[s.key]}`
                            :
                            Math.round(player[s.key])
                          }</p></div>
                        </div>
                      </div>
                    </td>          
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default ElisaPlayerStatsCard;