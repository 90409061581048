import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const TeamWrapper = styled(Row)`
  background: ${styles.tertiary};
  text-transform: uppercase;

  img {
    height: 220px;
  }

  h1 {
    font-size: 70px;
  }

  h4 {
    font-size: 30px;
  }

  span {
    color: ${styles.info};
    font-size: 20px;
  }
`;

const VsCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function HeadToHeadBody(props) {
  const { left, right, stats } = props;

  const TeamNames = ({leftTeam, rightTeam}) => {
    return (
      <Row>
        <Col xs="5">
          <h3>{leftTeam.teamName}</h3>
        </Col>
        <Col xs="5" className="offset-2">
          <h3>{rightTeam.teamName}</h3>
        </Col>
      </Row>
    );
  }

  const TeamLogos = ({leftTeam, rightTeam}) => {
    return (
      <Row>
        <Col xs="5">
          <Row>
            <Col xs="12" className="mt-4">
              <img 
                src={
                  leftTeam.logo ?
                  leftTeam.logo
                  :
                  getTeamLogo(leftTeam.teamName)
                }
                alt="team logo"
                onError={(e) => e.target.src = getTeamLogo('default.png')}
              />
            </Col>
          </Row>
        </Col>
        <VsCol xs="2">
          <h1>VS</h1>
        </VsCol>
        <Col xs="5">
          <Row>
            <Col xs="12" className="mt-4">
              <img 
                src={
                  rightTeam.logo ?
                  rightTeam.logo
                  :
                  getTeamLogo(rightTeam.teamName)
                }
                alt="team logo"
                onError={(e) => e.target.src = getTeamLogo('default.png')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  const TeamDetails = ({leftTeam, rightTeam, stats}) => {

    return (
      <Row className="mb-4">
        <Col xs="12">
          {
            (stats || []).map(s => {
              return (
                <Row className="mt-4">
                  <Col xs="3" className="offset-1">
                    <h4>{Math.round(leftTeam[s.key])}</h4>
                  </Col>
                  <VsCol xs="4">
                    <span>{s.value}</span>
                  </VsCol>
                  <Col xs="3">
                    <h4>{Math.round(rightTeam[s.key])}</h4>
                  </Col>
                </Row>
              );
            })
          }
        </Col>
      </Row>
    );
  }

  return (
    <TeamWrapper
      className="text-center"
    >
      <Col xs="12" className="mt-4">
        <TeamNames
          leftTeam={left}
          rightTeam={right}
        />
        <TeamLogos
          leftTeam={left}
          rightTeam={right}
        />
        <TeamDetails
          leftTeam={left}
          rightTeam={right}
          stats={stats}
        />
      </Col>
    </TeamWrapper>
  );
}

export default HeadToHeadBody;