import React, { Component } from 'react';
import styled from 'styled-components';

const TeamWrapper = styled.table`
  span {
    position: relative;
  }

  td {
    overflow: hidden;
    ${props => props.index % 2 === 0 ? 'background: #00000080;' : ''}

    &.spacing {
      background: transparent !important;
    }
  }

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease ${props => props.index/props.numberOfRows + 0.1}s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    p, span {
      bottom: -100px;
      animation: slideUp 0.3s ease ${props => props.index/props.numberOfRows + 0.1}s forwards;
  
      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease ${props => 1 - props.index/props.numberOfRows}s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    p, span {
      bottom: 0px;
      animation: slideDown 0.3s ease ${props => 1 - props.index/10}s forwards;
  
      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }
`;

class LeaderboardTeam extends Component {
  render() {
    const { leftTeam, leftTeamRank, rightTeam, rightTeamRank, index, numberOfRows, show, exportOverlay, side } = this.props;

    return (
      <TeamWrapper
        id={exportOverlay ? `team-tr-${index}` : undefined}
        className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}
        index={index}
        numberOfRows={numberOfRows}
        side={side}
      >
        <tbody>
          <tr>
            <td className="td-rank rank text-center">
              <div>
                <span>{ leftTeamRank }.</span>
              </div>
            </td>
            {
              numberOfRows > 8 ?
              <td className="td-match match text-center">
                <div>
                  <span>{ leftTeam.numberOfMatches }</span>
                </div>
              </td>
              :
              null
            }
            <td className="td-team team text-center">
              <div>
                <span>{ leftTeam.name }</span>
              </div>
            </td>
            <td className="td-placement-points placement-points text-center">
              <div>
                <span>{ leftTeam.totalPoints - leftTeam.totalKills }</span>
              </div>
            </td>
            <td className="td-kill-points kill-points text-center">
              <div>
                <span>{ leftTeam.totalKills }</span>
              </div>
            </td>
            <td className="td-total-points total-points text-center">
              <div>
                <span>{ leftTeam.totalPoints }</span>
              </div>
            </td>
            <td className="spacing"></td>
            <td className="td-rank rank text-center">
              <div>
                <span>{ rightTeamRank }.</span>
              </div>
            </td>
            {
              numberOfRows > 8 ?
              <td className="td-match match text-center">
                <div>
                  <span>{ rightTeam.numberOfMatches }</span>
                </div>
              </td>
              :
              null
            }
            <td className="td-team team text-center">
              <div>
                <span>{ rightTeam.name }</span>
              </div>
            </td>
            <td className="td-placement-points placement-points text-center">
              <div>
                <span>{ rightTeam.totalPoints - rightTeam.totalKills }</span>
              </div>
            </td>
            <td className="td-kill-points kill-points text-center">
              <div>
                <span>{ rightTeam.totalKills }</span>
              </div>
            </td>
            <td className="td-total-points total-points text-center">
              <div>
                <span>{ rightTeam.totalPoints }</span>
              </div>
            </td>
          </tr>
        </tbody>
      </TeamWrapper>
    );
  }
}

export default LeaderboardTeam;
