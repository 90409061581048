import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardTitle from './LeaderboardTitle';

const LeaderboardTable = styled.table`
  &.bc-show {
    margin-left: -250px;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -250px;}
      to {margin-left: 32px;}
    }
  }

  &.bc-hide {
    margin-left: 32px;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 32px;}
      to {margin-left: -250px;}
    }
  }
`;

class EslLivePlayerKills extends Component {
  render() {
    const { loaded, players, show } = this.props;
    if (players.length < 6) Array.from(Array(6-players.length)).forEach(() => players.push({ ign: 'None', teamInitial: 'None', totalKills: 0, totalDamage: 0 }));

    return (
      loaded ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <LeaderboardTitle />
          <LeaderboardHeader />
          {
            players.slice(0, 6).map((p, i) => {
              return (
                <LeaderboardBody
                  player={p}
                  rank={i+1}
                />
              );
            })
          }
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default EslLivePlayerKills;