import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import TextInput from '../../../common/base/controller/inputs/TextInput';
import { COMPONENT_STATE_MAP_ROTATION, COMPONENT_STATE_START_TIME, COMPONENT_STATE_TIME_BETWEEN_MATCHES } from '../../../constants';
import { PUBG_GAME } from '../../../../../util/Games';

class PUBGTodaysWinnersController extends Component {
  render() {
    const { component } = this.props;

    return (
      <ControllerBase
        game={PUBG_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <TextInput
              component={component}
              label={'Map Rotation'}
              placeholder={'MMEEE'}
              stateField={COMPONENT_STATE_MAP_ROTATION}
            />
          </Col>
          <Col xs="6">
            <TextInput
              type="time"
              component={component}
              label={'Start Time'}
              placeholder={'20:00'}
              stateField={COMPONENT_STATE_START_TIME}
            />
          </Col>
          <Col xs="6">
            <TextInput
              type="time"
              component={component}
              label={'Time Between Matches'}
              placeholder={'01:00'}
              stateField={COMPONENT_STATE_TIME_BETWEEN_MATCHES}
            />
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

export default PUBGTodaysWinnersController;
