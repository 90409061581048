import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import {  
  REQUEST_TEAM_SEARCH,
  REQUEST_TEAM_SEARCH_ERROR,
  REQUEST_TEAM_SEARCH_SUCCESS,
  REQUEST_TEAM_SEARCH_PENDING,
} from './types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import { teamSearch } from '../../graphql/apollo/tournamentQueries';
import { displayErrorNotification } from '../notification/actions';

/**
 * Makes a request to server for all my tournaments
 * 
 * @param {*} action 
 */
function* requestTournament(action) {
  yield put({ type: REQUEST_TEAM_SEARCH_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'query'], {
      query: teamSearch,
      variables: action.payload,
    })).data.teamSearch;

    yield put({ type: REQUEST_TEAM_SEARCH_SUCCESS, payload: result });
  } catch (err) {
    yield put({ type: REQUEST_TEAM_SEARCH_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TEAM_SEARCH, requestTournament),
  ]);
}