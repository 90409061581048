import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Pagination } from 'reactstrap';

export default styled(Pagination)`
  ul {
    justify-content: center;
  }

  .page-link {
    background: ${styles.tertiary};
    border-color: ${styles.tertiaryDark};

    &:hover {
      border-color: ${styles.tertiaryDark};
    }
  }

  .active .page-link {
    border-color: ${styles.tertiaryDark};

    &:hover {
      color: ${styles.tertiaryDark};
    }
  }
`;