import React from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.div`
  font-size: 40px;
  text-transform: uppercase;

  table {
    tr {
      &.component-show {
        transform: rotate3d(1, 0, 0, 90deg);
        animation: 
          rotateIn 0.5s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1}s forwards,
          fadeIn 1s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1}s forwards;
  
        @keyframes rotateIn {
          from {transform: rotate3d(1, 0, 0, 90deg);}
          to {transform: rotate3d(1, 0, 0, 0deg);}
        }
  
        @keyframes fadeIn {
          from {opacity: 0;}
          to {opacity: 1;}
        }
      }

      &.component-hide {
        transform: rotate3d(1, 0, 0, 0deg);
        animation: 
          rotateOut 0.5s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1}s forwards,
          fadeOut 1s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1}s forwards;
  
        @keyframes rotateOut {
          from {transform: rotate3d(1, 0, 0, 0deg);}
          to {transform: rotate3d(1, 0, 0, 90deg);}
        }
  
        @keyframes fadeOut {
          from {opacity: 1;}
          to {opacity: 0;}
        }
      }
    }

    td {
      height: 70px;
    }
  }

  .team, .kills, .points {
    padding: 0;

    div {
      background: #404040;
    }
  }

  .team {
    width: 470px;

    div {
      padding-left: 130px;
      position: relative;
      overflow: hidden;

      img {
        height: 100px;
        position: absolute;
        top: -20px;
        left: 10px;
        transform: rotate(30deg);

        &:first-child {
          filter: blur(15px);
        }
      }
    }
  }

  .rank {
    width: 70px;
    background: #ff8317;
  }
  
  .kills, .points {
    width: 100px;
  }
`;

function LeaderboardTeam(props) {
  const { team, rank, show, exportOverlay } = props;

  return (
    <td>
      <Wrapper rank={rank}>
        <table>
          <tbody>
            <tr className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}>
              <td className="rank text-center">{ rank }</td>
              <td className="team">
                <div>
                  <img 
                    src={exportOverlay && team.exportLogo ? team.exportLogo : getTeamLogo(team.team)} 
                    alt="team logo" 
                    onError={(e) => e.target.src = getTeamLogo('default')}
                  />
                  <img 
                    src={exportOverlay && team.exportLogo ? team.exportLogo : getTeamLogo(team.team)} 
                    alt="team logo" 
                    onError={(e) => e.target.src = getTeamLogo('default')}
                  />
                  { team.team }
                </div>
              </td>
              <td className="kills text-center">
                <div>{ team.kills }</div>
              </td>
              <td className="points text-center">
                <div>{ team.totalPoints }</div>
              </td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    </td>
  );
}

export default LeaderboardTeam;