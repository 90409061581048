import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const StyledHeaderCol = styled.div`
  width: 760px;
  background: ${props => styles[props.shard + 'Color'] ? styles[props.shard + 'Color'] : styles.primaryLight};
  padding: 5px 25px;
  color: #FFF;
  border-top-${props => props.radius}-radius: 25px;

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
  }
`;

function LeaderboardHeader(props) {
  const HeaderCol = ({ radius }) => {
    return (
      <StyledHeaderCol 
        radius={radius} 
        xs="5"
        shard={props.shard.split('-')[0]}
      >
        <Row>
          <Col xs="3" className="text-center">
            <Row>
              <Col xs="5" className="offset-1">
                <span>#</span>
              </Col>
            </Row>
          </Col>
          <Col xs="6">
            <span>TEAM</span>
          </Col>
          <Col xs="3" className="text-left">
            <Row>
              <Col xs="5" className="offset-1">
                <span>KILLS</span>
              </Col>
              <Col xs="5">
                <span>TOTAL</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledHeaderCol>
    );
  }

  return (
    <Row style={{ marginLeft: 20 }}>
      <HeaderCol radius="left"/>
      <HeaderCol radius="right"/>
    </Row>
  );
}

export default LeaderboardHeader;