import React from 'react';
import OnslaughtLeaguePlayerStats from './onslaughtLeague/TeamStats';

const ValorantTeamStatsOverlay = (props) => {
  const { shard } = props;

  return (
    shard.includes('fs') ? 
    <OnslaughtLeaguePlayerStats {...props} />
    :
    shard.includes('ol') ? 
    <OnslaughtLeaguePlayerStats {...props} />
    :
    null
  );
}

export default ValorantTeamStatsOverlay;
