import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {  
  REQUEST_TEAM_READY,
  REQUEST_TEAM_READY_PENDING,
  REQUEST_TEAM_READY_SUCCESS,
  REQUEST_TEAM_READY_ERROR,
  SUBSCRIBE_TO_MAP_VETO_DATA,
  SUBSCRIBE_TO_MAP_VETO_DATA_PENDING,
  SUBSCRIBE_TO_MAP_VETO_DATA_SUCCESS,
  SUBSCRIBE_TO_MAP_VETO_DATA_ERROR,
  REQUEST_MAP_VETO_PICK,
  REQUEST_MAP_VETO_PICK_PENDING,
  REQUEST_MAP_VETO_PICK_SUCCESS,
  REQUEST_MAP_VETO_PICK_ERROR,
} from './types';
import { mapVetoTeamReady, mapVetoMakePick } from '../../graphql/apollo/componentMutations';
import { subscribeToMapVeto } from '../../graphql/apollo/componentSubscriptions';
import { setMapVetoData } from './actions';

/**
 * Makes a request to server for team ready
 * 
 * @param {*} action 
 */
function* requestTeamReady(action) {
  yield put({ type: REQUEST_TEAM_READY_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'query'], {
      query: mapVetoTeamReady,
      variables: {
        token: action.payload.teamToken,
        overlayToken: action.payload.overlayToken,
      },
    })).data.teamReady;
    
    yield put({ type: REQUEST_TEAM_READY_SUCCESS, payload: {
      ...result,
      teamToken: action.payload.teamToken,
      overlayToken: action.payload.overlayToken,
    } });
  } catch (err) {
    yield put({ type: REQUEST_TEAM_READY_ERROR });
  }
}

/**
 * Makes a request to server to subscribe to updates
 * 
 * @param {*} action 
 */
function* subscribeToMapVetoData(action) {
  yield put({ type: SUBSCRIBE_TO_MAP_VETO_DATA_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    
    const result = (yield call([client, 'subscribe'], {
      query: subscribeToMapVeto,
      variables: {
        token: action.payload.teamToken,
      },
    }));
    result.subscribe({
      next: (result) => action.payload.callback(result.data.subscribeToMapVeto),
      error: () => {},
    });
    
    yield put({ type: SUBSCRIBE_TO_MAP_VETO_DATA_SUCCESS });
  } catch (err) {
    yield put({ type: SUBSCRIBE_TO_MAP_VETO_DATA_ERROR });
  }
}

/**
 * Makes a request to server to subscribe to updates
 * 
 * @param {*} action 
 */
function* requestMapVetoPick(action) {
  yield put({ type: REQUEST_MAP_VETO_PICK_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const { status, teamToken, overlayToken } = yield select(state => state.mapVeto);

    const result = (yield call([client, 'mutate'], {
      mutation: mapVetoMakePick,
      variables: {
        token: teamToken,
        overlayToken: overlayToken,
        status: status,
        value: action.payload,
      },
    })).data.makePick;
    
    yield put({ type: REQUEST_MAP_VETO_PICK_SUCCESS });
    yield put(setMapVetoData(result));
  } catch (err) {
    yield put({ type: REQUEST_MAP_VETO_PICK_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TEAM_READY, requestTeamReady),
    takeLatest(SUBSCRIBE_TO_MAP_VETO_DATA, subscribeToMapVetoData),
    takeLatest(REQUEST_MAP_VETO_PICK, requestMapVetoPick),
  ]);
}