// Twire assets URL
export const TWIRE_ASSETS_URL = "https://assets.twire.gg/";

// Auth constants
export const AUTH_TOURNAMENT_ORGANIZER_GROUP = 'TOURNAMENT_ORGANIZER';

// Tournament types
export const TOURNAMENT_TYPE_TOURNAMENT = 'tournament';
export const TOURNAMENT_TYPE_SCRIM = 'scrim';
export const TOURNAMENT_TYPES = {
  [TOURNAMENT_TYPE_TOURNAMENT]: TOURNAMENT_TYPE_TOURNAMENT.toUpperCase(),
  [TOURNAMENT_TYPE_SCRIM]: TOURNAMENT_TYPE_SCRIM.toUpperCase(),
};

// Tournament servers
export const TOURNAMENT_SERVER_EVENT = 'event';
export const TOURNAMENT_SERVER_LIVE = 'live';
export const TOURNAMENT_SERVERS = {
  [TOURNAMENT_SERVER_EVENT]: TOURNAMENT_SERVER_EVENT.toUpperCase(),
  [TOURNAMENT_SERVER_LIVE]: TOURNAMENT_SERVER_LIVE.toUpperCase(),
};

// Tournament regions
export const TOURNAMENT_REGION_GLOBAL = 'global';
export const TOURNAMENT_REGION_GLOBAL_NAME = 'Global';
export const TOURNAMENT_REGION_EUROPE = 'eu';
export const TOURNAMENT_REGION_EUROPE_NAME = 'Europe';
export const TOURNAMENT_REGION_NA = 'na';
export const TOURNAMENT_REGION_NA_NAME = 'North America';
export const TOURNAMENT_REGION_AM = 'am';
export const TOURNAMENT_REGION_AM_NAME = 'Americas';
export const TOURNAMENT_REGION_LA = 'la';
export const TOURNAMENT_REGION_LA_NAME = 'South America';
export const TOURNAMENT_REGION_ASIA = 'asia';
export const TOURNAMENT_REGION_ASIA_NAME = 'Asia';
export const TOURNAMENT_REGION_OCE = 'oce';
export const TOURNAMENT_REGION_OCE_NAME = 'Oceania';
export const TOURNAMENT_REGION_APAC = 'apac';
export const TOURNAMENT_REGION_APAC_NAME = 'APAC';
export const TOURNAMENT_REGIONS = {
  [TOURNAMENT_REGION_GLOBAL]: TOURNAMENT_REGION_GLOBAL_NAME.toUpperCase(),
  [TOURNAMENT_REGION_EUROPE]: TOURNAMENT_REGION_EUROPE_NAME.toUpperCase(),
  [TOURNAMENT_REGION_NA]: TOURNAMENT_REGION_NA_NAME.toUpperCase(),
  [TOURNAMENT_REGION_AM]: TOURNAMENT_REGION_AM_NAME.toUpperCase(),
  [TOURNAMENT_REGION_LA]: TOURNAMENT_REGION_LA_NAME.toUpperCase(),
  [TOURNAMENT_REGION_ASIA]: TOURNAMENT_REGION_ASIA_NAME.toUpperCase(),
  [TOURNAMENT_REGION_OCE]: TOURNAMENT_REGION_OCE_NAME.toUpperCase(),
  [TOURNAMENT_REGION_APAC]: TOURNAMENT_REGION_APAC_NAME.toUpperCase(),
};

// Tournament tiers
export const TOURNAMENT_TIERS = {
  's': 's',
  'a': 'a',
  'b': 'b',
  'c': 'c',
}

// Players statistics
export const PLAYER_STATISTICS = {
  kills: 'Kills',
  assists: 'Assists',
  damageDealt: 'Damage Dealt',
  damageTaken: 'Damage Taken',
  dbnos: 'Knocks',
  revives: 'Revives',
  headshotKills: 'Headshot Kills',
  killSteals: 'Stolen Kills',
  killsStolenFrom: 'Kills stolen from player',
  swimDistance: 'Swim Distance',
  walkDistance: 'Walk Distance',
  rideDistance: 'Ride Distance',
  longestKill: 'Longest Kill',
  timeSurvived: 'Time Survived',
  killStreaks: 'Kill Streaks',
  heals: 'Heals',
  boosts: 'Boosts',
  vehicleDestroys: 'Vehicle Destroys',
}

// Players statistics texts
export const TOP_PLAYER_STATISTICS = {
  kills: 'Top Kill Leaders',
  assists: 'Top Assisters',
  damageDealt: 'Top Damage Dealers',
  damageTaken: 'Most Damage Taken',
  dbnos: 'Top Knockers',
  revives: 'Top Revivers',
  headshotKills: 'Top Headshot Killers',
  killSteals: 'Top Kill Stealers',
  killsStolenFrom: 'Most Kills Stolen From Players',
  swimDistance: 'Top Swimmers',
  walkDistance: 'Top Walkers',
  rideDistance: 'Top Drivers',
  longestKill: 'Longest Kill Leaders',
  timeSurvived: 'Time Survived',
  killStreaks: 'Kill Streaks',
  heals: 'Heals',
  boosts: 'Boosts',
  vehicleDestroys: 'Vehicle Destroys',
}

// Head To Head constants
export const HEAD_TO_HEAD_TEAM_TYPE = 'teams';
export const HEAD_TO_HEAD_PLAYER_TYPE = 'players';

// Head to head details
export const HEAD_TO_HEAD_TEAM_DETAILS = {
  kills: 'Total Kills',
  damageDealt: 'Total Damage Dealt',
  avgRank: 'Average Placement',
  avgPoints: 'Average Points'
}
export const HEAD_TO_HEAD_PLAYER_DETAILS = {
  kills: 'Kills',
  damageDealt: 'Damage Dealt',
  assists: 'Assists',
  dbnos: 'Knocks'
}

// PUBG game modes
export const PUBG_GAME_MODES = [
  'esports-squad-fpp',
  'esports-squad-tpp',
  'esports-duo-fpp',
  'esports-duo-tpp',
  'esports-solo-fpp',
  'esports-solo-tpp',
  'squad-fpp',
  'duo-fpp',
  'solo-fpp',
  'normal-squad-fpp',
  'normal-duo-fpp',
  'normal-solo-fpp'
];
export const PUBG_DEFAULT_GAME_MODE = 'esports-squad-fpp';
export const PUBG_SERVERS = ['live', 'event'];
export const PUBG_DEFAULT_SERVER = 'live';

// PUBG Console platforms
export const PUBGC_XBOX = 'xbox';
export const PUBGC_PSN = 'psn';
export const PUBGC_PLATFORMS = [PUBGC_XBOX, PUBGC_PSN];

// Stage filter types (match means that filter will not be shown on leaderboard page but only on matches page)
export const STAGE_FILTER_TYPE_ALL = 'all';
export const STAGE_FILTER_TYPE_MATCH = 'match';
export const STAGE_FILTER_TYPE_HIDDEN = 'hidden';
export const STAGE_FILTER_TYPE_OVERALL = 'overall';

export const PUBG_SUPER20_RULESET = 'super20';
export const PUBG_SUPER22_RULESET = 'super22';
export const PUBG_SUPER21_RULESET = 'super21';
export const PUBG_WWCD_RULESET = 'wwcd';
export const PUBG_SMASH_RULESET = 'smash';
// Rulesets that can be used for PUBG for scoring
export const PUBG_RULESETS = {
  [PUBG_SUPER22_RULESET]: 'SUPER 22',
  [PUBG_SUPER20_RULESET]: 'SUPER',
  [PUBG_WWCD_RULESET]: 'WWCD',
  [PUBG_SUPER21_RULESET]: 'MC',
  [PUBG_SMASH_RULESET]: 'SMASH (Beta)',
}

export const PUBG_DEFAULT_RULESET = PUBG_SUPER22_RULESET;