import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { InputGroupAddon } from 'reactstrap';

export default styled(InputGroupAddon)`
  span {
    background: ${styles.tertiaryDark};
    border-color: ${styles.tertiary};
    color: ${styles.secondary};
  }
`;