import React from 'react';
import styled from 'styled-components';
import { getTeamLogo, isTeamDeath, sortByPlayerStatus } from '../../../../../../util/CommonUtil';

const TeamRow = styled.tr`
  text-transform: uppercase;
  width: 297px !important;
  max-height: 30px !important;

  &:nth-child(even) {
    background: rgba(0, 0, 0, 0.2);
  }

  &.border-top {
    border-top: 1px solid #00ffae !important;
  }

  &.border-bottom {
    border-bottom: 1px solid #00ffae !important;
  }

  &.border-side {
    border-right: 1px solid #00ffae !important;
  }

  &.team-death span {
    opacity: 0.5;
  }

  &.team-death table {
    opacity: 0;
  }

  td {
    max-height: 30px;
    padding: 1.25px;
    padding-top: 1.67px;

    img {
      height: 20px !important;
      margin-top: -2px;
    }
  }

  td.team-rank {
    min-width: 50px !important;
    background: rgba(0, 0, 0, 0.5);
  }

  td.team-logo {
    min-width: 30px !important;
  }

  td.team-initial {
    min-width: 35px !important;
  }

  td.team-blank {
    min-width: 7px !important;
  }

  td.team-alive {
    min-width: 50px !important;
  }

  td.team-points {
    min-width: 58px !important;
  }

  td.team-kills {
    min-width: 58px !important;
  }

  .team-alive {
    padding: 0 10px;
    td {
      background: none;
      padding: 3px 2px;
      div {
        width: 3px;
        height: 20px;
      }
    }

    .alive {
      background: #eff923;
    }
    .death {
      background: #000000;
    }
    .knocked {
      background: #00ffae;
    }
  }
`;

function LeaderboardBody(props) {
  const { team, rank } = props;

  return (
    <TeamRow className={`${isTeamDeath(team) ? 'team-death' : ''}`}>
      <td className="team-rank"><span>#{ rank }</span></td>
      <td className="team-logo">
        <img
          src={getTeamLogo(team.name)}
          onError={e => e.target.src = getTeamLogo('default')}
          alt='team-logo'
        />
      </td>
      <td className="team-initial"><span>{ team.initial }</span></td>
      <td className="team-blank"></td>
      <td className="team-alive">
        <table>
          <tbody>
            <tr>
              {
                team.players.sort(sortByPlayerStatus).map(i => {
                  return (
                    <td>
                      <div className={i.status}></div>
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </td>
      <td className="team-points"><span>{ team.totalPoints }</span></td>
      <td className="team-kills"><span>{ team.currentKills }</span></td>
    </TeamRow>
  );
}

export default LeaderboardBody;