import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const StyledHeaderRow = styled.div`
  width: ${props => props.rowWidth}px;
  background: ${props => styles[props.tournament + 'Color'] ? styles[props.tournament + 'Color'] : styles.primaryLight};
  color: #FFF;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    text-transform: uppercase;
  }
`;

function HeadToHeadHeader(props) {

  return (
    <StyledHeaderRow
      rowWidth={props.rowWidth}
      className="text-center"
      shard={props.shard.split('-')[0]}
    >
      <span>Head To Head</span>
    </StyledHeaderRow>
  );
}

export default HeadToHeadHeader;