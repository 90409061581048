import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import StatisticsSelect from '../../../common/base/controller/inputs/StatisticsSelect';
import MatchSelect from '../../../common/base/controller/inputs/MatchSelect';
import TeamSelect from '../../../common/base/controller/inputs/TeamSelect';
import PlayerSelect from '../../../common/base/controller/inputs/PlayerSelect';
import {
  COMPONENT_STATE_LEFT_PLAYER,
  COMPONENT_STATE_LEFT_TEAM,
  COMPONENT_STATE_RIGHT_PLAYER,
  COMPONENT_STATE_RIGHT_TEAM,
} from '../../../constants';
import { VALORANT_GAME } from '../../../../../util/Games';

class ValorantPlayerHeadToHeadController extends Component {
  render() {
    const { component } = this.props;

    return (
      <ControllerBase
        game={VALORANT_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <StatisticsSelect component={component} />
          </Col>
          <Col xs="12">
            <MatchSelect component={component} />
          </Col>
          <Col xs="12" md="6">
            <TeamSelect
              component={component}
              label={'Left Team'}
              stateAttribute={COMPONENT_STATE_LEFT_TEAM}
              stateToMergeOnChange={{ [COMPONENT_STATE_LEFT_PLAYER]: null }}
            />
          </Col>
          <Col xs="12" md="6">
            <PlayerSelect
              component={component}
              label={'Left Player'}
              stateAttribute={COMPONENT_STATE_LEFT_PLAYER}
              parentTeamStateAttribute={COMPONENT_STATE_LEFT_TEAM}
            />
          </Col>
          <Col xs="12" md="6">
            <TeamSelect
              component={component}
              label={'Right Team'}
              stateAttribute={COMPONENT_STATE_RIGHT_TEAM}
              stateToMergeOnChange={{ [COMPONENT_STATE_RIGHT_PLAYER]: null }}
            />
          </Col>
          <Col xs="12" md="6">
            <PlayerSelect
              component={component}
              label={'Right Player'}
              stateAttribute={COMPONENT_STATE_RIGHT_PLAYER}
              parentTeamStateAttribute={COMPONENT_STATE_RIGHT_TEAM}
            />
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

export default ValorantPlayerHeadToHeadController;
