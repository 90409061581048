import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardPlayer from './LeaderboardPlayer';

const LeaderboardWrapper = styled.div`
  width: 1920px;
  height: 765px;
  font-family: 'SonySketchEF';
  background: url('/assets/img/being/live_player_kills_background.png');

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.3s ease 0s forwards;
    
    .twire-logo {
      opacity: 0;
      animation: fadeIn 0.3s ease 0s forwards;
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.3s ease 2s forwards;
    
    .twire-logo {
      opacity: 1;
      animation: fadeOut 0.3s ease 2s forwards;
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }

  .wrapper-table {
    position: relative;
    top: 220px;

    .w-384 {
      width: 384px;
    }
  }

  .twire-logo {
    margin-top: 40px;
  }
`;

class BeingPlayerLeaderBoard extends Component {
  render() {
    const { loaded, players, show } = this.props;

    if (!loaded) return null;

    const topPlayers = players.slice(0, 5);

    return (
      topPlayers.length ?
      <LeaderboardWrapper
        className={ show ? 'component-show' : 'component-hide' }
      >
        <table
          className="wrapper-table"
        >
          <tbody>
            <tr>
            {
              topPlayers.map((p, i) => {
                return (
                  <td className="w-384 player-talbe-wrapper">
                    <LeaderboardPlayer
                      player={p}
                      rank={i+1}
                      show={show}
                    />
                  </td>
                );
              })
            }
            </tr>
            <tr>
              <td
                colSpan="5"
                className="text-center"
              >
                <img
                  className="twire-logo"
                  src={'/assets/img/twire/powered-by-twire-no-background.png'}
                  alt="twire-logo"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default BeingPlayerLeaderBoard;
