import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  td {
    height: 37px;
    width: 224px;
    background: url('/assets/img/protality6/header.png');
  }
`;

function LeaderboardHeader(props) {

  return (
    <Wrapper
      colors={props.overlayColor}
    >
      <td colSpan="5">
      </td>
    </Wrapper>
  );
}

export default LeaderboardHeader;