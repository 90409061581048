import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardPlayer from './LeaderboardPlayer';
import LeaderboardFooter from './LeaderboardFooter';

const playerWidth = 350;
const playerMargin = 10;

const LeaderboardWrapper = styled.div`
  margin: 15px 30px;
  width: ${props => props.rowWidth}px;
  font-family: 'agency_fb';
`;

class PslPlayerLeaderBoard extends Component {
  render() {
    const { loaded, players, show, opts } = this.props;

    if (!loaded) return null;

    const stat = opts.stats ? opts.stats[0] : {};
    const topPlayers = players.slice(0, 5);
    const width = playerWidth * topPlayers.length + playerMargin * (topPlayers.length - 1);

    return (
      topPlayers.length ?
      <LeaderboardWrapper
        rowWidth={width}
      >
        <LeaderboardHeader
          rowWidth={width}
          headerWidth={width / 1.5}
          show={show}
          groupName={opts.groupName}
          matchName={opts.matchName}
        />
        {
          topPlayers.map((p, i) => {
            return (
              <LeaderboardPlayer
                rank={i + 1}
                player={p}
                stat={stat}
                rowWidth={playerWidth}
                margin={i !== topPlayers.length - 1 ? playerMargin : 0}
                numOfPlayers={5}
                show={show}
              />
            );
          })
        }
        <LeaderboardFooter
          rowWidth={width}
          show={show}
        />
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default PslPlayerLeaderBoard;
