import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo } from '../../../../../../util/CommonUtil';
import maps from '../../../../../../assets/map/mapMapping';

const teamLogoSize = 220;
const padding = 20;
const height = 450;
const margin = 12;

const Wrapper = styled.div`
  width: ${props => props.colWidth - margin}px;
  margin-right: ${margin/2}px;
  margin-left: ${margin/2}px;
  float: left;
  overflow: hidden;
  text-transform: uppercase;

  &.team-show {
    height: 0;
    animation: open-team .75s ease ${ props => props.delayShow * 0.3 + 0.6 }s forwards;

    @keyframes open-team {
      from {height: 0;}
      to {height: ${height}px;}
    }
  }

  &.team-hide {
    height: ${height}px;
    animation: close-team .75s ease ${ props => props.delayHide * 0.3 + 0.8 }s forwards;

    @keyframes close-team {
      from {height: ${height}px;}
      to {height: 0px;}
    }
  }

  .empty {
    opacity: .3;
  }

  .map-row {
    font-size: 38px;
    background: ${ styles.pslColor };
  }
`;

const TeamRow = styled(Row)`
  background: rgb(26,125,162);
  background: #3e3e3e;
  color: ${styles.secondary};
  padding: 10px;

  .match-name {
    font-size: 22px;
    font-weight: 200;
    line-height: 22px;
  }

  .match-winner {
    font-size: 35px;
    line-height: 55px;
  }
`;

const LogoRow = styled(Row)`
  background: url(/assets/img/ael/${props => maps[props.map].toLowerCase()}.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 600;
  height: 258px;
  color: ${styles.secondary};

  img {
    height: ${teamLogoSize}px;
    margin: ${padding}px ${props => (props.colWidth - teamLogoSize) / 2}px;
    z-index: 5;
    position: absolute;
    left: 7px;
  }

  span {
    position: relative;
    z-index: 10;
  }

  .overlay {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

function LastWinnersBody(props) {
  const { index, matches, colWidth, numOfMatches, map, matchTime, show } = props;
  let { currentMatchNumber } = props;
  matches.forEach(lw => {
    if (lw.teamName) {
      currentMatchNumber--;
    }
  });
  
  const determineTeamNameSize = () => {
    if (matches[index] && matches[index].teamName) {
      const teamNameLength = matches[index].teamName.length;
      if (teamNameLength > 10) {
        return 'small';
      } else if (teamNameLength > 13) {
        return 'x-small';
      } else if (teamNameLength > 16) {
        return 'xx-small';
      }
    }
  };

  return (
    <Wrapper
      colWidth={colWidth}
      margin={ 0 }
      className={ show ? 'team-show' : 'team-hide' }
      delayShow={index}
      delayHide={numOfMatches - index}
    >
      <Row>
        <Col xs="12" className="map-row text-center mb-2">
          <p>{ maps[map] }</p>
        </Col>
      </Row>
      <LogoRow
        className="text-center mb-2"
        colWidth={colWidth}
        map={map}
      >
        <Col xs="12" className="winning-team">
          {
            matches[index] && matches[index].teamName ?
            <>
              <div className="overlay"/>
              <img 
                src={getTeamLogo(matches[index] && matches[index].teamName ? matches[index].teamName : getTeamLogo('default'))} 
                alt="team logo" 
                onError={(e) => e.target.src = getTeamLogo('default')}
              />
            </>
            :
            null
          }
        </Col>
      </LogoRow>
      <TeamRow
        className="text-center"
        index={index}
      >
        <Col xs="12">
          <p className="match-name">{ matches[index] && matches[index].teamName ? 'Winner' : `Match ${ index + currentMatchNumber }`}</p>
        </Col>
        <Col xs="12">
          <p className={`match-winner ${ determineTeamNameSize() }`}>
            { matches[index] && matches[index].teamName ? matches[index].teamName : matchTime }
          </p>
        </Col>
      </TeamRow>
    </Wrapper>
  );
}

export default LastWinnersBody;