import React from 'react';
import styled from 'styled-components';
import { isTeamDeath, sortByPlayerStatus } from '../../../../../../util/CommonUtil';

const TeamRow = styled.div`
  text-transform: uppercase;
  width: ${props => props.width}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 22px;
  line-height: 22px;
  background: url('/assets/img/pjc22_1/team-background.png');


  .td {
    width: ${props => props.width/5}px;
    height: 37px;
    align-items: center;
    display: flex;
  }

  .team-rank {
    color: #f29f00;
    padding-right: 12px;
    padding-left: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    text-align: right !important;

    p {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .team-points {
    justify-content: center;
  }

  .team-alive {
    padding: 0 6px;
    td {
      background: none;
      padding: 3px 2px;
      div {
        width: 4px;
        height: 20px;
      }
    }

    .alive {
      background: #f29f00;
    }
    .death {
      background: #48484840;
    }
    .knocked {
      background: #d50001;
    }
  }

  &.team-death {
    background: #939393;
  }
`;

function LeaderboardBody(props) {
  const { team, rank, width } = props;

  return (
    <TeamRow
      className={isTeamDeath(team) ? 'team-death' : ''}
      width={width}
    >
      <div className={`td team-rank text-right ${isTeamDeath(team) ? 'team-death' : ''}`}>
          <p>{ rank }</p>
      </div>
      <div className={`td team-initial text-left ${isTeamDeath(team) ? 'team-death' : ''}`}>{ team.initial }</div>
      <div className={`td team-alive ${isTeamDeath(team) ? 'team-death' : ''}`}>
        <table>
          <tbody>
            <tr>
              {
                team.players.sort(sortByPlayerStatus).map(i => {
                  return (
                    <td>
                      <div className={i.status}></div>
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className={`td team-points ${isTeamDeath(team) ? 'team-death' : ''}`}>{ team.totalKills }</div>
      <div className={`td team-points ${isTeamDeath(team) ? 'team-death' : ''}`}>{ team.totalPoints }</div>
    </TeamRow>
  );
}

export default LeaderboardBody;