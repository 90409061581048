import React, { Component } from 'react';
import styled from 'styled-components';
import HeadToHeadHeader from './HeadToHeadHeader';
import HeadToHeadBody from './HeadToHeadBody';
import HeadToHeadFooter from './HeadToHeadFooter';

const width = 900;
const height = 700;

const HeadToHeadWrapper = styled.div`
  width: ${props => props.rowWidth}px;
  overflow: hidden;
  font-family: 'muli';

  &.head-to-head-show {
    display: block;
    height: 0;
    animation: fadeIn 0.6s ease 0s forwards, slideDown 1s ease 0.3s forwards;
  
    @keyframes slideDown {
      from {height: 0;}
      to {height: ${height}px;}
    }
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {}
    }
  }

  &.head-to-head-hide {
    height: ${height}px;
    animation: slideUp 1s ease 0s forwards, fadeOut 0.3s ease .8s forwards;
  
    @keyframes slideUp {
      from {height: ${height}px;}
      to {height: 0;}
    }
  
    @keyframes fadeOut {
      from {}
      to {opacity: 0;}
    }
  }

  &.no-animation {
    height: ${height}px;
  }
`;

class HeadToHeadPlayers extends Component {

  render() {
    const { loaded, left, right, opts, show, shard, exportAsImage } = this.props;

    return (
      loaded ?
      <HeadToHeadWrapper
        rowWidth={width}
        className={ exportAsImage ? 'no-animation' : show ? 'head-to-head-show' : 'head-to-head-hide head-to-head-fade-out' }
      >
        <HeadToHeadHeader
          shard={shard}
          rowWidth={width}
        />
        <HeadToHeadBody
          rowWidth={width}
          left={left}
          right={right}
          stats={opts.stats}
        />
        <HeadToHeadFooter
          rowWidth={width}
        />
      </HeadToHeadWrapper>
      :
      null
    );
  }
}

export default HeadToHeadPlayers;
