import React from 'react'
import OSL from './onslaughtLeague/PlayerStats'
import SDP from './sdp/PlayerStats'

const ValorantPlayerStatsOverlay = (props) => {
  const { shard } = props;

  return (
    shard.includes('sdp') ? 
    <SDP {...props} />
    :
    shard.includes('ol') ? 
    <OSL {...props} />
    :
    null
  );
}

export default ValorantPlayerStatsOverlay
