import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const StyledTeamCol = styled.div`
  width: 760px;
  padding: 0 15px;
  color: #FFFFFF;

  img {
    height: 40px;
  }
`;

const StyledTeamRow = styled(Row)`
  background: ${styles.tertiary};
  padding: ${props => props.numberOfTeams > 20 ? '5px 5px' : '10px 10px'};
  border-top-${props => props.radius}-radius: 25px;
  border-bottom-${props => props.radius}-radius: 25px;

  span {
    text-transform: uppercase;
    line-height: 40px;
    font-size: 20px;
    font-weight: 600;
  }
`;

function LeaderboardBody(props) {
  const { leaderboard } = props;
  if (leaderboard.length % 2 !== 0) leaderboard.push({ team: '-', kills: '-', points: '' });
  const numberOfHalfTeams = leaderboard.length/2;
  const firstHalfTeams = leaderboard.slice(0, numberOfHalfTeams);
  const secondHalfTeams = leaderboard.slice(numberOfHalfTeams, leaderboard.length);

  const TeamCol = (team, rank, radius) => {
    return (
      <StyledTeamRow radius={radius} numberOfTeams={leaderboard.length}>
        <Col xs="3" className="text-center">
          <Row>
            <Col xs="5" className="offset-1">
              <span>{rank}</span>
            </Col>
            <Col xs="5">
              <img 
                src={
                  team.logo ?
                  team.logo
                  :
                  getTeamLogo(team.name)
                } 
                alt="team logo" 
                onError={(e) => e.target.src = getTeamLogo('default')}
              />
            </Col>
          </Row>
        </Col>
        <Col xs="6">
          <span>{team.name}</span>
        </Col>
        <Col xs="3" className="text-left">
          <Row>
            <Col xs="5" className="offset-1">
              <span>{team.totalKills < 0 ? 0 : team.totalKills}</span>
            </Col>
            <Col xs="5">
              <span>{team.totalPoints}</span>
            </Col>
          </Row>
        </Col>
      </StyledTeamRow>
    );
  }

  return (
    <Row style={{ marginLeft: 20 }}>
      <StyledTeamCol xs="5" showBorder={true} radius="left">
        {
          firstHalfTeams.map((team, i) => {
            return team.name ? TeamCol(team, i + 1) : null;
          })
        }
      </StyledTeamCol>
      <StyledTeamCol xs="5" radius="right">
      {
        secondHalfTeams.map((team, i) => {
          return team.name ? TeamCol(team, i + numberOfHalfTeams + 1) : null;
        })
      }
      </StyledTeamCol>
    </Row>
  );
}

export default LeaderboardBody;