import React from 'react';
import styled from 'styled-components';
import { getTeamLogo, numberForDisplay } from '../../../../../../util/CommonUtil';

const WIDTH = 909;
const HEIGHT = 431;

const Wrapper = styled.div`
  .overlay {
    position: fixed;
    top: 568px;
    left: ${props => props.marginLeft}px;
    width: ${WIDTH}px;
    height: ${HEIGHT}px;

    display: flex;
    flex-wrap: wrap;
    
    font-family: noto-black;
    font-size: 48px;
    line-height: 48px;
    overflow: hidden;

    .small-font {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 0;

      span {
        border-bottom: 2px solid red;
      }
    }

    .left-side, .right-side {
      display: flex;
      flex-wrap: wrap;
    }

    .right-side {
      width: 524px;
      display: flex;
      flex-wrap: wrap;

      >div.team-name {
        width: 100%;
        height: 175px;
      }

      >div {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        p {
          width: 100%;
        }
      }

      .team-kills, .team-damage, .team-points {
        margin-bottom 45px;
      }
    }

    .left-side {
      width: 385px;
      justify-content: center;
      align-items: center;
    }

    background: url(/assets/img/pjc21/team-head-to-head-background.png);
    border-bottom: 2px solid red;

    &.bc-show {
      height: 0px;
      animation: slideUp 0.3s ease 0.1s forwards,
        fadeIn 0.1s ease 0s forwards;
    
      @keyframes slideUp {
        from {height: 0px;}
        to {height: ${HEIGHT}px;}
      }
      
      @keyframes fadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  
    &.bc-hide {
      height: ${HEIGHT}px;
      animation: slideDown 0.3s ease 0s forwards,
        fadeOut 0.1s ease 0.3s forwards;
    
      @keyframes slideDown {
        from {height: ${HEIGHT}px;}
        to {height: 0px;}
      }
      
      @keyframes fadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }
`;

const TEAM_NAME_MAP = {
  'Zeta Division': 'ZETA DIVISION',
  'Matsui Gaming Club': '松井GAMING CLUB'
}

function Team(props) {
  const { show, team, marginLeft, opts } = props;

  return (
    <Wrapper
      marginLeft={marginLeft}
    >
      <div className={`overlay ${show ? 'bc-show' : 'bc-hide'}`}>
        <div className="left-side">
          <div className="team-logo">
            <img
              src={getTeamLogo(`pjc-${team.teamName}`)}
              onError={e => e.target.src = getTeamLogo('default')}
              alt="team logo"
            />
          </div>
        </div>
        <div className="right-side">
          <div className="team-name">
            <p>{ TEAM_NAME_MAP[team.teamName] || team.teamName }</p>
          </div>
          <div className="team-kills">
            <p className="small-font"><span>AVERAGE KILLS</span></p>
            <p>{ numberForDisplay(team.kills / (opts.selectedSpecificMatch ? 1 : opts.numberOfMatches)) }</p>
          </div>
          <div className="team-damage">
            <p className="small-font"><span>AVERAGE DAMAGE</span></p>
            <p>{ numberForDisplay(team.damageDealt / (opts.selectedSpecificMatch ? 1 : opts.numberOfMatches)) }</p>
          </div>
          <div className="team-points">
            <p className="small-font"><span>POINT</span></p>
            <p>{ team.totalPoints || 0 }</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Team;