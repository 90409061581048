import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';

const LeaderboardWrapper = styled.div`
  width: 1775px;
  font-family: 'osl5-2';
  color: #FFFFFF;
`;

class ProtalityLivePlayerKillsDamage extends Component {
  render() {
    const { loaded, players, show } = this.props;

    if (!loaded) return null;
    const leaderboard = players.slice(0, 5);

    return (
      leaderboard.length ?
      <LeaderboardWrapper>
        <LeaderboardBody
          show={show}
          leaderboard={leaderboard}
        />
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default ProtalityLivePlayerKillsDamage;
