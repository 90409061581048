import React, { Component } from 'react';
import styled from 'styled-components';

const TeamWrapper = styled.table`
  span {
    position: relative;
  }

  td {
    overflow: hidden;
  }

  td.title-left-td {
    font-size: 38px;
    padding-left: 18px;
  }

  td.title-right-td {
    font-size: 38px;
    padding-right: 30px;
  }

  .spacing {
    width: 14px;
  }

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease 0s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    span {
      bottom: -100px;
      animation: slideUp 0.3s ease 0s forwards;
  
      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease ${props => props.numberOfRows * 0.1 + 0.3}s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    span {
      bottom: 0px;
      animation: slideDown 0.3s ease ${props => props.numberOfRows * 0.1 + 0.3}s forwards;
  
      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }

  .noto-font {
    font-family: 'noto-bold';
  }
`;

class LeaderboardHeader extends Component {
  render() {
    const { opts, show, numberOfRows, exportOverlay } = this.props;
    const { selectedSpecificMatch, numberOfMatches } = opts;

    return (
      <TeamWrapper
        className={`header-table ${ exportOverlay ? '' : show ? 'component-show' : 'component-hide' }`}
        numberOfRows={numberOfRows}
      >
        <tbody>
          <tr>
            <td
              colSpan="6"
              className="text-left title-left-td"
            ><span>PUBG JAPAN CHALLENGE</span></td>
            <td
              colSpan="5"
              className="text-right title-right-td"
              ><span><span className="noto-font"></span> Grand Final Match { selectedSpecificMatch ? numberOfMatches-30 || 1 : `1-${numberOfMatches-30 || 1}` }</span></td>
          </tr>
          <tr>
            <td className="th-rank text-center"><span>RANK</span></td>
            <td className="th-team"><span>TEAM</span></td>
            <td className="th-wins text-center"><span>WWCD</span></td>
            <td className="th-kills text-center"><span>KILLS</span></td>
            <td className="spacing"></td>
            <td className="th-rank text-center"><span>RANK</span></td>
            <td className="th-team"><span>TEAM</span></td>
            <td className="th-wins text-center"><span>WWCD</span></td>
            <td className="th-kills text-center"><span>KILLS</span></td>
          </tr>
        </tbody>
      </TeamWrapper>
    );
  }
}

export default LeaderboardHeader;
