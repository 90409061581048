import React from 'react';
import { connect } from 'react-redux';
import Default from './default/TwireCurrentMatch';

const PUBGCurrentMatchOverlay = (props) => {
  const { shard } = props;

  return (
    shard.includes('psl') ? 
    <Default {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  currentMatch: state.overlay.pubg ? state.overlay.pubg.currentMatch : null,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGCurrentMatchOverlay);
