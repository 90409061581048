import React from 'react';
import styled from 'styled-components';
import {
  Col,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button } from '../../StyledCoreUI';
import { Link } from 'react-router-dom';

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  p {
    margin: 0;
  }
`;

function VerifiedTO(props) {
  return (
    <Row>
      <Col xs="12">
        <Alert color="danger">
          <Row>
            <Col xs="9" md="10">
              <TextContainer>
                <p><FontAwesomeIcon icon={faExclamationTriangle} /> Your turnaments will be visible only with direct link on twire.gg since you are not verified tournament organizers.</p>
              </TextContainer>
            </Col>
            <Col xs="3" md="2" className="text-right">
              <Link to={`/app/create-tournaments-guidelines`}>
                <Button outline>Become verified</Button>
              </Link>
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  );
}

export default VerifiedTO;