import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Modal } from 'reactstrap';

export default styled(Modal)`
  background: ${styles.tertiaryDark};
  color: ${styles.secondary};

  .modal-header {
    background: ${styles.primary};
  }

  .modal-header, .modal-footer {
    border: none;
  }

  .modal-body, .modal-footer {
    background: ${styles.tertiaryDark};
    border-left: 1px solid ${styles.primary};
    border-right: 1px solid ${styles.primary};
  }

  .modal-footer {
    border-bottom: 1px solid ${styles.primary};
  }
`;