import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo, numberForDisplay } from '../../../../../../util/CommonUtil';
import TeamStat from './TeamStat';

const LeaderboardTable = styled.table`
  background: url('/assets/img/pjc/team-stats.png');
  width: 265px;
  height: 571px;
  font-family: machine, noto-bold;
  font-weight: 600;
  border-collapse: separate;

  &.bc-show {
    margin-left: -280px;
    animation: slideRight 0.3s ease 0.5s forwards;
  
    @keyframes slideRight {
      from {margin-left: -280px;}
      to {margin-left: 32px;}
    }
  }

  &.bc-hide {
    margin-left: 32px;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 32px;}
      to {margin-left: -280px;}
    }
  }

  td {
    width: 130px;

    &.team-logo {
      padding: 0px 0px 0px;
      height: 150px;

      div {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        height: 150px;
      }
    }

    &.team-name {
      padding: 35px 0px 0px;
      color: #FFF;
      font-size: 40px;
      line-height: 36px;
      height: 80px;
      vertical-align: initial;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.team-stats {
      padding: 0px 10px 0px;
      height: 50px;
    }
  }
`;

class EslLiveTeamStats extends Component {
  render() {
    const { team, show } = this.props;

    return (
      team ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <tr>
            <td
              colSpan="2"
              className="team-name text-center"
            >
              { team.name }
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="team-logo text-center"
            >
              <div>
                <img
                  src={getTeamLogo(`${team.name}`)}
                  onError={e => e.target.src = getTeamLogo('default')}
                  alt="team-logo"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='WWCD'
                value={team.wins}
              />
            </td>
            <td className="team-stats">
              <TeamStat
                name='Rank'
                value={numberForDisplay(team.currentPlacement)}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Kills'
                value={numberForDisplay(team.totalKills)}
                last={true}
              />
            </td>
            <td className="team-stats">
              <TeamStat
                name='Dmg'
                value={parseInt(team.totalDamage)}
                last={true}
              />
            </td>
          </tr>
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default EslLiveTeamStats;