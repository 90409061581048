import gql from 'graphql-tag';

export const getPlayerStats = gql`query GetPlayerStats($token: String!) {
  getPlayerStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      firstName
      lastName
      ign
      teamName
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      characters
      country {
        name
        iso
      }
    }
    pubg {
      firstName
      lastName
      ign
      teamName
      country {
        name
        iso
      }
      kills
      assists
      deaths
      damageDealt
      damageTaken
      knocks
      revives
      headshotKills
      stolenKills
      killsStolenFromPlayer
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroyed
    }
    csgo {
      assists
      avgArmorLeft
      avgBurningTime
      avgDmg
      avgEquipmentValue
      avgFlashedTime
      avgHealthLeft
      deaths
      firstName
      hsKills
      hsPercentage
      id
      kD
      kR
      kills
      lastName
      maxDmg
      maxEquipmentValue
      name
      roundsAceKills
      roundsFourKills
      roundsThreeKills
      teamName
      totalBurningTime
      totalDmg
      totalEquipmentValue
      totalFlashedTime
    }
  }
}`;

export const getTeamStats = gql`query GetTeamStats($token: String!) {
  getTeamStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      teamName
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      country {
        name
        iso
      }
    }
    pubg {
      teamName
      country {
        name
        iso
      }
      kills
      assists
      deaths
      damageDealt
      damageTaken
      knocks
      revives
      headshotKills
      stolenKills
      killsStolenFromPlayer
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroyed
    }
  }
}`;

export const getPlayerHeadToHead = gql`query GetPlayerHeadToHead($token: String!) {
  getPlayerHeadToHead(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      valorant {
        firstName
        lastName
        ign
        teamName
        kills
        assists
        deaths
        esr
        firstBloods
        firstDeaths
        econRating
        adr
        hsProcentage
        plants
        defuses
        aces
        multikills
        clutches
        kd
        characters
        country {
          name
          iso
        }
      }
      pubg {
        firstName
        lastName
        ign
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
      }
    }
    right{
      valorant {
        firstName
        lastName
        ign
        teamName
        kills
        assists
        deaths
        esr
        firstBloods
        firstDeaths
        econRating
        adr
        hsProcentage
        plants
        defuses
        aces
        multikills
        clutches
        kd
        characters
        country {
          name
          iso
        }
      }
      pubg {
        firstName
        lastName
        ign
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
      }
    }
  }
}`;

export const getTeamHeadToHead = gql`query GetTeamHeadToHead($token: String!) {
  getTeamHeadToHead(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      pubg {
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
        totalPoints
      }
    }
    right{
      pubg {
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
        totalPoints
      }
    }
  }
}`;

export const getPlayerLeaderboard = gql`query GetPlayerLeaderboard($token: String!) {
  getPlayerLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      firstName
      lastName
      ign
      teamName
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      characters
      country {
        name
        iso
      }
    }
    pubg {
      firstName
      lastName
      ign
      teamName
      country {
        name
        iso
      }
      kills
      assists
      deaths
      damageDealt
      damageTaken
      knocks
      revives
      headshotKills
      stolenKills
      killsStolenFromPlayer
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroyed
      avgTimeSurvived
      flashbangThrow
      grenadeThrow
      smokebombThrow
      molotovThrow
      avgTimeSurvived
    }
    csgo {
      assists
      avgArmorLeft
      avgBurningTime
      avgDmg
      avgEquipmentValue
      avgFlashedTime
      avgHealthLeft
      deaths
      firstName
      hsKills
      hsPercentage
      id
      kD
      kR
      kills
      lastName
      maxDmg
      maxEquipmentValue
      name
      roundsAceKills
      roundsFourKills
      roundsThreeKills
      teamName
      totalBurningTime
      totalDmg
      totalEquipmentValue
      totalFlashedTime
    }
  }
}`;

export const getTeamLeaderboard = gql`query GetTeamLeaderboard($token: String!) {
  getTeamLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      teamName
      wins
      draws
      losses
      points
      killDifference
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      rd
      played
      country {
        name
        iso
      }
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentKills
      currentRank
      initial
      name
      numberOfMatches
      players {
        damage
        kills
        ign
        status
      }
      previousKills
      previousPosition
      previousPositionChange
      totalDamage
      totalKills
      totalPoints
      wins
    }
  }
}`;

export const getLiveTeamLeaderboard = gql`query GetLiveTeamLeaderboard($token: String!) {
  getLiveTeamLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentKills
      currentRank
      initial
      name
      numberOfMatches
      players {
        damage
        kills
        ign
        status
      }
      previousKills
      previousPosition
      previousPositionChange
      totalDamage
      totalKills
      totalPoints
      wins
    }
  }
}`;

export const getLiveMapTeams = gql`query GetLiveMapTeams($token: String!) {
  getLiveMapTeams(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      initial
      totalKills
      totalDamage
      numberOfMatches
      name
      players {
        damage
        kills
        ign
        status
      }
      totalPoints
      wins
    }
  }
}`;

export const getLiveSurvivalStats = gql`query GetLiveSurvivalStats($token: String!) {
  getLiveSurvivalStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentKills
      currentRank
      initial
      name
      numberOfMatches
      players {
        damage
        kills
        ign
        status
      }
      previousKills
      previousPosition
      previousPositionChange
      totalDamage
      totalKills
      totalPoints
      wins
    }
  }
}`;

export const getLiveTeamStats = gql`query GetLiveTeamStats($token: String!) {
  GetLiveTeamStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentPlacement
      initial
      name
      totalDamage
      totalKills
      totalPoints
      wins
      timeSurvived
      numberOfMatches
      currentDamage
      currentKills
    }
  }
}`;

export const getLivePlayerStats = gql`query GetLivePlayerStats($token: String!) {
  GetLivePlayerStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      teamName
      teamInitial
      totalKills
      totalDamage
      killRank
      damageRank
    }
  }
}`;

export const getLivePlayerKills = gql`query GetLivePlayerKills($token: String!) {
  GetLivePlayerKills(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      totalDamage
      totalKills
      teamInitial
      teamName
    }
  }
}`;

export const getLivePlayerKillsDamage = gql`query GetLivePlayerKillsDamage($token: String!) {
  GetLivePlayerKillsDamage(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      totalDamage
      totalKills
      teamInitial
      teamName
    }
  }
}`;

export const getScoreboard = gql`query GetScoreboard($token: String!) {
  getScoreboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      valorant {
        teamName
        score {
          score
          wins
          draws
          losses
        }
        players {
          firstName
          lastName
          ign
          teamName
          kills
          assists
          deaths
          esr
          firstBloods
          firstDeaths
          econRating
          adr
          hsProcentage
          plants
          defuses
          aces
          multikills
          clutches
          kd
          characters
          country {
            name
            iso
          }
        }
      }
    }
    right{
      valorant {
        teamName
        score {
          score
          wins
          draws
          losses
        }
        players {
          firstName
          lastName
          ign
          teamName
          kills
          assists
          deaths
          esr
          firstBloods
          firstDeaths
          econRating
          adr
          hsProcentage
          plants
          defuses
          aces
          multikills
          clutches
          kd
          characters
          country {
            name
            iso
          }
        }
      }
    }
  }
}`;

export const getLiveTeamHeadToHead = gql`query GetLiveTeamHeadToHead($token: String!) {
  getLiveTeamHeadToHead(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      pubg {
        name
        initial
        avgAssists
        avgDamageDealt
        avgKills
        avgKnocks
        avgPoints
        numberOfMatches
        players {
          damage
          ign
          kills
          status
        }
        totalDamage
        totalKills
        totalPoints
        wins
      }
    }
    right{
      pubg {
        name
        initial
        avgAssists
        avgDamageDealt
        avgKills
        avgKnocks
        avgPoints
        numberOfMatches
        players {
          damage
          ign
          kills
          status
        }
        totalDamage
        totalKills
        totalPoints
        wins
      }
    }
  }
}`;

export const getLivePlayerLeaderboard = gql`query GetLivePlayerLeaderboard($token: String!) {
  getLivePlayerLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      teamName
      teamInitial
      damageRank
      ign
      killRank
      teamInitial
      teamName
      totalDamage
      totalKills
    }
  }
}`;

export const getCurrentMatch = gql`query GetCurrentMatch($token: String!) {
  getCurrentMatch(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      currentMatch
    }
    pubg {
      currentMatch
    }
  }
}`;

export const getTodaysWinners = gql`query GetTodayWinner($token: String!) {
  getTodayWinner(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      currentMatchNumber
      matches {
        teamName
        map
        matchNumber
      }
    }
  }
}`;

export const getMapVeto = gql`query GetMapVeto($token: String!, $overlayToken: String!) {
  getMapVeto(token: $token, overlayToken: $overlayToken) {
    token
    tokenOverlay
    status
    pickValues
    timer
    logs {
      map
      type
      mapVoter
      attackers
      defenders
    }
  }
}`;