import { all, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {  
  DISPLAY_SUCCESS_NOTIFICATION,
  DISPLAY_ERROR_NOTIFICATION,
  DISPLAY_INFO_NOTIFICATION
} from './types';

/**
 * Displays success notification
 */
function* displaySuccess(action) {
  yield call(toast.success, action.payload);
}

/**
 * Displays error notification
 */
function* displayError(action) {
  yield call(toast.error, action.payload);
}

/**
 * Displays info notification
 */
function* displayInfo(action) {
  yield call(toast.info, action.payload);
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(DISPLAY_SUCCESS_NOTIFICATION, displaySuccess),
      takeLatest(DISPLAY_ERROR_NOTIFICATION, displayError),
      takeLatest(DISPLAY_INFO_NOTIFICATION, displayInfo)
  ]);
}