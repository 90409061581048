// eslint-disable
// this is an auto generated file. This will be overwritten

export const fileByParentFileId = `query FileByParentFileId($parentFileId: Int) {
  fileByParentFileId(parentFileId: $parentFileId) {
    id
    actionType
    fileType
    filename
    createdAt
    parentFileId
    matchId
  }
}
`;
export const highlightByFileId = `query HighlightByFileId($fileId: Int!) {
  highlightByFileId(fileId: $fileId) {
    fileId
    isPublic
    filename
    highlights {
      time
      comment
      drawings
      sub
      username
    }
  }
}
`;
export const settings = `query Settings {
  settings {
    pubgUsernames {
      id
      pubgUsername
      pubgUsernameDone
      pubgUsernameError
    }
    emailNotifications
  }
}
`;
export const tutorial = `query Tutorial($gameId: Int!) {
  tutorial(gameId: $gameId) {
    name
    done
  }
}
`;
export const userSubscription = `query UserSubscription {
  userSubscription {
    status
    trial
    validFrom
    validTo
    planId
  }
}
`;
export const tournamentOrganizerToken = `query TournamentOrganizerToken {
  tournamentOrganizerToken
}
`;
export const liveLeaderboardToken = `query LiveLeaderboardToken($shardInfo: String!) {
  liveLeaderboardToken(shardInfo: $shardInfo)
}
`;
