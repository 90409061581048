import { 
  REQUEST_CREATE_COMPONENT_SUCCESS,
  REQUEST_CHANGE_COMPONENT_SUCCESS,
  REQUEST_REMOVE_COMPONENT_SUCCESS,
  REQUEST_SHARD_INFO_CHANGE_COMPONENT_SUCCESS,
  REQUEST_CREATE_COMPONENTS_BULK_SUCCESS,
  REQUEST_REMOVE_ALL_COMPONENT_SUCCESS,
  SET_COMPONENT_STATE,
  SET_COMPONENT_DATA,
} from './types';

const INITIAL_STATE = {
  components: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On component create
    case REQUEST_CREATE_COMPONENT_SUCCESS:
      return {
        ...state,
        components: [...state.components, action.payload],
      };

    // On component create bulk
    case REQUEST_CREATE_COMPONENTS_BULK_SUCCESS:
      return {
        ...state,
        components: [...state.components, ...action.payload],
      };

    // On component chage
    case REQUEST_CHANGE_COMPONENT_SUCCESS:
      return {
        ...state,
        components: state.components.map(c => c.token === action.payload.token ? action.payload : c),
      };

    // On components shard info change
    case REQUEST_SHARD_INFO_CHANGE_COMPONENT_SUCCESS:
      return {
        ...state,
        components: action.payload,
      }
      
    // On component remove
    case REQUEST_REMOVE_COMPONENT_SUCCESS:
      return {
        ...state,
        components: state.components.filter(c => c.token !== action.payload),
      };

    // On remove all components
    case REQUEST_REMOVE_ALL_COMPONENT_SUCCESS:
      return {
        ...state,
        components: [],
      }

    // On set component state
    case SET_COMPONENT_STATE:
      return {
        ...state,
        components: state.components.map(c =>
          c.token === action.payload.component.token ?
          { ...c, state: { ...c.state, ...action.payload.state} }
          :
          c
        ),
      }

    // On set component data
    case SET_COMPONENT_DATA:
      return {
        ...state,
        components: state.components.map(c =>
          c.token === action.payload.component.token ?
          {
            ...c,
            matches: action.payload.data.matches,
            teams: action.payload.data.teams,
            availableStatistics: action.payload.data.availableStatistics,
          }
          :
          c
        ),
      }
      
    default:
      return state;
  }
}

export default reducer;