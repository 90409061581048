import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const height = 450;

const Wrapper = styled.div`
  width: ${props => props.rowWidth}px;
  margin-right: ${props => props.margin}px;
  float: left;
  overflow: hidden;

  &.player-show {
    height: 0;
    animation: open-player .75s ease ${ props => props.delayShow * 0.3 + 0.5 }s forwards;

    @keyframes open-player {
      from {height: 0;}
      to {height: ${height}px;}
    }
  }

  &.player-hide {
    height: ${height}px;
    animation: close-player .75s ease ${ props => props.delayHide * 0.3 + 0.8 }s forwards;

    @keyframes close-player {
      from {height: ${height}px;}
      to {height: 0px;}
    }
  }
`;

const PlayerRow = styled(Row)`
  margin-top: 5px;
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.fontSize}px;
  background: #3e3e3e;
  font-weight: 600;
  color: ${props => props.color ? props.color : styles.secondary};
  padding: 10px;
`;

const ScoreRow = styled(Row)`
  padding: 10px;
  height: ${props => props.fontSize + 20}px;
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.fontSize}px;
  background: #3e3e3e;
  font-weight: 600;
  color: ${props => props.color ? props.color : styles.secondary};
  overflow: hidden;

  img {
    height: 200px;
    position: absolute;
    left: ${props => (props.rowWidth - 200) / 2}px;
    top: -35px;
    opacity: 0.2;
    z-index: 5;
  }

  span {
    position: relative;
    z-index: 10;

    span {
      font-size: 50px;
    }
  }
`;

function LeaderboardPlayer(props) {
  const { rank, player, rowWidth, margin, numOfPlayers, show } = props;

  return (
    <Wrapper
      rowWidth={rowWidth}
      margin={ margin }
      className={ show ? 'player-show' : 'player-hide' }
      delayShow={rank}
      delayHide={numOfPlayers - rank}
    >
      <PlayerRow
        className="text-center mb-1"
        fontSize={40}
      >
        <Col xs="12">
          <span>{rank}.</span>
        </Col>
      </PlayerRow>
      <PlayerRow
        className="text-center mb-1"
        fontSize={50}
        color={styles.primary}
      >
        <Col xs="12">
          <span>{ player.ign.replace(new RegExp(`${player.teamInitial}_`, 'ig'), '').toUpperCase() }</span>
        </Col>
      </PlayerRow>
      <ScoreRow
        className="text-center mb-1"
        rowWidth={rowWidth}
        fontSize={130}
      >
        <Col xs="12">
          <img
            src={getTeamLogo(player.teamName)}
            alt="team logo"
            onError={(e) => e.target.src = getTeamLogo('default')}
            className="mr-3"
          />
          <span>{ player.totalKills }<span> K</span></span>
        </Col>
      </ScoreRow>
      <ScoreRow
        className="text-center mb-1"
        rowWidth={rowWidth}
        fontSize={50}
        color={styles.primary}
      >
        <Col xs="12">
          <span>{ parseInt(player.totalDamage) }<span> D</span></span>
        </Col>
      </ScoreRow>
      <ScoreRow
        className="text-center mb-1"
        rowWidth={rowWidth}
        fontSize={50}
      >
        <Col xs="12">
          <span>{ parseInt(player.totalDamage / player.totalKills) }<span> D/K</span></span>
        </Col>
      </ScoreRow>
    </Wrapper>
  );
}

export default LeaderboardPlayer;