import React from 'react';
import styled from 'styled-components';

const HeaderRow = styled.div`
  width: ${props => props.width}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: url(/assets/img/predator/header.png);
  background-position: -5px -6px;
  max-height: 60px;

  .header {
    width: ${props => props.width}px;
    font-size: 20px;
    line-height: 20px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: Predator-Regular;
  }

  .sub-header {
    width: ${props => props.width/5}px;
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(right, #1eff96 85%, #00b4ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-position: center;
    background-attachment: fixed;
    font-family: Predator-Regular;

    &.text-left {
      justify-content: start;
    }
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow width={props.width}>
      <div className="header text-center">LEADERBOARD</div>
      <div className="sub-header"></div>
      <div className="sub-header text-left">TEAM</div>
      <div className="sub-header text-center">ALIVE</div>
      <div className="sub-header text-center">KILLS</div>
      <div className="sub-header text-center">TOTAL</div>
    </HeaderRow>
  );
}

export default LeaderboardHeader;