import React, { Component } from 'react';
import styled from 'styled-components';
import { getPlayerImage, getTeamLogo } from '../../../../../../util/CommonUtil';

const WIDTH = 1800;
const HORIZONTAL_SPACING = 5;

const LeaderboardWrapper = styled.table`
  font-family: machine;
  font-size: 35px;

  border-collapse:separate; 
  border-spacing: ${HORIZONTAL_SPACING}px;
  width: ${WIDTH}px;

  text-align: center;
  vertical-align: middle;

  h1 {
    font-size: 75px;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 50px;
    position: relative;
  }

  td {
    background-image: url('/assets/img/pjc/player-leaderboard-background.png');
    background-attachment: fixed;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  tr:first-child td {
    &.component-show {
      opacity: 0;
      animation: fadeIn 0.6s ease 0s forwards;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      p, span {
        bottom: -100px;
        animation: slideUp 0.3s ease 0s forwards;

        @keyframes slideUp {
          from {
            opacity: 0;
            bottom: -100px;
          }
          to {
            opacity: 1;
            bottom: 0px;
          }
        }
      }
    }

    &.component-hide {
      opacity: 1;
      animation: fadeOut 0.6s ease ${5 * 0.2}s forwards;

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      p, span {
        bottom: 0px;
        animation: slideDown 0.3s ease ${5 * 0.2}s forwards;

        @keyframes slideDown {
          from {
            opacity: 1;
            bottom: 0px;
          }
          to {
            opacity: 0;
            bottom: -100px;
          }
        }
      }
    }
  }
`;

const StyledTd = styled.td`
  span {
    position: relative;
  }

  .images {
    padding: 20px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .player-image {
      height: 220px;
    }

    .team-image {
      position: absolute;
      bottom: -20px;
      right: 50px;
      height: 100px;
    }
  }

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease ${props => props.index/5 + 0.2}s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    p, span {
      bottom: -100px;
      animation: slideUp 0.3s ease ${props => props.index/5 + 0.2}s forwards;

      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease ${props => 1 - props.index/5 - 0.2}s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    p, span {
      bottom: 0px;
      animation: slideDown 0.3s ease ${props => 1 - props.index/5 - 0.2}s forwards;

      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }
`;

class PjcPlayerLeaderBoard extends Component {
  render() {
    const { loaded, leaderboard, show, opts, exportOverlay } = this.props;

    if (!loaded) return null;

    const stat = opts.stats ? opts.stats[0] : {};
    const topPlayers = leaderboard.slice(0, 5);

    return (
      topPlayers.length ?
      <LeaderboardWrapper>
        <tbody>
          <tr>
            <td
              colSpan="5"
              className={ `${exportOverlay ? '' : show ? 'component-show' : 'component-hide' }`}
            >
              <p>{ stat.value }</p>
            </td>
          </tr>
          <tr>
            {
              topPlayers.map((p, i) => {
                return (
                  <StyledTd
                    className={ `${exportOverlay ? '' : show ? 'component-show' : 'component-hide' }`}
                    index={i}
                  >
                    <span>{ i+1 }.</span>
                  </StyledTd>
                );
              })
            }
          </tr>
          <tr>
            {
              topPlayers.map((p, i) => {
                return (
                  <StyledTd
                    className={ `team-logo-td ${exportOverlay ? '' : show ? 'component-show' : 'component-hide' }`}
                    index={i}
                  >
                    <div className="images">
                      <img
                        className="player-image"
                        src={getPlayerImage(`${p.ign}`)}
                        onError={e => e.target.src = getPlayerImage('default')}
                        alt="player"
                      />
                      <img
                        className="team-image"
                        src={getTeamLogo(`${p.teamName}`)}
                        onError={e => e.target.src = getTeamLogo('default')}
                        alt="team-logo"
                      />
                    </div>
                    <p>{ p[stat.key] }</p>
                  </StyledTd>
                );
              })
            }
          </tr>
          <tr>
            {
              topPlayers.map((p, i) => {
                return (
                  <StyledTd
                    className={ `${exportOverlay ? '' : show ? 'component-show' : 'component-hide' }`}
                    index={i}
                  >
                    <span>{ p.ign.replace(new RegExp(`${p.teamInitial}_`, 'ig'), '').toUpperCase() }</span>
                  </StyledTd>
                );
              })
            }
          </tr>
        </tbody>
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default PjcPlayerLeaderBoard;
