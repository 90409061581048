import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import maps from '../../../../../../assets/map/mapMapping';
import { Row, Col } from 'reactstrap';

const Wrapper = styled.div`
  text-transform: uppercase;
  width: ${ props => props.rowWidth / 2 }px;
  margin-left: ${ props => props.rowWidth / 4 }px;
  margin-top: 35px;
  margin-bottom: 35px !important;
  background: ${ styles.pslColor };
  color: #000000;
  height: 180px;

  &.head-to-head-header-show {
    display: block;
    width: 0px;
    animation: headerSlideDown 0.5s ease 0.2s forwards;
  
    @keyframes headerSlideDown {
      from {width: 0px;}
      to {width: ${ props => props.rowWidth / 2 }px;}
    }

    p {
      opacity: 0;
      animation: headerFadeIn 0.6s ease 0.6s forwards;
  
      @keyframes headerFadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }

    img {
      opacity: 0;
      animation: headerFadeInImg 0.3s ease 0s forwards;
  
      @keyframes headerFadeInImg {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  }

  &.head-to-head-header-hide {
    width: ${ props => props.rowWidth / 2 }px;
    animation: headerSlideUp 0.5s ease 3s forwards;
  
    @keyframes headerSlideUp {
      from {width: ${ props => props.rowWidth / 2 }px;}
      to {width: 0px;}
    }

    p {
      opacity: 1;
      animation: headerFadeOut 0.6s ease 2.5s forwards;
  
      @keyframes headerFadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }

    img {
      opacity: 1;
      animation: headerFadeOutImg 0.3s ease 3.3s forwards;
  
      @keyframes headerFadeOutImg {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }

  img {
    height: 250px;
    position: absolute;
    top: -0px;
  }

  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .next-match {
    vertical-align: middle;
    font-size: 35px;
    letter-spacing: 8px;
    filter: drop-shadow(0px 0px 4px #00000040);
    line-height: 1;
    margin-left: 5px;
  }

  .next-map {
    vertical-align: middle;
    font-size: 130px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 4px #00000080);
    line-height: 1;
  }
`;

function LastWinnersHeader(props) {
  const { rowWidth, show, nextMap } = props;

  return (
    <Wrapper
      rowWidth={rowWidth}
      className={ 'mb-2 ' + (show ? 'head-to-head-header-show' : 'head-to-head-header-hide') }
    >
      <img 
        src={`/assets/img/psl/logo-winter.png`}
        alt={`PSL logo`}
      />
      <Row>
        <Col xs="4">
        </Col>
        <Col xs="8">
          <p className="next-match">{ maps[nextMap] ? 'Next Match' : 'Today\'s' }</p>
          <p className="next-map">{ maps[nextMap] ? maps[nextMap] : 'WINNERS' }</p>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default LastWinnersHeader;