import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardPlayer from './LeaderboardPlayer';

const PLAYER_STATISTICS = {
  kills: 'Top Kills',
  assists: 'Top Assists',
  damageDealt: 'Top Damage',
  damageTaken: 'Top Damage Taken',
  knocks: 'Knock Stats',
  revives: 'Revive Stats',
  headshotKills: 'Headshot Kills',
  stolenKills: 'Stolen Kills',
  killsStolenFromPlayer: 'Kills Lost',
  swimDistance: 'Swim Distance',
  walkDistance: 'Walk Distance',
  rideDistance: 'Ride Distance',
  longestKill: 'Longest Kill',
  timeSurvived: 'Time Survived',
  killStreaks: 'Kill Streaks',
  heals: 'Heal Stats',
  boosts: 'Boost Stats',
  vehicleDestroyed: 'Vehicles Destroyed',
  deaths: 'Deaths Stats',
}

const LeaderboardWrapper = styled.table`
  font-family: 'agency_fb';
  text-align: center; 
  vertical-align: middle;
  text-transform: uppercase;
  border-collapse:separate;
  border-spacing: 10px 10px;

  h1 {
    font-size: 70px;
  }

  &.component-show {
    .header, .footer {
      animation: fadeIn 0.3s ease 0s forwards;
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    .header, .footer {
      animation: fadeOut 0.3s ease 0s forwards;
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

class RyerPlayerLeaderBoard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;

    if (!loaded) return null;

    const stat = opts.stats ? opts.stats[0] : {};
    const topPlayers = leaderboard.slice(0, 5);

    return (
      topPlayers.length ?
      <LeaderboardWrapper
        className={ show ? 'component-show' : 'component-hide' }
      >
        <tbody>
          <tr className="header">
            <td colSpan="5">
              <h1>{ PLAYER_STATISTICS[stat.key] }</h1>
            </td>
          </tr>
          <tr>
            {
              topPlayers.map((p, i) => {
                return (
                  <LeaderboardPlayer
                    player={p}
                    rank={i+1}
                    stat={stat}
                    show={show}
                  />
                );
              })
            }
          </tr>
          <tr className="footer">
            <td colSpan="5">
              <img
                className="mt-4"
                src="/assets/img/twire/powered-by-twire-no-background.png"
                alt="tournament logo"
              />
            </td>
          </tr>
        </tbody>
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default RyerPlayerLeaderBoard;
