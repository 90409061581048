import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getTeamLogoByGame, getValorantAgentIcon } from '../../../../../../util/CommonUtil';

const ScoreboardTable = styled.table`
  font-family: teko-regular;
  text-transform: uppercase;

  td {
    text-align: center; 
    vertical-align: middle;
    width: 300px;
    padding: 8px;
  }

  transition: opacity 0.5s ease-out;
  transition-delay: 0.5s;

  &.cg-show {
    opacity: 1;
  }

  &.cg-hide {
    opacity: 0;
  }
`;

const TeamLogos = styled.tr`
  img {
    height: 150px;
  }

  p {
    font-family: teko-bold;
    font-size: 150px;
    line-height: 150px;
    margin: 0;
    padding-top: 30px;
  }
`;

const HeaderRow = styled.tr`
  .header-td {
    font-family: teko-bold;
    background: #5848f1;
    font-size: 2.3rem;
  }
`;

const StatsColumn = styled.td`
  background: rgba(0,0,0,.80);
  font-size: 2rem;
  white-space: nowrap;

  .agent-image {
    height: 50px;
  }
`;

function Scoreboard(props) {
  const { left, right, opts, game, show } = props;

  opts.stats = (opts.stats || []);
  const numOfColumns = opts.stats.length + 1;

  const TeamHeader = () => {
    return (
      <>
        <td className="header-td"></td>
        {
          (opts.stats || []).map(s => {
            return (
              <td className="header-td">{ s.value }</td>
            );
          })
        }
      </>
    );
  }

  const PlayerStats = ({player}) => {
    return (
      <>
        <StatsColumn className="text-left">
          <img
            className="agent-image mr-2"
            src={getValorantAgentIcon(player.characters[0])}
            alt="agent"
          />
          { player.ign }
        </StatsColumn>
        {
          (opts.stats || []).map(s => {
            return (
              <StatsColumn>{ player[s.key] }</StatsColumn>
            );
          })
        }
      </>
    )
  }

  const StatsRow = ({index}) => {
    return (
      <tr>
        <PlayerStats player={left.players[index]} />
        <td></td>
        <PlayerStats player={right.players[index]} />
      </tr>
    );
  }
  
  return (
    <ScoreboardTable
      className={`text-center ${!show ? 'cg-hide': 'cg-show'}`}
    >
      <tbody>
        <TeamLogos>
          <td>
            <img
              src={getTeamLogoByGame(game, left.teamName)}
              alt="team logo"
              onError={e => e.target.src = getTeamLogoByGame(game)}
            />
          </td>
          <td colSpan={numOfColumns-2}></td>
          <td>
            <p className="ml-2">{(left.score || {}).score}</p>
          </td>
          <td></td>
          <td>
            <p className="ml-2">{(right.score || {}).score}</p>
          </td>
          <td colSpan={numOfColumns-2}></td>
          <td>
            <img
              src={getTeamLogoByGame(game, right.teamName)}
              alt="team logo"
              onError={e => e.target.src = getTeamLogoByGame(game)}
            />
          </td>
        </TeamLogos>
        <HeaderRow>
          <TeamHeader/>
          <td></td>
          <TeamHeader/>
        </HeaderRow>
        {
          left.players ?
          Array.from(Array(left.players.length).keys()).map(i => <StatsRow index={i}/>)
          :
          null
        }
      </tbody>
    </ScoreboardTable>
  );
}

const mapStateToProps = state => ({
  left: state.overlay.left ? state.overlay.left.valorant : {},
  right: state.overlay.right ? state.overlay.right.valorant : {},
  opts: state.overlay.opts,
  show: state.overlay.show,
});

export default connect(mapStateToProps)(Scoreboard);