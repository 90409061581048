import React from 'react';
import styled from 'styled-components';

const height = 50;

const Wrapper = styled.div`
  width: ${ props => props.rowWidth / 3 }px;
  margin-left: ${ props => props.rowWidth / 3 }px;
  overflow: hidden;
  background: #3e3e3e;

  img {
    margin-top: 2px;
    height: 45px;
  }

  &.footer-show {
    height: 0;
    animation: open-footer .75s ease 1s forwards;

    @keyframes open-footer {
      from {height: 0;}
      to {height: ${height}px;}s
    }
  }

  &.footer-hide {
    height: ${height}px;
    animation: close-footer .75s ease-in 0s forwards;

    @keyframes close-footer {
      from {height: ${height}px;}
      to {height: 0px;}
    }
  }
`;

function PoweredByTwire(props) {
  const { rowWidth, show } = props;

  return (
    <Wrapper
      rowWidth={rowWidth}
      className={ 'text-center ' + (show ? 'footer-show' : 'footer-hide') }
    >
      <img
        src="/assets/img/twire/powered-by-twire-no-background.png"
        alt="tournament logo"
      />
    </Wrapper>
  );
}

export default PoweredByTwire;