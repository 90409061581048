import gql from 'graphql-tag';

export const createTournament = gql`mutation CreateTournament($game: String!, $tournament: TournamentInput!) {
  createTournament(game: $game, tournament: $tournament) {
    active
    pubgTournamentId
    # components
    delay
    filter
    friendlyName
    gameMode
    id
    imageName
    overlayColor
    overlayDesign
    region
    server
    shard
    tournamentFilters {
      id
      name
      parentId
      phases {
        dateFrom
        dateTo
        id
        tournamentFilterId
        tournamentId
        trackBy {
          status
          username
        }
      }
      teams {
        id
        name
        slotNo
      }
      tournamentId
      type
      value
    }
    tournamentPhases {
      dateFrom
      dateTo
      id
      tournamentFilterId
      tournamentId
      trackBy {
        status
        username
        platform
      }
    }
  }
}`;

export const editTournament = gql`mutation EditTournament($game: String!, $tournament: TournamentInput!) {
  editTournament(game: $game, tournament: $tournament) {
    active
    pubgTournamentId
    # components
    delay
    filter
    friendlyName
    gameMode
    id
    imageName
    overlayColor
    overlayDesign
    region
    server
    shard
    tournamentFilters {
      id
      name
      parentId
      phases {
        dateFrom
        dateTo
        id
        tournamentFilterId
        tournamentId
        trackBy {
          status
          username
        }
      }
      teams {
        id
        name
        slotNo
      }
      tournamentId
      type
      value
    }
    tournamentPhases {
      dateFrom
      dateTo
      id
      tournamentFilterId
      tournamentId
      trackBy {
        status
        username
        platform
      }
    }
  }
}`;

export const createTournamentFilter = gql`mutation CreateTournamentFilter($game: String!, $tournamentFilter: TournamentFilterInput!) {
  createTournamentFilter(game: $game, tournamentFilter: $tournamentFilter) {
    id
    name
    parentId
    tournamentId
    type
    ruleset
    value
  }
}`;

export const editTournamentFilter = gql`mutation EditTournamentFilter($game: String!, $tournamentFilter: TournamentFilterInput!) {
  editTournamentFilter(game: $game, tournamentFilter: $tournamentFilter) {
    id
    name
    parentId
    tournamentId
    type
    ruleset
    value
  }
}`;

export const deleteTournamentFilter = gql`mutation DeleteTournamentFilter($game: String!, $id: Int!) {
  deleteTournamentFilter(game: $game, id: $id)
}`;

export const createTournamentPhase = gql`mutation CreateTournamentPhase($game: String!, $tournamentPhase: TournamentPhaseInput!) {
  createTournamentPhase(game: $game, tournamentPhase: $tournamentPhase) {
    dateFrom
    dateTo
    id
    tournamentFilterId
    tournamentId
    trackBy {
      status
      username
    }
  }
}`;

export const editTournamentPhase = gql`mutation EditTournamentPhase($game: String!, $tournamentPhase: TournamentPhaseInput!) {
  editTournamentPhase(game: $game, tournamentPhase: $tournamentPhase) {
    dateFrom
    dateTo
    id
    tournamentFilterId
    tournamentId
    trackBy {
      status
      username
    }
  }
}`;

export const deleteTournamentPhase = gql`mutation DeleteTournamentPhase($game: String!, $id: Int!) {
  deleteTournamentPhase(game: $game, id: $id)
}`;

export const changeTrackBy = gql`mutation ChangeTrackBy($game: String!, $tournamentFilterId: Int!, $tournamentId: Int!, $trackBy: TrackingObject!) {
  changeTrackBy(game: $game, tournamentFilterId: $tournamentFilterId, tournamentId: $tournamentId, trackBy: $trackBy) {
    dateFrom
    dateTo
    id
    tournamentFilterId
    tournamentId
    trackBy {
      status
      username
      platform
    }
  }
}`;

export const addTournamentMatch = gql`mutation AddTournamentMatch($game: String!, $match: MatchInput!) {
  addTournamentMatch(game: $game, match: $match) {
    gameCreatedAt
    gameMatchId
    gameMode
    id
    map
    name
    shard
    shardInfo
    valid
  }
}`;

export const editTournamentMatch = gql`mutation EditTournamentMatch($game: String!, $match: MatchInput!) {
  editTournamentMatch(game: $game, match: $match) {
    gameCreatedAt
    gameMatchId
    gameMode
    id
    map
    name
    shard
    shardInfo
    valid
  }
}`;

export const addTournamentTeamSlots = gql`mutation AddTournamentTeamSlots($game: String!, $teams: TournamentTeamInput!, $tournamentFilterId: Int!, $tournamentId: Int!) {
  addTournamentTeamSlots(game: $game, teams: $teams, tournamentFilterId: $tournamentFilterId, tournamentId: $tournamentId) {
    id
    name
    slotNo
  }
}`;

export const addTournamentPrizePool = gql`mutation AddTournamentPrizePool($game: String!, $tournamentPrizePoolDistributions: TournamentPrizePoolInput!, $tournamentFilterId: Int!, $tournamentId: Int!) {
  addTournamentPrizePool(game: $game, tournamentPrizePoolDistributions: $tournamentPrizePoolDistributions, tournamentFilterId: $tournamentFilterId, tournamentId: $tournamentId) {
    id
    placement
    prize
  }
}`;

export const removeTournamentPrizePool = gql`mutation RemoveTournamentPrizePool($game: String!, $tournamentPrizePoolDistributions: TournamentPrizePoolInput!) {
  removeTournamentPrizePool(game: $game, tournamentPrizePoolDistributions: $tournamentPrizePoolDistributions) {
    id
    placement
    prize
  }
}`;

export const createTournamentAward = gql`mutation CreateTournamentAward($game: String!, $tournamentAward: TournamentAwardInput!) {
  createTournamentAward(game: $game, tournamentAward: $tournamentAward) {
    description
    id
    title
    tournamentId
    type
    columns {
      id
      sortingIndex
      sortingOrder
      title
      tournamentAwardFilterId
      tournamentAwardId
    }
    filters {
      id
      title
      tournamentAwardId
    }
    rows {
      id
      player {
        id
        inGameUsername
      }
      team {
        id
        name
      }
      tournamentAwardColumnId
      tournamentAwardFilterId
      tournamentAwardId
      value
    }
  }
}`;

export const editTournamentAward = gql`mutation EditTournamentAward($game: String!, $tournamentAward: TournamentAwardInput!) {
  editTournamentAward(game: $game, tournamentAward: $tournamentAward) {
    description
    id
    title
    tournamentId
    type
    columns {
      id
      sortingIndex
      sortingOrder
      title
      tournamentAwardFilterId
      tournamentAwardId
    }
    filters {
      id
      title
      tournamentAwardId
    }
    rows {
      id
      player {
        id
        inGameUsername
      }
      team {
        id
        name
      }
      tournamentAwardColumnId
      tournamentAwardFilterId
      tournamentAwardId
      value
    }
  }
}`;

export const deleteTournamentAward = gql`mutation DeleteTournamentAward($game: String!, $tournamentAward: TournamentAwardInput!) {
  deleteTournamentAward(game: $game, tournamentAward: $tournamentAward) {
    id
  }
}`;

export const createTournamentAwardRow = gql`mutation CreateTournamentAwardRow($game: String!, $tournamentAwardRow: TournamentAwardRowInput!) {
  createTournamentAwardRow(game: $game, tournamentAwardRow: $tournamentAwardRow) {
    description
    id
    title
    tournamentId
    type
    columns {
      id
      sortingIndex
      sortingOrder
      title
      tournamentAwardFilterId
      tournamentAwardId
    }
    filters {
      id
      title
      tournamentAwardId
    }
    rows {
      id
      player {
        id
        inGameUsername
      }
      team {
        id
        name
      }
      tournamentAwardColumnId
      tournamentAwardFilterId
      tournamentAwardId
      value
    }
  }
}`;

export const editTournamentAwardRow = gql`mutation EditTournamentAwardRow($game: String!, $tournamentAwardRow: TournamentAwardRowInput!) {
  editTournamentAwardRow(game: $game, tournamentAwardRow: $tournamentAwardRow) {
    description
    id
    title
    tournamentId
    type
    columns {
      id
      sortingIndex
      sortingOrder
      title
      tournamentAwardFilterId
      tournamentAwardId
    }
    filters {
      id
      title
      tournamentAwardId
    }
    rows {
      id
      player {
        id
        inGameUsername
      }
      team {
        id
        name
      }
      tournamentAwardColumnId
      tournamentAwardFilterId
      tournamentAwardId
      value
    }
  }
}`;

export const deleteTournamentAwardRow = gql`mutation DeleteTournamentAwardRow($game: String!, $tournamentAwardRow: TournamentAwardRowInput!) {
  deleteTournamentAwardRow(game: $game, tournamentAwardRow: $tournamentAwardRow) {
    id
  }
}`;