import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Input } from 'reactstrap';

export default styled(Input)`
  background: none;
  border: none;
  border-bottom: solid 1px ${styles.secondary};
  border-radius: 0;
  color: ${styles.secondary};
  line-height: 1;
  padding: 0;
  margin: 0;
  height: 1.5em;
  ${props => props.fontSize ? `font-size: ${props.fontSize};` : ''}

  &:focus {
    background: none;
    border: none;
    border-bottom: solid 1px ${styles.secondary};
    box-shadow: none;
    color: ${styles.secondary};
    line-height: 1;
    padding: 0;
    margin: 0;
  }

  &[disabled] {
    background: none;
    color: ${styles.info};
    cursor: not-allowed;
    line-height: 1;
    padding: 0;
    margin: 0;
  }
`;