import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { TWIRE_ASSETS_URL } from '../../../../../../util/Constants';
import { getTeamLogo, numberToPlacementSurfix } from '../../../../../../util/CommonUtil';

const teamLogoSize = 220;
const padding = 50;
const height = 392;

const Wrapper = styled.div`
  width: ${props => props.backgroundWidth}px;
  margin-right: ${props => props.margin}px;
  float: left;
  overflow: hidden;

  &.player-show {
    height: 0;
    animation: open-player .75s ease ${props => props.delayShow * 0.3 + 0.2}s forwards;

    @keyframes open-player {
      from {height: 0;}
      to {height: ${height}px;}
    }
  }

  &.player-hide {
    height: ${height}px;
    animation: close-player .75s ease ${props => props.delayHide * 0.3 + 0.8}s forwards;

    @keyframes close-player {
      from {height: ${height}px;}
      to {height: 0px;}
    }
  }

  &.no-animation {
    height: ${height}px;
  }
`;

const PlayerRow = styled(Row)`
  font-size: 25px;
  line-height: 30px;
  background: ${styles.tertiary};
  font-weight: 600;
  color: ${styles.secondary};
  border-top: 1px solid ${props => styles[props.shard + 'Color'] ? styles[props.shard + 'Color'] : styles.primary};
  padding: 15px;

  .player-name {
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
`;

const ScoreRow = styled(Row)`
  height: ${teamLogoSize + padding}px;
  font-size: 75px;
  line-height: ${teamLogoSize + padding}px;
  background: ${styles.tertiary};
  font-weight: 600;
  color: ${styles.secondary};

  img {
    height: ${teamLogoSize}px;
    position: absolute;
    left: ${props => (props.backgroundWidth - teamLogoSize) / 2}px;
    top: ${padding / 2}px;
    transform: rotate(30deg);
    opacity: 0.3;
    z-index: 5;
  }

  span {
    position: relative;
    z-index: 10;
  }
`;

function LeaderboardPlayer(props) {
  const { rank, player, stat, backgroundWidth, numOfPlayers, show, shard, exportAsImage } = props;

  return (
    <Wrapper
      backgroundWidth={backgroundWidth}
      margin={0}
      className={exportAsImage ? 'no-animation' : show ? 'player-show' : 'player-hide'}
      delayShow={rank}
      delayHide={numOfPlayers - rank}
    >
      <PlayerRow
        className="text-center"
        shard={shard.split('-')[0]}
      >
        <Col xs="12">
          {rank + numberToPlacementSurfix(rank)}
        </Col>
      </PlayerRow>
      <ScoreRow
        className="text-center"
        backgroundWidth={backgroundWidth}
      >
        <Col xs="12">
          <img
            src={
              player.logo ?
                player.logo
                :
                getTeamLogo(player.teamName)
            }
            alt="team logo"
            onError={(e) => e.target.src = TWIRE_ASSETS_URL + "pubg/team-logos/default.png"}
          />
          <span>{player[stat]}</span>
        </Col>
      </ScoreRow>
      <PlayerRow
        className="text-center"
        shard={shard.split('-')[0]}
      >
        <Col xs="12" className="player-name">
          <span>{player.ign.toUpperCase()}</span>
        </Col>
      </PlayerRow>
    </Wrapper>
  );
}

export default LeaderboardPlayer;