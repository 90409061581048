import { 
  REQUEST_CURRENT_MATCH_SUCCESS,
  REQUEST_CURRENT_MATCH_PENDING,
  REQUEST_CURRENT_MATCH_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  currentMatch: 0,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request last winners pending or error
    case REQUEST_CURRENT_MATCH_PENDING:
    case REQUEST_CURRENT_MATCH_ERROR:
      return INITIAL_STATE;

    // On request last winners success
    case REQUEST_CURRENT_MATCH_SUCCESS:
      return {
        loaded: true,
        currentMatch: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;