// eslint-disable
// this is an auto generated file. This will be overwritten

export const saveFile = `mutation SaveFile($file: FileInput, $game: String!) {
  saveFile(file: $file, game: $game)
}
`;
export const saveHighlights = `mutation SaveHighlights($fileId: Int!, $highlights: [HighlightInput!]!) {
  saveHighlights(fileId: $fileId, highlights: $highlights) {
    fileId
    isPublic
    filename
    highlights {
      time
      comment
      drawings
      sub
      username
    }
  }
}
`;
export const savePubgUsername = `mutation SavePubgUsername($pubgUsername: String, $pubgUsernameId: Int) {
  savePubgUsername(
    pubgUsername: $pubgUsername
    pubgUsernameId: $pubgUsernameId
  ) {
    id
    pubgUsername
    pubgUsernameDone
    pubgUsernameError
  }
}
`;
export const saveEmailNotifications = `mutation SaveEmailNotifications($emailNotifications: Boolean!) {
  saveEmailNotifications(emailNotifications: $emailNotifications)
}
`;
export const renameFile = `mutation RenameFile($fileId: Int!, $filename: String!) {
  renameFile(fileId: $fileId, filename: $filename)
}
`;
export const deleteFile = `mutation DeleteFile($fileId: Int!) {
  deleteFile(fileId: $fileId)
}
`;
export const tutorialDone = `mutation TutorialDone($done: Boolean!, $name: String!, $gameId: Int!) {
  tutorialDone(done: $done, name: $name, gameId: $gameId)
}
`;
export const savePayment = `mutation SavePayment($subscriptionId: String!) {
  savePayment(subscriptionId: $subscriptionId)
}
`;
export const makeFilePublic = `mutation MakeFilePublic($fileId: Int!) {
  makeFilePublic(fileId: $fileId)
}
`;
export const collaboration = `mutation Collaboration($fileId: Int!, $collaborationInput: AWSJSON!) {
  collaboration(fileId: $fileId, collaborationInput: $collaborationInput) {
    fileId
    settings
  }
}
`;
export const usePromoCode = `mutation UsePromoCode($code: String!) {
  usePromoCode(code: $code)
}
`;
export const updateTournamentTeamName = `mutation UpdateTournamentTeamName(
  $tournamentId: Int!
  $igns: [String!]!
  $teamName: String!
) {
  updateTournamentTeamName(
    tournamentId: $tournamentId
    igns: $igns
    teamName: $teamName
  )
}
`;
export const saveAdditionalTournamentInfo = `mutation SaveAdditionalTournamentInfo(
  $game: String!
  $token: String!
  $tournamentId: Int!
  $image: String!
  $visible: Boolean!
) {
  saveAdditionalTournamentInfo(
    game: $game
    token: $token
    tournamentId: $tournamentId
    image: $image
    visible: $visible
  )
}
`;
