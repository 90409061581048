import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLivePlayerLeaderboard';

const PUBGLivePlayerLeaderboardOverlay = (props) => {
  const { shard, overlayDesign } = props;

  return (
    overlayDesign === 'esl' ? 
    <ESL {...props} />
    :
    null
  );
}

const mapStateToProps = state => ({
  leaderboard: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLivePlayerLeaderboardOverlay);