import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLivePlayerKillsDamage';
import PSL from './psl/PslLivePlayerKillsDamage';
import Being from './being/BeingLivePlayerKillsDamage';
import Protality from './protality/ProtalityLivePlayerKillsDamage';
import Default from './default/DefaultPlayerKillsDamage';

const PUBGLivePlayerKillsOverlay = (props) => {
  const { shard, overlayDesign } = props;
  props.players.sort((a, b) => a.totalKills > b.totalKills ? -1 : 1);

  return (
    overlayDesign === 'psl' || shard.includes('psl') ? 
    <PSL {...props} />
    :
    overlayDesign === 'esl' ? 
    <ESL {...props} />
    :
    overlayDesign === 'being' ? 
    <Being {...props} />
    :
    overlayDesign === 'protality' ? 
    <Protality {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  players: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  overlayColor: (state.overlay.tournament || {}).overlayColor,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLivePlayerKillsOverlay);
