import React from 'react';
import styled from 'styled-components';
import { getPlayerImage, getTeamLogo } from '../../../../../../util/CommonUtil';

const Leaderboard = styled.table`
  color: #000;
  width: 1775px;
  border-collapse: collapse;
  padding: 0;
  
  td {
    border-collapse: collapse;
    padding: 0;
  }

  table {
    margin: 0 auto;
  }
`;

const PlayerTable = styled.table`
  background: url('/assets/img/protality/player-leaderboard.background.png');
  background-repeat: no-repeat;
  overflow: hidden;

  .team-logo {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    width: 65px;
  }

  &.leaderboard-show {
    opacity: 0;
    animation: fadeIn 0.6s ease ${props => 0.3 * (props.index + 1)}s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }

    .player-image {
      position: relative;
      z-index: 10;
      width: 250px;
      margin-top: 250px;
      animation: slideIn 0.6s ease ${props => 0.3 * (props.index + 1) + 0.3}s forwards;

      @keyframes slideIn {
        from {margin-top: 250px;}
        to {margin-top: 0;}
      }
    }

    .player-score {
      margin-top: 150px;
      animation: slideSideIn 0.6s ease ${props => 0.3 * (props.index + 1) + 0.3}s forwards;

      @keyframes slideSideIn {
        from {margin-top: 150px;}
        to {margin-top: 0px;}
      }
    }
  }

  &.leaderboard-hide {
    opacity: 1;
    animation: fadeOut 0.3s ease ${props => 0.3 * (props.index + 1) + 0.3}s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }

    .player-image {
      position: relative;
      z-index: 10;
      width: 250px;
      margin-top: 0px;
      animation: slideOut 0.6s ease ${props => 0.3 * props.index}s forwards;

      @keyframes slideOut {
        from {margin-top: 0px;}
        to {margin-top: 250px;}
      }
    }

    .player-score {
      margin-top: 0px;
      animation: slideSideOut 0.6s ease ${props => 0.3 * props.index}s forwards;

      @keyframes slideSideOut {
        from {margin-top: 0px;}
        to {margin-top: 150px;}
      }
    }
  }
`;

const PictureDiv = styled.div`
  width: 250px;
  height: 250px;
  overflow: hidden;
  position: relative;
  overflow: hidden;
`;

const TeamDiv = styled.div`
  text-transform: uppercase;
  font-size: 25px;
  margin-top: -7px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  font-family: 'DrukText-BoldItalic';
  letter-spacing: 3px;

  img {
    height: 50px;
    margin: 10px;
  }

  .player-score {
    font-size: 73px;
    font-family: 'DrukTextWide-BoldItalic';
    margin-top: 13px;
    line-height: 1em;
    color: #000;
  }

  &.bottom {
    height: 70px;
    overflow: hidden;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const AttributeDiv = styled.div`
  text-transform: uppercase;
  font-family: 'DrukWide-SuperItalic';
  font-size: 27px;
  margin-top: -3px;
`;

const TwireDiv = styled.div`
  background: #00000000;
  border-radius: 3px;
  width: 250px;
  margin: 0 auto;

  img {
    height: 35px;
  }

  &.leaderboard-show {
    opacity: 0;
    animation: fadeIn 0.6s ease 1.8s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.leaderboard-hide {
    opacity: 1;
    animation: fadeOut 0.3s ease 1.8s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function LeaderboardBody(props) {
  const { show, leaderboard } = props;

  return (
    <Leaderboard
      cellspacing="0"
      cellpadding="0"
    >
      <tbody>
        <tr>
          {
            leaderboard.map((p, i) => {
              return (
                <td>
                  <PlayerTable
                    className={`${ show ? 'leaderboard-show' : 'leaderboard-hide' }`}
                    index={i}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <PictureDiv>
                            <img
                              className="team-logo"
                              src={getTeamLogo(p.teamName)}
                              onError={e => e.target.src = getTeamLogo('default')}
                              alt="team"
                            />
                            <img 
                              className="player-image"
                              src={getPlayerImage(p.ign)}
                              onError={e => e.target.src = getPlayerImage('default')}
                              alt="player"
                            />
                          </PictureDiv>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TeamDiv className="text-center p-2">
                            { p.ign.replace(/^[A-Za-z0-9]{0,4}_/g, '') }
                          </TeamDiv>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <AttributeDiv className="text-center">
                            ÖLDÜRME
                          </AttributeDiv>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TeamDiv className="bottom text-center">
                            <p className="player-score">{ parseInt(p.totalKills) }</p>
                          </TeamDiv>
                        </td>
                      </tr>
                    </tbody>
                  </PlayerTable>
                </td>
              );
            })
          }
        </tr>
        <tr>
          <td colSpan="5">
            <TwireDiv className={`mt-2 text-center ${ show ? 'leaderboard-show' : 'leaderboard-hide' }`}>
              <img
                src="/assets/img/twire/powered-by-twire-no-background.png"
                alt="twire"
              />
            </TwireDiv>
          </td>
        </tr>
      </tbody>
    </Leaderboard>
  );
}

export default LeaderboardBody;