import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { ListGroup } from 'reactstrap';

export default styled(ListGroup)`
  .list-group-item {
    background: ${styles.tertiaryDark};
    color: ${styles.secondary};
    ${props => props.borderColor ? 'border-color: ' + props.borderColor + ';' : ''}
  }

  .list-group-item::before {
    background-color: ${styles.tertiary} !important;
  }

  .list-group-item.text-muted {
    background: ${styles.tertiary} !important;
    color: ${styles.secondary};
    ${props => props.borderColor ? 'border-color: ' + props.borderColor + ';' : ''}
  }
`;