import React from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.div`
  font-size: 35px;
  .team-logo-main  {
    height: 400px;

    img {
      height: 220px;
    }
  }

  &.component-show table tbody {
    .team-logo-main, .stats {
      transform: rotate3d(1, 0, 0, 90deg);
      animation: 
        rotateIn 0.5s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards,
        fadeIn 1s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards;
    }

    tr:nth-child(2) {
      transform: rotate3d(1, 0, 0, 90deg);
      animation: 
        rotateIn 0.5s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards,
        fadeIn 1s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards;
    }

    @keyframes rotateIn {
      from {transform: rotate3d(1, 0, 0, 90deg);}
      to {transform: rotate3d(1, 0, 0, 0deg);}
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide table tbody {
    .team-logo-main, .stats {
      animation: 
        rotateOut 0.5s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards,
        fadeOut 1s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards;
    }

    tr:nth-child(2) {
      transform: rotate3d(1, 0, 0, 0deg);
      animation: 
        rotateOut 0.5s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards,
        fadeOut 1s ease ${props => ((props.rank > 5 ? props.rank - 5 : props.rank)) * 0.3}s forwards;
    }

    @keyframes rotateOut {
      from {transform: rotate3d(1, 0, 0, 0deg);}
      to {transform: rotate3d(1, 0, 0, 90deg);}
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }

  td {
    height: 65px;
  }

  .rank {
    width: 65px;
    background: ${ props => props.colors && props.colors.primary ? props.colors.primary : '#FFF'};
    color: ${ props => props.colors && props.colors.rankText ? props.colors.rankText : '#000'};
  }

  .team-logo {
    padding: 0;
    width: 315px;
    color: ${ props => props.colors && props.colors.teamText ? props.colors.teamText : '#FFF'};

    div {
      background: ${ props => props.colors && props.colors.secondary ? props.colors.secondary : '#404040'};
      padding-right: 15px;
      padding-left: 80px;
      position: relative;
      overflow: hidden;
  
      img {
        height: 70px;
        position: absolute;
        top: -10px;
        left: 10px;
        transform: rotate(30deg);
  
        &:first-child {
          filter: blur(15px);
        }
      }
    }
  }

  .stats {
    font-size: 50px;
    color: ${ props => props.colors && props.colors.headerText ? props.colors.headerText : '#FFF'};
  }
`;

function LeaderboardPlayer(props) {
  const { player, rank, show, overlayColor } = props;

  return (
    <td>
      <Wrapper
        className={ show ? 'component-show' : 'component-hide' }
        rank={rank}
        colors={overlayColor}
      >
        <table>
          <tbody>
            <tr>
              <td colSpan="2" className="team-logo-main">
                <img
                  src={getTeamLogo(player.teamName)}
                  onError={(e) => e.target.src = getTeamLogo('default')}
                  alt="team logo"
                />
              </td>
            </tr>
            <tr>
              <td className="rank">{ rank }</td>
              <td className="team-logo">
                <div>
                  <img
                    src={getTeamLogo(player.teamName)}
                    onError={(e) => e.target.src = getTeamLogo('default')}
                    alt="team logo"
                  />
                  <img 
                    src={getTeamLogo(player.teamName)} 
                    alt="team logo" 
                    onError={(e) => e.target.src = getTeamLogo('default')}
                  />
                  { player.ign }
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="stats">
                <div>{ player.totalKills }</div>
              </td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    </td>
  );
}

export default LeaderboardPlayer;