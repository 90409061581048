import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo, numberToPlacementSurfix } from '../../../../../../util/CommonUtil';

const teamLogoSize = 220;
const padding = 20;
const height = 390;

const maps = {
  m: 'Miramar',
  v: 'Vikendi',
  e: 'Erangel',
  s: 'Sanhok',
}

const Wrapper = styled.div`
  width: ${props => props.colWidth}px;
  margin-right: ${props => props.margin}px;
  float: left;
  overflow: hidden;
  text-transform: uppercase;

  &.team-show {
    height: 0;
    animation: open-team .75s ease ${ props => props.delayShow * 0.3 + 0.2 }s forwards;

    @keyframes open-team {
      from {height: 0;}
      to {height: ${height}px;}
    }
  }

  &.team-hide {
    height: ${height}px;
    animation: close-team .75s ease ${ props => props.delayHide * 0.3 }s forwards;

    @keyframes close-team {
      from {height: ${height}px;}
      to {height: 0px;}
    }
  }

  .empty {
    opacity: .3;
  }
`;

const TeamRow = styled(Row)`
  font-size: 30px;
  line-height: 30px;
  background: ${styles.tertiary};
  font-weight: 600;
  color: ${styles.secondary};
  border-top: 1px solid ${styles.primary};
  padding: 12px;

  span {
    font-size: 20px;
  }
`;

const LogoRow = styled(Row)`
  background: ${styles.tertiary};
  font-weight: 600;
  color: ${styles.secondary};

  img {
    height: ${teamLogoSize}px;
    margin: ${padding}px 0;
    z-index: 5;
  }

  span {
    position: relative;
    z-index: 10;
  }
`;

function LastWinnersBody(props) {
  const { index, matches, colWidth, numOfMatches, map, show } = props;
  let { currentMatchNumber } = props;
  matches.forEach(lw => {
    if (lw.teamName) {
      currentMatchNumber--;
    }
  });

  return (
    <Wrapper
      colWidth={colWidth}
      margin={ 0 }
      className={ show ? 'team-show' : 'team-hide' }
      delayShow={index}
      delayHide={numOfMatches - index}
    >
      <LogoRow
        className="text-center"
        colWidth={colWidth}
      >
        <Col xs="12" className="mt-3">
          <h3>{index + currentMatchNumber}<sup>{ numberToPlacementSurfix(index + currentMatchNumber) }</sup> match</h3>
          <h6>{ matches[index] ? 'Winner' : '-' }</h6>
        </Col>
        <Col xs="12">
          <img 
            src={getTeamLogo(matches[index] ? matches[index].teamName : getTeamLogo('default'))} 
            className={matches[index] ? '' : 'empty'}
            alt="team logo" 
            onError={(e) => e.target.src = getTeamLogo('default')}
          />
        </Col>
      </LogoRow>
      <TeamRow className="text-center">
        <Col xs="12">
          <span className={matches[index] ? '' : 'empty'}>{ matches[index] ? matches[index].teamName : maps[map] }</span>
        </Col>
      </TeamRow>
    </Wrapper>
  );
}

export default LastWinnersBody;