import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardTeam from './LeaderboardTeam';

const WIDTH = 1796;

const LeaderboardWrapper = styled.div`
  width: 1920px;
  height: 1080px;
  padding-top: 95px;

  table {
    // background: gray;
    font-family: 'Tungsten-Semibold';
    text-transform: uppercase;
    font-size: 33px;
    line-height: ${props => props.lineHeight}px;
    margin: 0 auto;
  
    width: ${WIDTH}px;
  
    table {
      width: 100%;
  
      &.header-table {
        height: 120px;
      }
  
      td {
        padding: 0;
        margin: 0;
      }
  
      td.th-rank, td.th-match, td.th-team, td.th-placement-points, td.th-kill-points, td.th-total-points {
        font-size: 40px;
        line-height: 45px;
      }
  
      td.spacing {
        width: 50px;
      }
  
      .rank {
        width: 75px;
      }

      .match {
        width: 80px;
      }
  
      .team {
        width: 300px;
      }
  
      .placement-points {
        width: 140px;
      }
  
      .kill-points {
        width: 140px;
      }
  
      .total-points {
        width: 140px;
      }
    }
  }
`;

class ProtalityTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, opts, show, exportOverlay } = this.props;
    if (leaderboard.length % 2 !== 0) leaderboard.push({ name: '', totalKills: '', totalPoints: '' });
    const numberOfHalfTeams = leaderboard.length / 2;
    const firstHalfTeams = leaderboard.slice(0, numberOfHalfTeams);
    const secondHalfTeams = leaderboard.slice(numberOfHalfTeams, leaderboard.length);

    if (!loaded) return null;

    return (
      <LeaderboardWrapper
        lineHeight={leaderboard.length > 16 ? 38 : 55}
      >
        <table>
          <tbody>
            <tr>
              <td colSpan="3">
                <LeaderboardHeader
                  opts={opts}
                  show={show}
                  numberOfRows={numberOfHalfTeams}
                  exportOverlay={exportOverlay}
                />
              </td>
            </tr>
            {
              [...new Array(numberOfHalfTeams).keys()].map(i => {
                return (
                  <tr className="team-tr">
                    <td>
                      <LeaderboardTeam
                        leftTeam={firstHalfTeams[i]}
                        leftTeamRank={i + 1}
                        rightTeam={secondHalfTeams[i]}
                        rightTeamRank={i + numberOfHalfTeams + 1}
                        index={i}
                        show={show}
                        exportOverlay={exportOverlay}
                        numberOfRows={numberOfHalfTeams}
                        side={'left'}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </LeaderboardWrapper>
    );
  }
}

export default ProtalityTeamLeaderboard;
