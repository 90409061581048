// Types of action for authenticated user
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const UNSET_AUTH_USER = 'UNSET_AUTH_USER';
// Login action
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
// Get logged in user
export const REQUEST_LOGGED_IN_USER = 'REQUEST_LOGGED_IN_USER';
// Request tournament organizer token
export const REQUEST_TOURNAMENT_ORGANIZER_TOKEN = 'REQUEST_TOURNAMENT_ORGANIZER_TOKEN';
export const REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS = 'REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS';
// Get user subscription action
export const REQUEST_USER_SUBSCRIPTION = 'REQUEST_USER_SUBSCRIPTION';
export const REQUEST_USER_SUBSCRIPTION_PENDING = 'REQUEST_USER_SUBSCRIPTION_PENDING';
export const REQUEST_USER_SUBSCRIPTION_SUCCESS = 'REQUEST_USER_SUBSCRIPTION_SUCCESS';
export const REQUEST_USER_SUBSCRIPTION_ERROR = 'REQUEST_USER_SUBSCRIPTION_ERROR';