import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { DropdownMenu } from 'reactstrap';

export default styled(DropdownMenu)`
  background: ${styles.tertiaryDark};
  border-color: ${styles.infoDark};

  .dropdown-header {
    background: ${styles.tertiary};
    color: ${styles.secondary};
    border-color: ${styles.infoDark};
  }

  .dropdown-item {
    i {
      color: ${styles.secondary};
    }
    background: ${styles.tertiaryDark};
    color: ${styles.secondary};
    border: none;
  }

  .dropdown-item:hover {
    background: ${styles.primary};
  }

  .dropdown-divider {
    border-color: ${styles.tertiary};
  }
`;