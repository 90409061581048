import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { COMPONENT_STATE_LEFT_PLAYER, COMPONENT_STATE_LEFT_TEAM, COMPONENT_STATE_MATCH, COMPONENT_STATE_PLAYER, COMPONENT_STATE_RIGHT_PLAYER, COMPONENT_STATE_RIGHT_TEAM, COMPONENT_STATE_TEAM } from '../../../../constants';
import { Select } from '../../../../../../components/StyledCoreUI';

function MatchSelect(props) {
  const { component, allMatches = true, requestChangeComponent } = props;

  const options = [];
  if (allMatches) options.push({ key: component.args.shard_info, value: 'ALL' });
  // if (lastMatch) options.push({ key: -1, value: 'LAST MATCH' });

  return (
    <FormGroup>
      <Label htmlFor='match'>Match</Label>
      <Select
        name='match'
        color={styles.tertiaryDark}
        value={component.state[COMPONENT_STATE_MATCH] ? component.state[COMPONENT_STATE_MATCH] : component.args.shard_info}
        onChange={id => requestChangeComponent(component, {
          state: {
            [COMPONENT_STATE_MATCH]: isNaN(id) ? id : parseInt(id),
            [COMPONENT_STATE_TEAM]: null,
            [COMPONENT_STATE_LEFT_TEAM]: null,
            [COMPONENT_STATE_RIGHT_TEAM]: null,
            [COMPONENT_STATE_PLAYER]: null,
            [COMPONENT_STATE_LEFT_PLAYER]: null,
            [COMPONENT_STATE_RIGHT_PLAYER]: null,
          },
          args: isNaN(id) ? { [COMPONENT_STATE_MATCH]: null } : { [COMPONENT_STATE_MATCH]: parseInt(id) },
        })}
        options={[
          ...options,
          ...component.matches.sort((a, b) => a.startAt > b.startAt ? -1 : 1).map(m => {
            return { key: m.id, value: m.name };
          })
        ]}
        warning={!(component.state[COMPONENT_STATE_MATCH] || component.args.shard_info) && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(MatchSelect);
