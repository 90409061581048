import {
  REQUEST_TOURNAMENT_PLAYERS_PENDING,
  REQUEST_TOURNAMENT_PLAYERS_SUCCESS,
  REQUEST_TOURNAMENT_PLAYERS_ERROR,
} from './types';

const INITIAL_STATE = {
  tournamentPlayersLoaded: false,
  tournamentPlayers: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request tournament players pending
    case REQUEST_TOURNAMENT_PLAYERS_PENDING:
      return {
        ...state,
        tournamentPlayersLoaded: false,
        tournamentPlayers: [],
      }

    // On request tournament players success
    case REQUEST_TOURNAMENT_PLAYERS_SUCCESS:
      return {
        ...state,
        tournamentPlayersLoaded: true,
        tournamentPlayers: action.payload,
      }

    // On request tournament players success
    case REQUEST_TOURNAMENT_PLAYERS_ERROR:
      return {
        ...state,
        tournamentPlayersLoaded: true,
        tournamentPlayers: [],
      }

    default:
      return state;
  }
}

export default reducer;