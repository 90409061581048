import React, { Component } from 'react';
import Team from './Team';

class BeingLiveMapTeams extends Component {
  render() {
    const { loaded, teams, show } = this.props;
    if (teams.length < 16) Array.from(Array(16-teams.length)).forEach(() => teams.push({ name: '', players: [{ status: 'death' }] }));

    return (
      loaded ?
      teams.map((t, i) => {
        return (
          <Team
            show={show}
            team={t}
            index={i > 7 ? i - 8 : i}
            side={i < 8 ? 'left' : 'right'}
          />
        );
      })
      :
      null
    );
  }
}

export default BeingLiveMapTeams;