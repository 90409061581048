import React from 'react';
import { connect } from 'react-redux';
import Default from './default/TwireTeamLeaderboard';
import PSL from './psl/PslTeamLeaderboard';
import PJC from './pjc/PjcTeamLeaderboard';
import RYER from './ryer/RyerTeamLeaderboard';
import Protality from './protality/ProtalityTeamLeaderboard';

const PUBGTeamLeaderboardOverlay = (props) => {
  const { shard, overlayDesign } = props;

  return (
    shard.includes('psl') || overlayDesign === 'psl' ? 
    <PSL {...props} />
    :
    shard.includes('ryer') ? 
    <RYER {...props} />
    :
    overlayDesign === 'protality' ? 
    <Protality {...props} />
    :
    overlayDesign === 'pjc' || shard.includes('test-pjc21') || shard.includes('pjc') ? 
    <PJC {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  leaderboard: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGTeamLeaderboardOverlay);
