import { TWIRE_ASSETS_URL } from "./Constants";

/**
 * Convert an image 
 * to a base64 url
 * @param  {String}   url      
 */
export function convertImgToBase64URL(url) {
  return new Promise((resolve, reject) => {
    getImageAsBase64(url)
      .then(resolve)
      .catch(() => {
        getImageAsBase64(TWIRE_ASSETS_URL + "pubg/team-logos/default.png")
          .then(resolve)
          .catch(reject);
      });
  });
}

function getImageAsBase64(url) {
  return new Promise((resolve, reject) => {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url + '?_=' + new Date().getTime());
      xhr.responseType = "blob";
      xhr.onerror = function () { reject("Network error.") };
      xhr.onload = function () {
        if (xhr.status === 200) {
          var reader = new FileReader();
          reader.readAsDataURL(xhr.response);
          reader.onloadend = function () {
            var base64data = reader.result;
            resolve(base64data);
          }
        } else {
          reject("Loading error:" + xhr.statusText);
        }
      };
      xhr.send();
    } catch {
      reject();
    }
  });
}