import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

const TeamWrapper = styled.table`
  color: #f2a12b;

  span {
    position: relative;
  }

  td {
    overflow: hidden;
  }

  td.title-left-td {
    color: #d8d9d9;
    font-size: 38px;
    padding-left: 18px;
    position: relative;
    ${props => props.selectedSpecificMatch ? 'padding-bottom: 60px;' : ''}

    img {
      margin-left: 170px;
      margin-bottom: 10px;
    }

    .number-of-matches {
      position: absolute;
      top: 220px;
      left: 30px;
      font-size: 65px;
    }
  }

  td.title-right-td {
    color: #d8d9d9;
    ${props => props.selectedSpecificMatch ? 'padding-bottom: 60px;' : ''}

    .match-title {
      width: ${props => props.selectedSpecificMatch ? 420 : 620}px;
      font-size: 60px;
      font-family: 'Druk-Medium';
      letter-spacing: 3px;
      text-transform: uppercase;
  
      span {
        background: #00000080;
        padding: 10px 30px;
      }

      div {
        margin-left: 150px;
        width: 420px;
        height: 96px;
        line-height: 77px;
        background: #00000080;
        padding: 10px 30px;
      }

      &.first-match-title {
        padding-bottom: 5px;
      }
    }
    
    .powered-by-twire {
      font-size: 20px;
      letter-spacing: 6px;

      .row {
        width: 150px;
        margin: 0;
        padding: 0;
        margin-left: 5px;
        height: 48px;
        background: #00000080;

        .col {
          padding: 0;
        }

        p {
          margin: 0px 0 0;
          padding: 0;
        }
      }

      img {
        height: 65px;
        margin-top: -18px;
      }
    }
  }

  table {
    height: 120px;
  }

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease 0s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    span {
      bottom: -100px;
      animation: slideUp 0.3s ease 0s forwards;
  
      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease ${props => 1.1}s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    span {
      bottom: 0px;
      animation: slideDown 0.3s ease ${props => 1.1}s forwards;
  
      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }
`;

class LeaderboardHeader extends Component {
  render() {
    const { show, numberOfRows, exportOverlay, opts } = this.props;
    const { numberOfMatches } = opts;

    return (
      <TeamWrapper
        className={`header-table ${ exportOverlay ? '' : show ? 'component-show' : 'component-hide' }`}
        numberOfRows={numberOfRows}
        selectedSpecificMatch={numberOfRows <= 8}
      >
        <tbody>
          <tr>
            <td
              colSpan={ numberOfRows <= 8 ? 6 : 7 }
              className="text-center title-left-td"
            >
              <span>
                <img
                  src="/assets/img/protality/logo.png"
                  alt="logo"
                />
              </span>
              {
                numberOfRows <= 8 ?
                <span className="number-of-matches">
                  MAÇ {numberOfMatches}/20
                </span>
                :
                null
              }
            </td>
            <td
              colSpan={ numberOfRows <= 8 ? 5 : 6 }
              className="text-center title-right-td"
              >
                <table>
                  <tbody>
                    {
                      numberOfRows <= 8 ?
                      <>
                        <tr>
                          <td className="match-title first-match-title">
                            <div>Final AŞAMASI</div>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="match-title">
                            <div>Gün 4 Sıralaması</div>
                          </td>
                          <td className="powered-by-twire">
                            <span>
                              <Row className="text-center">
                                <Col>
                                  <p>POWERED BY</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <img
                                    src="/assets/img/twire/logo-bottom-square.png"
                                    alt="twire-logo"
                                  />
                                </Col>
                              </Row>
                            </span>
                          </td>
                        </tr>
                      </>
                      :
                      <tr>
                        <td className="match-title">
                          <span>GRUP AŞAMASI GENEL SIRALAMA</span>
                        </td>
                        <td className="powered-by-twire">
                          <span>
                            <Row className="text-center">
                              <Col>
                                <p>POWERED BY</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <img
                                  src="/assets/img/twire/logo-bottom-square.png"
                                  alt="twire-logo"
                                />
                              </Col>
                            </Row>
                          </span>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </td>
          </tr>
          <tr>
            <td className="th-rank rank text-center"><span>#</span></td>
            {
              numberOfRows > 8 ?
              <td className="th-match match text-center"><span>O. Maç</span></td>
              :
              null
            }
            <td className="th-team team text-center"><span>TAKIM</span></td>
            <td className="th-placement-points placement-points text-center"><span>SIRALAMA P.</span></td>
            <td className="th-kill-points kill-points text-center"><span>KILL P.</span></td>
            <td className="th-total-points total-points text-center"><span>TOPLAM P.</span></td>
            <td className="spacing"></td>
            <td className="th-rank rank text-center"><span>#</span></td>
            {
              numberOfRows > 8 ?
              <td className="th-match match text-center"><span>O. Maç</span></td>
              :
              null
            }
            <td className="th-team team text-center"><span>TAKIM</span></td>
            <td className="th-placement-points placement-points text-center"><span>SIRALAMA P.</span></td>
            <td className="th-kill-points kill-points text-center"><span>KILL P.</span></td>
            <td className="th-total-points total-points text-center"><span>TOPLAM P.</span></td>
          </tr>
        </tbody>
      </TeamWrapper>
    );
  }
}

export default LeaderboardHeader;
