import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { Row, Col } from 'reactstrap';

const Wrapper = styled.div`
  text-transform: uppercase;
  font-family: 'agency_fb';
  width: ${props => props.rowWidth}px;
  margin-top: 35px;
  background: ${styles.pslColor};
  height: 180px;

  &.head-to-head-header-show {
    display: block;
    width: 0px;
    animation: headerSlideDown 0.5s ease 0.2s forwards;
  
    @keyframes headerSlideDown {
      from {width: 0px;}
      to {width: ${props => props.rowWidth}px;}
    }

    p {
      opacity: 0;
      animation: headerFadeIn 0.6s ease 0.6s forwards;
  
      @keyframes headerFadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  }

  &.head-to-head-header-hide {
    width: ${props => props.rowWidth}px;
    animation: headerSlideUp 0.5s ease 1s forwards;
  
    @keyframes headerSlideUp {
      from {width: ${props => props.rowWidth}px;}
      to {width: 0px;}
    }

    p {
      opacity: 1;
      animation: headerFadeOut 0.6s ease 0.6s forwards;
  
      @keyframes headerFadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  img {
    height: 250px;
    position: absolute;
    top: 0;
    z-index: 100;
  }

  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .next-match {
    vertical-align: middle;
    font-size: 35px;
    letter-spacing: 8px;
    filter: drop-shadow(0px 0px 4px #00000040);
    line-height: 1;
    margin-left: 5px;
  }

  .next-map {
    vertical-align: middle;
    font-size: 130px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 4px #00000080);
    line-height: 1;
  }
`;

function HeadToHeadHeader(props) {
  const { rowWidth, show } = props;

  return (
    <Wrapper
      className={'mb-2 ' + (show ? 'head-to-head-header-show' : 'head-to-head-header-hide')}
      rowWidth={rowWidth}
    >
      <img
        src={`/assets/img/psl/logo-winter.png`}
        alt={`PSL logo`}
      />
      <Row>
        <Col xs="3">
        </Col>
        <Col xs="9">
          <p className="next-match">Teams</p>
          <p className="next-map">Head To head</p>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default HeadToHeadHeader;