import { call } from 'redux-saga/effects';
import gql from "graphql-tag";
import { privateClient, publicClient } from './client';

/**
 * Makes a query request to server (public or private)
 * 
 * @param {*} client public or private client
 * @param {*} query query that needs to be executed
 * @param {*} variables varaibles
 */
const queryRequest = function* (client, query, variables) {
  return yield call([client, 'query'], {
    query: gql(query),
    fetchPolicy: 'no-cache',
    variables: variables
  });
}

/**
 * Makes a mutation request to server (public or private)
 * 
 * @param {*} client public or private client
 * @param {*} mutation mutation that needs to be executed
 * @param {*} variables varaibles
 */
const mutationRequest = function* (client, mutation, variables) {
  return yield call([client, 'mutate'], { 
    mutation: gql(mutation), 
    variables: variables 
  });
}

/**
 * Makes a subscription request to server (public or private)
 * 
 * @param {*} client public or private client
 * @param {*} subscription subscription that needs to be executed
 * @param {*} variables variables
 * @param {*} next next function that will be executed when data arrives
 * @param {*} error error function that will be executed on error
 */
const subscriptionRequest = function* (client, subscription, variables, next, error) {
  const result = yield call([client, 'subscribe'], {
    query: gql(subscription),
    variables: variables
  });
  result.subscribe({
    next: data => next(data),
    error: err => error(err),
  });
}

/**
 * Public query helper
 * 
 * @param {*} query query that needs to be executed
 * @param {*} variables variables
 */
export const publicQuery = function* (query, variables) {
  return yield queryRequest(publicClient, query, variables);
};

/**
 * Private query helper
 * 
 * @param {*} query query that needs to be executed
 * @param {*} variables variables
 */
export const privateQuery = function* (query, variables) {
  return yield queryRequest(privateClient, query, variables);
};

/**
 * Public mutation helper
 * 
 * @param {*} mutation mutation that needs to be executed
 * @param {*} variables variables
 */
export const publicMutation = function* (mutation, variables) {
  return yield mutationRequest(publicClient, mutation, variables);
}

/**
 * Private mutation helper
 * 
 * @param {*} mutation mutation that needs to be executed
 * @param {*} variables variables
 */
export const privateMutation = function* (mutation, variables) {
  return yield mutationRequest(privateClient, mutation, variables);
}

/**
 * Public subscription helper
 * 
 * @param {*} subscription subscription that needs to be executed
 * @param {*} variables variables
 * @param {*} next next function that will be executed when data arrives
 * @param {*} error error function that will be executed on error
 */
export const publicSubscription = function* (subscription, variables, next, error) {
  yield subscriptionRequest(publicClient, subscription, variables, next, error);
}

/**
 * Private subscription helper
 * 
 * @param {*} subscription subscription that needs to be executed
 * @param {*} variables variables
 * @param {*} next next function that will be executed when data arrives
 * @param {*} error error function that will be executed on error
 */
export const privateSubscription = function* (subscription, variables, next, error) {
  yield subscriptionRequest(privateClient, subscription, variables, next, error);
}