import React from 'react';
import { connect } from 'react-redux';
import ElisaPlayerStats from './elisa/ElisaPlayerStats';

const CSGOPlayerStatsOverlay = (props) => {
  const { overlayDesign } = props;

  return (
    overlayDesign === 'elisa' ?
    <ElisaPlayerStats {...props} />
    :
    <div>
      No Design
    </div>
  );
}

const mapStateToProps = state => ({
  player: state.overlay.csgo || {},
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(CSGOPlayerStatsOverlay);
