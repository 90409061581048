import React, { Component } from 'react';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import { PUBG_GAME } from '../../../../../util/Games';

class PUBGCurrentMatchController extends Component {
  render() {
    return (
      <ControllerBase
        game={PUBG_GAME}
        {...this.props}
      />
    );
  }
}

export default PUBGCurrentMatchController;
