import React from 'react';
import { connect } from 'react-redux';
import Default from './default/TwirePlayerLeaderboard';
import PSL from './psl/PslPlayerLeaderboard';
import PJC from './pjc/PjcPlayerLeaderboard';
import PJC21 from './pjc21/Pjc21PlayerLeaderboard';
import RYER from './ryer/RyerPlayerLeaderboard';

const PUBGPlayerLeaderboardOverlay = (props) => {
  const { shard, overlayDesign } = props;

  return (
    shard.includes('psl') || overlayDesign === 'psl' ? 
    <PSL {...props} />
    :
    shard.includes('ryer') ? 
    <RYER {...props} />
    :
    overlayDesign === 'pjc' ? 
    <PJC {...props} />
    :
    overlayDesign === 'pjc21' ? 
    <PJC21 {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  leaderboard: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGPlayerLeaderboardOverlay);