import React from 'react';
import styled from 'styled-components';

const HeaderRow = styled.tr`
  background: url('/assets/img/being2/header.png');
  font-family: 'BarlowCondensed-Light';
  color: #000;
  font-size: 22px;

  td {
    width: 298px !important;
    height: 49px !important;

    letter-spacing: 8px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow>
      <td colSpan="7"><strong>LEADERBOARD</strong></td>
    </HeaderRow>
  );
}

export default LeaderboardHeader;