import { all, fork } from 'redux-saga/effects';

import auth from './auth/saga';
import currentMatch from './currentMatch/saga';
import dashboard from './dashboard/saga';
import notification from './notification/saga';
import tournaments from './tournaments/saga';
import tournamentFilters from './tournamentFilters/saga';
import teamLeaderboard from './teamLeaderboard/saga';
import playerLeaderboard from './playerLeaderboard/saga';
import todaysWinners from './todaysWinners/saga';
import mapVeto from './mapVeto/saga';
import tournament from './tournament/saga';
import tournamentPlayers from './tournamentPlayers/saga';
import tournamentTeams from './tournamentTeams/saga';
import customTournaments from './customTournaments/saga';
import overlay from './overlay/saga';
import organization from './organization/saga';
import headToHeadTeams from './headToHeadTeams/saga';
import headToHeadPlayers from './headToHeadPlayers/saga';
import teamSearch from './teamSearch/saga';

// The root saga
function* rootSaga() {
    // Add all active sagas here to combine them
    yield all([
        fork(auth),
        fork(currentMatch),
        fork(dashboard),
        fork(notification),
        fork(tournaments),
        fork(tournamentFilters),
        fork(teamLeaderboard),
        fork(playerLeaderboard),
        fork(todaysWinners),
        fork(mapVeto),
        fork(tournament),
        fork(tournamentPlayers),
        fork(tournamentTeams),
        fork(customTournaments),
        fork(overlay),
        fork(organization),
        fork(headToHeadTeams),
        fork(headToHeadPlayers),
        fork(teamSearch),
    ]);
}

export default rootSaga;