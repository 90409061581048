import React from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { AppSwitch } from '../../../../../../components/StyledCoreUI';

function CheckBox(props) {
  const { component, label, stateAttribute, requestChangeComponent } = props;

  return (
    <FormGroup>
      <Row>
        <Col xs="12">
          <Label className="mb-2">{ label }</Label>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <AppSwitch 
            color="primary" 
            checked={component.state[stateAttribute]} 
            onClick={() => requestChangeComponent(component, { state: {
              [stateAttribute]: !component.state[stateAttribute],
            }})}
          />
        </Col>
      </Row>
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(CheckBox);
