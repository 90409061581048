import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { AppAside } from '@coreui/react';

export default styled(AppAside)`
  background: ${styles.tertiaryDark};
  border-color: ${styles.tertiaryDark};
  overflow: auto;

  .tab-content {
    background: ${styles.tertiaryDark};
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: ${styles.transparent};
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
	  background-color: ${styles.transparent};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
  }
`;