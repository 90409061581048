import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const TeamRow = styled.tr`
  text-transform: uppercase;

  &.team-death {
    opacity: 0.7;
  }

  td {
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    background: ${styles.tertiary}CC;
    color: ${styles.secondary}AA;

    img {
      height: 20px !important;
    }
  }

  .team-rank {
    width: 30px !important;
    color: ${styles.secondary};
    background: ${styles.tertiary};
  }

  .team-points {
    width: 45px !important;
    color: ${styles.secondary};
  }

  .team-initial {
    padding-left: 7px;
    width: 60px !important;
  }

  .team-alive {
    padding: 0 6px;
    td {
      background: none;
      padding: 3px 2px;
      div {
        width: 3px;
        height: 17px;
      }
    }

    .alive {
      background: #ff8317;
    }
    .death {
      background: #60606090;
    }
  }
`;

function LeaderboardBody(props) {
  const { team, rank } = props;

  return (
    <TeamRow className={team.deaths >= team.initialPlayers ? 'team-death' : ''}>
      <td className="team-rank">
          <strong>{ rank }</strong>
      </td>
      <td className="team-initial text-left"><strong>{ team.initial }</strong></td>
      <td className="team-points"><strong>{ team.points }</strong></td>
      <td className="team-alive">
        <table>
          <tbody>
            <tr>
              {
                Array.from(Array(4).keys()).map(i => {
                  return (
                    <td>
                      <div className={team.initialPlayers-i > team.deaths ? 'alive' : 'death'}></div>
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </td>
    </TeamRow>
  );
}

export default LeaderboardBody;