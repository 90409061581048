import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';
// import LeaderboardFooter from './LeaderboardFooter';
import LeaderboardColumnTitle from './LeaderboardColumnTitle';
// import LeaderboardTwireFooter from './LeaderboardTwireFooter';

const LeaderboardTable = styled.table`
  width: 250px;
  text-align: center;
  vertical-align: middle;

  font-family: 'BebasNeue Regular';
  font-size: 1.4em;

  &.bc-show {
    margin-left: -260px;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -260px;}
      to {margin-left: 40px;}
    }
  }

  &.bc-hide {
    margin-left: 10;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 10px;}
      to {margin-left: -260px;}
    }
  }
`;

class ProtalityLiveSurvivalLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, overlayColor } = this.props;

    return (
      loaded ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <LeaderboardHeader
            overlayColor={overlayColor}
          /><LeaderboardColumnTitle />
          {
            leaderboard.map((t, i) => {
              return (
                <LeaderboardBody
                  team={t}
                  rank={i+1}
                />
              );
            })
          }
          {/* <LeaderboardFooter /> */}
          {/* <LeaderboardTwireFooter /> */}
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default ProtalityLiveSurvivalLeaderboard;