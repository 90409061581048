import React from 'react';
import { connect } from 'react-redux';
import Default from './default/TwireHeadToHeadTeams';
import PSL from './psl/PslHeadToHeadTeams';
import PJC from './pjc/PjcTeamHeadToHead';
import PJC21 from '../../teamHeadToHead/overlay/pjc21/Pjc21TeamHeadToHead';
import RYER from './ryer/RyerHeadToHeadTeams';

const PUBGTeamHeadToHeadOverlay = (props) => {
  const { shard, overlayDesign } = props;

  return (
    overlayDesign === 'psl' || shard.includes('psl') ? 
    <PSL {...props} />
    :
    shard.includes('ryer') ? 
    <RYER {...props} />
    :
    overlayDesign === 'pjc' ? 
    <PJC {...props} />
    :
    overlayDesign === 'pjc21' ?
    <PJC21 {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  left: state.overlay.left ? state.overlay.left.pubg : {},
  right: state.overlay.right ? state.overlay.right.pubg : {},
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGTeamHeadToHeadOverlay);