import {
  // Component Names
  PLAYER_STATS_COMPONENT_NAME,
  PLAYER_LEADERBOARD_COMPONENT_NAME,
  PLAYER_HEAD_TO_HEAD_COMPONENT_NAME,
  TEAM_STATS_COMPONENT_NAME,
  TEAM_HEAD_TO_HEAD_COMPONENT_NAME,
  TEAM_LEADERBOARD_COMPONENT_NAME,
  SCOREBOARD_COMPONENT_NAME,
  MAP_VETO_COMPONENT_NAME,
  // Component Types
  PLAYER_STATS_COMPONENT_TYPE,
  PLAYER_LEADERBOARD_COMPONENT_TYPE,
  PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
  TEAM_STATS_COMPONENT_TYPE,
  TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  TEAM_LEADERBOARD_COMPONENT_TYPE,
  SCOREBOARD_COMPONENT_TYPE,
  MAP_VETO_COMPONENT_TYPE,
  // State to opts mappings
  PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  PLAYER_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  SCOREBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  MAP_VETO_COMPONENT_STATE_TO_OPTS_MAPPING,
  // Additional component parameters
  MAP_VETO_VETO_STATUS,
} from '../constants';

import PlayerStatsController from './playerStats/controller/ValorantPlayerStatsController';
import PlayerStatsOverlay from './playerStats/overlay/ValorantPlayerStatsOverlay';
import PlayerLeaderboardController from './playerLeaderboard/controller/ValorantPlayerLeaderboardController';
import PlayerLeaderboardOverlay from './playerLeaderboard/overlay/ValorantPlayerLeaderboardOverlay';
import PlayerHeadToHeadController from './playerHeadToHead/controller/ValorantPlayerHeadToHeadController';
import PlayerHeadToHeadOverlay from './playerHeadToHead/overlay/ValorantPlayerHeadToHeadOverlay';
import TeamStatsController from './teamStats/controller/ValorantTeamStatsController';
import TeamStatsOverlay from './teamStats/overlay/ValorantTeamStatsOverlay';
import TeamHeadToHeadController from './teamHeadToHead/controller/ValorantTeamHeadToHeadController';
import TeamHeadToHeadOverlay from './teamHeadToHead/overlay/ValorantTeamHeadToHeadOverlay';
import TeamLeaderboardController from './teamLeaderboard/controller/ValorantTeamLeaderboardController';
import TeamLeaderboardOverlay from './teamLeaderboard/overlay/ValorantTeamLeaderboardOverlay';
import ScoreboardController from './scoreboard/controller/ValorantScoreboardController';
import ScoreboardOverlay from './scoreboard/overlay/ValorantScoreboardOverlay';
import MapVetoController from '../common/mapVeto/controller/MapVetoController';
import MapVetoOverlay from '../common/mapVeto/overlay/MapVetoOverlay';

export default {
  PlayerStats: {
    width: 4,
    name: PLAYER_STATS_COMPONENT_NAME,
    type: PLAYER_STATS_COMPONENT_TYPE,
    controller: PlayerStatsController,
    overlay: PlayerStatsOverlay,
    maxStatistics: 5,
    stateOptsMapping: PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  PlayerLeaderboard: {
    width: 4,
    name: PLAYER_LEADERBOARD_COMPONENT_NAME,
    type: PLAYER_LEADERBOARD_COMPONENT_TYPE,
    controller: PlayerLeaderboardController,
    overlay: PlayerLeaderboardOverlay,
    maxStatistics: 1,
    stateOptsMapping: PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  PlayerHeadToHead: {
    width: 4,
    name: PLAYER_HEAD_TO_HEAD_COMPONENT_NAME,
    type: PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
    controller: PlayerHeadToHeadController,
    overlay: PlayerHeadToHeadOverlay,
    maxStatistics: 4,
    stateOptsMapping: PLAYER_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  TeamStats: {
    width: 4,
    name: TEAM_STATS_COMPONENT_NAME,
    type: TEAM_STATS_COMPONENT_TYPE,
    controller: TeamStatsController,
    overlay: TeamStatsOverlay,
    maxStatistics: 5,
    stateOptsMapping: TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  TeamHeadToHead: {
    width: 4,
    name: TEAM_HEAD_TO_HEAD_COMPONENT_NAME,
    type: TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
    controller: TeamHeadToHeadController,
    overlay: TeamHeadToHeadOverlay,
    maxStatistics: 4,
    stateOptsMapping: TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  TeamLeaderboard: {
    width: 4,
    name: TEAM_LEADERBOARD_COMPONENT_NAME,
    type: TEAM_LEADERBOARD_COMPONENT_TYPE,
    controller: TeamLeaderboardController,
    overlay: TeamLeaderboardOverlay,
    maxStatistics: null,
    stateOptsMapping: TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  Scoreboard: {
    width: 4,
    name: SCOREBOARD_COMPONENT_NAME,
    type: SCOREBOARD_COMPONENT_TYPE,
    controller: ScoreboardController,
    overlay: ScoreboardOverlay,
    maxStatistics: 4,
    stateOptsMapping: SCOREBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  MapVeto: {
    width: 4,
    name: MAP_VETO_COMPONENT_NAME,
    type: MAP_VETO_COMPONENT_TYPE,
    controller: MapVetoController,
    overlay: MapVetoOverlay,
    maxStatistics: null,
    stateOptsMapping: MAP_VETO_COMPONENT_STATE_TO_OPTS_MAPPING,
    useOnlyShard: true,
    skipOnCreateBulk: true,
    fixArgs: true,
    additionalParameters: [ MAP_VETO_VETO_STATUS ]
  },
}
