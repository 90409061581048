import React from 'react';
import OnslaughtMapVeto from './onslaughtLeague/MapVeto';

const MapVetoOverlay = (props) => {
  const { shard } = props;

  switch (shard) {
    case shard.includes('fs'):
      return <OnslaughtMapVeto {...props} />
    default:
      return <OnslaughtMapVeto {...props} />
  }
}

export default MapVetoOverlay;
