/**
 * Compares teams for team leaderboard
 */
export const compareTeams = (teamA, teamB) => {
  const totalPointsA = teamA.points + teamA.kills;
  const totalPointsB = teamB.points + teamB.kills;
  if (totalPointsA !== totalPointsB) {
    return totalPointsA > totalPointsB ? -1 : 1;
  } else if (teamA.kills !== teamB.kills) {
    return teamA.kills > teamB.kills ? -1 : 1;
  } else if (teamA.bestMatchPoints !== teamB.bestMatchPoints) {
    return teamA.bestMatchPoints > teamB.bestMatchPoints ? -1 : 1;
  } else if (teamA.lastKills !== teamB.lastKills) {
    return teamA.lastKills > teamB.lastKills ? -1 : 1;
  } else {
    return teamA.lastPoints > teamB.lastPoints ? -1 : 1;
  }
}