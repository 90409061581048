import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_LAST_WINNERS,
  REQUEST_LAST_WINNERS_PENDING,
  REQUEST_LAST_WINNERS_SUCCESS,
  REQUEST_LAST_WINNERS_ERROR,
} from './types';
import { todaysWinners } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';

/**
 * Makes a request to server for last winners
 * 
 * @param {*} action 
 */
function* requestTodaysWinners(action) {
  yield put({ type: REQUEST_LAST_WINNERS_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(todaysWinners, {
      game: action.payload.game,
      tournament: action.payload.tournament,
      numOfMatches: action.payload.numOfMatches,
      token: action.payload.token,
    });
    yield put({ type: REQUEST_LAST_WINNERS_SUCCESS, payload: result.data.todaysWinners });
  } catch (err) {
    yield put({ type: REQUEST_LAST_WINNERS_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_LAST_WINNERS, requestTodaysWinners),
  ]);
}