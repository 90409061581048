import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import { PUBG_GAME } from '../../../../../util/Games';
import CheckBox from '../../../common/base/controller/inputs/CheckBox';
import { COMPONENT_STATE_OVERALL_STATS, COMPONENT_STATE_SUBSCRIPTION } from '../../../constants';
import DataTypeSelect from '../../../common/base/controller/inputs/DataTypeSelect';

class PUBGLiveSurvivalLeaderboardController extends Component {
  render() {
    const { component } = this.props;

    return (
      <ControllerBase
        game={PUBG_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <DataTypeSelect
              component={component}
              stateAttribute={COMPONENT_STATE_OVERALL_STATS}
            />
          </Col>
          <Col xs="12">
            <CheckBox
              component={component}
              label={'Activate Leaderboard'}
              stateAttribute={COMPONENT_STATE_SUBSCRIPTION}
            />
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

export default PUBGLiveSurvivalLeaderboardController;
