import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { AppHeader } from '@coreui/react';

export default styled(AppHeader)`
    background: ${styles.tertiaryDark};
    border-color: ${styles.background};

    .nav-link {
      color: ${styles.font};

      &.active,
      &:hover,
      &:focus,
      &.active:hover {
        color: ${styles.primary};
      }
    }

    .navbar-toggler {
      outline: 0;
    }
`;