// Types of action for creating custom tournament
export const REQUEST_CREATE_TOURNAMENT = 'REQUEST_CREATE_TOURNAMENT';
export const REQUEST_CREATE_TOURNAMENT_PENDING = 'REQUEST_CREATE_TOURNAMENT_PENDING';
export const REQUEST_CREATE_TOURNAMENT_SUCCESS = 'REQUEST_CREATE_TOURNAMENT_SUCCESS';
export const REQUEST_CREATE_TOURNAMENT_ERROR = 'REQUEST_CREATE_TOURNAMENT_ERROR';
// Types of action for editing custom tournament
export const REQUEST_EDIT_TOURNAMENT = 'REQUEST_EDIT_TOURNAMENT';
export const REQUEST_EDIT_TOURNAMENT_PENDING = 'REQUEST_EDIT_TOURNAMENT_PENDING';
export const REQUEST_EDIT_TOURNAMENT_SUCCESS = 'REQUEST_EDIT_TOURNAMENT_SUCCESS';
export const REQUEST_EDIT_TOURNAMENT_ERROR = 'REQUEST_EDIT_TOURNAMENT_ERROR';
// Types of action for requesting custom tournament
export const REQUEST_TOURNAMENT = 'REQUEST_TOURNAMENT';
export const REQUEST_TOURNAMENT_PENDING = 'REQUEST_TOURNAMENT_PENDING';
export const REQUEST_TOURNAMENT_SUCCESS = 'REQUEST_TOURNAMENT_SUCCESS';
export const REQUEST_TOURNAMENT_ERROR = 'REQUEST_TOURNAMENT_ERROR';
// Types of action requesting creation of tournament filter
export const REQUEST_CREATE_TOURNAMENT_FILTER = 'REQUEST_CREATE_TOURNAMENT_FILTER';
export const REQUEST_CREATE_TOURNAMENT_FILTER_PENDING = 'REQUEST_CREATE_TOURNAMENT_FILTER_PENDING';
export const REQUEST_CREATE_TOURNAMENT_FILTER_SUCCESS = 'REQUEST_CREATE_TOURNAMENT_FILTER_SUCCESS';
export const REQUEST_CREATE_TOURNAMENT_FILTER_ERROR = 'REQUEST_CREATE_TOURNAMENT_FILTER_ERROR';
// Types of action requesting upsert of tournament filter
export const REQUEST_EDIT_TOURNAMENT_FILTER = 'REQUEST_EDIT_TOURNAMENT_FILTER';
export const REQUEST_EDIT_TOURNAMENT_FILTER_PENDING = 'REQUEST_EDIT_TOURNAMENT_FILTER_PENDING';
export const REQUEST_EDIT_TOURNAMENT_FILTER_SUCCESS = 'REQUEST_EDIT_TOURNAMENT_FILTER_SUCCESS';
export const REQUEST_EDIT_TOURNAMENT_FILTER_ERROR = 'REQUEST_EDIT_TOURNAMENT_FILTER_ERROR';
// Types of action requesting deletion of tournament filter
export const REQUEST_DELETE_TOURNAMENT_FILTER = 'REQUEST_DELETE_TOURNAMENT_FILTER';
export const REQUEST_DELETE_TOURNAMENT_FILTER_PENDING = 'REQUEST_DELETE_TOURNAMENT_FILTER_PENDING';
export const REQUEST_DELETE_TOURNAMENT_FILTER_SUCCESS = 'REQUEST_DELETE_TOURNAMENT_FILTER_SUCCESS';
export const REQUEST_DELETE_TOURNAMENT_FILTER_ERROR = 'REQUEST_DELETE_TOURNAMENT_FILTER_ERROR';
// Types of action requesting creation or edit of tournament phase
export const REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE = 'REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE';
export const REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_PENDING = 'REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_PENDING';
export const REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_SUCCESS = 'REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_SUCCESS';
export const REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_ERROR = 'REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_ERROR';
// Types of action requesting deletion of tournament phase
export const REQUEST_DELETE_TOURNAMENT_PHASE = 'REQUEST_DELETE_TOURNAMENT_PHASE';
export const REQUEST_DELETE_TOURNAMENT_PHASE_PENDING = 'REQUEST_DELETE_TOURNAMENT_PHASE_PENDING';
export const REQUEST_DELETE_TOURNAMENT_PHASE_SUCCESS = 'REQUEST_DELETE_TOURNAMENT_PHASE_SUCCESS';
export const REQUEST_DELETE_TOURNAMENT_PHASE_ERROR = 'REQUEST_DELETE_TOURNAMENT_PHASE_ERROR';
// Types of action requesting change of trached IGNs on phases
export const REQUEST_CHANGE_TRACKED_IGNS = 'REQUEST_CHANGE_TRACKED_IGNS';
export const REQUEST_CHANGE_TRACKED_IGNS_PENDING = 'REQUEST_CHANGE_TRACKED_IGNS_PENDING';
export const REQUEST_CHANGE_TRACKED_IGNS_SUCCESS = 'REQUEST_CHANGE_TRACKED_IGNS_SUCCESS';
export const REQUEST_CHANGE_TRACKED_IGNS_ERROR = 'REQUEST_CHANGE_TRACKED_IGNS_ERROR';
// Types of action requesting subscription for IGN changes
export const REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS = 'REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS';
export const REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_PENDING = 'REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_PENDING';
export const REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_SUCCESS = 'REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_SUCCESS';
export const REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_ERROR = 'REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_ERROR';
// Types of action requesting addition of the match to tournament
export const REQUEST_ADD_TOURNAMENT_MATCH = 'REQUEST_ADD_TOURNAMENT_MATCH';
export const REQUEST_ADD_TOURNAMENT_MATCH_PENDING = 'REQUEST_ADD_TOURNAMENT_MATCH_PENDING';
export const REQUEST_ADD_TOURNAMENT_MATCH_SUCCESS = 'REQUEST_ADD_TOURNAMENT_MATCH_SUCCESS';
export const REQUEST_ADD_TOURNAMENT_MATCH_ERROR = 'REQUEST_ADD_TOURNAMENT_MATCH_ERROR';
// Types of action for requesting edit of the tournament match
export const REQUEST_EDIT_TOURNAMENT_MATCH = 'REQUEST_EDIT_TOURNAMENT_MATCH';
export const REQUEST_EDIT_TOURNAMENT_MATCH_PENDING = 'REQUEST_EDIT_TOURNAMENT_MATCH_PENDING';
export const REQUEST_EDIT_TOURNAMENT_MATCH_SUCCESS = 'REQUEST_EDIT_TOURNAMENT_MATCH_SUCCESS';
export const REQUEST_EDIT_TOURNAMENT_MATCH_ERROR = 'REQUEST_EDIT_TOURNAMENT_MATCH_ERROR';
// Types of action requesting subscription to tournament matches
export const REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH = 'REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH';
export const REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_PENDING = 'REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_PENDING';
export const REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_SUCCESS = 'REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_SUCCESS';
export const REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_ERROR = 'REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_ERROR';
// Types of action requesting tournament leaderboard
export const REQUEST_TOURNAMENT_LEADERBOARD = 'REQUEST_TOURNAMENT_LEADERBOARD';
export const REQUEST_TOURNAMENT_LEADERBOARD_PENDING = 'REQUEST_TOURNAMENT_LEADERBOARD_PENDING';
export const REQUEST_TOURNAMENT_LEADERBOARD_SUCCESS = 'REQUEST_TOURNAMENT_LEADERBOARD_SUCCESS';
export const REQUEST_TOURNAMENT_LEADERBOARD_ERROR = 'REQUEST_TOURNAMENT_LEADERBOARD_ERROR';
// Types of action requesting addition of team solts
export const REQUEST_ADD_TOURNAMENT_TEAM_SLOTS = 'REQUEST_ADD_TOURNAMENT_TEAM_SLOTS';
export const REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_PENDING = 'REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_PENDING';
export const REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_SUCCESS = 'REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_SUCCESS';
export const REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_ERROR = 'REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_ERROR';
// Types of action requesting addition of prize pools
export const REQUEST_ADD_TOURNAMENT_PRIZE_POOL = 'REQUEST_ADD_TOURNAMENT_PRIZE_POOL';
export const REQUEST_ADD_TOURNAMENT_PRIZE_POOL_PENDING = 'REQUEST_ADD_TOURNAMENT_PRIZE_POOL_PENDING';
export const REQUEST_ADD_TOURNAMENT_PRIZE_POOL_SUCCESS = 'REQUEST_ADD_TOURNAMENT_PRIZE_POOL_SUCCESS';
export const REQUEST_ADD_TOURNAMENT_PRIZE_POOL_ERROR = 'REQUEST_ADD_TOURNAMENT_PRIZE_POOL_ERROR';
// Types of action requesting removal of prize pools
export const REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL = 'REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL';
export const REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_PENDING = 'REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_PENDING';
export const REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_SUCCESS = 'REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_SUCCESS';
export const REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_ERROR = 'REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_ERROR';
// Types of action requesting saving of award
export const REQUEST_SAVE_TOURNAMENT_AWARD = 'REQUEST_ADD_TOURNAMENT_AWARD';
export const REQUEST_SAVE_TOURNAMENT_AWARD_PENDING = 'REQUEST_ADD_TOURNAMENT_AWARD_PENDING';
export const REQUEST_SAVE_TOURNAMENT_AWARD_SUCCESS = 'REQUEST_ADD_TOURNAMENT_AWARD_SUCCESS';
export const REQUEST_SAVE_TOURNAMENT_AWARD_ERROR = 'REQUEST_ADD_TOURNAMENT_AWARD_ERROR';
// Action for adding new award
export const ADD_NEW_TOURNAMENT_AWARD = 'ADD_NEW_TOURNAMENT_AWARD';
// Types of action requesting delete of award
export const REQUEST_DELETE_TOURNAMENT_AWARD = 'REQUEST_DELETE_TOURNAMENT_AWARD';
export const REQUEST_DELETE_TOURNAMENT_AWARD_PENDING = 'REQUEST_DELETE_TOURNAMENT_AWARD_PENDING';
export const REQUEST_DELETE_TOURNAMENT_AWARD_SUCCESS = 'REQUEST_DELETE_TOURNAMENT_AWARD_SUCCESS';
export const REQUEST_DELETE_TOURNAMENT_AWARD_ERROR = 'REQUEST_DELETE_TOURNAMENT_AWARD_ERROR';
// Types of action requesting saving of award row
export const REQUEST_SAVE_TOURNAMENT_AWARD_ROW = 'REQUEST_SAVE_TOURNAMENT_AWARD_ROW';
export const REQUEST_SAVE_TOURNAMENT_AWARD_ROW_PENDING = 'REQUEST_SAVE_TOURNAMENT_AWARD_ROW_PENDING';
export const REQUEST_SAVE_TOURNAMENT_AWARD_ROW_SUCCESS = 'REQUEST_SAVE_TOURNAMENT_AWARD_ROW_SUCCESS';
export const REQUEST_SAVE_TOURNAMENT_AWARD_ROW_ERROR = 'REQUEST_SAVE_TOURNAMENT_AWARD_ROW_ERROR';
// Types of action for receiving track IGNs change
export const ON_CHANGE_TRACK_IGNS = 'ON_CHANGE_TRACK_IGNS';
// Types of action for receiving tournament match
export const ON_TOURNAMENT_MATCH_RECEIVED = 'ON_TOURNAMENT_MATCH_RECEIVED';
// Action for setting the current tournament group teams
export const SET_TOURNAMENT_GROUP_TEAMS = 'SET_TOURNAMENT_GROUP_TEAMS';
// Action for clearing tournament state
export const CLEAR_TOURNAMENT_STATE = 'CLEAR_TOURNAMENT_STATE';