import {
  REQUEST_TOURNAMENT_FILTERS_PENDING,
  REQUEST_TOURNAMENT_FILTERS_SUCCESS,
} from './types';

const INITIAL_STATE = {
  tournamentFiltersLoaded: false,
  tournamentFilters: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request tournament filters pending
    case REQUEST_TOURNAMENT_FILTERS_PENDING:
      return {
        ...state,
        tournamentFiltersLoaded: false,
        tournamentFilters: [],
      }

    // On request tournament filters success
    case REQUEST_TOURNAMENT_FILTERS_SUCCESS:
      return {
        ...state,
        tournamentFiltersLoaded: true,
        tournamentFilters: action.payload,
      }

    default:
      return state;
  }
}

export default reducer;