import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardTeam from './LeaderboardTeam';
import LeaderboardFooter from './LeaderboardFooter';
import LeaderboardSubHeader from './LeaderboardSubHeader';

const StyledTable = styled.table`
  font-family: agency_fb;
  
  border-collapse:separate;
  border-spacing: 30px 10px;
`;

class RyerTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, opts, show, exportOverlay } = this.props;
    const firstHalfTeams = leaderboard.slice(0, 8);
    const secondHalfTeams = leaderboard.slice(8, leaderboard.length);

    if (!loaded) return null;

    return (
      <StyledTable>
        <tbody>
          <tr>
            <LeaderboardHeader
              show={show}
              opts={opts}
              exportOverlay={exportOverlay}
            />
          </tr>
          <tr>
            <LeaderboardSubHeader
              show={show}
              exportOverlay={exportOverlay}
            />
            <LeaderboardSubHeader
              show={show}
              exportOverlay={exportOverlay}
            />
          </tr>
          {
            firstHalfTeams.map((_, i) => {
              return (
                <tr key={`leaderboard-row-${i}`}>
                  <LeaderboardTeam
                    team={firstHalfTeams[i]}
                    rank={i+1}
                    show={show}
                    exportOverlay={exportOverlay}
                  />
                  <LeaderboardTeam
                    team={secondHalfTeams[i]}
                    rank={i+9}
                    show={show}
                    exportOverlay={exportOverlay}
                  />
                </tr>
              )
            })
          }
          <tr>
            <LeaderboardFooter
              show={show}
              exportOverlay={exportOverlay}
            />
          </tr>
        </tbody>
      </StyledTable>
    );
  }
}

export default RyerTeamLeaderboard;
