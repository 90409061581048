import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { Select } from '../../../../../../components/StyledCoreUI';
import { CURRENT_MATCH_DATA, OVERALL_DATA } from '../../../../constants';

const options = [
  {
    key: CURRENT_MATCH_DATA,
    value: 'Current Match Data'
  },
  {
    key: OVERALL_DATA,
    value: 'Overall Data'
  },
];

function DataTypeSelect(props) {
  const { component, stateAttribute, requestChangeComponent } = props;

  return (
    <FormGroup>
      <Label>Data Type</Label>
      <Select
        name={stateAttribute}
        color={styles.tertiaryDark}
        value={component.state[stateAttribute] || component.state[stateAttribute] === undefined ? OVERALL_DATA : CURRENT_MATCH_DATA}
        onChange={key => requestChangeComponent(component, { state: { [stateAttribute]: key === OVERALL_DATA } })}
        disabledOptionText='-- select data type --'
        options={options}
        // warning={!component.state[stateAttribute] && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(DataTypeSelect);
