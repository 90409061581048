import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  &.component-show {
    opacity: 0;
    animation: fadeIn 0.3s ease ${props => (props.numberOfStats + 2) * 0.3}s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: fadeOut 0.3s ease ${props => (props.numberOfStats + 2) * 0.3}s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function HeadToHeadFooter(props) {
  const { show, numberOfStats } = props;

  return (
    <Wrapper
      className={ show ? 'component-show' : 'component-hide' }
      numberOfStats={numberOfStats}
    >
      <td>
        <img
          src="/assets/img/twire/powered-by-twire-no-background.png"
          alt="tournament logo"
        />
      </td>
    </Wrapper>
  );
}

export default HeadToHeadFooter;