import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  td:first-child {
    background: url('/assets/img/esl/IG_07_In_Game_Stats_Team_Total_Damage_Done.png');
    background-repeat: no-repeat;
    text-transform: uppercase;
    font-family: 'ESL Legend';
    font-weight: 600;
    width: 220px;
    height: 49px;
    font-size: 25px;
    line-height: 25px;
    color: #000;

    text-align: center;
    vertical-align: middle;
  }
`;

function LeaderboardTitle(props) {
  return (
    <Wrapper>
      <td colSpan="3">Overall Leaderboard</td>
    </Wrapper>
  );
}

export default LeaderboardTitle;