import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Button } from 'reactstrap';

export default styled(Button)`
  color: ${styles.secondary};
  text-transform: uppercase;
  font-weight: 600;
  ${props => props.size && props.size === 'xs' ? 'font-size: 0.75em;' : ''}

  &.btn-light {
    background: ${styles.transparent};
    border: none;
  }

  &.btn-light:hover, &.btn-light:active {
    color: ${styles.secondary};
    background: ${styles.tertiary};
    border-color: ${styles.tertiary};
  }
`;