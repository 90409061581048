import React from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const TEAM_MAPPING = {
  "Toxic Players (RUS)": "Toxic Players",
  "BigBossLayf": "BBL Esports",
  "Natus Vincere": "NAVI",
  "Orgless (EU)": "Orgless",
}

const TeamRow = styled.tr`
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);

  &.dark-bg td {
    background: rgba(0, 0, 0, 0.2);
  }

  table {
    width: 218px;

    td {
      font-family: 'ESL Legend';
      font-weight: 600;
      height: 30px;
      font-size: 22px;
      line-height: 22px;

      &.player-ign, &.player-total-kills {
        font-size: 30px;
        line-height: 36px;
      }
  
      &.player-total-kills {
        padding-right: 10px;
        width: 30px;
      }

      &.player-ign {
        padding-left: 10px;
        padding-top: 12px;
        max-width: 175px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.player-team {
        padding-left: 10px;
        font-size: 22px;
        line-height: 22px;
        padding-bottom: 15px;
        max-width: 175px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        img {
          height: 20px;
          margin-top: -5px;
          margin-right: 5px;
        }
      }
    }
  }
`;

function LeaderboardBody(props) {
  const { player, rank } = props;

  return (
    <TeamRow
      className={rank % 2 === 1 ? 'dark-bg' : ''}
    >
      <td>
        <table>
          <tbody>
            <tr>
              <td
                className="player-ign"
              >
                { player.ign.replace(new RegExp(`${player.teamInitial}_`, 'ig'), '') }
              </td>
              <td
                className="player-total-kills text-center"
                rowSpan="2"
              >
                { player.totalKills }
              </td>
            </tr>
            <tr>
              <td
                className="player-team"
              >
                <span>
                  <img
                    src={getTeamLogo(`${player.teamName}`)}
                    onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                    alt="team-logo"
                  />
                  { TEAM_MAPPING[player.teamName] ? TEAM_MAPPING[player.teamName] : player.teamName }
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </TeamRow>
  );
}

export default LeaderboardBody;