import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_CURRENT_MATCH,
  REQUEST_CURRENT_MATCH_ERROR,
  REQUEST_CURRENT_MATCH_PENDING,
  REQUEST_CURRENT_MATCH_SUCCESS,
} from './types';
import { currentMatch } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';

/**
 * Makes a request to server for last winners
 * 
 * @param {*} action 
 */
function* requestCurrentMatch(action) {
  yield put({ type: REQUEST_CURRENT_MATCH_PENDING });
  try {
    // Calling the server
    const result = yield publicQuery(currentMatch, { game: action.payload.game, tournament: action.payload.tournament });
    yield put({ type: REQUEST_CURRENT_MATCH_SUCCESS, payload: result.data.currentMatch });
  } catch (err) {
    yield put({ type: REQUEST_CURRENT_MATCH_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_CURRENT_MATCH, requestCurrentMatch),
  ]);
}