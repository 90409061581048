import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const TitleWrapper = styled.div`
  font-family: 'agency_fb';
  text-transform: uppercase;
  width: ${props => props.rowWidth / 2}px;
  margin-left: ${props => props.rowWidth / 4}px;
  margin-top: 35px;
  margin-bottom: 35px !important;
  background: ${styles.pslColor};
  height: 180px;

  p {
    padding: 0;
    margin: 0;
  }

  &.title-header-show {
    display: block;
    width: 0px;
    animation: headerSlideDown 0.5s ease 0.2s forwards;

    @keyframes headerSlideDown {
      from {width: 0px;}
      to {width: ${props => props.rowWidth / 2}px;}
    }

    p {
      opacity: 0;
      animation: headerFadeIn 0.6s ease 0.6s forwards;

      @keyframes headerFadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }

    img {
      opacity: 0;
      animation: headerFadeInImg 0.3s ease 0s forwards;

      @keyframes headerFadeInImg {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  }

  &.title-header-hide {
    width: ${props => props.rowWidth / 2}px;
    animation: headerSlideUp 0.5s ease 1s forwards;

    @keyframes headerSlideUp {
      from {width: ${props => props.rowWidth / 2}px;}
      to {width: 0px;}
    }

    p {
      opacity: 1;
      animation: headerFadeOut 0.6s ease 0.5s forwards;

      @keyframes headerFadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }

    img {
      opacity: 1;
      animation: headerFadeOutImg 0.3s ease 1.3s forwards;

      @keyframes headerFadeOutImg {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }

  img {
    height: 250px;
    position: absolute;
    top: 0px;
  }

  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .subtitle {
    vertical-align: middle;
    font-size: 35px;
    letter-spacing: 8px;
    filter: drop-shadow(0px 0px 4px #00000040);
    line-height: 1;
    margin-left: 5px;
  }

  .title {
    vertical-align: middle;
    font-size: 130px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 4px #00000080);
    line-height: 1;
  }
`;

function LeaderboardHeader(props) {

  return (
    <>
      <TitleWrapper
        rowWidth={props.rowWidth}
        className={'mb-2 ' + (props.show ? 'title-header-show' : 'title-header-hide')}
      >
        <img
          src={`/assets/img/psl/logo-winter.png`}
          alt={`PSL logo`}
        />
        <Row>
          <Col xs="3">
          </Col>
          <Col xs="9" className="text-center">
            <p className="subtitle">{props.groupName && props.groupName !== 'undefined' ? props.groupName : 'Lower Bracket'}</p>
            <p className="title">Standings</p>
          </Col>
        </Row>
      </TitleWrapper>
    </>
  );
}

export default LeaderboardHeader;