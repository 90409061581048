import React from 'react';
import styled from 'styled-components';

const HeaderRow = styled.div`
  width: ${props => props.width}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: url(/assets/img/pjc22_1/header-background.png);
  max-height: 60px;

  .header {
    width: ${props => props.width}px;
    font-size: 24px;
    line-height: 24px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub-header {
    width: ${props => props.width/5}px;
    font-size: 18px;
    line-height: 18px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.text-left {
      justify-content: start;
    }
  }

  .underline {
    text-decoration: underline;
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow width={props.width}>
      <div className="header text-center underline">Leaderboard</div>
      <div className="sub-header"></div>
      <div className="sub-header text-left">TEAM</div>
      <div className="sub-header text-center">ALIVE</div>
      <div className="sub-header text-center">KILL</div>
      <div className="sub-header text-center">POINT</div>
    </HeaderRow>
  );
}

export default LeaderboardHeader;