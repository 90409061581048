import React from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const TeamRow = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 46px;
  line-height: 46px;
  width: 50%;
  height: 73px;
  margin-bottom: 5px;

  margin-bottom: 2px;

  .td-rank {
    width: 14%;
  }

  .td-kills, .td-points {
    width: 10%;
  }

  .td-team {
    width: 60%;
    font-family: noto-black;
    font-size: 35px;
    line-height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    span {
      vertical-align: text-top;
    }
  }

  .td-image {
    width: 6%;

    img {
      width: 90%;
      margin-top: -5px;
    }
  }
`;

const TEAM_NAME_MAP = {
  'Zeta Division': 'ZETA DIVISION',
  'Matsui Gaming Club': '松井GAMING CLUB'
}

function LeaderboardBody(props) {
  const { team, rank } = props;

  return (
    <TeamRow>
      <div className="td td-rank text-center">
          { rank }.
      </div>
      <div className="td td-image text-left">
        <img
          src={getTeamLogo(`pjc-${team.name}`)}
          onError={e => e.target.src = getTeamLogo('default')}
          alt="team logo"
        />
      </div>
      <div className="td td-team text-left"><span>{ TEAM_NAME_MAP[team.name] || team.name }</span></div>
      <div className="td td-kills text-center">{ team.totalKills }</div>
      <div className="td td-points text-center">{ team.totalPoints }</div>
    </TeamRow>
  );
}

export default LeaderboardBody;