import React from 'react';

const PUBGPlayerStatsOverlay = (props) => {
  return (
    <div>
    </div>
  );
}

export default PUBGPlayerStatsOverlay;
