import { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

let publicConfig;
let privateConfig;
if (process.env.REACT_APP_STAGE === 'prod') {
  publicConfig = require('../appsync-config-public-prod');
  privateConfig = require('../appsync-config-private-prod');
} else {
  publicConfig = require('../appsync-config-public');
  privateConfig = require('../appsync-config-private');
}

// Public Appsync Client
export const publicClient = new AWSAppSyncClient({
  url: publicConfig.default.aws_appsync_graphqlEndpoint,
  region: publicConfig.default.aws_appsync_region,
  auth: { type: AUTH_TYPE.API_KEY, apiKey: publicConfig.default.aws_appsync_apiKey },
  offlineConfig: {
    keyPrefix: 'public'
  },
  disableOffline: true
});

// Private AppSync client
export const privateClient = new AWSAppSyncClient({
  url: privateConfig.default.aws_appsync_graphqlEndpoint,
  region: privateConfig.default.aws_appsync_region,
  auth: { type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS, jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken },
  offlineConfig: {
    keyPrefix: 'private'
  },
  disableOffline: true
});