import React from 'react'
import './style.scss'

export const Image = ({
  style,
  imageStyle,
  src,
  size = 'normal',
  onError = null,
  center = false,
}) => {
  switch (size) {
    case 'gigantic':
      imageStyle = { maxWidth: '18rem', ...imageStyle }
      break
    case 'giant':
      imageStyle = { maxWidth: '15rem', ...imageStyle }
      break
    case 'largest':
      imageStyle = { maxWidth: '13rem', ...imageStyle }
      break
    case 'large':
      imageStyle = { maxWidth: '8rem', ...imageStyle }
      break
    case 'small':
      imageStyle = { maxWidth: '6rem', ...imageStyle }
      break
    case 'smallest':
      imageStyle = { maxWidth: '5rem', ...imageStyle }
      break
    case 'tiny':
      imageStyle = { maxWidth: '3rem', ...imageStyle }
      break
    default:
      break
  }

  if (center) {
    style = {
      ...style,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }

  return (
    <div style={style} className="image-wrapper">
      <img
        alt=""
        style={imageStyle}
        className="image"
        src={src}
        onError={onError}
      />
    </div>
  )
}
