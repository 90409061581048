import { isInt } from '../../util/CommonUtil';
import { OPTS_OVERALL_STATS, OPTS_SUBSCRIPTION } from '../../views/Components/constants';
import { 
  REQUEST_CREATE_COMPONENT,
  REQUEST_CHANGE_COMPONENT,
  REQUEST_CHANGE_COMPONENT_WITH_VALIDATION,
  REQUEST_REMOVE_COMPONENT,
  SUBSCRIBE_TO_CHANGE_COMPONENT,
  REQUEST_SHARD_INFO_CHANGE_COMPONENT,
  REQUEST_CREATE_COMPONENTS_BULK,
  REQUEST_REMOVE_ALL_COMPONENT,
  SET_COMPONENT_STATE,
  SET_COMPONENT_DATA,
} from './types';

/**
 * Action for requesting create component
 */
export const requestCreateComponent = (component, shard, shardInfo, game) => ({
    type: REQUEST_CREATE_COMPONENT,
    payload: {
      component: component,
      shard: shard,
      args: {
        type: component.type,
        shardInfo: component.useOnlyShard ? shard : shardInfo,
        match: null,
        game: game,
      },
    }
});

/**
 * Action for requesting create components in bulk
 */
export const requestCreateComponentsBulk = (components, shard, shardInfo, game) => {
  const componentsToCreate = {...components};
  Object.keys(componentsToCreate).forEach(c => {
    if (componentsToCreate[c].skipOnCreateBulk) {
      delete componentsToCreate[c];
    }
  })
  return {
    type: REQUEST_CREATE_COMPONENTS_BULK,
    payload: {
      components: componentsToCreate,
      shard: shard,
      args: Object.keys(componentsToCreate).map(k => {
        const c = componentsToCreate[k];
        return {
          type: c.type,
          shardInfo: shardInfo,
          match: null,
          game: game,
        };
      }),
    }
  }
};

/**
 * Action for requesting change component
 */
export const requestChangeComponent = (component, changes) => ({
    type: REQUEST_CHANGE_COMPONENT,
    payload: {
      component: component,
      state: changes.state,
      componentParameters: {
        show: changes.show !== null ? changes.show : component.show,
        name: changes.name ? changes.name : component.name,
        opts: JSON.stringify({ 
          ...component.opts, 
          ...mapStateToOpts({ ...component.state, ...changes.state }, component.stateOptsMapping), 
          selectedSpecificMatch: isInt((changes.state && changes.state.match) || (component.state && component.state.match)) 
            || ((changes.state && changes.state.overall === false) || (component.state && component.state.overall === false))
        }),
        args: component.fixArgs ? null : { ...component.args, ...changes.args },
        ...mapAdditionalParameters(changes, component.additionalParameters),
      },
    }
});

/**
 * Action for requesting change component with state validation
 */
export const requestChangeComponentWithValidation = (component, changes) => ({
    type: REQUEST_CHANGE_COMPONENT_WITH_VALIDATION,
    payload: {
      component: component,
      changes: changes,
    }
});

/**
 * Action for subscribing to change component
 */
export const subscribeToChangeComponent = (component, callback) => ({
    type: SUBSCRIBE_TO_CHANGE_COMPONENT,
    payload: {
      component: component,
      callback: callback,
    },
});

/**
 * Action for requesting remove of the component
 */
export const requestRemoveComponent = (component) => ({
    type: REQUEST_REMOVE_COMPONENT,
    payload: component,
});

/**
 * Action for requesting remove of the component
 */
export const requestRemoveAllComponent = () => ({
    type: REQUEST_REMOVE_ALL_COMPONENT,
});

/**
 * Action for requesting change of shard info component
 */
export const requestShardInfoChangeComponent = (shardInfo) => ({
    type: REQUEST_SHARD_INFO_CHANGE_COMPONENT,
    payload: shardInfo,
});

/**
 * Action for setting the state of the component
 */
export const setComponentState = (component, state) => ({
    type: SET_COMPONENT_STATE,
    payload: {
      component: component,
      state: state,
    },
});

/**
 * Action for setting the data of the component
 */
export const setComponentData = (component, data) => ({
    type: SET_COMPONENT_DATA,
    payload: {
      component: component,
      data: data,
    },
});

/**
 * Mapps component state to opts according to mapping rules
 * 
 * @param {*} state 
 * @param {*} stateOptsMapping 
 */
function mapStateToOpts(state, stateOptsMapping) {
  const opts = {};
  Object.keys(state || {}).forEach(key => {
    if (stateOptsMapping[key]) {
      opts[stateOptsMapping[key]] = state[key];
    }
  });
  // FIX ME: set default values somehow
  if (!opts[OPTS_SUBSCRIPTION] && stateOptsMapping[OPTS_SUBSCRIPTION]) opts[OPTS_SUBSCRIPTION] = false;
  if (opts[OPTS_OVERALL_STATS] === undefined && stateOptsMapping[OPTS_OVERALL_STATS]) opts[OPTS_OVERALL_STATS] = true;
  return opts;
}

/**
 * Mapps component's additional parameters from changes into object
 * 
 * @param {*} changes 
 * @param {*} additionalParameters 
 */
function mapAdditionalParameters(changes, additionalParameters) {
  if (!additionalParameters) return null;

  const parameters = {};
  Object.keys(changes || {}).forEach(key => {
    if (changes[key] || additionalParameters.includes(key)) {
      parameters[key] = changes[key];
    }
  });
  return parameters;
}