import React from 'react';
import styled from 'styled-components';

const TeamRow = styled.tr`
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);

  &.dark-bg td {
    background: rgba(0, 0, 0, 0.2);
  }
  padding-right: 10px;

  td {
    font-family: 'ESL Legend';
    font-weight: 600;
    height: 30px;
    font-size: 22px;
    line-height: 22px;

    &.player-ign {
      font-size: 30px;
      line-height: 36px;
      padding-left: 10px;
      padding-top: 3px;
      padding-bottom: 9px;
      max-width: 218px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.player-team, &.player-stat {
      font-size: 22px;
      line-height: 22px;
      padding-bottom: 9px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.player-team {
      padding-left: 10px;
    }

    &.player-stat {
      width: 50px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }
`;

function LeaderboardBody(props) {
  const { player, rank } = props;

  return (
    <>
      <TeamRow
        className={rank % 2 === 1 ? 'dark-bg' : ''}
      >
        <td
          className="player-ign"
          colSpan="4"
        >
          { player.ign.replace(new RegExp(`${player.teamInitial}_`, 'ig'), '') }
        </td>
      </TeamRow>
      <TeamRow
        className={rank % 2 === 1 ? 'dark-bg' : ''}
      >
        <td
          className="player-team"
        >
          { player.teamInitial }
        </td>
        <td
          className="player-stat text-center"
        >
          { player.totalKills }
        </td>
        <td
          className="player-stat text-center"
        >
          { parseInt(player.totalDamage) }
        </td>
      </TeamRow>
    </>
  );
}

export default LeaderboardBody;