import React, { Component } from 'react';
import { OVERLAY_TYPE_CARD, OVERLAY_TYPE_FULL_SCREEN } from '../../../../constants';
import ElisaPlayerStatsCard from './Card/ElisaPlayerStatsCard';
import ElisaPlayerStatsFullScreen from './FullScreen/ElisaPlayerStatsFullScreen';
import ElisaPlayerStatsFullScreenSponsor from './FullScreenSponsor/ElisaPlayerStatsFullScreenSponsor';

class ElisaPlayerStats extends Component {
  render() {
    const { opts } = this.props;

    return (
      opts.overlayType && opts.overlayType === OVERLAY_TYPE_CARD ?
      <ElisaPlayerStatsCard {...this.props} />
      :
      opts.overlayType && opts.overlayType === OVERLAY_TYPE_FULL_SCREEN ?
      <ElisaPlayerStatsFullScreen {...this.props} />
      :
      <ElisaPlayerStatsFullScreenSponsor {...this.props} />
    );
  }
}

export default ElisaPlayerStats;