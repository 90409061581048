import React, { Component } from 'react';
import { getCsgoPlayerImage, getCsgoTeamLogo } from '../../../../../../util/CommonUtil';
import { STATS_NAME_MAP } from '../../../playerStats/overlay/elisa/constants';

import './ElisaPlayerLeaderboard.scss';

class ElisaPlayerLeaderboard extends Component {
  render() {
    const { show, leaderboard, opts } = this.props;
    const player = leaderboard.slice(0, 5);

    return (
      <div 
        className={`player-leaderboard ${show ? 'bc-open' : 'bc-close'}`}
      >
        <table>
          <tbody className="text-center">
            <tr>
              <td colSpan="5">
                <h1 className="leaderboard_title">{ STATS_NAME_MAP[opts.stats[0].key] }</h1>
              </td>
            </tr>
            <tr>
              {
                player.map((_, i) => {
                  return (
                    <td>
                      <h1 className="player_placement">{ i + 1 }.</h1>
                    </td>
                  );
                })
              }
            </tr>
            <tr>
              {
                player.map(p => {
                  return (
                    <td>
                      <img
                        className="player_image"
                        src={getCsgoPlayerImage(p.teamName + '-' + p.name)}
                        onError={e => e.target.src = getCsgoPlayerImage('default')}
                        alt="player"
                      />
                      <div className="points_wrapper">
                        <div className="points_container">
                          <p className="player_stat points">{
                            opts.stats[0].key === 'hsPercentage' ? 
                            `${Math.round(p[opts.stats[0].key] * 100)} %`
                            :
                            opts.stats[0].key === 'kD' || opts.stats[0].key === 'kR' ?
                            p[opts.stats[0].key]
                            :
                            Math.round(p[opts.stats[0].key])
                          }</p>
                        </div>
                      </div>
                    </td>
                  );
                })
              }
            </tr>
            <tr>
              {
                player.map(p => {
                  return (
                    <td>
                      <div className="player_info">
                        <img
                          className="team_image"
                          src={getCsgoTeamLogo(p.teamName)}
                          onError={e => e.target.src = getCsgoTeamLogo('default')}
                          alt="team-logo"
                        />
                        <span className="player_name">{ p.name }</span>
                      </div>
                    </td>
                  );
                })
              }
            </tr>
            <tr>
              <td
                className="text-center"
                colSpan="5"
              >
                <img
                  className="twire-logo"
                  src={'https://twire-assets.s3-eu-west-1.amazonaws.com/twire/overlays/logo-footer-small.png'}
                  alt="twire-logo"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ElisaPlayerLeaderboard;