import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const height = 50;

const Wrapper = styled.div`
  width: ${props => props.backgroundWidth}px;
  overflow: hidden;
  &.footer-show {
    height: 0;
    animation: open-footer .75s ease 1.8s forwards;

    @keyframes open-footer {
      from {height: 0;}
      to {height: ${height}px;}
    }
  }

  &.footer-hide {
    height: ${height}px;
    animation: close-footer .75s ease 0s forwards;

    @keyframes close-footer {
      from {height: ${height}px;}
      to {height: 0px;}
    }
  }

  &.no-animation {
    height: ${height}px;
  }
`;

const Content = styled(Col)`
  background: ${styles.tertiary};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 5px 0;

  img {
    height: 38px;
  }
`;

function PoweredByTwire(props) {
  const { backgroundWidth, show, exportAsImage } = props;

  return (
    <Wrapper
      backgroundWidth={backgroundWidth}
      className={ exportAsImage ? 'no-animation' : show ? 'footer-show' : 'footer-hide' }
    >
      <Row>
        <Content xs="2" className="offset-5 text-center">
          <img src="/assets/img/twire/powered-by-twire-no-background.png" alt="twire logo" />
        </Content>
      </Row>
    </Wrapper>
  );
}

export default PoweredByTwire;