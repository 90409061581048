import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardFooter from './LeaderboardFooter';

const LeaderboardWrapper = styled.div`
  width: 1600px;
  padding: 15px;
  overflow: hidden;
  font-family: 'muli';

  &.leaderboard-show {
    display: block;
    height: 0;
    animation: fadeIn 0.6s ease 0s forwards, slideDown 1s ease 0.3s forwards;
  
    @keyframes slideDown {
      from {height: 0;}
      to {height: ${props => props.height}px;}
    }
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {}
    }
  }

  &.leaderboard-hide {
    height: ${props => props.height}px;
    animation: slideUp 1s ease 0s forwards, fadeOut 0.3s ease .8s forwards;
  
    @keyframes slideUp {
      from {height: ${props => props.height}px;}
      to {height: 0;}
    }
  
    @keyframes fadeOut {
      from {}
      to {opacity: 0;}
    }
  }

  &.no-animation {
    height: ${props => props.height}px;
  }
`;

class TwireTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, shard, exportAsImage } = this.props;

    return (
      loaded ?
      <LeaderboardWrapper
        height={Math.round(leaderboard.length / 2) * 60 + 130}
        className={ exportAsImage ? 'no-animation' : show ? 'leaderboard-show' : 'leaderboard-hide leaderboard-fade-out' }
      >
        <LeaderboardHeader shard={shard} />
        <LeaderboardBody {...this.props} />
        <LeaderboardFooter />
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default TwireTeamLeaderboard;
