import { 
  REQUEST_ORGANIZATION_SUCCESS,
} from './types';

const INITIAL_STATE = {
  id: null,
  name: null,
  users: [],
  verified: false,
  availableComponents: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request logged in organization success
    case REQUEST_ORGANIZATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default reducer;