import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
  height: 22px;
  margin-top: 5px;
`;

function LeaderboardFooter(props) {

  return (
    <tr>
      <td colSpan="5" className="offset-5 text-center">
        <StyledLogo
          src="/assets/img/twire/logo.png"
          alt="tournament logo"
        />
      </td>
    </tr>
  );
}

export default LeaderboardFooter;