import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  width: ${ props => props.rowWidth / 2 }px;
  margin-left: ${ props => props.rowWidth / 4 }px;
  margin-bottom: 25px !important;

  &.title-header-show {
    img {
      opacity: 0;
      animation: headerFadeInImg 0.4s ease 0s forwards;

      @keyframes headerFadeInImg {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  }

  &.title-header-hide {
    img {
      opacity: 1;
      animation: headerFadeOutImg 0.3s ease 1.1s forwards;

      @keyframes headerFadeOutImg {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }
`;

function LeaderboardHeader(props) {

  return (
    <>
      <TitleWrapper
        rowWidth={props.rowWidth}
        className={ 'mb-2 ' + (props.show ? 'title-header-show' : 'title-header-hide') }
      >
        <img 
          src={`/assets/img/being/live_team_leaderboard_header_background.png`}
          alt={`PSL logo`}
        />
      </TitleWrapper>
    </>
  );
}

export default LeaderboardHeader;