import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  td:first-child {
    background: url('/assets/img/esl/IG_04_WWCD_Live_Status_Header.png');
    text-transform: uppercase;
    font-family: 'ESL Legend';
    font-weight: 600;
    width: 220px;
    height: 50px;
    font-size: 25px;
    line-height: 25px;
    color: #000;

    text-align: center;
    vertical-align: middle;
  }
`;

function LeaderboardTitle(props) {
  return (
    <Wrapper>
      <td colSpan="5">Leaderboard</td>
    </Wrapper>
  );
}

export default LeaderboardTitle;