import React, { Component } from 'react';
import { getCsgoPlayerImage, getCsgoTeamLogo } from '../../../../../../../util/CommonUtil';
import { STATS_NAME_MAP } from '../constants';

import './ElisaPlayerStatsFullScreenSponsor.scss';

class ElisaPlayerStatsFullScreenSponsor extends Component {
  render() {
    const { show, player, opts } = this.props;

    return (
      <div 
        className={`player-stats-full-screen ${show ? 'bc-open' : 'bc-close'}`}
        numberOfStats={5}
      >
        <div className="main-wrapper animated fadeIn">
          <div className="wrapper">
            <div 
              className="team_player team_left"
              id="playerImage"
            >
              <img
                src={getCsgoPlayerImage(player.teamName + '-' + player.name)}
                onError={e => e.target.src = getCsgoPlayerImage('default')}
                alt="player"
              />
            </div>
            <div className="teams_info">
              <div className="player_info">
                <div className="sponsor_name">
                  <span>COOLBET MVP</span>
                </div>
                <div className="player-name-container">
                  <h1 className="player-name">{ player.firstName } <span>{ player.name }</span> { player.lastName }</h1>
                </div>
                <div className="team_logo_name">
                  <img
                    src={getCsgoTeamLogo(player.teamName)}
                    onError={e => e.target.src = getCsgoTeamLogo('default')}
                    alt="team-logo"
                  />
                  <span>{ player.teamName }</span>
                </div>
              </div>
              <div className="maps_info">
                  {
                    opts.stats.map((s, i) => {
                      return (
                        <p id={`map${i+1}`} className={`map_${i+1} maps`}>{STATS_NAME_MAP[s.key]}</p>
                      );
                    })
                  }
              </div>
              <div className="lines_container right">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="points_wrapper team_right">
                <div className="points_container">
                    {
                      opts.stats.map((s, i) => {
                        return (
                          <div className="points mvp">
                            <p id={`playerStat${i+1}`} className="point_title">{
                              s.key === 'hsPercentage' ?
                              `${Math.round(parseFloat(player[s.key]) * 100)} %`
                              :
                              s.key === 'kD' || s.key === 'kR' ?
                              `${player[s.key]}`
                              :
                              Math.round(player[s.key])}</p>
                          </div>
                        );
                      })
                    }
                </div> 
              </div>
              <div className="twire-logo">
                <img
                  src={'https://twire-assets.s3-eu-west-1.amazonaws.com/twire/overlays/logo-footer-small.png'}
                  alt="twire-logo"
                />
              </div>
              <div className="sponsor-logo">
                <img
                  src={'https://twire-assets.s3.eu-west-1.amazonaws.com/csgo/elisa/coolbet.png'}
                  alt="sponsor-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ElisaPlayerStatsFullScreenSponsor;