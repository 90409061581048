import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { isTeamDeath, sortByPlayerStatus } from '../../../../../../util/CommonUtil';

const TeamRow = styled.tr`
  text-transform: uppercase;

  &.team-death {
    opacity: 0.7;
  }

  td {
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    background: ${styles.tertiary}EE;
    color: ${styles.secondary}DD;

    img {
      height: 20px !important;
    }
  }

  .team-rank {
    width: 25px !important;
  }

  .team-logo, .team-points {
    width: 30px !important;
  }

  .team-initial, .team-alive {
    width: 40px !important;
  }

  .team-alive {
    padding: 0 6px;
    td {
      background: none;
      padding: 3px 2px;
      div {
        width: 3px;
        height: 20px;
      }
    }

    .alive {
      background: #00C12B;
    }
    .death {
      background: #60606090;
    }
    .knocked {
      background: #FF1300;
    }
  }

  .team-wins, .team-kills {
    color: #ffa900;
  }
`;

function LeaderboardBody(props) {
  const { team, rank } = props;

  return (
    <TeamRow className={isTeamDeath(team) ? 'team-death' : ''}>
      <td className="team-rank">
          <strong>{ rank }</strong>
      </td>
      <td className="team-initial text-left"><strong>{ team.initial }</strong></td>
      <td className="team-alive">
        <table>
          <tbody>
            <tr>
              {
                team.players.sort(sortByPlayerStatus).map(i => {
                  return (
                    <td>
                      <div className={i.status}></div>
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </td>
      <td className="team-wins"><strong>{ team.wins }</strong></td>
      <td className="team-kills"><strong>{ team.totalKills }</strong></td>
    </TeamRow>
  );
}

export default LeaderboardBody;