import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLiveTeamStats';
import PJC from './pjc/PjcLiveTeamStats';
import PJC21 from './pjc21/Pjc21LiveTeamStats';
import Being from './being/BeingLiveTeamStats';

const PUBGLiveTeamStatsOverlay = (props) => {
  const { overlayDesign } = props;

  return (
    overlayDesign === 'pjc' ?
    <PJC {...props} />
    :
    overlayDesign === 'pjc21' ?
    <PJC21 {...props} />
    :
    overlayDesign === 'esl' ?
    <ESL {...props} />
    :
    overlayDesign === 'being' ?
    <Being {...props} />
    :
    null
  );
}

const mapStateToProps = state => ({
  team: state.overlay.pubg || {},
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLiveTeamStatsOverlay);
