import React, { Component } from 'react';
import styled from 'styled-components';

const HeadToHeadWrapper = styled.table`
  &.bc-show {
  }

  &.bc-hide {
  }
`;

class EslLiveTeamHeadToHead extends Component {

  render() {
    const { loaded, left, right, show } = this.props;

    if (!loaded || !left || !right) return null;
    console.log(left)
    console.log(right)

    return (
      <HeadToHeadWrapper
        className={ show ? 'bc-show' : 'bc-hide' }
      >
      </HeadToHeadWrapper>
    );
  }
}

export default EslLiveTeamHeadToHead;
