import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  background: rgba(0, 0, 0, 0.5);

  td {
    background: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    text-transform: uppercase;
    font-family: 'ESL Legend';
    font-weight: 300;
    height: 40px;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    vertical-align: middle;

    &.th-kills {
      padding-right: 12px;
    }
  }
`;

function LeaderboardHeader(props) {
  const { overall } = props;

  return (
    <Wrapper overall={overall}>
      <td></td>
      <td className="text-center">Team</td>
      <td className="text-center">Alive</td>
      <td className="text-center">
        <img
          src="/assets/img/esl/IG_05_SUPER_Scoreboard_Header_Chicken.png"
          alt="wins"
        />
      </td>
      <td className="text-right th-kills">Kills</td>
    </Wrapper>
  );
}

export default LeaderboardHeader;