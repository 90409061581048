// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config =  {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://qpyhfrkl6fhjjlqwvac3v23h7i.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
};


export default config;