import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { AppSwitch } from '@coreui/react';

export default styled(AppSwitch)`
  .switch-slider {
    background: ${styles.tertiary};
    border-color: ${styles.infoDark};
  }

  .switch-slider::before {
    background: ${styles.infoDark};
    border-color: ${styles.infoDark};
  }

  .switch-input:checked + .switch-slider {
    border-color: ${styles.primary}; 
  }

  .switch-input:checked + .switch-slider::before {
    border-color: ${styles.secondary}; 
    background: ${styles.secondary}; 
  }
`;