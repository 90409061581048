import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';
import teamTranslations from '../../../../../../util/TeamTranslations';

const TeamWrapper = styled.table`
  &#team-tr-${props => props.index}{
    background-image: url('/assets/img/pjc/leaderboard-background.png');
    background-attachment: initial;
    background-position: ${props => props.side === 'right' ? -900 : 0}px ${props => -(props.index + 1) * 82 - 130}px;
    background-repeat: no-repeat;
  }

  span {
    position: relative;
  }

  td {
    overflow: hidden;
  }

  td.td-team {
    img {
      height: 65px;
      margin-top: 5px;
    }
  }

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease ${props => props.index/10 + 0.1}s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    p, span {
      bottom: -100px;
      animation: slideUp 0.3s ease ${props => props.index/10 + 0.1}s forwards;
  
      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease ${props => 1 - props.index/10}s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    p, span {
      bottom: 0px;
      animation: slideDown 0.3s ease ${props => 1 - props.index/10}s forwards;
  
      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }
`;

class LeaderboardTeam extends Component {
  render() {
    const { team, rank, index, show, exportOverlay, side } = this.props;

    return (
      <TeamWrapper
        id={exportOverlay ? `team-tr-${index}` : undefined}
        className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}
        index={index}
        side={side}
      >
        <tbody>
          <tr>
            <td className="td-rank text-center">
              <div>
                <span>{ rank }.</span>
              </div>
            </td>
            <td className="td-team">
              <div>
                <img 
                  src={exportOverlay && team.exportLogo ? team.exportLogo : getTeamLogo(team.name)}
                  onError={e => e.target.src = getTeamLogo('default')} alt="team logo"
                />
                <span className="ml-3">{ teamTranslations[team.name] ? teamTranslations[team.name] : team.name }</span>
              </div>
            </td>
            <td className="td-wins text-center">
              <div>
                <span>{ team.wins }</span>
              </div>
            </td>
            <td className="td-kills text-center">
              <div>
                <span>{ team.totalKills }</span>
              </div>
            </td>
          </tr>
        </tbody>
      </TeamWrapper>
    );
  }
}

export default LeaderboardTeam;
