import { 
  REQUEST_TEAM_READY,
  SUBSCRIBE_TO_MAP_VETO_DATA,
  REQUEST_MAP_VETO_PICK,
  SET_MAP_VETO_DATA,
} from './types';

/**
 * Action for requesting team ready
 */
export const requestTeamReady = (teamToken, overlayToken) => ({
    type: REQUEST_TEAM_READY,
    payload: {
      teamToken: teamToken,
      overlayToken: overlayToken,
    }
});

/**
 * Action for requesting subscription to map veto data
 */
export const subscribeToMapVetoData = (teamToken, overlayToken, callback) => ({
    type: SUBSCRIBE_TO_MAP_VETO_DATA,
    payload: {
      teamToken: teamToken,
      overlayToken: overlayToken,
      callback: callback,
    }
});

/**
 * Action for requesting pick
 */
export const requestMapVetoPick = (pick) => ({
    type: REQUEST_MAP_VETO_PICK,
    payload: pick,
});

/**
 * Action for setting new map veto data
 */
export const setMapVetoData = (data) => ({
    type: SET_MAP_VETO_DATA,
    payload: data,
});