import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  img {
    width: 100px;
  }
`;

function LeaderboardFooter(props) {

  return (
    <Wrapper>
      <td colSpan="5">
        <img
          src="/assets/img/twire/logo.png"
          alt="twire logo"
        />
      </td>
    </Wrapper>
  );
}

export default LeaderboardFooter;