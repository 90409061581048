import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import styles from '../../../../../scss/style.scss';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import { CSGO_GAME } from '../../../../../util/Games';
import TeamSelect from '../../../common/base/controller/inputs/TeamSelect';
import {
  COMPONENT_STATE_OVERLAY_TYPE,
  COMPONENT_STATE_PLAYER,
  COMPONENT_STATE_TEAM,
  OVERLAY_TYPE_CARD,
  OVERLAY_TYPE_FULL_SCREEN,
  OVERLAY_TYPE_MVP,
} from '../../../constants';
import PlayerSelect from '../../../common/base/controller/inputs/PlayerSelect';
import StatisticsSelect from '../../../common/base/controller/inputs/StatisticsSelect';
import MatchSelect from '../../../common/base/controller/inputs/MatchSelect';
import { FormGroup, Label } from 'reactstrap';
import { Select } from '../../../../../components/StyledCoreUI';
import { requestChangeComponent } from '../../../../../redux/dashboard/actions';

class CSGOPlayerStatsController extends Component {
  render() {
    const { component, requestChangeComponent } = this.props;

    return (
      <ControllerBase
        game={CSGO_GAME}
        {...this.props}
      >
      <Row>
        <Col xs="12">
          <FormGroup>
            <Label>Overlay Type</Label>
            <Select
              name={COMPONENT_STATE_OVERLAY_TYPE}
              color={styles.tertiaryDark}
              value={component.state.overlayType}
              onChange={type => requestChangeComponent(component, { state: { [COMPONENT_STATE_OVERLAY_TYPE]: type }})}
              disabledOptionText='-- select overlay type --'
              options={[
                {
                  key: OVERLAY_TYPE_FULL_SCREEN,
                  value: 'Full Screen',
                },
                {
                  key: OVERLAY_TYPE_CARD,
                  value: 'Card',
                },
                {
                  key: OVERLAY_TYPE_MVP,
                  value: 'MVP',
                },
              ]}
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <StatisticsSelect component={component} />
        </Col>
        <Col xs="12">
          <MatchSelect component={component} />
        </Col>
        <Col xs="12">
          <TeamSelect
            component={component}
            label={'Team'}
            stateAttribute={COMPONENT_STATE_TEAM}
            childPlayerStateAttribute={COMPONENT_STATE_PLAYER}
          />
        </Col>
        <Col xs="12">
          <PlayerSelect
            component={component}
            label={'Player'}
            stateAttribute={COMPONENT_STATE_PLAYER}
            parentTeamStateAttribute={COMPONENT_STATE_TEAM}
            playerId={'id'}
          />
        </Col>
      </Row>
      </ControllerBase>
    );
  }
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(CSGOPlayerStatsController);
