import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { Input } from '../../../../../../components/StyledCoreUI';

function TextInput(props) {
  const { component, requestChangeComponent, label, placeholder, type, stateField } = props;

  useEffect(() => {
    requestChangeComponent(component, { state: { [stateField]: component.opts ? component.opts[stateField] : null } });
  // eslint-disable-next-line
  }, []);

  return (
    <FormGroup>
      <Label>{ label }</Label>
      <Input
        type={type}
        value={component.state[stateField]}
        placeholder={placeholder}
        onChange={e => requestChangeComponent(component, { state: { [stateField]: e.target.value } })}
        color={styles.tertiaryDark}
        warning={!component.state[stateField] && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(TextInput);
