import gql from 'graphql-tag';

export const subscribeToTournamentMatch = gql`subscription SubscribeToTournamentMatch($shard: String!) {
  subscribeToTournamentMatch(shard: $shard) {
    gameCreatedAt
    gameMatchId
    gameMode
    id
    map
    name
    shard
    shardInfo
    valid
  }
}`;

export const subscribeToUsernameCheck = gql`subscription SubscribeToUsernameCheck($shard: String!) {
  subscribeToUsernameCheck(shard: $shard) {
    dateFrom
    dateTo
    id
    tournamentFilterId
    tournamentId
    trackBy {
      status
      username
    }
  }
}`;