import React, { Component } from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';

const LeaderboardTable = styled.table`
  border-collapse:separate; 
  border-spacing: 0 2px;

  text-align: center;
  vertical-align: middle;

  font-family: Purista-Medium;
  font-size: 1.4em;

  &.bc-show {
    margin-left: -200;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -200px;}
      to {margin-left: 40px;}
    }
  }

  &.bc-hide {
    margin-left: 10;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 10px;}
      to {margin-left: -200px;}
    }
  }

  .tr-header {
    font-family: Purista-Bold;
    background: ${styles.tertiary}EE;
  }
`;

class DnwLiveTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show } = this.props;

    return (
      loaded ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <LeaderboardHeader/>
          <tr className="tr-header">
            <td>#</td>
            <td>TEAM</td>
            <td></td>
            <td>W</td>
            <td>K</td>
          </tr>
          {
            leaderboard.map((t, i) => {
              return (
                <LeaderboardBody
                  team={t}
                  rank={i+1}
                />
              );
            })
          }
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default DnwLiveTeamLeaderboard;