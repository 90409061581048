import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardTeam from './LeaderboardTeam';

const WIDTH = 1800;
const NUM_OF_HORIZONTAL_SPACING = 3;
const HORIZONTAL_SPACING = 5;

const LeaderboardWrapper = styled.table`
  font-family: machine, noto-bold;
  font-size: 33px;

  border-collapse:separate; 
  border-spacing: ${HORIZONTAL_SPACING}px;
  width: ${WIDTH}px;

  tr.team-tr > td {
    width: ${(WIDTH - NUM_OF_HORIZONTAL_SPACING * HORIZONTAL_SPACING) / 2}px;
  }

  table {
    background-image: url('/assets/img/pjc/leaderboard-background.png');
    background-attachment: fixed;
    background-position: 0px -70px;
    background-repeat: no-repeat;

    height: 75px;
    width: 100%;

    &.header-table {
      height: 120px;
    }

    td {
      padding: 0;
      margin: 0;
    }

    td.td-team {
      width: 55%;
    }

    td.th-team {
      font-size: 28px;
      width: ${(WIDTH - NUM_OF_HORIZONTAL_SPACING * (HORIZONTAL_SPACING - 1)) * 0.26}px;
    }

    td.td-rank, td.td-wins, td.td-kills, td.td-pts {
      width: 10%;
    }

    td.th-rank, td.th-kills, td.th-pts {
      font-size: 28px;
      width: ${(WIDTH - NUM_OF_HORIZONTAL_SPACING * (HORIZONTAL_SPACING - 1)) * 0.045}px;
    }

    td.th-wins {
      font-size: 28px;
      width: ${(WIDTH - NUM_OF_HORIZONTAL_SPACING * (HORIZONTAL_SPACING - 1)) * 0.05}px;
    }
  }
`;

class PjcTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, opts, show, exportOverlay } = this.props;
    if (leaderboard.length % 2 !== 0) leaderboard.push({ name: '', totalKills: '', totalPoints: '' });
    // const numberOfHalfTeams = leaderboard.length / 2;
    const numberOfHalfTeams = 8;
    // const firstHalfTeams = leaderboard.slice(0, numberOfHalfTeams);
    const firstHalfTeams = leaderboard.slice(0, 8);
    // const secondHalfTeams = leaderboard.slice(numberOfHalfTeams, leaderboard.length);
    const secondHalfTeams = leaderboard.slice(8, 16);

    if (!loaded) return null;

    return (
      <LeaderboardWrapper>
        <tbody>
          <tr>
            <td colSpan="2">
              <LeaderboardHeader
                opts={opts}
                show={show}
                numberOfRows={numberOfHalfTeams}
                exportOverlay={exportOverlay}
              />
            </td>
          </tr>
          {
            [...new Array(numberOfHalfTeams).keys()].map(i => {
              return (
                <tr className="team-tr">
                  <td>
                    <LeaderboardTeam
                      team={firstHalfTeams[i]}
                      rank={i + 1}
                      index={i}
                      show={show}
                      exportOverlay={exportOverlay}
                      side={'left'}
                    />
                  </td>
                  <td>
                    <LeaderboardTeam
                      team={secondHalfTeams[i]}
                      rank={i + numberOfHalfTeams + 1}
                      index={i}
                      show={show}
                      exportOverlay={exportOverlay}
                      side={'right'}
                    />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </LeaderboardWrapper>
    );
  }
}

export default PjcTeamLeaderboard;
