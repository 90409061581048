import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getMapByGame, getTeamLogo } from '../../../../../../util/CommonUtil';
import { VALORANT_GAME } from '../../../../../../util/Games';
import { MAP_VETO_SIDE_COLORS, MAP_VETO_SIDE_MAPPING } from '../../../../constants';

const MapPick = styled.table`
  background: url('${props => getMapByGame(VALORANT_GAME, props.map)}-muted.png');
  background-size: cover;
  background-position-y: 50px;
  margin: 0px 30px;
`;

const Header = styled.td`
  height: 50px;
  background: #5848f1;
  text-transform: uppercase;
  color: white;
  font-size: 2.5rem;
  font-family: teko-semibold;
`;

const MapPicker = styled.td`
  width: 175px;
  padding: 15px 25px;
  p {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: teko-semibold;
  }

  img {
    height: 100px;
  }
`;

const MapSide = styled.td`
  width: 175px;
  padding: 15px 25px;
  img {
    height: 50px;
  }

  div {
    height: 15px;
    margin-bottom: 10px;

    &.side-indicator {
      background: ${props => props.color};
    }
  }

  .empty-holder {
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }
`;

function MapVeto(props) {
  const { logs } = props;

  return (
    <table>
      <tbody>
        <tr>
          {
            (logs || []).map(mapPick => {
              return (
                <td>
                  <MapPick map={ mapPick.map }>
                    <tbody className="text-center">
                      <tr>
                        <Header colSpan="2">{ mapPick.map }</Header>
                      </tr>
                      <tr>
                        <MapPicker rowSpan="2">
                          <p>{ mapPick.type }</p>
                          <img
                            src={getTeamLogo(mapPick.mapVoter)}
                            alt="team logo"
                            onError={getTeamLogo('default')}
                          />
                        </MapPicker>
                        <MapSide color={MAP_VETO_SIDE_COLORS[MAP_VETO_SIDE_MAPPING.left]}>
                          <div className="side-indicator"></div>
                          {
                            mapPick[MAP_VETO_SIDE_MAPPING.left] ?
                            <img
                              src={getTeamLogo(mapPick[MAP_VETO_SIDE_MAPPING.left])}
                              alt="team logo"
                              onError={getTeamLogo('default')}
                            />
                            :
                            <div className="empty-holder"></div>
                          }
                        </MapSide>
                      </tr>
                      <tr>
                        <MapSide color={MAP_VETO_SIDE_COLORS[MAP_VETO_SIDE_MAPPING.right]}>
                          <div className="side-indicator"></div>
                          {
                            mapPick[MAP_VETO_SIDE_MAPPING.right] ?
                            <img
                              src={getTeamLogo(mapPick[MAP_VETO_SIDE_MAPPING.right])}
                              alt="team logo"
                              onError={getTeamLogo('default')}
                            />
                            :
                            <div className="empty-holder"></div>
                          }
                        </MapSide>
                      </tr>
                    </tbody>
                  </MapPick>
                </td>
              );
            })
          }
        </tr>
      </tbody>
    </table>
  );
}

const mapStateToProps = state => ({
  logs: state.overlay.logs,
});

export default connect(mapStateToProps)(MapVeto);