import React from 'react';

const PUBGTeamStatsOverlay = (props) => {
  return (
    <div>
    </div>
  );
}

export default PUBGTeamStatsOverlay;
