import { 
  SAVE_APOLLO_CLIENT,
} from './types';

const INITIAL_STATE = {
  client: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On Apollo client save
    case SAVE_APOLLO_CLIENT:
      return {
        ...state,
        ...action.payload,
      };
      
    default:
      return state;
  }
}

export default reducer;