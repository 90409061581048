import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const ShowInAnimation = ({ children, show, direction, delay }) => {
  return (
    <Animation show={show} direction={direction} delay={delay}>
      {children}
    </Animation>
  )
}

ShowInAnimation.defaultProps = {
  delay: 0,
  direction: 'left',
}

ShowInAnimation.propTypes = {
  show: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  delay: PropTypes.number,
  children: PropTypes.object,
}

const Animation = styled.div`
  ${({ show }) => {
    if (!show) {
      return null
    }
    return `opacity: 0`
  }};

  animation-name: animation-${({ show, direction }) => {
      return show ? `in` : `out`
    }};
  animation-delay: ${({ delay }) => delay}s;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;

  @keyframes animation-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes animation-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
