import React, { Component } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import styles from '../../../scss/style.scss';

const LoadingImage = styled.img`
  max-width: 75px;
`;

const StyledText = styled.p`
  color: ${styles.secondary};
`;

class Loading extends Component {
  render() {
    const { text } = this.props;

    return (
      <Row>
        <Col xs="12" className="text-center">
          <LoadingImage src="/assets/img/loading/loading.svg" />
        </Col>
        <Col xs="12" className="text-center">
          <StyledText>{ text ? text : 'Loading...' }</StyledText>
        </Col>
      </Row>
    );
  }
}

export default Loading;