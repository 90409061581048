import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardSubHeader from './LeaderboardSubHeader';

const LeaderboardTable = styled.table`
  background: rgba(0, 0, 0, 0.7);
  margin-top: 140px;
  width: 297px !important;

  text-align: center;
  vertical-align: middle;

  font-family: 'BarlowCondensed-Regular';
  font-size: 18px;

  &.bc-show {
    margin-left: -300px;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -300px;}
      to {margin-left: 32px;}
    }
  }

  &.bc-hide {
    margin-left: 32px;
    animation: slideLeft 0.3s ease 0.2s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 10px;}
      to {margin-left: -300px;}
    }
  }
`;

const Qualified = styled.div`
  position: fixed;
  background: #00ffae;

  width: 23px;
  height: 121px;
  top: 231px;
  left: 9px;

  p {
    font-family: 'BarlowCondensed-Regular';
    color: #000;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    margin-top: 7px;
    margin-left: 3px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 10px;
  }

  &.bc-show {
    opacity: 0;
    animation: fadeIn 0.3s ease 0.3s forwards;
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.bc-hide {
    opacity: 1;
    animation: fadeOut 0.2s ease 0s forwards;
  
    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

class PacLiveTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;
    const top16 = leaderboard.slice(0, 16);

    return (
      loaded ?
      <>
        <LeaderboardTable
          className={show ? 'bc-show' : 'bc-hide'}
          overall={opts.overall}
        >
          <tbody>
            <LeaderboardHeader/>
            <LeaderboardSubHeader overall={opts.overall}/>
            {
              top16.map((t, i) => {
                return (
                  <LeaderboardBody
                    team={t}
                    rank={i+1}
                    overall={opts.overall}
                  />
                );
              })
            }
          </tbody>
        </LeaderboardTable>
      </>
      :
      null
    );
  }
}

export default PacLiveTeamLeaderboard;