import React, { Component } from 'react';
import styled from 'styled-components';
import maps from '../../../../../../assets/map/mapMapping';
import { getTeamLogo } from '../../../../../../util/CommonUtil';
import teamTranslations from '../../../../../../util/TeamTranslations';

const WIDTH = 1800;
const HORIZONTAL_SPACING = 5;

const TodaysWinnersWrapper = styled.table`
  font-family: machine;
  font-size: 35px;

  border-collapse:separate; 
  border-spacing: ${HORIZONTAL_SPACING}px;
  width: ${WIDTH}px;

  text-align: center;
  vertical-align: middle;

  p {
    padding: 0;
    margin: 0;
  }

  .background-td {
    background-image: url('/assets/img/pjc/todays-winners-background.png');
    background-attachment: fixed;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .map-overlay {
    position: relative;
    height: 400px;
    width: 354px;
    top: 0;
    left: 0;
    background: #18141b80;
  }

  .component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease 0.2s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    p, span {
      bottom: -100px;
      animation: slideUp 0.3s ease 0.2s forwards;
  
      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  span {
    position: relative;
  }

  .component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease 0s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    p, span {
      bottom: 0px;
      animation: slideDown 0.3s ease 0s forwards;
  
      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }
`;

const Map = styled.td`
  background: url('/assets/img/maps/${props => maps[props.map]}.png');
  height: 400px;
  width: 354px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  img {
    position: absolute;
    width: 220px;
    left: 67px;
    top: 90px;
  }
`;

class PjcTodaysWinners extends Component {
  render() {
    const { loaded, matches, show } = this.props;

    if (!loaded) return null;

    return (
      <TodaysWinnersWrapper>
        <tbody>
          <tr>
            {
              matches.map(m => {
                return (
                  <td className={`background-td ${show ? 'component-show' : 'component-hide'}`}><span>{ m.time }</span></td>
                );
              })
            }
          </tr>
          <tr>
            {
              matches.map(m => {
                return (
                  <Map
                    className={`${show ? 'component-show' : 'component-hide'}`}
                    map={m.map}
                  >
                    {
                      m.teamName ?
                      <div className="map-overlay" />
                      :
                      null
                    }
                    {
                      m.teamName ?
                      <img
                        src={getTeamLogo(m.teamName)}
                        onError={e => e.target.src = getTeamLogo('default')}
                        alt="team logo"
                      />
                      :
                      null
                    }
                  </Map>
                );
              })
            }
          </tr>
          <tr>
            {
              matches.map(m => {
                return (
                  <td className={`background-td ${show ? 'component-show' : 'component-hide'}`}>
                    <span>{ (teamTranslations[m.teamName] ? teamTranslations[m.teamName] : m.teamName) || maps[m.map] }</span>
                  </td>
                );
              })
            }
          </tr>
        </tbody>
      </TodaysWinnersWrapper>
    );
  }
}

export default PjcTodaysWinners;
