import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  td {
    background: #ff8317;
    text-transform: uppercase;
    padding: 1px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <Wrapper>
      <td colSpan="5"><strong>Leaderboard</strong></td>
    </Wrapper>
  );
}

export default LeaderboardHeader;