import React, { Component } from 'react';
import styled from 'styled-components';
import { getPlayerImage } from '../../../../../../util/CommonUtil';
import moment from 'moment';

const PLAYER_CARD_WIDTH = 344;
const PLAYER_CARD_HEIGHT = 435;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;

  .overlay {
    width: 100%;
    height: ${PLAYER_CARD_HEIGHT}px;
    padding: 0 72px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: space-around;
  
    position: fixed;
    top: 263px;
  
    font-family: machine;

    .player-card {
      width: ${PLAYER_CARD_WIDTH}px;
      background: url(/assets/img/pjc21/player-leaderboard-background.png);

      img {
        position: absolute;
        width: ${PLAYER_CARD_WIDTH}px;
        bottom: 0;
      }

      .fade {
        position: absolute;
        width: ${PLAYER_CARD_WIDTH}px;
        bottom: 0;
        height: 250px;
        z-index: 5;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      }
    }

    .player-wrapper {
      position: relative;
      z-index: 10;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      >div {
        height: ${PLAYER_CARD_HEIGHT/2}px;
      }

      div {
        width: 100%;
      }

      .player-rank {
        font-size: 105px;
        line-height: 120px;
        padding-left: 20px;
      }

      .player-info {
        padding-left: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: end;

        .player-stats {
          height: 78px;
          font-size: 78px;
          line-height: 78px;
          margin-bottom: 20px;

          .stat {
            border-bottom: 2px solid #ff0000;
          }

          .stat-name {
            font-size: 42px;
            line-height: 42px;
          }
        }

        .player-name {
          font-family: noto-black;
          height: 38px;
          font-size: 38px;
          line-height: 38px;
          margin-bottom: 20px;
          overflow-x: clip;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            vertical-align: text-top;
          }
        }
      }
    }
  
    &.bc-show .player-card {
      height: 0px;
      animation: slideUp 0.3s ease 0s forwards;
    
      @keyframes slideUp {
        from {height: 0px;}
        to {height: ${PLAYER_CARD_HEIGHT}px;}
      }

      .player-wrapper, img, .fade {
        opacity: 0;
        animation: fadeIn 0.3s ease 0.4s forwards;
      
        @keyframes fadeIn {
          from {opacity: 0;}
          to {opacity: 1;}
        }
      }
    }
  
    &.bc-hide .player-card {
      height: ${PLAYER_CARD_HEIGHT}px;
      animation: slideDown 0.3s ease 0.3s forwards;
    
      @keyframes slideDown {
        from {height: ${PLAYER_CARD_HEIGHT}px;}
        to {height: 0px;}
      }

      .player-wrapper, img, .fade {
        opacity: 1;
        animation: fadeOut 0.3s ease 0s forwards;
      
        @keyframes fadeOut {
          from {opacity: 1;}
          to {opacity: 0;}
        }
      }
    }
  }
`;

const STATS_NAME_MAP = {
  kills: 'kills',
  damageDealt: 'damage',
  damageTaken: 'dmg taken',
  revives: 'revives',
  longestKill: 'long. kill',
  avgTimeSurvived: 'avg time',
  timeSurvived: 'time sur.',
  heals: 'heals',
  flashbangThrow: 'flashbangs',
  grenadeThrow: 'grenades',
  smokebombThrow: 'smokes',
  molotovThrow: 'molotovs',
  headshotKills: 'hs kills',
}

class Pjc21PlayerLeaderBoard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;

    if (!loaded) return null;

    const stat = opts.stats ? opts.stats[0] : {};
    const topPlayers = leaderboard.slice(0, 5);
    console.log(stat)

    return (
      topPlayers.length ?
      <Wrapper>
        <div className={`overlay ${show ? 'bc-show' : 'bc-hide'}`}>
          {
            topPlayers.map((p, i) => {
              return (
                <div
                  key={`player-${i}`}
                  className="player-card"
                >
                  <img
                    src={getPlayerImage(p.ign)}
                    onError={e => e.target.src = '/assets/img/pjc21/player-leaderboard-default.png'}
                    alt="player"
                  />
                  <div className="fade"></div>
                  <div className="player-wrapper">
                    <div className="player-rank">{ i + 1 }</div>
                    <div className="player-info">
                      <div>
                        <div className="player-stats">
                          <span className="stat">{ 
                            stat.key === 'avgTimeSurvived' || stat.key === 'timeSurvived' ?
                            moment().startOf('day')
                              .seconds(p[stat.key])
                              .format('mm:ss')
                            :
                            Math.round(p[stat.key])
                          }<span className="stat-name">{ STATS_NAME_MAP[stat.key] || stat.value }</span></span>
                        </div>
                        <div className="player-name">
                          <span>{ p.ign.split("_").slice(1).join("_") }</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </Wrapper>
      :
      null
    );
  }
}

export default Pjc21PlayerLeaderBoard;
