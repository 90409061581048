import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import styles from '../../../../../scss/style.scss';
import { CardBody, Col, Collapse, Row } from 'reactstrap';
import { Card, CardHeader } from '../../../../../components/StyledCoreUI';
import { IconButton } from '../../../../../components/common';
import { faTimes, faMinus, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  requestChangeComponentWithValidation,
  requestRemoveComponent,
  setComponentState,
  setComponentData,
  subscribeToChangeComponent,
} from '../../../../../redux/dashboard/actions';
import { openModal } from '../../../../../redux/modal/actions';
import { displaySuccessNotification } from '../../../../../redux/notification/actions';
import CheckBox from './inputs/CheckBox';
import { COMPONENT_STATE_GREEN_BACKGROUND } from '../../../constants';

const SmallShowToggleButton = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgb(226,0,0);
  box-shadow: inset 0px 1px 0px 0px rgba(250,250,250,0.5),
    0px 0px 3px 2px rgba(226,0,0,0.5);
  border-radius: 4px;
  float: left;
  margin-top: 5px;
  cursor: pointer;
  
  transition: background-color 350ms, box-shadow 700ms;
    -o-transition: background-color 350ms, box-shadow 700ms;
    -moz-transition: background-color 350ms, box-shadow 700ms;
    -webkit-transition: background-color 350ms, box-shadow 700ms;

  &.show {
    box-shadow: inset 0px 1px 0px 0px rgba(250,250,250,0.5), 0px 0px 3px 2px rgba(135,187,83,0.5);
    background-color: rgb(135,187,83);
  }
`;

const ShowToggleButton = styled.div`
  &.overlay-toggle-button {
    cursor: pointer;
    margin: 15px auto;
    width: 75px;
    height: 95px;
    position: relative;
    text-align: center;
  }
  :active, :focus {
    outline: 0;
  }

  &.overlay-toggle-button a {
    font-family: "FontAwesome";
    color: rgb(37,37,37);
    text-shadow: 0px 1px 1px rgba(250,250,250,0.1);
    font-size: 15pt;
    display: block;
    position: relative;
    text-decoration: none;
    background-color: rgb(83,87,93);
      box-shadow: 0px 3px 0px 0px rgb(34,34,34), /* 1st Shadow */
            0px 7px 10px 0px rgb(17,17,17), /* 1nd Shadow */
            inset 0px 1px 1px 0px rgba(250, 250, 250, .2), /* 3rd Shadow */
            inset 0px -12px 35px 0px rgba(0, 0, 0, .5); /* 4th Shadow */
    width: 70px;
    height: 70px;
    border: 0;
    border-radius: 35px;
    text-align: center;
    line-height: 75px;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
  }

  &.overlay-toggle-button a:before {
    content: "";
    width: 80px;
    height: 80px;
    display: block;
    z-index: -2;
    position: absolute;
    background-color: rgb(26,27,29);
    left: -5px;
    top: -2px;
    border-radius: 40px;
    box-shadow: 0px 1px 0px 0px rgba(250,250,250,0.1), 
          inset 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  &.overlay-toggle-button a + span {
    display: block;
    width: 8px;
    height: 8px;
    background-color: rgb(226,0,0);
    box-shadow: inset 0px 1px 0px 0px rgba(250,250,250,0.5),
          0px 0px 3px 2px rgba(226,0,0,0.5);
    border-radius: 4px;
    clear: both;
    position: absolute;
    bottom: 0;
    left: 42%;
    transition: background-color 350ms, box-shadow 700ms;
      -o-transition: background-color 350ms, box-shadow 700ms;
      -moz-transition: background-color 350ms, box-shadow 700ms;
      -webkit-transition: background-color 350ms, box-shadow 700ms;
  }

  &.overlay-toggle-button a:active {
    box-shadow: 0px 0px 0px 0px rgb(34,34,34), /* 1st Shadow */
          0px 3px 7px 0px rgb(17,17,17), /* 2nd Shadow */
          inset 0px 1px 1px 0px rgba(250, 250, 250, .2), /* 3rd Shadow */
          inset 0px -10px 35px 5px rgba(0, 0, 0, .5); /* 4th Shadow */
    background-color: rgb(83,87,93);
    top: 3px;
  }

  &.overlay-toggle-button a:target {
    box-shadow: 0px 0px 0px 0px rgb(34,34,34),
          0px 3px 7px 0px rgb(17,17,17), 
          inset 0px 1px 1px 0px rgba(250, 250, 250, .2), 
          inset 0px -10px 35px 5px rgba(0, 0, 0, .5);
    background-color: rgb(83,87,93);
    top: 3px;
    color: #fff;
    text-shadow: 0px 0px 3px rgb(250,250,250);
  }

  &.overlay-toggle-button a:active:before, &.overlay-toggle-button a:target:before {
    top: -5px;
    background-color: rgb(26,27,29);
    box-shadow: 0px 1px 0px 0px rgba(250,250,250,0.1), 
          inset 0px 1px 2px rgba(0, 0, 0, 0.5);
  }

  &.overlay-toggle-button a.show {
    box-shadow: 0px 0px 0px 0px rgb(34,34,34), 0px 3px 7px 0px rgb(17,17,17), inset 0px 1px 1px 0px rgba(250, 250, 250, .2), inset 0px -10px 35px 5px rgba(0, 0, 0, .5);
    background-color: rgb(83,87,93);
    top: 3px;
    color: #fff;
    text-shadow: 0px 0px 3px rgb(250,250,250);
  }

  &.overlay-toggle-button a.show + span {
    box-shadow: inset 0px 1px 0px 0px rgba(250,250,250,0.5), 0px 0px 3px 2px rgba(135,187,83,0.5);
    background-color: rgb(135,187,83);
  }

  &.overlay-toggle-button a.loading + span {
    box-shadow: inset 0px 1px 0px 0px rgba(250,250,250,0.5), 0px 0px 3px 2px rgba(135,187,83,0.5);
    background-color: rgb(187, 138, 83);
  }
`;

class ControllerBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: true,
    }
  }

  componentDidMount() {
    const { component, subscribeToChangeComponent, setComponentData } = this.props;
    subscribeToChangeComponent(component, data => setComponentData(component, data));
  }

  getOverlayUrl = (game, componentType, shard, token) => {
    return `${window.location.protocol}//${window.location.host}/overlay/${game}/${componentType}/${shard}/${token}`;
  }

  render() {
    const { opened } = this.state;
    const { component, shard, game, loading, children, requestRemoveComponent, openModal, requestChangeComponentWithValidation } = this.props;

    return (
      <Card color={styles.tertiary}>
        <CardHeader color={styles.tertiary}>
          <Row>
            <Col xs="6">
              <strong>{ component.name }</strong>
              {
                !opened ?
                <SmallShowToggleButton
                  className={`mr-3 small-overlay-toggle-button ${component.show ? 'show' : ''}`}
                  onClick={() => requestChangeComponentWithValidation(component, { show: !component.show })}
                />
                :
                null
              }
            </Col>
            <Col xs="6" className="text-right">
              <CopyToClipboard text={this.getOverlayUrl(game, component.type, shard, component.token)}
                onCopy={() => this.props.displaySuccessNotification('Link Copied')}
              >
                <IconButton
                  className="mr-3"
                  icon={faCopy}
                />
              </CopyToClipboard>
              {
                component.exportOverlay ?
                <a
                  href={`${this.getOverlayUrl(game, component.type, shard, component.token)}?export=true`}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <IconButton
                    className="mr-3"
                    icon={faDownload}
                  />
                </a>
                :
                null
              }
              <IconButton
                className="mr-3"
                icon={faMinus}
                onClick={() => this.setState({ opened: !opened })}
              />
              <IconButton
                icon={faTimes}
                onClick={() => openModal('Remove Component', `Are you sure you want to remove ${component.name}?`, () => requestRemoveComponent(component))}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={opened}>
            { children }
            <Row>
              <Col xs="12">
                <CheckBox
                  component={component}
                  label={'Green Background'}
                  stateAttribute={COMPONENT_STATE_GREEN_BACKGROUND}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <ShowToggleButton className={`overlay-toggle-button`}>
                  <a
                    className={`${component.show ? 'show' : ''} ${loading ? 'loading' : ''}`}
                    onClick={() => requestChangeComponentWithValidation(component, { show: !component.show })}
                  >
                    SHOW
                  </a>
                  <span></span>
                </ShowToggleButton>
              </Col>
            </Row>
          </Collapse>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  requestChangeComponentWithValidation,
  requestRemoveComponent,
  subscribeToChangeComponent,
  openModal,
  displaySuccessNotification,
  setComponentState,
  setComponentData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControllerBase);
