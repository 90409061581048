import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.table`
  width: ${props => props.rowWidth}px;
  font-size: 28px;
`;

const TeamRow = styled.tr`
  background: #1f1f1f;

  &.component-show {
    transform: rotate3d(1, 0, 0, 90deg);
    animation: 
      rotateIn 0.5s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1 + 0.3}s forwards,
      fadeIn 1s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1 + 0.3}s forwards;

    @keyframes rotateIn {
      from {transform: rotate3d(1, 0, 0, 90deg);}
      to {transform: rotate3d(1, 0, 0, 0deg);}
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    transform: rotate3d(1, 0, 0, 0deg);
    animation: 
      rotateOut 0.5s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1}s forwards,
      fadeOut 1s ease ${props => ((props.rank > 8 ? props.rank - 8 : props.rank)) * 0.1}s forwards;

    @keyframes rotateOut {
      from {transform: rotate3d(1, 0, 0, 0deg);}
      to {transform: rotate3d(1, 0, 0, 90deg);}
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function LeaderboardBody(props) {
  const { teams, rowWidth, startingRank, show, exportOverlay } = props;

  return (
    <Wrapper
      rowWidth={rowWidth}
    >
      <tbody>
        {
          teams.map((t, i) => {
            return (
              <TeamRow
                className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}
                rank={i + 1}
              >
                <td className="text-semibold-italic text-center td-rank">#{ startingRank + i }</td>
                <td className="text-semibold td-team">{ t.name }</td>
                <td className="text-semibold text-center td-wins">{ t.wins }</td>
                <td className="text-semibold text-center td-kills">{ t.totalKills }</td>
              </TeamRow>
            );
          })
        }
      </tbody>
    </Wrapper>
  );
}

export default LeaderboardBody;