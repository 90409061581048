import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { ShowInAnimation } from '../../../../animations/ShowInAnimation'

const TEAM_PADDING = 3

function TeamLeaderboard(props) {
  const { show, teamLeaderboard } = props;

  if (!teamLeaderboard || !teamLeaderboard.length) return null;

  // Set up the table here
  const STANDARD_TEAM_SIZE = 4
  const HOW_MANY_PAGES = 1 // If pagination is needed: Math.ceil(teamLeaderboard.length / 8)
  const TEAM_COL_LEN = 25
  const WINS_COL_LEN = 3
  const DASH_COL = 1
  const LOSE_COL_LEN = 3
  const GAP_BETWEEN_PAGES = 1

  const numberOfExtraTeams =
    teamLeaderboard.length - STANDARD_TEAM_SIZE > 0 &&
    teamLeaderboard.length - STANDARD_TEAM_SIZE

  const howManyToDisplayPerPage = Math.ceil(
    teamLeaderboard.length / HOW_MANY_PAGES,
  )

  const shouldEvenPaginate =
    teamLeaderboard.length - howManyToDisplayPerPage > 0

  const mainContainerWidth =
    (TEAM_COL_LEN + WINS_COL_LEN + LOSE_COL_LEN + DASH_COL + TEAM_PADDING * 2) *
    HOW_MANY_PAGES

  const tableHeight = howManyToDisplayPerPage * 6 + numberOfExtraTeams * 6

  return (
    <OverflowContainer>
      <FontWrapper>
        <ShowInAnimation show={show}>
          <Container width={mainContainerWidth}>
            <Title>Group A</Title>
            <Table height={tableHeight}>
              {teamLeaderboard.map((team, i) => {
                const page = Math.ceil((i + 1) / howManyToDisplayPerPage)
                const half = Math.ceil(teamLeaderboard.length / 2) === i + 1
                return (
                  <Team
                    shouldEvenPaginate={shouldEvenPaginate}
                    half={half}
                    backgroundColor={'rgba(0,0,0,.80)'}
                    page={page}
                    gap={GAP_BETWEEN_PAGES}
                  >
                    <Cell width={TEAM_COL_LEN} uppercase textToLeft>
                      {team.teamName}
                    </Cell>
                    <Cell width={WINS_COL_LEN}>{team.wins}</Cell>
                    <Cell width={DASH_COL}>-</Cell>
                    <Cell borderLeft width={LOSE_COL_LEN}>
                      {team.losses}
                    </Cell>
                  </Team>
                )
              })}
            </Table>
          </Container>
        </ShowInAnimation>
      </FontWrapper>
    </OverflowContainer>
  )
}

const mapStateToProps = state => ({
  teamLeaderboard: state.overlay.valorant,
  opts: state.overlay.opts,
  show: state.overlay.show,
});

export default connect(mapStateToProps)(TeamLeaderboard);

const OverflowContainer = styled.div`
  overflow: hidden;
  background-color: transparent;
`

const FontWrapper = styled.div`
  font-size: 1.7rem;
  color: white;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`

const Container = styled.div`
  width: ${({ width }) => width}rem;
`

const Cell = styled.div`
  width: ${({ width }) => width}rem;
  ${({ color }) => color && `color: ${color};`}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: teko-regular;

  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};

  display: flex;
  justify-content: ${({ textToLeft }) => (textToLeft ? 'start' : 'center')};
  align-items: ${({ textToBottom }) => (textToBottom ? 'flex-end' : 'center')};
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;

  width: 100%;
  height: ${({ height }) => height}rem;
`

const Team = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: stretch;

  font-family: teko-regular;
  font-size: 2.5rem;
  letter-spacing: 2px;

  padding: 0 ${TEAM_PADDING};

  margin${({ page, gap, shouldEvenPaginate }) => {
    if (shouldEvenPaginate) {
      return page % 2 === 0 ? `-left:${gap}rem` : `-right:${gap}rem`
    }
  }};

  background-color: ${({ backgroundColor }) => backgroundColor};
`

const Title = styled.div`
  width: 100%;
  font-size: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1a3ffb;
  padding: 1 ${TEAM_PADDING};

  font-family: teko-semibold;
  letter-spacing: 1px;
`
