import React, { Component } from 'react';
import styled from 'styled-components';
import HeadToHeadBody from './HeadToHeadBody';
import HeadToHeadHeader from './HeadToHeadHeader';
import HeadToHeadFooter from './HeadToHeadFooter';

const HeadToHeadWrapper = styled.table`
  font-family: 'agency_fb';
  text-align: center; 
  vertical-align: middle;
  text-transform: uppercase;
  border-collapse:separate;
  border-spacing: 10px 10px;

  h1 {
    font-size: 70px;
  }
`;

class RyerHeadToHeadPlayer extends Component {

  render() {
    const { loaded, left, right, opts, show } = this.props;

    if (!loaded || !left || !right) return null;

    return (
      <HeadToHeadWrapper>
        <tbody>
          <HeadToHeadHeader
            show={show}
            numberOfStats={(opts.stats || []).length}
          />
          <HeadToHeadBody
            left={left}
            right={right}
            opts={opts}
            show={show}
          />
          <HeadToHeadFooter
            show={show}
            numberOfStats={(opts.stats || []).length}
          />
        </tbody>
      </HeadToHeadWrapper>
    );
  }
}

export default RyerHeadToHeadPlayer;
