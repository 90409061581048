import React from 'react';
import styled from 'styled-components';
import { addMissingPlayers, isTeamDeath, sortByPlayerStatus } from '../../../../../../util/CommonUtil';

const TeamRow = styled.tr`
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);

  &.team-death {
    color: rgba(255, 255, 255, 0.2);
  }

  &.dark-bg td {
    background: rgba(0, 0, 0, 0.2);
  }

  td {
    padding: 0;
    font-family: 'ESL Legend';
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    height: 30px;
    font-size: 22px;
    line-height: 22px;
  }

  .team-death {
    table {
      display: none;
    }
  }

  .team-initial {
    width: 44px;
  }

  .team-players {
    width: 42px;
  }

  .team-wins {
    width: 38px;
  }

  .team-kills {
    text-align: right;
    width: 64px;
    padding-right: 12px;
  }

  .team-rank {
    text-align: right;
    width: 32px;
  }

  .team-alive {
    table {
      margin: 0 auto;
    }

    td {
      background: none;
      padding: 3px 2px;
      div {
        width: 4px;
        height: 22px;
      }
    }

    .alive {
      background: #ffff08;
    }
    .knocked {
      background: #6bff36;
    }
    .death {
      background: #000000;
    }
  }
`;

function LeaderboardBody(props) {
  const { team, rank } = props;
  const teamDeath = isTeamDeath(team);
  addMissingPlayers(team.players);

  return (
    <TeamRow
      className={`${teamDeath ? 'team-death' : ''} ${rank%2 === 0 ? 'dark-bg' : ''}`}
    >
      <td className="team-rank">{ `#${rank}` }</td>
      <td className="team-initial">{ team.initial }</td>
      <td className={'team-players ' + (teamDeath ? `team-death` : `team-alive`)}>
        <table>
          <tbody>
            <tr>
              {
                team.players.sort(sortByPlayerStatus).map(i => {
                  return (
                    <td>
                      <div className={i.status}></div>
                    </td>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      </td>
      <td className="team-wins">{ team.wins }</td>
      <td className="team-kills">{ team.totalKills } ({ team.currentKills })</td>
    </TeamRow>
  );
}

export default LeaderboardBody;