import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { COMPONENT_STATE_STATS } from '../../../../constants';
import Multiselect from '../../../../../../components/common/multiselect/Multiselect';

function StatisticsSelect(props) {
  const { component, requestChangeComponent } = props;

  return (
    <FormGroup>
      <Label>Statistic (max {component.maxStatistics})</Label>
      <Multiselect
        options={component.availableStatistics}
        selectedValues={component.state[COMPONENT_STATE_STATS]}
        selectionLimit={component.maxStatistics}
        onSelect={s => requestChangeComponent(component, { state: { [COMPONENT_STATE_STATS]: s } })}
        onRemove={s => requestChangeComponent(component, { state: { [COMPONENT_STATE_STATS]: s.length ? s : null } })}
        color={styles.tertiaryDark}
        displayValue='value'
        warning={!component.state[COMPONENT_STATE_STATS] && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(StatisticsSelect);
