import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLivePlayerKills';
import Being from './being/BeingLivePlayerKillsDamage';
import Protality from './protality/ProtalityLivePlayerKills';
import Default from './default/DefaultPlayerKills';

const PUBGLivePlayerKillsOverlay = (props) => {
  const { overlayDesign } = props;

  return (
    overlayDesign === 'esl' ? 
    <ESL {...props} />
    :
    overlayDesign === 'being' ? 
    <Being {...props} />
    :
    overlayDesign === 'protality' ? 
    <Protality {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  players: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  overlayColor: (state.overlay.tournament || {}).overlayColor,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLivePlayerKillsOverlay);
