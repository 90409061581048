import {
  DISPLAY_SUCCESS_NOTIFICATION,
  DISPLAY_ERROR_NOTIFICATION,
  DISPLAY_INFO_NOTIFICATION
} from './types';

/**
 * Action for displaying success notifications
 * 
 * @param {*} message message to be displayed
 */
export const displaySuccessNotification = message => ({
  type: DISPLAY_SUCCESS_NOTIFICATION,
  payload: message
});

/**
 * Action for displaying success notifications
 * 
 * @param {*} message message to be displayed
 */
export const displayErrorNotification = message => ({
  type: DISPLAY_ERROR_NOTIFICATION,
  payload: message
});

/**
 * Action for displaying info notifications
 * 
 * @param {*} message message to be displayed
 */
export const displayInfoNotification = message => ({
  type: DISPLAY_INFO_NOTIFICATION,
  payload: message
});