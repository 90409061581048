import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Card } from 'reactstrap';

export default styled(Card)`
  background: ${props => props.color ? props.color : styles.tertiaryDark};
  border-color: ${styles.tertiaryDark};
  color: ${styles.secondary};

  .card-footer {
    background: ${props => props.color ? props.color : styles.tertiaryDark};
    border: none;
  }
`;