import React, { Component } from 'react';
import styled from 'styled-components';
import { getPlayerImage, getTeamLogo, numberForDisplay } from '../../../../../../util/CommonUtil';
import PlayerStat from './PlayerStat';

const LeaderboardTable = styled.table`
  background: url('/assets/img/protality/player-stats-background.png');
  width: 265px;
  height: 571px;
  font-family: machine, noto-bold;
  font-weight: 600;
  border-collapse: separate;
  text-transform: uppercase;

  &.bc-show {
    margin-left: -280px;
    animation: slideRight 0.3s ease 0.5s forwards;
  
    @keyframes slideRight {
      from {margin-left: -280px;}
      to {margin-left: 32px;}
    }
  }

  &.bc-hide {
    margin-left: 32px;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 32px;}
      to {margin-left: -280px;}
    }
  }

  td {
    width: 130px;

    &.images {
      padding: 0px 0px 0px;
      height: 220px;

      div {
        margin-top: -52px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .player-image {
          height: 220px;
        }

        .team-image {
          position: absolute;
          bottom: -20px;
          right: 20px;
          height: 100px;
        }
      }
    }

    &.player-name {
      vertical-align: initial;
      text-overflow: ellipsis;
      overflow: hidden;

      p {
        margin: 0;
      }

      .name {
        font-family: 'DrukTextWide-BoldItalic';
        padding: 40px 0px 0px;
        color: #000;
        font-size: ${ props => props.nameLength > 10 ? 20 : props.nameLength > 9 ? 23 : 28 }px;
        line-height: 55px;
        height: 80px;
      }

      .initial {
        font-family: 'DrukWide-BoldItalic';
        font-size: 20px;

      }
    }

    &.player-stats {
      padding: 0px 10px 0px;
      height: 100px;

      p {
        margin: 0;
      }
    }
  }
`;

class ProtalityLivePlayerStats extends Component {
  render() {
    const { player, show } = this.props;

    return (
      player && player.ign ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'} nameLength={player.ign.length}>
        <tbody>
          <tr>
            <td
              colSpan="2"
              className="player-name text-center"
            >
              <p className="name">{ player.ign.replace(new RegExp(`${player.teamInitial}_`, 'ig'), '').toUpperCase() }</p>
              <p className="initial">{ (player.teamInitial || '').toUpperCase() }</p>
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="images text-center"
            >
              <div>
                <img
                  className="player-image"
                  src={getPlayerImage(`${player.ign}`)}
                  onError={e => e.target.src = getPlayerImage('default')}
                  alt="player"
                />
                <img
                  className="team-image"
                  src={getTeamLogo(`${player.teamName}`)}
                  onError={e => e.target.src = getTeamLogo('default')}
                  alt="team-logo"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="player-stats">
              <PlayerStat
                name='Öldürme'
                value={player.totalKills}
              />
            </td>
            <td className="player-stats">
              <PlayerStat
                name='ÖLD SIRA'
                value={numberForDisplay(player.killRank)}
              />
            </td>
          </tr>
          <tr>
            <td className="player-stats">
              <PlayerStat
                name='Hasar'
                value={parseInt(player.totalDamage)}
                last={true}
              />
            </td>
            <td className="player-stats">
              <PlayerStat
                name='HSR SIRA'
                value={numberForDisplay(player.damageRank)}
                last={true}
              />
            </td>
          </tr>
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default ProtalityLivePlayerStats;