import gql from 'graphql-tag';

export const getTournaments = gql`query GetTournaments {
  getTournaments {
    csgoTournaments {
      id
      friendlyName
      shard
      imageName
      region
      active
      filter
    }
    pubgTournaments {
      id
      friendlyName
      shard
      imageName
      region
      active
      filter
    }
    pubgcTournaments {
      id
      friendlyName
      shard
      imageName
      region
      active
      filter
    }
    valorantTournaments {
      id
      friendlyName
      shard
      imageName
      region
      active
      filter
    }
  }
}`;

export const getTournament = gql`query GetTournament($game: String!, $tournament: TournamentInput!) {
  getTournament(game: $game, tournament: $tournament) {
    active
    delay
    eventType
    startDate
    stopDate
    tier
    prizePool
    filter
    friendlyName
    gameMode
    id
    imageName
    matches {
      gameCreatedAt
      gameMatchId
      gameMode
      id
      map
      name
      shard
      shardInfo
      valid
    }
    overlayColor
    overlayDesign
    region
    server
    pubgTournamentId
    shard
    tournamentAwards {
      columns {
        id
        sortingIndex
        sortingOrder
        title
        tournamentAwardFilterId
        tournamentAwardId
      }
      description
      filters {
        id
        title
        tournamentAwardId
      }
      id
      rows {
        id
        player {
          id
          username
        }
        team {
          id
          name
        }
        tournamentAwardColumnId
        tournamentAwardFilterId
        tournamentAwardId
        value
      }
      title
      tournamentId
      type
    }
    tournamentFilters {
      id
      name
      numberOfMatches
      parentId
      phases {
        dateFrom
        dateTo
        id
        tournamentFilterId
        tournamentId
        trackBy {
          status
          username
        }
      }
      teams {
        id
        name
        slotNo
      }
      tournamentId
      type
      ruleset
      value
      prizePoolsDefinitons {
        id
        placement
        prize
      }
    }
    tournamentPhases {
      dateFrom
      dateTo
      id
      tournamentFilterId
      tournamentId
      trackBy {
        status
        username
        platform
      }
    }
  }
}`;

export const teamSearch = gql`query TeamSearch($game: String!, $team: String!) {
  teamSearch(game: $game, team: $team) {
    country {
      id
      iso
      name
    }
    id
    name
  }
}`;

export const getOrganization = gql`query GetOrganization {
  getOrganization {
    id
    name
    users {
      id
      username
    }
    verified
    availableComponents
  }
}`;