import { 
  REQUEST_TEAM_LEADERBOARD_PENDING,
  REQUEST_TEAM_LEADERBOARD_SUCCESS,
  REQUEST_TEAM_LEADERBOARD_ERROR,
  ADD_TEAM_LEADERBOARD,
} from './types';
import { compareTeams } from '../../util/LeaderboardUtil';

const INITIAL_STATE = {
  loaded: false,
  leaderboard: [],
  matchName: null,
  lastMatchName: null,
  groupName: null,
  overlayDesign: null,
  overlayColor: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request team leaderboard pending or error
    case REQUEST_TEAM_LEADERBOARD_PENDING:
    case REQUEST_TEAM_LEADERBOARD_ERROR:
      return INITIAL_STATE;

    // On request team leaderboard success
    case REQUEST_TEAM_LEADERBOARD_SUCCESS:
      return {
        loaded: true,
        matchName: action.payload.matchName,
        lastMatchName: action.payload.lastMatchName,
        groupName: action.payload.groupName,
        leaderboard: action.payload.leaderboard.sort((a, b) => compareTeams(a, b)),
        overlayDesign: action.payload.overlayDesign,
        overlayColor: action.payload.overlayColor,
      };

    case ADD_TEAM_LEADERBOARD:
      return {
        loaded: true,
        leaderboard: action.payload,
      }

    default:
      return state;
  }
}

export default reducer;