import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import MatchSelect from '../../../common/base/controller/inputs/MatchSelect';
import { VALORANT_GAME } from '../../../../../util/Games';

class ValorantTeamLeaderboardController extends Component {
  render() {
    const { component } = this.props;

    return (
      <ControllerBase
        game={VALORANT_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <MatchSelect component={component} />
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

export default ValorantTeamLeaderboardController;
