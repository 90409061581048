import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogoByGame, numberForDisplay } from '../../../../../../util/CommonUtil';

const PlayerStatsTable = styled.table`
  font-family: sdp;
  text-transform: uppercase;

  td {
    text-align: center; 
    vertical-align: middle;
    width: 500px;
    padding: 8px 30px;
  }

  transition: opacity 0.5s ease-out;
  transition-delay: 0.5s;

  &.cg-show {
    opacity: 1;
  }

  &.cg-hide {
    opacity: 0;
  }
`;

const TeamLogos = styled.tr`
  img {
    height: 150px;
  }
`;

const HeaderRow = styled.tr`
  font-family: sdp;
  background: ${styles.sdpPrimary};
  font-size: 2.3rem;
`;

const StatsRow = styled.tr`
  background: rgba(0,0,0,.80);
  font-size: 2rem;
`;

function PlayerStats(props) {
  const { player, opts, game, show } = props;

  return (
    <PlayerStatsTable
      className={`text-center ${!show ? 'cg-hide': 'cg-show'}`}
    >
      <tbody>
        <TeamLogos>
          <td colSpan="2">
            <img
              src={getTeamLogoByGame(game, player.teamName)}
              alt="team logo"
              onError={e => e.target.src = getTeamLogoByGame(game)}
            />
          </td>
        </TeamLogos>
        <HeaderRow>
          <td colSpan="2">
            {player.ign}
          </td>
        </HeaderRow>
        {
          (opts.stats || []).map(s => {
            return (
              <StatsRow>
                <td className="text-left">
                  {s.value}
                </td>
                <td className="text-right">
                  {numberForDisplay(player[s.key])}
                </td>
              </StatsRow>
            );
          })
        }
      </tbody>
    </PlayerStatsTable>
  );
}

const mapStateToProps = state => ({
  player: state.overlay.valorant || {},
  opts: state.overlay.opts,
  show: state.overlay.show,
});

export default connect(mapStateToProps)(PlayerStats);