import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const HeaderRow = styled.tr`
  background: #222682;

  td {
    color: ${styles.secondary};
    text-transform: uppercase;
    padding: 1px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow>
      <td colSpan="5"><strong>Leaderboard</strong></td>
    </HeaderRow>
  );
}

export default LeaderboardHeader;