import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import StatisticsSelect from '../../../common/base/controller/inputs/StatisticsSelect';
import CheckBox from '../../../common/base/controller/inputs/CheckBox';
import { PUBG_GAME } from '../../../../../util/Games';
import MatchSelect from '../../../common/base/controller/inputs/MatchSelect';
import { COMPONENT_STATE_SUBSCRIPTION } from '../../../constants';

class PUBGLivePlayerLeaderboardController extends Component {
  render() {
    const { component } = this.props;
    // TODO: remove when actual statistics will be available
    component.availableStatistics = [
      {key: "kills", value: "kills"},
      {key: "damage_dealt", value: "damage dealt"},
    ]

    return (
      <ControllerBase
        game={PUBG_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <StatisticsSelect component={component} />
          </Col>
          <Col xs="12">
            <MatchSelect component={component} />
          </Col>
          <Col xs="12">
            <CheckBox
              component={component}
              label={'Activate Leaderboard'}
              stateAttribute={COMPONENT_STATE_SUBSCRIPTION}
            />
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

export default PUBGLivePlayerLeaderboardController;
