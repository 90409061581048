import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import PrivateRoute from './PrivateRoute';
import history from './history';
import apolloClient from './apolloClients/apolloClient';
import './App.scss';
import { saveApolloClient } from './redux/apollo/actions';
import { HEAD_TO_HEAD_PLAYER_TYPE, HEAD_TO_HEAD_TEAM_TYPE } from './util/Constants';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Home = React.lazy(() => import('./views/Home/Home'));
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const OverlayBase = React.lazy(() => import('./views/Components/common/base/overlay/OverlayBase'));
const MapVetoPanel = React.lazy(() => import('./views/Components/common/mapVeto/panel/MapVetoPanel'));
const TeamLeaderboard = React.lazy(() => import('./views/CustomTournamentOverlays/TeamLeaderboard'));
const PlayerLeaderboard = React.lazy(() => import('./views/CustomTournamentOverlays/PlayerLeaderboard'));
const TodaysWinners = React.lazy(() => import('./views/CustomTournamentOverlays/TodaysWinners'));
const HeadToHeadTeams = React.lazy(() => import('./views/CustomTournamentOverlays/HeadToHeadTeams'));
const HeadToHeadPlayers = React.lazy(() => import('./views/CustomTournamentOverlays/HeadToHeadPlayers'));
const CurrentMatch = React.lazy(() => import('./views/CustomTournamentOverlays/CurrentMatch'));

class App extends Component {
  componentDidMount() {
    this.props.saveApolloClient(apolloClient);
  }

  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <Router history={history}>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route exact path={"/overlay/:game/:componentType/:shard/:token"} name="Overlay" render={props => <OverlayBase {...props}/>} />
                <Route exact path={"/map-veto/:game/:overlayToken/:teamToken"} name="Map Veto Panel" render={props => <MapVetoPanel {...props}/>} />
                <Route exact path="/team-leaderboard/:params" name="Team Leaderboard" render={(props) => <TeamLeaderboard {...props} />} />
                <Route exact path="/player-leaderboard/:params" name="Player Leaderboard" render={(props) => <PlayerLeaderboard {...props} />} />
                <Route exact path="/todays-winners/:params" name="Todays Winners" render={(props) => <TodaysWinners {...props} />} />
                <Route exact path={'/head-to-head-' + HEAD_TO_HEAD_TEAM_TYPE + '/:params'} name="Head To Head Teams" render={(props) => <HeadToHeadTeams {...props} />} />
                <Route exact path={'/head-to-head-' + HEAD_TO_HEAD_PLAYER_TYPE + '/:params'} name="Head To Head Players" render={(props) => <HeadToHeadPlayers {...props} />} />
                <Route exact path="/current-match/:params" name="Current Match" render={(props) => <CurrentMatch {...props} />} />
                <Route exact path="/" name="Home" component={Home} />
                <PrivateRoute path="/app" name="Home" component={DefaultLayout} />
              </Switch>
            </React.Suspense>
        </Router>
      </ApolloProvider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveApolloClient: (client) => dispatch(saveApolloClient(client)),
});

export default connect(null, mapDispatchToProps)(App);
