import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Progress } from 'reactstrap';

export default styled(Progress)`
  background: ${styles.infoDark};

  .progress-bar {
    background: ${props => props.color ? styles[props.color] : styles.primary};
  }
`;