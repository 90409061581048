import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Tooltip,
} from 'reactstrap';
import {
  Dropdown,
} from '..';

const StyledDropdownWrapper = styled(Col)`
  div button {
    text-transform: uppercase;
  }
`;

function DropdownWithInfo(props) {
  const { title, value, choices, defaultChoice, toolTipText, onChange } = props;
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  return (
    <Row>
      <Col xs="12" className="mb-1">
        <span>{ title }:</span>
      </Col>
      <StyledDropdownWrapper xs="12" className="mb-3">
        <Dropdown 
          choices={choices}
          selected={value}
          controlled={true}
          defaultChoice={defaultChoice}
          onChange={i => {
            onChange(i);
          }}
          alignLeft={true}
        />
        {
          toolTipText ?
          <>
            <FontAwesomeIcon
              id={`${title.toLowerCase().replaceAll(' ', '-')}-tooltip`}
              className="ml-2"
              icon={faQuestion}
            />
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={`${title.toLowerCase().replaceAll(' ', '-')}-tooltip`}
              toggle={() => setTooltipOpen(!tooltipOpen)}
            >
              { toolTipText }
            </Tooltip>
          </>
          :
          null
        }
      </StyledDropdownWrapper>
    </Row>
  );
}

export default DropdownWithInfo;