import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.table`
  width: ${props => props.rowWidth / 2}px;

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.3s ease 0.3s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: fadeOut 0.3s ease 0s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

const HeaderRow = styled.tr`
  td {
    padding: 15px;
    background: #ffa900;
    border-bottom: 5px solid #00000000;
  }
`;

function LeaderboardHeader(props) {
  const { rowWidth, show, exportOverlay } = props;

  return (
    <Wrapper
      className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}
      rowWidth={rowWidth}
    >
      <tbody>
        <HeaderRow>
          <td className="text-bold text-center td-rank">Rank</td>
          <td className="text-bold td-team">Team</td>
          <td className="text-bold text-center td-wins">WWCD</td>
          <td className="text-bold text-center td-kills">Kill</td>
        </HeaderRow>
      </tbody>
    </Wrapper>
  );
}

export default LeaderboardHeader;