import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_TEAM_LEADERBOARD,
  REQUEST_TEAM_LEADERBOARD_PENDING,
  REQUEST_TEAM_LEADERBOARD_SUCCESS,
  REQUEST_TEAM_LEADERBOARD_ERROR,
  REQUEST_TEAM_LEADERBOARD_AS_CSV,
} from './types';
import {
  REQUEST_EXPORT_AS_IMAGE_PENDING,
  REQUEST_EXPORT_AS_IMAGE_SUCCESS,
} from '../exportAsImage/types';
import { productionLeaderboard, platformLeaderboard } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';
import { convertImgToBase64URL } from '../../util/ExportAsImageUtil';
import { arrayToCsv, downloadCsv, getTeamLogo } from '../../util/CommonUtil';
import { compareTeams } from '../../util/LeaderboardUtil';
import { displayErrorNotification } from '../notification/actions';
import { LOADING_ERROR } from '../../util/ErrorMessages';

/**
 * Makes a request to server for team leaderboard
 * 
 * @param {*} action 
 */
function* requestTeamLeaderboard(action) {
  yield put({ type: REQUEST_TEAM_LEADERBOARD_PENDING });
  yield put({ type: REQUEST_EXPORT_AS_IMAGE_PENDING });
  try {
    // Calling the server
    let result;
    if (action.payload.token) {
      result = yield publicQuery(productionLeaderboard, {
        game: action.payload.game,
        tournament: action.payload.tournament,
        token: action.payload.token,
      });

      // Loading images as base64 if leaderboard will be exported as image
      if (action.payload.forExport) {
        for (let i = 0; i < result.data.productionLeaderboard.leaderboard.length; i++) {
          result.data.productionLeaderboard.leaderboard[i].logo = yield convertImgToBase64URL(getTeamLogo(result.data.productionLeaderboard.leaderboard[i].team));
        }
      }

      yield put({ type: REQUEST_TEAM_LEADERBOARD_SUCCESS, payload: result.data.productionLeaderboard });
      yield put({ type: REQUEST_EXPORT_AS_IMAGE_SUCCESS });
    } else {
      result = yield publicQuery(platformLeaderboard, {
          game: action.payload.game,
          tournament: action.payload.tournament,
        });
      yield put({ type: REQUEST_TEAM_LEADERBOARD_SUCCESS, payload: result.data.platformLeaderboard });
    }
  } catch (err) {
    yield put({ type: REQUEST_TEAM_LEADERBOARD_ERROR });
  }
}

/**
 * Makes a request to server for team leaderboard for CSV export
 * 
 * @param {*} action 
 */
function* requestTeamLeaderboardAsCsv(action) {
  try {
    // Calling the server
    const result = yield publicQuery(productionLeaderboard, { tournament: action.payload.tournament, token: action.payload.token });
    const leaderboard = result.data.productionLeaderboard.leaderboard.sort((a, b) => compareTeams(a, b)).map((team, i) => {
      return {
        rank: i + 1,
        teamName: team.team,
        teamLogo: getTeamLogo(team.team),
        wins: team.wins,
        points: team.points + team.kills,
        kills: team.kills,
      };
    });
    const csv = arrayToCsv({ rank: 'Rank', teamLogo: 'Team Logo', teamName: 'Team Name', wins: 'Wins', kills: 'Kill Points', points: 'Total Points' }, leaderboard);
    downloadCsv(csv, `leaderboard`);
  } catch (err) {
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TEAM_LEADERBOARD, requestTeamLeaderboard),
    takeLatest(REQUEST_TEAM_LEADERBOARD_AS_CSV, requestTeamLeaderboardAsCsv),
  ]);
}