import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const mainLogoSize = 200;

const Wrapper = styled(Row)`
  text-transform: uppercase;
  font-family: 'agency_fb';
  text-transform: uppercase;
  background-repeat: no-repeat;

  p {
    margin: 0;
    padding: 0;
  }

  .main-image {
    width: ${ mainLogoSize }px;
  }

  .logos-wrapper {
    padding: 10px;
    background: #3e3e3e;
  }

  .team-logo {
    height: 130px;
    bottom: 0;
    filter: drop-shadow(0px 0px 8px #000000);
    position: absolute;
    z-index: 5;

    &.right {
      right: 50px;
    }
    &.left {
      left: 20px;
    }
  }

  .head-to-head-body {
    width: ${props => props.rowWidth - 2 * props.rowMargin}px;
    padding: 5px 15px;
    font-size: 20px;

    .main-name {
      font-size: 40px;
      margin-bottom: 15px;
    }

    .details-team-name {
      font-size: 25px;
      margin-bottom: -15px;

      &.left {
        color: ${styles.aelBlue};
      }
      &.right {
        color: ${styles.aelGreen};
      }
    }
  }

  .details-wrapper {
    color: ${styles.secondary};
    font-size: 35px;
  }

  .detail {
    padding: 10px;
    background: #3e3e3e;
  }
`;

const VsCol = styled(Col)`
  display: flex;
  justify-content: center;
  font-size: 100px;

  p {
    text-shadow: none !important;
  }
`;

function HeadToHeadBody(props) {
  const { left, right } = props;

  const Details = ({left, right}) => {
    return (
      <Row>
        <Col xs="12" className="details-wrapper">
          {
            props.stats.map(s => {
              return (
                <Row className="text-center detail mb-2">
                  <Col xs="3" className="offset-1">
                    <p>{left[s.key]}</p>
                  </Col>
                  <Col xs="4">
                    { s.value }
                  </Col>
                  <Col xs="3">
                    <p>{right[s.key]}</p>
                  </Col>
                </Row>
              );
            })
          }
        </Col>
      </Row>
    );
  }

  return (
    <Wrapper
      className="text-center"
      rowWidth={props.rowWidth}
      rowMargin={props.margins}
    >
      <Col xs="12">
        <div className="head-to-head-body">
          <Row className="text-center">
            <Col xs="12">
              <Row className="logos-wrapper mb-2">
                <Col xs="5" className="main-name">
                  { left.teamName }
                </Col>
                <Col xs="5" className="offset-2 main-name">
                  { right.teamName }
                </Col>
                <Col xs="5">
                  <Row className="text-center">
                    <Col xs="12">
                      <img 
                        src={getTeamLogo(left.teamName)}
                        alt="team logo"
                        className="main-image"
                        onError={(e) => e.target.src = getTeamLogo('default')}
                      />
                    </Col>
                  </Row>
                </Col>
                <VsCol xs="2" className="font-subtitle">
                  <p>VS</p>
                </VsCol>
                <Col xs="5">
                  <Row className="text-center">
                    <Col xs="12">
                      <img 
                        src={getTeamLogo(right.teamName)}
                        alt="team logo"
                        className="main-image"
                        onError={(e) => e.target.src = getTeamLogo('default')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="gradient-border" />
              <Details
                left={props.left}
                right={props.right}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Wrapper>
  );
}

export default HeadToHeadBody;