import { combineReducers } from 'redux';

import apollo from './apollo/reducer';
import auth from './auth/reducer';
import currentMatch from './currentMatch/reducer';
import dashboard from './dashboard/reducer';
import tournaments from './tournaments/reducer';
import tournamentFilters from './tournamentFilters/reducer';
import teamLeaderboard from './teamLeaderboard/reducer';
import modal from './modal/reducer';
import playerLeaderboard from './playerLeaderboard/reducer';
import todaysWinners from './todaysWinners/reducer';
import mapVeto from './mapVeto/reducer';
import tournament from './tournament/reducer';
import tournamentPlayers from './tournamentPlayers/reducer';
import tournamentTeams from './tournamentTeams/reducer';
import customTournaments from './customTournaments/reducer';
import overlay from './overlay/reducer';
import organization from './organization/reducer';
import headToHeadTeams from './headToHeadTeams/reducer';
import headToHeadPlayers from './headToHeadPlayers/reducer';
import exportAsImage from './exportAsImage/reducer';
import teamSearch from './teamSearch/reducer';

export default combineReducers({
  apollo: apollo,
  auth: auth,
  currentMatch: currentMatch,
  dashboard: dashboard,
  tournaments: tournaments,
  tournamentFilters: tournamentFilters,
  teamLeaderboard: teamLeaderboard,
  modal: modal,
  playerLeaderboard: playerLeaderboard,
  todaysWinners: todaysWinners,
  mapVeto: mapVeto,
  tournament: tournament,
  tournamentPlayers: tournamentPlayers,
  tournamentTeams: tournamentTeams,
  customTournaments: customTournaments,
  overlay: overlay,
  organization: organization,
  headToHeadTeams: headToHeadTeams,
  headToHeadPlayers: headToHeadPlayers,
  exportAsImage: exportAsImage,
  teamSearch: teamSearch,
});