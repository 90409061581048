import React from 'react';
import styled from 'styled-components';

const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 80px;
  margin-bottom: 17px;
  width: 100%;
  font-size: 26px;
  line-height: 26px;

  .td-rank {
    width: 7%;
  }

  .td-kills, .td-points {
    width: 5%;
  }

  .td-team {
    width: 30%;
  }

  .td-image {
    width: 3%;
  }

  .title {
    width: 100%;
    font-size: 42px;
    line-height: 42px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow>
      <div className="title text-center">MATCH { props.overall ? '1-' : '' }{ props.numberOfMatches }</div>
      <div className="td-rank text-center">RANK</div>
      <div className="td-image text-left"></div>
      <div className="td-team text-left">TEAM</div>
      <div className="td-kills text-center">KILLS</div>
      <div className="td-points text-center">POINT</div>
      <div className="td-rank text-center">RANK</div>
      <div className="td-image text-left"></div>
      <div className="td-team text-left">TEAM</div>
      <div className="td-kills text-center">KILLS</div>
      <div className="td-points text-center">POINT</div>
    </HeaderRow>
  );
}

export default LeaderboardHeader;