import {
  getPlayerStats,
  getTeamStats,
  getPlayerHeadToHead,
  getTeamHeadToHead,
  getPlayerLeaderboard,
  getTeamLeaderboard,
  getLiveTeamLeaderboard,
  getLiveMapTeams,
  getLiveSurvivalStats,
  getLiveTeamStats,
  getLivePlayerStats,
  getLivePlayerKills,
  getLivePlayerKillsDamage,
  getLiveTeamHeadToHead,
  getLivePlayerLeaderboard,
  getCurrentMatch,
  getTodaysWinners,
  getScoreboard,
  getMapVeto,
} from './componentQueries';
import {
  PLAYER_STATS_COMPONENT_TYPE,
  TEAM_STATS_COMPONENT_TYPE,
  PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
  TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  PLAYER_LEADERBOARD_COMPONENT_TYPE,
  TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_MAP_TEAMS_COMPONENT_TYPE,
  LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE,
  LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE,
  CURRENT_MATCH_COMPONENT_TYPE,
  TODAYS_WINNERS_COMPONENT_TYPE,
  SCOREBOARD_COMPONENT_TYPE,
  MAP_VETO_COMPONENT_TYPE,
} from '../../views/Components/constants';

export default {
  [PLAYER_STATS_COMPONENT_TYPE]: getPlayerStats,
  [TEAM_STATS_COMPONENT_TYPE]: getTeamStats,
  [PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE]: getPlayerHeadToHead,
  [TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: getTeamHeadToHead,
  [PLAYER_LEADERBOARD_COMPONENT_TYPE]: getPlayerLeaderboard,
  [TEAM_LEADERBOARD_COMPONENT_TYPE]: getTeamLeaderboard,
  [LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE]: getLiveTeamLeaderboard,
  [LIVE_MAP_TEAMS_COMPONENT_TYPE]: getLiveMapTeams,
  [LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE]: getLiveSurvivalStats,
  [LIVE_TEAM_STATS_COMPONENT_TYPE]: getLiveTeamStats,
  [LIVE_PLAYER_STATS_COMPONENT_TYPE]: getLivePlayerStats,
  [LIVE_PLAYER_KILLS_COMPONENT_TYPE]: getLivePlayerKills,
  [LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE]: getLivePlayerKillsDamage,
  [LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: getLiveTeamHeadToHead,
  [LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE]: getLivePlayerLeaderboard,
  [CURRENT_MATCH_COMPONENT_TYPE]: getCurrentMatch,
  [TODAYS_WINNERS_COMPONENT_TYPE]: getTodaysWinners,
  [SCOREBOARD_COMPONENT_TYPE]: getScoreboard,
  [MAP_VETO_COMPONENT_TYPE]: getMapVeto,
}