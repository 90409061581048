import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {  
  REQUEST_LOGIN,
  REQUEST_LOGGED_IN_USER,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS,
  REQUEST_USER_SUBSCRIPTION,
  REQUEST_USER_SUBSCRIPTION_PENDING,
  REQUEST_USER_SUBSCRIPTION_SUCCESS,
  REQUEST_USER_SUBSCRIPTION_ERROR,
} from './types';
import { AUTH_LOGIN_ERROR } from '../../util/ErrorMessages';
import { setAuthUser, unsetAuthUser } from './actions';
import { displayErrorNotification } from '../notification/actions';
import { privateQuery } from '../../graphql/requestHelper';
import { tournamentOrganizerToken, userSubscription } from '../../graphql/private/queries';

/**
 * Makes a request to server to login user
 * 
 * @param {*} action 
 */
function* requestLogin(action) {
  try {
    // Calling the server
    const user = yield call([Auth, 'signIn'], { username: action.payload.username, password: action.payload.password });
    // Triggering success action
    yield put(setAuthUser(user));
  } catch (err) {
    yield put(displayErrorNotification(AUTH_LOGIN_ERROR));
  }
}

/**
 * Gets logged in user if exists
 * 
 * @param {*} action 
 */
function* requestLoggedInUser(action) {
  try {
    // Calling the server
    const user = yield call([Auth, 'currentAuthenticatedUser']);
    // Triggering success action
    yield put(setAuthUser(user));
  } catch (err) {
    yield put(unsetAuthUser());
  }
}

/**
 * Gets tournament organizer token
 * 
 * @param {*} action 
 */
function* requestTournamentOrganizerToken(action) {
  try {
    // Calling the server
    const result = yield privateQuery(tournamentOrganizerToken);
    const token = result.data.tournamentOrganizerToken;
    // Triggering success action
    yield put({ type: REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS, payload: token });
  } catch (err) {
    
  }
}

/**
 * Displays success notification
 */
 function* requestUserSubscription(action) {
  try {
    yield put({ type: REQUEST_USER_SUBSCRIPTION_PENDING });
    // Calling the server
    const result = yield privateQuery(userSubscription);
    yield put({ type: REQUEST_USER_SUBSCRIPTION_SUCCESS, payload: result.data.userSubscription });
  } catch (err) {
    yield put({ type: REQUEST_USER_SUBSCRIPTION_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_LOGIN, requestLogin),
    takeLatest(REQUEST_LOGGED_IN_USER, requestLoggedInUser),
    takeLatest(REQUEST_TOURNAMENT_ORGANIZER_TOKEN, requestTournamentOrganizerToken),
    takeLatest(REQUEST_USER_SUBSCRIPTION, requestUserSubscription),
  ]);
}