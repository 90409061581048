import React from 'react';
import styles from '../../scss/style.scss';
import Input from './Input';

function Select(props) {
  const { name, color, value, onChange, disabledOptionText, options, warning } = props;

  return (
    <Input
      id={name}
      type='select'
      name={name}
      color={color ? color : styles.tertiary}
      value={value}
      onChange={e => onChange(e.target.value)}
      warning={warning}
    >
      {
        disabledOptionText ?
        <option disabled defaultValue value={0}>{ disabledOptionText }</option>
        :
        null
      }
      {
        options.map(o => {
          return (
            <option
              key={`${name}-${o.key}`}
              value={o.key}
              defaultValue={o.defaultValue}
            >{ o.value }</option>
          );
        })
      }
    </Input>
  );
}

export default Select;