import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-transform: uppercase;
  color: ${ props => props.colors && props.colors.headerText ? props.colors.headerText : '#FFF'};
  
  &.component-show {
    animation: fadeIn 0.3s ease 0s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: fadeOut 0.3s ease 0s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }

  table {
    width: 100%;

    td {
      width: 50%;
    }
  }

  .title {
    font-size: 90px;
  }

  .group-title p {
    font-size: 50px;
    line-height: 50px;
  }

  .match-title p {
    font-size: 35px;
    line-height: 35px;
    margin-top: -30px;
  }
`;

function LeaderboardHeader(props) {
  const { show, opts, overlayColor, exportOverlay } = props;

  return (
    <td colSpan={2}>
      <Wrapper
        className={ exportOverlay ? '' : show ? 'component-show' : 'component-hide' }
        colors={overlayColor}
      >
        <table>
          <tbody>
            <tr>
              <td rowSpan="2" className="title">Leaderboard</td>
              <td className="group-title text-right"><p>{ opts.groupName ? `${opts.groupName}` : '' }</p></td>
            </tr>
            <tr>
              <td className="match-title text-right"><p>{`Match ${opts.numberOfMatches}`}</p></td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    </td>
  );
}

export default LeaderboardHeader;