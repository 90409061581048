import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const StyledLogoCol = styled(Col)`
  margin: 0px 20px 0 calc(41.666666% - 10px);
  background: ${styles.tertiary};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  img {
    height: 45px;
    margin-bottom: 5px;
  }
`;

function LeaderboardFooter(props) {

  return (
    <Row>
      <StyledLogoCol xs="2" className="offset-5 text-center">
        <img
          src="/assets/img/twire/powered-by-twire-no-background.png"
          alt="tournament logo"
        />
      </StyledLogoCol>
    </Row>
  );
}

export default LeaderboardFooter;