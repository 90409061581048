import React from 'react';
import styled from 'styled-components';

const Stat = styled.table`
  width: 100%;
  background: #1d1d1b;
  ${props => props.last ? 'margin-bottom: 25px;' : ''}

  td {
    padding: 5px 10px;
  }

  .stat-name {
    font-size: 22px;
    line-height: 20px;
    font-weight: 400;
    width: 60%;
  }

  .stat-value {
    font-size: 40px;
    line-height: 40px;
    width: 40%;
  }
`;

function TeamStat(props) {
  const { name, value, last } = props;

  return (
    <Stat last={last}>
      <tbody>
        <tr>
          <td className="stat-name text-left">
            { name }
          </td>
          <td className="stat-value text-right">
            { value }
          </td>
        </tr>
      </tbody>
    </Stat>
  );
}

export default TeamStat;