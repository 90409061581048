import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const TitleTable = styled.table`
  width: ${props => props.rowWidth}px;

  .headline {
    width: ${props => props.overall ? '577px' : '610px'};
    color: #000;
    font-size: 80px;
    padding: 0;
    vertical-align: top;

    div {
      background: #FFF;
      height: 90px;

      p {
        margin: 0;
        padding: 0px 14px 10px;
        line-height: 80px;
        white-space: nowrap;
      }
    }
  }

  .match-title {
    padding: 20px;
    width: ${props => props.rowWidth / 2 - 577}px;

    div {
      p {
        padding: 0;
        margin: 0;
        border-top: 2px solid #ffa900;
        width: fit-content;
      }
    }
  }

  .tournament-logo {
    width: ${props => props.rowWidth / 2}px;
    vertical-align: top;
  }

  &.component-show {
    .headline {
      div {
        width: 0;
        animation: slideIn 0.3s ease 0s forwards;

        p {
          opacity: 0;
          animation: fadeIn 0.3s ease 0.5s forwards;
        }
      }
    }

    .match-title, .tournament-logo {
      opacity: 0;
      animation: fadeIn 0.3s ease 0.5s forwards;
    }

    @keyframes slideIn {
      from {width: 0px;}
      to {width: ${props => props.overall ? '577px' : '610px'};}
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    .headline {
      div {
        animation: slideOut 0.3s ease 0.3s forwards;

        p {
          animation: fadeOut 0.3s ease 0s forwards;
        }
      }
    }

    .match-title, .tournament-logo {
      animation: fadeOut 0.3s ease 0s forwards;
    }
    

    @keyframes slideOut {
      from {width: ${props => props.overall ? '577px' : '610px'};}
      to {width: 0px;}
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function LeaderboardTitle(props) {
  const { rowWidth, numberOfMatches, show, exportOverlay, overall } = props;

  return (
    <TitleTable
      className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}
      rowWidth={rowWidth}
      overall={overall}
    >
      <tbody>
        <tr>
          <td className="headline text-bold">
            <div>
              <p>{ overall ? 'Total' : 'Match' } Result</p>
            </div>
          </td>
          <td className="match-title">
            <div className="text-center">
              <p><span className="text-bold">Final</span>{ ` // Match ${numberOfMatches}` }</p>
            </div>
          </td>
          <td className="tournament-logo text-right">
            <img
              src="/assets/img/dnw/logo.png"
              alt="tournament-logo"
            />
          </td>
        </tr>
      </tbody>
    </TitleTable>
  );
}

const mapStateToProps = state => ({
  matchName: state.teamLeaderboard.matchName,
  lastMatchName: state.teamLeaderboard.lastMatchName,
  groupName: state.teamLeaderboard.groupName,
});

export default connect(mapStateToProps)(LeaderboardTitle);