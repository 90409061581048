import { STAGE_FILTER_TYPE_HIDDEN, STAGE_FILTER_TYPE_OVERALL } from '../../util/Constants';
import {
  REQUEST_TOURNAMENT_PENDING,
  REQUEST_TOURNAMENT_ERROR,
  REQUEST_TOURNAMENT_SUCCESS,
  REQUEST_EDIT_TOURNAMENT_SUCCESS,
  REQUEST_CREATE_TOURNAMENT_FILTER_SUCCESS,
  REQUEST_EDIT_TOURNAMENT_FILTER_SUCCESS,
  REQUEST_DELETE_TOURNAMENT_FILTER_SUCCESS,
  REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_SUCCESS,
  ON_TOURNAMENT_MATCH_RECEIVED,
  REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_SUCCESS,
  REQUEST_EDIT_TOURNAMENT_MATCH_SUCCESS,
  REQUEST_CHANGE_TRACKED_IGNS_SUCCESS,
  REQUEST_TOURNAMENT_LEADERBOARD_SUCCESS,
  REQUEST_TOURNAMENT_LEADERBOARD_ERROR,
  REQUEST_CREATE_TOURNAMENT_SUCCESS,
  ON_CHANGE_TRACK_IGNS,
  REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_SUCCESS,
  REQUEST_DELETE_TOURNAMENT_PHASE_SUCCESS,
  REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_SUCCESS,
  REQUEST_ADD_TOURNAMENT_PRIZE_POOL_SUCCESS,
  SET_TOURNAMENT_GROUP_TEAMS,
  CLEAR_TOURNAMENT_STATE,
  REQUEST_SAVE_TOURNAMENT_AWARD_SUCCESS,
  REQUEST_SAVE_TOURNAMENT_AWARD_ROW_SUCCESS,
  ADD_NEW_TOURNAMENT_AWARD,
  REQUEST_DELETE_TOURNAMENT_AWARD_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  matches: [],
  phases: [],
  teams: [],
  leaderboard: [],
  tournamentAwards: [],
  tournamentFilters: [],
  tournamentPhases: [],
  structureSelected: false,
  tournamentMatchSubscription: null,
  changeTrackIgnsSubscription: null,
  tournamentGroupTeams: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request all custom tournament pending
    case REQUEST_TOURNAMENT_PENDING:
      return {
        ...state,
        loaded: false,
      }

    // On request all custom tournament error
    case REQUEST_TOURNAMENT_ERROR:
      return INITIAL_STATE;

    // On request all custom tournament success
    case REQUEST_TOURNAMENT_SUCCESS:
    case REQUEST_EDIT_TOURNAMENT_SUCCESS:
      action.payload.tournamentFilters.sort((a, b) => a.type === STAGE_FILTER_TYPE_OVERALL ? -1 : b.type === STAGE_FILTER_TYPE_OVERALL ? 1 : a.id > b.id ? -1 : 1);
      const selectedStageFilter = (action.payload.tournamentFilters.find(f => !f.parentId) || {});
      return {
        ...state,
        loaded: true,
        ...action.payload,
        structureSelected: action.payload.tournamentFilters.length || action.payload.tournamentPhases.length,
        tournamentWithoutGroups: action.payload.tournamentFilters.length === 1 && action.payload.tournamentFilters[0].type === STAGE_FILTER_TYPE_HIDDEN,
        tournamentGroupTeams: [...(selectedStageFilter.teams || [])],
      };

    // On tournament create success
    case REQUEST_CREATE_TOURNAMENT_SUCCESS:
      return {
        ...INITIAL_STATE,
        ...action.payload,
      }

    // On request upsert 
    case REQUEST_CREATE_TOURNAMENT_FILTER_SUCCESS:
    case REQUEST_EDIT_TOURNAMENT_FILTER_SUCCESS:
      const filters = state.tournamentFilters;
      const filterIndex = filters.indexOf(filters.find(f => f.id === action.payload.id));
      if (filterIndex !== -1) {
        filters[filterIndex] = action.payload;
      } else {
        filters.push(action.payload);
      }
      return {
        ...state,
        tournamentFilters: [...filters],
        tournamentWithoutGroups: filters.length === 1 && filters[0].type === STAGE_FILTER_TYPE_HIDDEN,
      };

    // On tournament filter delete success
    case REQUEST_DELETE_TOURNAMENT_FILTER_SUCCESS:
      const newFilters = [...state.tournamentFilters.filter(f => f.id !== action.payload)];
      return {
        ...state,
        tournamentFilters: newFilters,
      }

    // On tournament phase create, edit or success
    case REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_SUCCESS:
      const phases = state.tournamentPhases;
      const phaseIndex = phases.indexOf(phases.find(f => f.id === action.payload.id));
      if (phaseIndex !== -1) {
        phases[phaseIndex] = action.payload;
      } else {
        phases.push(action.payload);
      }
      return {
        ...state,
        tournamentPhases: [...phases],
      }
      
    // On tournament phase delete success
    case REQUEST_DELETE_TOURNAMENT_PHASE_SUCCESS:
      return {
        ...state,
        tournamentPhases: action.payload,
      }

    // On change tracked igns
    case REQUEST_CHANGE_TRACKED_IGNS_SUCCESS:
      const notUpdatedPhases = state.tournamentPhases.filter(p => p.tournamentFilterId !== action.payload.tournamentFilterId);
      return {
        ...state,
        tournamentPhases: [...notUpdatedPhases, ...action.payload.newPhases],
      }

    // On tournament match subscription created
    case REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_SUCCESS:
      return {
        ...state,
        tournamentMatchSubscription: action.payload,
      }

    // On change track IGN subscription created
    case REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_SUCCESS:
      return {
        ...state,
        changeTrackIgnsSubscription: action.payload,
      }

    // On tournament match edit success
    case REQUEST_EDIT_TOURNAMENT_MATCH_SUCCESS:
      return {
        ...state,
        matches: state.matches.map(m => m.id === action.payload.id ? action.payload : m),
      }

    // On tournament leaderboard success
    case REQUEST_TOURNAMENT_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload,
      }

    // On tournament leaderboard error
    case REQUEST_TOURNAMENT_LEADERBOARD_ERROR:
      return {
        ...state,
        leaderboard: [],
      }

    // On tournament team add success
    case REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_SUCCESS:
      const tournamentFilters = state.tournamentFilters.map(f => {
        if (f.id === action.payload.tournamentFilterId) {
          f.teams = action.payload.teams;
        }
        return f;
      });
      return {
        ...state,
        tournamentFilters: tournamentFilters,
        tournamentGroupTeams: action.payload.teams,
      }

    // On tournament prize pool add success
    case REQUEST_ADD_TOURNAMENT_PRIZE_POOL_SUCCESS:
      const tfs = state.tournamentFilters.map(f => {
        if (f.id === action.payload.tournamentFilterId) {
          f.prizePoolDefinitions = action.payload.prizePoolDefinitions;
        }
        return f;
      });
      return {
        ...state,
        tournamentFilters: tfs,
      }

    // On track IGNs change received
    case ON_CHANGE_TRACK_IGNS:
      const newPhases = state.tournamentPhases.map(p => {
        if (p.tournamentFilterId === action.payload.tournamentFilterId) {
          p.trackBy = action.payload.trackBy;
        }
        return p;
      })
      return {
        ...state,
        tournamentPhases: newPhases,
      }

    // On tournament match received
    case ON_TOURNAMENT_MATCH_RECEIVED:
      if (!state.matches.some(m => m.id === action.payload.id || m.gameMatchId === action.payload.gameMatchId)) {
        state.matches.push(action.payload);
        state.matches.sort((a, b) => a.gameCreatedAt > b.gameCreatedAt ? -1 : 1);
      }
      return {
        ...state,
        matches: state.matches.map(m => m.id === action.payload.id ? action.payload : m),
      }

    // Action for setting the current tournament group teams
    case SET_TOURNAMENT_GROUP_TEAMS:
      return {
        ...state,
        tournamentGroupTeams: [...action.payload],
      }

    // On new award added
    case ADD_NEW_TOURNAMENT_AWARD:
      return {
        ...state,
        tournamentAwards: [...state.tournamentAwards, action.payload],
      }

    // On tournament award save success
    case REQUEST_SAVE_TOURNAMENT_AWARD_SUCCESS:
    case REQUEST_SAVE_TOURNAMENT_AWARD_ROW_SUCCESS:
      const tournamentAwards = state.tournamentAwards;
      tournamentAwards[action.payload.index] = action.payload.award;
      return {
        ...state,
        tournamentAwards: [...tournamentAwards],
      }

    // On tournament award delete success
    case REQUEST_DELETE_TOURNAMENT_AWARD_SUCCESS:
      return {
        ...state,
        tournamentAwards: [...state.tournamentAwards.filter(p => p.id !== action.payload.id)],
      }

    // Action for clearing tournament state
    case CLEAR_TOURNAMENT_STATE:
      return INITIAL_STATE

    default:
      return state;
  }
}

export default reducer;