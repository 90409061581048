import ApolloClient from 'apollo-client';
import { Auth } from 'aws-amplify';
import { split } from 'apollo-link';
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from "apollo-link-context";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import { hasSubscription } from "@jumpn/utils-graphql";

let endpointConfig;
if (process.env.REACT_APP_STAGE === 'prod') {
  endpointConfig = require('./configs/endpointConfigProd').default;
} else {
  endpointConfig = require('./configs/endpointConfig').default;
}

// Create an HTTP link to the Absinthe server.
const httpLink = createHttpLink({
  uri: endpointConfig.http,
});

// Use setContext to create a chainable link object that sets
// the token cookie to the Authorization header.
const authLink = setContext((_, { headers }) => {
  // Get the authentication token from the cookie if it exists.
  return Auth.currentAuthenticatedUser().then(user => {
    // Add the new Authorization header.
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
      },
    };
  }).catch(() => {
    return headers;
  });
});

// Chain the HTTP link and the authorization link.
const authedHttpLink = authLink.concat(httpLink);

// Create a standard Phoenix websocket connection. If you need
// to provide additional params, like an authentication token,
// you can configure them in the `params` option.
const phoenixSocket = new PhoenixSocket(endpointConfig.ws, {
  params: () => {
    // Get the authentication token from the cookie if it exists.
    return Auth.currentAuthenticatedUser().then(user => {
      // Add the new Authorization header.
      return {
        token: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
      };
    }).catch(() => {
      return {};
    });
  }
});

// Wrap the Phoenix socket in an AbsintheSocket.
const absintheSocket = AbsintheSocket.create(phoenixSocket);

// Create an Apollo link from the AbsintheSocket instance.
const websocketLink = createAbsintheSocketLink(absintheSocket);

// If the query contains a subscription, send it through the
// websocket link. Otherwise, send it through the HTTP link.
const link = split(
  operation => hasSubscription(operation.query),
  websocketLink,
  authedHttpLink
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// Apollo client
export default new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});