export const STATS_NAME_MAP = {
  assists: 'assists',
  avgArmorLeft: 'avg armor left',
  avgBurningTime: 'avg burning',
  avgDmg: 'adr',
  avgEquipmentValue: 'avg equip. value',
  avgFlashedTime: 'avg flashed time',
  avgHealthLeft: 'avg health left',
  deaths: 'deaths',
  hsKills: 'hs kills',
  hsPercentage: 'hs%',
  kD: 'kd',
  kR: 'kr',
  kills: 'kills',
  maxDmg: 'most damage',
  maxEquipmentValue: 'max equip. value',
  roundsAceKills: 'aces',
  roundsFourKills: 'quads',
  roundsThreeKills: 'triples',
  totalBurningTime: 'burning time',
  totalDmg: 'damage',
  totalEquipmentValue: 'equip. value',
  totalFlashedTime: 'flashed time',
}