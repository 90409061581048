import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const HeaderRow = styled.tr`
  background: rgba(0, 0, 0, 0.5);
  width: 297px !important;

  td {
    color: ${styles.secondary};
    text-transform: uppercase;
    padding: 7.25px 4.53px;

    &:first-child {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow>
      <td>RANK</td>
      <td></td>
      <td>TEAM</td>
      <td></td>
      <td>ALIVE</td>
      <td>POINTS</td>
      <td>KILLS</td>
    </HeaderRow>
  );
}

export default LeaderboardHeader;