import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo, numberForDisplay } from '../../../../../../util/CommonUtil';
import TeamStat from './TeamStat';

const LeaderboardTable = styled.table`
  background: url('/assets/img/being/live_team_stats_background.png');
  background-repeat: no-repeat;
  width: 220px;
  height: 571px;
  font-family: 'CenturyGothicBold';
  border-collapse: separate;
  position: fixed;
  right: 0;

  &.bc-show {
    margin-right: -240px;
    animation: slideRight 0.3s ease 0.5s forwards;
  
    @keyframes slideRight {
      from {margin-right: -240px;}
      to {margin-right: 32px;}
    }
    
    .twire-logo-wrapper {
      opacity: 0;
      animation: fadeIn 0.3s ease 1.0s forwards;
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.bc-hide {
    margin-right: 32px;
    animation: slideLeft 0.3s ease 0.5s forwards;
  
    @keyframes slideLeft {
      from {margin-right: 32px;}
      to {margin-right: -240px;}
    }
    
    .twire-logo-wrapper {
      opacity: 1;
      animation: fadeOut 0.3s ease 0s forwards;
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }

  td {
    &.team-logo {
      padding: 30px 20px 0px;
      height: 190px;

      div {
        background: url('/assets/img/being/live_team_stats_logo_background.png');
        background-repeat: no-repeat;
        background-position: center;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -10px;
      }

      img {
        height: 145px;
      }
    }

    &.team-name {
      padding: 10px 20px 0px;
      color: #FFF;
      font-family: 'SonySketchEF';
      font-size: 40px;
      line-height: 36px;
      height: 80px;
      vertical-align: initial;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 215px;
    }

    &.team-stats {
      padding: 0px 20px 0px;
      height: 50px;
    }
  }

  .twire-logo-wrapper {
    position: absolute;
    width: 220px;
    right: 0px;

    img {
      margin-top: 15px;
      width: 125px;
    }
  }
`;

class BeingLiveTeamStats extends Component {
  render() {
    const { team, show } = this.props;

    return (
      team ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <tr>
            <td className="team-logo text-center">
              <div>
                <img
                  src={getTeamLogo(`${team.name}`)}
                  onError={e => e.target.src = getTeamLogo('being-default')}
                  alt="team-logo"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="team-name text-center">
              { team.name }
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Chicken Dinners'
                value={team.wins}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Avg. Kills'
                value={numberForDisplay(team.avgKills)}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Avg. Dmg'
                value={parseInt(team.avgDamageDealt)}
              />
            </td>
          </tr>
          <tr>
            <td className="team-stats">
              <TeamStat
                name='Avg. Kko'
                value={numberForDisplay(team.avgKnocks)}
                last={true}
              />
            </td>
          </tr>
        </tbody>
        <div className="twire-logo-wrapper text-center">
          <img
            className="twire-logo"
            src={'/assets/img/twire/logo.png'}
            alt="twire-logo"
          />
        </div>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default BeingLiveTeamStats;