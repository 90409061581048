import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { requestLoggedInUser } from './redux/auth/actions';

function PrivateRoute(props) {
  const { component: Component, ...attributes } = props;
  const { sub, loaded } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!loaded) {
      dispatch(requestLoggedInUser());
    }
  });

  if (!loaded) return <div className="animated fadeIn pt-1 text-center">Loading...</div>

  return (
    <Route
      {...attributes}
      render={props => {
        return sub ?
          <Component {...props} />
          :
          <Redirect to="/login" />
      }}
    />
  )
}

export default PrivateRoute;