import React from 'react';
import { connect } from 'react-redux';
import PSL from './psl/PslLiveTeamLeaderboard';
import RYER from './ryer/RyerLiveTeamLeaderboard';
import ESL from './esl/EslLiveTeamLeaderboard';
import PJC from './pjc/PjcLiveTeamLeaderboard';
import PJC21 from './pjc21/Pjc21LiveTeamLeaderboard';
import DNW from './dnw/DnwLiveTeamLeaderboard';
import Being from './being/BeingLiveTeamLeaderboard';
import Protality from './protality/ProtalityLiveTeamLeaderboard';
import Default from './default/DefaultTeamLeaderboard';

const PUBGLiveTeamLeaderboardOverlay = (props) => {
  const { shard, overlayDesign } = props;

  return (
    overlayDesign === 'psl' ? 
    <PSL {...props} />
    :
    shard.includes('ryer') ? 
    <RYER {...props} />
    :
    overlayDesign === 'pjc' ? 
    <PJC {...props} />
    :
    overlayDesign === 'pjc21' ? 
    <PJC21 {...props} />
    :
    overlayDesign === 'dnw' ? 
    <DNW {...props} />
    :
    overlayDesign === 'esl' ? 
    <ESL {...props} />
    :
    overlayDesign === 'being' ? 
    <Being {...props} />
    :
    overlayDesign === 'protality' ? 
    <Protality {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  leaderboard: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  overlayColor: (state.overlay.tournament || {}).overlayColor,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLiveTeamLeaderboardOverlay);
