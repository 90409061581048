import React, { Component } from 'react';
import styled from 'styled-components';
import styles from '../../../scss/style.scss';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { 
  Button,
  DropdownMenu,
} from '../../StyledCoreUI';

const StyledDropdownMenu = styled(DropdownMenu)`
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
    
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: ${styles.transparent};
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${styles.transparent};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
  }
`;

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedChoice: this.props.defaultChoice,
      noneChoice: this.props.noneChoice,
    }
  }

  render() {
    const { isOpen, selectedChoice, noneChoice } = this.state;
    const { onChange, choices, selected, controlled, alignLeft, alignRight } = this.props;
    
    return (
      <ButtonDropdown isOpen={isOpen} toggle={() => { this.setState({ isOpen: !isOpen }); }} {...this.props}>
        <Button color="primary">
          { selected ? choices[selected] : choices[selectedChoice] ? choices[selectedChoice] : noneChoice }
        </Button>
        <DropdownToggle color="primary" caret/>
        <StyledDropdownMenu right={alignRight} left={alignLeft}>
          {
            noneChoice ?
            <DropdownItem onClick={ () => {
              onChange('', noneChoice);
              this.setState({ selectedChoice: null });
            }}>
              { noneChoice }
            </DropdownItem>
            :
            null
          }
          {
            Object.keys(choices).sort((a, b) => parseInt(a) > parseInt(b) ? -1 : 1).map(id => {
              return (
                <DropdownItem key={'dropdown-choice-' + choices[id]} onClick={ () => {
                  onChange(id, choices[id]);
                  if (!controlled) {
                    this.setState({ selectedChoice: id });
                  }
                }}>
                  { choices[id] }
                </DropdownItem>
              )
            })
          }
        </StyledDropdownMenu>
      </ButtonDropdown>
    );
  }
}

export default Dropdown;