import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.td`
  &.component-show {
    opacity: 0;
    animation: fadeIn 0.3s ease 1.1s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: fadeOut 0.3s ease 1s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function LeaderboardFooter(props) {
  const { show, exportOverlay } = props;

  return (
    <Wrapper
      colSpan="2"
      className={`${ exportOverlay ? '' : show ? 'component-show' : 'component-hide' } text-center`}
    >
      <img
        src="/assets/img/twire/powered-by-twire-no-background.png"
        alt="tournament logo"
      />
    </Wrapper>
  );
}

export default LeaderboardFooter;