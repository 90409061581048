import { 
  CLOSE_MODAL,
  OPEN_MODAL
} from './types';

const INITIAL_STATE = {
  title: null,
  content: null,
  opened: false,
  onConfirm: () => {},
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On modal open
    case OPEN_MODAL:
      return {
        ...state,
        title: action.payload.title,
        content: action.payload.content,
        opened: true,
        onConfirm: action.payload.onConfirm,
      };

    // On modal close
    case CLOSE_MODAL:
      return INITIAL_STATE;
      
    default:
      return state;
  }
}

export default reducer;