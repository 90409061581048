import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  REQUEST_TOURNAMENT_PLAYERS,
  REQUEST_TOURNAMENT_PLAYERS_PENDING,
  REQUEST_TOURNAMENT_PLAYERS_SUCCESS,
  REQUEST_TOURNAMENT_PLAYERS_ERROR,
} from './types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import { playerNamesByTournament } from '../../graphql/public/queries';
import { publicQuery } from '../../graphql/requestHelper';
import { displayErrorNotification } from '../notification/actions';

/**
 * Makes a request to get tournament players
 *
 * @param {*} action 
 */
function* requestTournamentPlayers(action) {
  yield put({ type: REQUEST_TOURNAMENT_PLAYERS_PENDING });
  try {
    const result = (yield publicQuery(playerNamesByTournament, {
      game: action.payload.game,
      tournament: action.payload.shard,
    })).data.playerNamesByTournament;

    yield put({ type: REQUEST_TOURNAMENT_PLAYERS_SUCCESS, payload: result });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_PLAYERS_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TOURNAMENT_PLAYERS, requestTournamentPlayers),
  ]);
}