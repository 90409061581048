import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {  
  REQUEST_OVERLAY_DATA,
  REQUEST_OVERLAY_DATA_PENDING,
  REQUEST_OVERLAY_DATA_SUCCESS,
  REQUEST_OVERLAY_DATA_ERROR,
  SUBSCRIBE_TO_OVERLAY_DATA,
  SUBSCRIBE_TO_OVERLAY_DATA_PENDING,
  SUBSCRIBE_TO_OVERLAY_DATA_SUCCESS,
  SUBSCRIBE_TO_OVERLAY_DATA_ERROR,
} from './types';
import componentQueries from '../../graphql/apollo/componentTypeQueries';
import { MAP_VETO_COMPONENT_TYPE } from '../../views/Components/constants';
import { overlayDataSubscriptions } from '../../graphql/apollo/componentTypeSubscriptions';
import components from '../../views/Components';
import { getTeamLogo } from '../../util/CommonUtil';
import { convertImgToBase64URL } from '../../util/ExportAsImageUtil';

/**
 * Makes a request to server to get the overlay data
 * 
 * @param {*} action 
 */
function* requestOverlayData(action) {
  yield put({ type: REQUEST_OVERLAY_DATA_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'query'], {
      query: componentQueries[action.payload.componentType],
      // TODO: ugly ugly code! fix it!
      variables: action.payload.componentType === MAP_VETO_COMPONENT_TYPE ? 
        { token: action.payload.token, overlayToken: action.payload.token }
        :
        { token: action.payload.token },
    })).data;
    const overlayData = result ? result[Object.keys(result)[0]] : null;

    const gameComponents = components[action.payload.game];
    const component = Object.values(gameComponents).find(c => c.type === action.payload.componentType);
    if (action.payload.exportOverlay && component.exportOverlay) {
      for(let i = 0; i < overlayData[action.payload.game].length; i++) {
        const item = overlayData[action.payload.game][i];
        const teamName = item[component.exportOverlay.teamNameProperty];
        item.exportLogo = yield convertImgToBase64URL(getTeamLogo(teamName));
      }
    }
    
    yield put({ type: REQUEST_OVERLAY_DATA_SUCCESS, payload: overlayData });
  } catch (err) {
    yield put({ type: REQUEST_OVERLAY_DATA_ERROR });
  }
}

/**
 * Makes a request to server to subscribe to overlay data
 * 
 * @param {*} action 
 */
function* subscribeToOverlayData(action) {
  yield put({ type: SUBSCRIBE_TO_OVERLAY_DATA_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    
    const result = (yield call([client, 'subscribe'], {
      query: overlayDataSubscriptions[action.payload.componentType],
      variables: { token: action.payload.token },
    }));
    result.subscribe({
      next: (result) => action.payload.callback(result.data[Object.keys(result.data)[0]]),
      error: () => {},
    });
    
    yield put({ type: SUBSCRIBE_TO_OVERLAY_DATA_SUCCESS });
  } catch (err) {
    yield put({ type: SUBSCRIBE_TO_OVERLAY_DATA_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_OVERLAY_DATA, requestOverlayData),
    takeLatest(SUBSCRIBE_TO_OVERLAY_DATA, subscribeToOverlayData),
  ]);
}