// Logout error message
export const AUTH_LOGOUT_ERROR = 'Error while logging out';
// Error logging in
export const AUTH_LOGIN_ERROR = 'Wrong username or password';

// General loading error message
export const LOADING_ERROR = 'Error while loading data';

// General saving error message
export const SAVING_ERROR = 'Error while saving data';

// Dashboard change message
export const CREATE_COMPONENT_ERROR = 'Error while creating component';
export const CHANGE_COMPONENT_ERROR = 'Error while changing component';
export const REMOVE_COMPONENT_ERROR = 'Error while removing component';