import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  background: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
`;

function LeaderboardColumnTitle(props) {

  return (
    <Wrapper
      colors={props.overlayColor}
    >
      <td>SIRA</td>
      <td>O. Maç</td>
      <td>TAKIM</td>
      <td>OYUNCU</td>
      <td>PUAN</td>
    </Wrapper>
  );
}

export default LeaderboardColumnTitle;