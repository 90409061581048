import React, { Component } from 'react';
import styled from 'styled-components';

const LeaderboardWrapper = styled.div``;

class EslLivePlayerLeaderBoard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;

    if (!loaded) return null;

    const stat = opts.stats ? opts.stats[0] : {};
    const topPlayers = leaderboard.slice(0, 5);

    return (
      topPlayers.length ?
      <LeaderboardWrapper>
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default EslLivePlayerLeaderBoard;
