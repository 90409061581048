import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const HeaderRow = styled.tr`
  background: #000;

  td {
    color: ${styles.secondary};
    text-transform: uppercase;
    padding: 1px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow>
      <td></td>
      <td>TEAM</td>
      <td>ALIVE</td>
      <td>PP</td>
      {
        props.overall ?
        <td>PTS</td>
        :
        null
      }
    </HeaderRow>
  );
}

export default LeaderboardHeader;