import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const HeaderRow = styled.tr`
  background: ${styles.tertiary}EE;

  td {
    color: ${styles.secondary};
    text-transform: uppercase;
    padding: 1px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <HeaderRow>
      <td></td>
      <td>TEAM</td>
      <td>ALIVE</td>
      {
        props.overall ?
        <td>WWCD</td>
        :
        null
      }
      <td>KILLS</td>
    </HeaderRow>
  );
}

export default LeaderboardHeader;