import {
  REQUEST_EXPORT_AS_IMAGE_PENDING,
  REQUEST_EXPORT_AS_IMAGE_SUCCESS,
  REQUEST_EXPORT_AS_IMAGE_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request export image pending or error
    case REQUEST_EXPORT_AS_IMAGE_PENDING:
    case REQUEST_EXPORT_AS_IMAGE_ERROR:
      return INITIAL_STATE;

    // On request export image success
    case REQUEST_EXPORT_AS_IMAGE_SUCCESS:
      return {
        loaded: true,
      };

    default:
      return state;
  }
}

export default reducer;