import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Table } from 'reactstrap';

export default styled(Table)`
  color: ${styles.secondary};

  th, td {
    border: none;
  }

  tbody tr:nth-child(even),
  tbody tr.table-active {
    background: ${styles.tertiary};
  }

  tbody tr:nth-child(odd),
  tbody tr.table-active {
    background: ${styles.tertiaryDark};
  }

  tbody tr.table-inactive {
    background: ${styles.tertiaryDark};
  }

  thead th {
    border-color: ${styles.primary};
  }
`;