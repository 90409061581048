import { 
  REQUEST_TEAM_READY_SUCCESS,
  SET_MAP_VETO_DATA,
} from './types';

const INITIAL_STATE = {
  teamToken: null,
  overlayToken: null,
  status: null,
  logs: [],
  pickValues: [],
  timer: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On team ready success
    case REQUEST_TEAM_READY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    // On map veto data received
    case SET_MAP_VETO_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default reducer;