import React, { Component } from 'react';
import {
  ModalBody, 
  ModalFooter, 
  ModalHeader,
} from 'reactstrap';
import {
  Button,
  Modal,
} from '../../StyledCoreUI';

class CustomModal extends Component {
  render() {
    const { title, children, onClose, onConfirm, style } = this.props;

    return (
      <Modal isOpen={this.props.open} toggle={onClose} className={this.props.className} style={style}>
        <ModalHeader toggle={onClose}>{ title }</ModalHeader>
        <ModalBody>
          { children }
        </ModalBody>
        <ModalFooter>
          {
            onConfirm ?
            <Button color="primary" onClick={onConfirm}>OK</Button>
            :
            null
          }
          <Button color="light" onClick={onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CustomModal;