import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLiveMapTeams';
import PJC from './pjc/PjcLiveMapTeams';
import Being from './being/BeingLiveMapTeams';
import Being2 from './being2/Being2LiveMapTeams';

const PUBGLiveMapTeamsOverlay = (props) => {
  const { overlayDesign } = props;
  props.teams.sort((a, b) => a.name > b.name ? 1 : -1);
  // if (props.teams.length) {
  //   console.log(props.teams);
  //   for (const t of props.teams) {
  //     for (let i = 0; i < 4; i++) {
  //       t.players.push({
  //         ign: "Test IGN",
  //         status: Math.random() > 0.5 ? 'alive' : Math.random() > 0.5 ? 'knocked' : 'death'
  //       })
  //     }
  //   }
  // }
  // console.log(props.teams)

  return (
    overlayDesign === 'pjc' ?
    <PJC {...props} />
    :
    overlayDesign === 'esl' ?
    <ESL {...props} />
    :
    overlayDesign === 'being' ?
    <Being {...props} />
    :
    overlayDesign === 'being2' ?
    <Being2 {...props} />
    :
    null
  );
}

const mapStateToProps = state => ({
  teams: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLiveMapTeamsOverlay);
