import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getPlayerImageByGame, getTeamLogoByGame, numberForDisplay } from '../../../../../../util/CommonUtil'
import { SlideInAnimation } from '../../../../animations/SlideInAnimation'
import { Image } from '../../../../helpers/Image'

function PlayerLeaderboard({ show, players, game }) {
  const showOnlyMVP = true;

  // playerStats.adr = Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100

  const leaderboard = players.slice(0, 1)

  return (
    <OverflowContainer>
      <FontWrapper>
        <Container showOnlyMVP={showOnlyMVP}>
          <Table showOnlyMVP={showOnlyMVP}>
            {leaderboard.map((player, i) => (
              <SlideInAnimation show={show} delay={i / 5}>
                <PlayerContainer showOnlyMVP={showOnlyMVP}>
                <BackgroundImage>
                  <PlayerTeamImage>
                    <PlayerImage>
                      {showOnlyMVP ? (
                        <Image
                          src={getPlayerImageByGame(game, player.ign)}
                          onError={(e) =>
                            (e.target.src = getPlayerImageByGame(game, 'default'))
                          }
                          imageStyle={{
                            width: 'auto',
                            maxWidth: 'inherit',
                            height: '450px'
                          }}
                        />
                      ) : (
                        <Image
                          src={getPlayerImageByGame(game, player.ign)}
                          onError={(e) =>
                            (e.target.src = getPlayerImageByGame(game, 'default'))
                          }
                          size={'largest'}
                        />
                      )}
                      {showOnlyMVP ? (
                        <MVPTeamImage>
                          <Image
                            center
                            src={getTeamLogoByGame(game, player.teamName)}
                            onError={(e) =>
                              (e.target.src = getTeamLogoByGame(game, 'default'))
                            }
                            size={'smallest'}
                          />
                        </MVPTeamImage>
                      ) : (
                        <TeamImage>
                          <Image
                            src={getTeamLogoByGame(game, player.teamName)}
                            onError={(e) =>
                              (e.target.src = getTeamLogoByGame(game, 'default'))
                            }
                            size={'largest'}
                          />
                        </TeamImage>
                      )}
                    </PlayerImage>
                    {showOnlyMVP ? (
                      <MVP>MVP</MVP>
                    ) : (
                      <PlayerNumber>{i + 1}.</PlayerNumber>
                    )}
                  </PlayerTeamImage>
                  <PlayerFooter>
                    <PlayerName>{player.ign}</PlayerName>
                    <PlayerScore>
                      <Key>Kills</Key>
                      <Value>{ player.kills }</Value>
                    </PlayerScore>
                    <PlayerScore>
                      <Key>Assists</Key>
                      <Value>{ player.assists }</Value>
                    </PlayerScore>
                    <PlayerScore>
                      <Key>Damage</Key>
                      <Value>{ numberForDisplay(player.adr) }</Value>
                    </PlayerScore>
                  </PlayerFooter>
                </BackgroundImage>
                </PlayerContainer>
              </SlideInAnimation>
            ))}
          </Table>
        </Container>
      </FontWrapper>
    </OverflowContainer>
  )
}

const mapStateToProps = state => ({
  players: state.overlay.valorant || [],
  opts: state.overlay.opts,
  show: state.overlay.show,
});

export default connect(mapStateToProps)(PlayerLeaderboard);

const OverflowContainer = styled.div`
  overflow: hidden;
  background-color: transparent;
`

const FontWrapper = styled.div`
  font-family: IntegralCD-Regular;
  font-size: 1.3rem;
  color: black;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
`

const Container = styled.div`
${({showOnlyMVP}) => showOnlyMVP ? `` : `
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: stretch;`}
`

const Table = styled.div`
  ${({showOnlyMVP}) => showOnlyMVP ? `
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  ` : `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;`}
`

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${({showOnlyMVP}) => !showOnlyMVP && `margin: 1.2rem;`}

  min-width: 10rem;

  background: linear-gradient(to bottom, #ffffff, #ed1c24);
`

const PlayerTeamImage = styled.div`
  position: relative;
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${({ showOnlyMVP }) => {
    if (showOnlyMVP) {
      return `height: 40rem;
              min-width: 25rem;`
    }
  }}
`

const PlayerFooter = styled.div`
  flex: 2;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.3rem;

  background: linear-gradient(to bottom, #ed1c24, #a50006);
`

const PlayerImage = styled.div`
  position: relative;
`

const BackgroundImage = styled.div`
  background-image: url("/assets/img/aces/squares_mvp.png");
  background-position: center;
  background-repeat: no-repeat;
`

const MVPTeamImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(7%, 7%);
`

const TeamImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -10;
`

const PlayerScore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  text-align: end;
  font-size: 1.3rem;

  line-height: 22px;
`

const Key = styled.div`
  text-align: start;
  font-weight: 100;
`

const Value = styled.div`
  color: white;
  text-align: end;
  font-weight: 500;
`

const PlayerNumber = styled.div`
  color: red;

  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  font-size: 5.8rem;
  transform: translate(0.3rem, 18.1%);
`

const PlayerName = styled.div`
  font-weight: 900;
  font-size: 2rem;
  border-bottom: 1px solid black;
  padding: 0.35rem 0;
  padding-bottom: 0.55rem;
  margin-bottom: 0.55rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  justify-content: center;
  align-items: center;
`

const MVP = styled.div`
  font-family: IntegralCD-Demi-Bold-Oblique;
  color: red;
  font-weight: 700;
  font-size: 1.8rem;
  font-style: italic;

  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  transform: translate(-20%, 20%);
`
