import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  color: #000;
  
  td {
    background: #ff0050;
    text-transform: uppercase;
    padding: 1px;
    font-family: 'SonySketchEF';
  }
`;

function LeaderboardHeader(props) {

  return (
    <Wrapper
      colors={props.overlayColor}
    >
      <td colSpan="5"><strong>Leaderboard</strong></td>
    </Wrapper>
  );
}

export default LeaderboardHeader;