import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const WIDTH = 1400;
const HORIZONTAL_SPACING = 10;

const HeadToHeadWrapper = styled.table`
  font-family: machine, noto-bold;
  font-size: 33px;

  border-collapse:separate;
  border-spacing: ${HORIZONTAL_SPACING}px;
  width: ${WIDTH}px;
  height: 1px;

  table {
    width: 100%;
    height: 100%;

    td:first-child, td:last-child {
      width: 25%;
    }
  }

  span {
    position: relative;
  }

  td {    
    text-align: center;
    vertical-align: middle;

    &.team-logo-td {
      background-image: url('/assets/img/pjc/head-to-head-background.png');
      background-attachment: fixed;
      background-position: 0px 0px;
      background-repeat: no-repeat;

      width: 25%;
      padding: 20px;
      height: 400px;

      img {
        height: 220px;
      }
    }
  }

  &.component-show {
    .team-logo-td {
      opacity: 0;
      animation: fadeIn 0.6s ease ${props => props.numOfStats * 0.2}s forwards;
  
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  &.component-hide {
    .team-logo-td {
      opacity: 1;
      animation: fadeOut 0.6s ease 0s forwards;
  
      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }
  }
`;

const StatsTd = styled.td`
  background-image: url('/assets/img/pjc/head-to-head-background.png');
  background-attachment: fixed;
  background-position: 0px 0px;
  background-repeat: no-repeat;

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.6s ease ${props => props.index/5}s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    p, span {
      bottom: -100px;
      animation: slideUp 0.3s ease ${props => props.index/5}s forwards;

      @keyframes slideUp {
        from {
          opacity: 0;
          bottom: -100px;
        }
        to {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.6s ease ${props => 1 - props.index/5 - 0.2}s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    p, span {
      bottom: 0px;
      animation: slideDown 0.3s ease ${props => 1 - props.index/5 - 0.2}s forwards;

      @keyframes slideDown {
        from {
          opacity: 1;
          bottom: 0px;
        }
        to {
          opacity: 0;
          bottom: -100px;
        }
      }
    }
  }
`;

class PjcPlayerHeadToHead extends Component {

  render() {
    const { loaded, left, right, opts, show } = this.props;

    if (!loaded || !left || !right) return null;

    return (
      <HeadToHeadWrapper
        className={ show ? 'component-show' : 'component-hide' }
        numOfStats={(opts.stats || []).length}
      >
        <tbody>
          {
            (opts.stats || []).map((s, i) => {
              return (
                <tr>
                  {
                    i === 0 ?
                    <td
                      className="team-logo-td"
                      rowSpan={opts.stats.length}
                    >
                      <img
                        className="mb-2"
                        src={getTeamLogo(left.teamName)}
                        onError={e => e.target.src = getTeamLogo('default')}
                        alt="team logo"
                      />
                      <p>{ left.ign }</p>
                    </td>
                    :
                    null
                  }
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <StatsTd
                            className={ show ? 'component-show' : 'component-hide' }
                            index={i}
                          >
                            <span>{ parseInt(left[s.key]) }</span>
                          </StatsTd>
                          <StatsTd
                            className={ show ? 'component-show' : 'component-hide' }
                            index={i}
                          >
                            <span>{ s.value }</span>
                          </StatsTd>
                          <StatsTd
                            className={ show ? 'component-show' : 'component-hide' }
                            index={i}
                          >
                            <span>{ parseInt(right[s.key]) }</span>
                          </StatsTd>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  {
                    i === 0 ?
                    <td
                      className="team-logo-td"
                      rowSpan={opts.stats.length}
                    >
                      <img
                        className="mb-2"
                        src={getTeamLogo(right.teamName)}
                        onError={e => e.target.src = getTeamLogo('default')}
                        alt="team logo"
                      />
                      <p>{ right.ign }</p>
                    </td>
                    :
                    null
                  }
                </tr>
              );
            })
          }
        </tbody>
      </HeadToHeadWrapper>
    );
  }
}

export default PjcPlayerHeadToHead;
