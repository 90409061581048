import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import styles from '../../../scss/style.scss';
import { Tooltip } from 'reactstrap';

const FontAwesomeButton = styled(FontAwesomeIcon)`
  color: ${ props => props.color ? styles[props.color] : '#FFFFFF' }${ props => props.disabled ? '60' : '' };
  margin: 0 0.25em;
  ${ props => props.disabled ? '' : 'cursor: pointer;' }

  ${ props => props.disabled ? '' : `&:hover { color: ${styles.info}; }` }
`;

class IconButton extends Component {
  state = {
    tooltipOpen: false,
  }

  render() {
    return (
      <span id={`${this.props.id}-tooltip`} className="icon-button">
        <FontAwesomeButton {...this.props} />  
        {
          this.props.tooltip ?
          <Tooltip
            placement="right"
            isOpen={this.state.tooltipOpen}
            target={`${this.props.id}-tooltip`}
            toggle={() => this.setState({tooltipOpen: !this.state.tooltipOpen})}
          >
            { this.props.tooltip }
          </Tooltip>   
          :
          null
        } 
      </span>
    );
  }
}

export default IconButton;