import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardTitle from './LeaderboardTitle';
import LeaderboardHeader from './LeaderboardHeader';

const LeaderboardTable = styled.table`
  &.bc-show {
    margin-left: -250px;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -250px;}
      to {margin-left: 32px;}
    }
  }

  &.bc-hide {
    margin-left: 32px;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 32px;}
      to {margin-left: -250px;}
    }
  }
`;

class EslLiveSurvivalLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;
    // if (leaderboard.length < 16) {
    //   Array.from(Array(16-leaderboard.length).keys())
    //   .forEach(() => leaderboard.push({ 
    //     name: 'None', 
    //     initial: 'None', 
    //     totalKills: 0, 
    //     totalPoints: 0, 
    //     numberOfMatches: 0, 
    //     players: new Array(4).map(() => { return { status: 'death' } }) 
    //   }));
    // }

    return (
      loaded ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <LeaderboardTitle
            overall={opts.overall}
          />
          <LeaderboardHeader
            overall={opts.overall}
          />
          {
            leaderboard.map((t, i) => {
              return (
                <LeaderboardBody
                  team={t}
                  rank={i+1}
                  overall={opts.overall}
                />
              );
            })
          }
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default EslLiveSurvivalLeaderboard;