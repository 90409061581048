import { 
  SAVE_APOLLO_CLIENT,
} from './types';

/**
 * Action for saving Apollo client
 */
export const saveApolloClient = (client) => ({
    type: SAVE_APOLLO_CLIENT,
    payload: {
      client: client,
    }
});