import gql from 'graphql-tag';

export const subscribeToPlayerStats = gql`subscription SubscribeToPlayerStats($token: String!) {
  subscribeToPlayerStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      firstName
      lastName
      ign
      teamName
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      characters
      country {
        name
        iso
      }
    }
    pubg {
      firstName
      lastName
      ign
      teamName
      country {
        name
        iso
      }
      kills
      assists
      deaths
      damageDealt
      damageTaken
      knocks
      revives
      headshotKills
      stolenKills
      killsStolenFromPlayer
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroyed
    }
    csgo {
      assists
      avgArmorLeft
      avgBurningTime
      avgDmg
      avgEquipmentValue
      avgFlashedTime
      avgHealthLeft
      deaths
      firstName
      hsKills
      hsPercentage
      id
      kD
      kR
      kills
      lastName
      maxDmg
      maxEquipmentValue
      name
      roundsAceKills
      roundsFourKills
      roundsThreeKills
      teamName
      totalBurningTime
      totalDmg
      totalEquipmentValue
      totalFlashedTime
    }
  }
}`;

export const subscribeToTeamStats = gql`subscription SubscribeToTeamStats($token: String!) {
  subscribeToTeamStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      teamName
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      country {
        name
        iso
      }
    }
    pubg {
      teamName
      country {
        name
        iso
      }
      kills
      assists
      deaths
      damageDealt
      damageTaken
      knocks
      revives
      headshotKills
      stolenKills
      killsStolenFromPlayer
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroyed
    }
  }
}`;

export const subscribeToPlayerHeadToHead = gql`subscription SubscribeToPlayerHeadToHead($token: String!) {
  subscribeToPlayerHeadToHead(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      valorant {
        firstName
        lastName
        ign
        teamName
        kills
        assists
        deaths
        esr
        firstBloods
        firstDeaths
        econRating
        adr
        hsProcentage
        plants
        defuses
        aces
        multikills
        clutches
        kd
        characters
        country {
          name
          iso
        }
      }
      pubg {
        firstName
        lastName
        ign
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
      }
    }
    right{
      valorant {
        firstName
        lastName
        ign
        teamName
        kills
        assists
        deaths
        esr
        firstBloods
        firstDeaths
        econRating
        adr
        hsProcentage
        plants
        defuses
        aces
        multikills
        clutches
        kd
        characters
        country {
          name
          iso
        }
      }
      pubg {
        firstName
        lastName
        ign
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
      }
    }
  }
}`;

export const subscribeToTeamHeadToHead = gql`subscription SubscribeToTeamHeadToHead($token: String!) {
  subscribeToTeamHeadToHead(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      pubg {
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
        totalPoints
      }
    }
    right{
      pubg {
        teamName
        country {
          name
          iso
        }
        kills
        assists
        deaths
        damageDealt
        damageTaken
        knocks
        revives
        headshotKills
        stolenKills
        killsStolenFromPlayer
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroyed
        totalPoints
      }
    }
  }
}`;

export const subscribeToPlayerLeaderboard = gql`subscription SubscribeToPlayerLeaderboard($token: String!) {
  subscribeToPlayerLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      firstName
      lastName
      ign
      teamName
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      characters
      country {
        name
        iso
      }
    }
    pubg {
      firstName
      lastName
      ign
      teamName
      country {
        name
        iso
      }
      kills
      assists
      deaths
      damageDealt
      damageTaken
      knocks
      revives
      headshotKills
      stolenKills
      killsStolenFromPlayer
      swimDistance
      walkDistance
      rideDistance
      longestKill
      timeSurvived
      killStreaks
      heals
      boosts
      vehicleDestroyed
      flashbangThrow
      grenadeThrow
      smokebombThrow
      molotovThrow
      avgTimeSurvived
    }
    csgo {
      assists
      avgArmorLeft
      avgBurningTime
      avgDmg
      avgEquipmentValue
      avgFlashedTime
      avgHealthLeft
      deaths
      firstName
      hsKills
      hsPercentage
      id
      kD
      kR
      kills
      lastName
      maxDmg
      maxEquipmentValue
      name
      roundsAceKills
      roundsFourKills
      roundsThreeKills
      teamName
      totalBurningTime
      totalDmg
      totalEquipmentValue
      totalFlashedTime
    }
  }
}`;

export const subscribeToTeamLeaderboard = gql`subscription SubscribeToTeamLeaderboard($token: String!) {
  subscribeToTeamLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      teamName
      wins
      draws
      losses
      points
      killDifference
      kills
      assists
      deaths
      esr
      firstBloods
      firstDeaths
      econRating
      adr
      hsProcentage
      plants
      defuses
      aces
      multikills
      clutches
      kd
      rd
      played
      country {
        name
        iso
      }
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentKills
      currentRank
      initial
      name
      numberOfMatches
      players {
        damage
        kills
        ign
        status
      }
      previousKills
      previousPosition
      previousPositionChange
      totalDamage
      totalKills
      totalPoints
      wins
    }
  }
}`;

export const subscribeToLiveTeamLeaderboard = gql`subscription SubscribeToLiveTeamLeaderboard($token: String!) {
  subscribeToLiveTeamLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentKills
      currentRank
      initial
      name
      numberOfMatches
      players {
        damage
        kills
        ign
        status
      }
      previousKills
      previousPosition
      previousPositionChange
      totalDamage
      totalKills
      totalPoints
      wins
    }
  }
}`;

export const subscribeToLiveMapTeams = gql`subscription SubscribeToLiveMapTeams($token: String!) {
  subscribeToLiveMapTeams(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      initial
      totalKills
      totalDamage
      numberOfMatches
      name
      players {
        damage
        kills
        ign
        status
      }
      totalPoints
      wins
    }
  }
}`;

export const subscribeToLiveSurvivalStats = gql`subscription SubscribeToLiveSurvivalStats($token: String!) {
  subscribeToLiveSurvivalStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentKills
      currentRank
      initial
      name
      numberOfMatches
      players {
        damage
        kills
        ign
        status
      }
      previousKills
      previousPosition
      previousPositionChange
      totalDamage
      totalKills
      totalPoints
      wins
    }
  }
}`;

export const subscribeToLiveTeamStats = gql`subscription SubscribeToLiveTeamStats($token: String!) {
  SubscribeToLiveTeamStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      avgAssists
      avgDamageDealt
      avgKills
      avgKnocks
      avgPoints
      currentPlacement
      initial
      name
      totalDamage
      totalKills
      totalPoints
      wins
      timeSurvived
      numberOfMatches
      currentDamage
      currentKills
    }
  }
}`;

export const subscribeToLivePlayerStats = gql`subscription SubscribeToLivePlayerStats($token: String!) {
  SubscribeToLivePlayerStats(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      teamName
      teamInitial
      totalKills
      totalDamage
      killRank
      damageRank
    }
  }
}`;

export const subscribeToLivePlayerKills = gql`subscription SubscribeToLivePlayerKills($token: String!) {
  SubscribeToLivePlayerKills(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      totalDamage
      totalKills
      teamInitial
      teamName
    }
  }
}`;

export const subscribeToLivePlayerKillsDamage = gql`subscription SubscribeToLivePlayerKillsDamage($token: String!) {
  SubscribeToLivePlayerKillsDamage(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      totalDamage
      totalKills
      teamInitial
      teamName
    }
  }
}`;

export const subscribeToLiveTeamHeadToHead = gql`subscription SubscribeToLiveTeamHeadToHead($token: String!) {
  subscribeToLiveTeamHeadToHead(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      pubg {
        name
        initial
        avgAssists
        avgDamageDealt
        avgKills
        avgKnocks
        avgPoints
        numberOfMatches
        players {
          damage
          ign
          kills
          status
        }
        totalDamage
        totalKills
        totalPoints
        wins
      }
    }
    right{
      pubg {
        name
        initial
        avgAssists
        avgDamageDealt
        avgKills
        avgKnocks
        avgPoints
        numberOfMatches
        players {
          damage
          ign
          kills
          status
        }
        totalDamage
        totalKills
        totalPoints
        wins
      }
    }
  }
}`;

export const subscribeToLivePlayerLeaderboard = gql`subscription SubscribeToLivePlayerLeaderboard($token: String!) {
  subscribeToLivePlayerLeaderboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      ign
      teamName
      teamInitial
      damageRank
      ign
      killRank
      teamInitial
      teamName
      totalDamage
      totalKills
    }
  }
}`;

export const subscribeToScoreboard = gql`subscription SubscribeToScoreboard($token: String!) {
  subscribeToScoreboard(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    left {
      valorant {
        teamName
        score {
          score
          wins
          draws
          losses
        }
        players {
          firstName
          lastName
          ign
          teamName
          kills
          assists
          deaths
          esr
          firstBloods
          firstDeaths
          econRating
          adr
          hsProcentage
          plants
          defuses
          aces
          multikills
          clutches
          kd
          characters
          country {
            name
            iso
          }
        }
      }
    }
    right{
      valorant {
        teamName
        score {
          score
          wins
          draws
          losses
        }
        players {
          firstName
          lastName
          ign
          teamName
          kills
          assists
          deaths
          esr
          firstBloods
          firstDeaths
          econRating
          adr
          hsProcentage
          plants
          defuses
          aces
          multikills
          clutches
          kd
          characters
          country {
            name
            iso
          }
        }
      }
    }
  }
}`;

export const subscribeToCurrentMatch = gql`subscription SubscribeToCurrentMatch($token: String!) {
  subscribeToCurrentMatch(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    valorant {
      currentMatch
    }
    pubg {
      currentMatch
    }
  }
}`;

export const subscribeToTodaysWinners = gql`subscription SubscribeToTodayWinner($token: String!) {
  subscribeToTodayWinner(token: $token) {
    show
    opts
    tournament {
      friendlyName
      overlayColor
      overlayDesign
    }
    pubg {
      currentMatchNumber
      matches {
        teamName
        map
        matchNumber
      }
    }
  }
}`;

export const subscribeToMapVeto = gql`subscription SubscribeToMapVeto($token: String!) {
  subscribeToMapVeto(token: $token) {
    token
    tokenOverlay
    status
    pickValues
    timer
    logs {
      map
      type
      mapVoter
      attackers
      defenders
    }
  }
}`;

export const subscribeToChangeComponent = gql`subscription SubscribeToChangeComponent($token: String!) {
  subscribeToChangeComponent(token: $token) {
    token
    name
    show
    opts
    args {
      type
      shard_info
      match
      game
    }
    matches {
      id
      name
      startAt
    }
    teams {
      id
      name
      players {
        id
        firstName
        lastName
        ign
      }
    }
    availableStatistics {
      key
      value
    }
  }
}`;