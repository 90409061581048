import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';

const WIDTH = 245;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;

  .overlay {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: ${WIDTH}px;
  
    position: fixed;
    left: 35px;
    top: 142px;
  
    color: #000;
  
    font-family: machine;
  
    &.bc-show {
      left: -300px;
      animation: slideRight 0.3s ease 0s forwards;
    
      @keyframes slideRight {
        from {left: -300px;}
        to {left: 35px;}
      }
    }
  
    &.bc-hide {
      left: 35px;
      animation: slideLeft 0.3s ease 0s forwards;
    
      @keyframes slideLeft {
        from {left: 35px;}
        to {left: -300px;}
      }
    }
  }
`;

class Pjc21LiveTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show } = this.props;
    const top16 = leaderboard.slice(0, 16);
    // top16[0].players = [{status: 'alive'}, {status: 'alive'}, {status: 'knocked'}, {status: 'death'}];

    return (
      loaded ?
      <Wrapper
      >
        <div className={`overlay ${show ? 'bc-show' : 'bc-hide'}`}>
          <LeaderboardHeader width={WIDTH}/>
          {
            top16.map((t, i) => {
              return (
                <LeaderboardBody
                  team={t}
                  rank={i+1}
                  width={WIDTH}
                />
              );
            })
          }
        </div>
      </Wrapper>
      :
      null
    );
  }
}

export default Pjc21LiveTeamLeaderboard;