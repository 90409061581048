import { 
  CLOSE_MODAL,
  OPEN_MODAL,
} from './types';

/**
 * Action for opening modal
 */
export const openModal = (title, content, onConfirm) => ({
    type: OPEN_MODAL,
    payload: {
      title: title,
      content: content,
      onConfirm: onConfirm,
    }
});

/**
 * Action for opening modal
 */
export const closeModal = () => ({
    type: CLOSE_MODAL,
});