// Action for requesting overlay data
export const REQUEST_OVERLAY_DATA = 'REQUEST_OVERLAY_DATA';
export const REQUEST_OVERLAY_DATA_PENDING = 'REQUEST_OVERLAY_DATA_PENDING';
export const REQUEST_OVERLAY_DATA_SUCCESS = 'REQUEST_OVERLAY_DATA_SUCCESS';
export const REQUEST_OVERLAY_DATA_ERROR = 'REQUEST_OVERLAY_DATA_ERROR';
// Action for subscribing to overlay data
export const SUBSCRIBE_TO_OVERLAY_DATA = 'SUBSCRIBE_TO_OVERLAY_DATA';
export const SUBSCRIBE_TO_OVERLAY_DATA_PENDING = 'SUBSCRIBE_TO_OVERLAY_DATA_PENDING';
export const SUBSCRIBE_TO_OVERLAY_DATA_SUCCESS = 'SUBSCRIBE_TO_OVERLAY_DATA_SUCCESS';
export const SUBSCRIBE_TO_OVERLAY_DATA_ERROR = 'SUBSCRIBE_TO_OVERLAY_DATA_ERROR';
// Action for setting received overlay data
export const SET_RECEIVED_OVERLAY_DATA = 'SET_RECEIVED_OVERLAY_DATA';