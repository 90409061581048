import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { displaySuccessNotification } from '../../../../../../redux/notification/actions';
import { Button, Select } from '../../../../../../components/StyledCoreUI';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function TeamSelectWithCopy(props) {
  const { component, label, stateAttribute, copyText, stateToMergeOnChange, requestChangeComponent, displaySuccessNotification } = props;

  return (
    <FormGroup>
      <Label>{ label }</Label>
      <InputGroup>
        <Select
          name={stateAttribute}
          color={styles.tertiaryDark}
          value={component.state[stateAttribute] ? component.state[stateAttribute] : 0}
          onChange={id => requestChangeComponent(component, { state: {
            ...stateToMergeOnChange,
            [stateAttribute]: parseInt(id),
          }})}
          disabledOptionText='-- select team --'
          options={component.teams.map(t => {
            return { key: t.id, value: t.name };
          })}
          warning={!component.state[stateAttribute] && component.state.showEmptyFieldWarning}
        />
        <InputGroupAddon addonType="append">
          <CopyToClipboard 
            text={copyText}
            onCopy={() => displaySuccessNotification('Link Copied')}
          >
            <Button type="button" color="primary" disabled={copyText === null}><i className="fa fa-copy"></i></Button>
          </CopyToClipboard>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
  displaySuccessNotification,
};

export default connect(null, mapDispatchToProps)(TeamSelectWithCopy);
