import React from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.table`
  overflow: hidden;
  min-height: 422px;

  >tr td {
    padding: 10px;
  }

  tr:first-child td {
    padding-bottom: 40px;

    img {
      width: 220px;
    }
  }

  tr:nth-child(2) td {
    div {
      height: 73px;
      background: url('/assets/img/being/live_player_kills_damage_player_name_background.png');
      background-repeat: no-repeat;
      background-position: center;

      img {
        width: 73px;
      }
    }
  }

  table {
    td {
      padding: 0;
      height: 73px;
      font-size: 31px;
      text-transform: uppercase;
      position: relative;

      &:first-child {
        padding-left: 8px;
        max-width: 73px;

        p {
          position: absolute;
          font-size: 40px;
          top: 5px;
          left: 34px;
        }
      }

      &:nth-child(2) {
        width: 310px !important;
      }
    }
  }

  .stats-value {
    font-family: 'CenturyGothicBold';
    font-size: 45px;

    p {
      background: -webkit-linear-gradient(#FFF 45%, #404040 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 0.7px;
      -webkit-text-stroke-color: white;
      filter: drop-shadow(0px 0px 5px #000);
    }
  }

  &.component-show {
    opacity: 0;
    animation: fadeIn 0.3s ease ${props => props.delayShow * 0.3}s forwards;

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
    
    .team-logo {
      animation: slideUp 0.3s ease ${props => props.delayShow * 0.3}s forwards;

      @keyframes slideUp {
        from {margin-bottom: -200px;}
        to {margin-bottom: 0;}
      }
    }
  }

  &.component-hide {
    opacity: 1;
    animation: fadeOut 0.3s ease ${props => props.delayShow * 0.3}s forwards;

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
    
    .team-logo {
      animation: slideDown 0.3s ease ${props => props.delayShow * 0.3}s forwards;

      @keyframes slideDown {
        from {margin-bottom: 0;}
        to {margin-bottom: -200px;}
      }
    }
  }
  
`;

function LeaderboardPlayer(props) {
  const { rank, player, show } = props;

  return (
    <Wrapper
      className={ show ? 'component-show' : 'component-hide' }
      delayShow={rank}
    >
      <tbody>
        <tr>
          <td className="w-384 text-center">
            <img
              className="team-logo"
              src={getTeamLogo(player.teamName)}
              alt="team-logo"
              onError={e => e.target.src = getTeamLogo("default")}
            />
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img
                        className="mr-3"
                        src={'/assets/img/being/live_player_kills_damage_player_rank_background.png'}
                        alt="player-rank-background"
                      />
                      <p>{ rank }</p>
                    </td>
                    <td className="text-center">
                      { player.ign }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td className="stats-value text-center">
            <p>{ player.totalKills }</p>
          </td>
        </tr>
      </tbody>
    </Wrapper>
  );
}

export default LeaderboardPlayer;