import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import CheckBox from '../../../common/base/controller/inputs/CheckBox';
import TeamSelect from '../../../common/base/controller/inputs/TeamSelect';
import { COMPONENT_STATE_SUBSCRIPTION, COMPONENT_STATE_TEAM } from '../../../constants';
import { PUBG_GAME } from '../../../../../util/Games';

class PUBGLiveTeamStatsController extends Component {
  render() {
    const { component } = this.props;

    return (
      <ControllerBase
        game={PUBG_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <TeamSelect
              component={component}
              label={'Team'}
              stateAttribute={COMPONENT_STATE_TEAM}
            />
          </Col>
          <Col xs="12">
            <CheckBox
              component={component}
              label={'Activate Component'}
              stateAttribute={COMPONENT_STATE_SUBSCRIPTION}
            />
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

export default PUBGLiveTeamStatsController;
