import { 
  REQUEST_HEAD_TO_HEAD_TEAMS_SUCCESS,
  REQUEST_HEAD_TO_HEAD_TEAMS_PENDING,
  REQUEST_HEAD_TO_HEAD_TEAMS_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  leftTeam: null,
  rightTeam: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request head to head team pending or error
    case REQUEST_HEAD_TO_HEAD_TEAMS_PENDING:
    case REQUEST_HEAD_TO_HEAD_TEAMS_ERROR:
      return INITIAL_STATE;

    // On request head to head team success
    case REQUEST_HEAD_TO_HEAD_TEAMS_SUCCESS:
      return {
        loaded: true,
        leftTeam: action.payload.left,
        rightTeam: action.payload.right,
      };

    default:
      return state;
  }
}

export default reducer;