import { STAGE_FILTER_TYPE_HIDDEN, STAGE_FILTER_TYPE_OVERALL, TWIRE_ASSETS_URL } from './Constants';
import components from '../views/Components';

/**
 * Sets page title
 *
 * @param {*} title
 */
export const setPageTitle = (title) => {
  document.title = title
}

/**
 * Converts number into placement string.
 * For example: 1 -> st
 *
 * @param {*} n number to convert
 */
export const numberToPlacementSurfix = (n) => {
  if (n >= 10 && n <= 20) {
    return 'th'
  } else if (n % 10 === 1) {
    return 'st'
  } else if (n % 10 === 2) {
    return 'nd'
  } else if (n % 10 === 3) {
    return 'rd'
  } else {
    return 'th'
  }
}

/**
 * Returns url to the team logo picture
 *
 * @param {*} teamName
 */
export const getTeamLogo = (teamName) => {
  return teamName
    ? TWIRE_ASSETS_URL +
    'pubg/team-logos/' +
    teamName.replaceAll(' ', '-').toLowerCase() +
    '.png'
    : ''
}

/**
 * Returns url to the team logo picture by game
 *
 * @param {*} game
 * @param {*} teamName
 */
export const getTeamLogoByGame = (game, teamName) => {
  return TWIRE_ASSETS_URL +
    game.toLowerCase() + '/' +
    'pubg/team-logos/' +
    (teamName || 'default').replaceAll(' ', '-').toLowerCase() +
    '.png'
}

/**
 * Returns url to the country flag
 *
 * @param {*} isoCode
 * @param {*} size
 */
export const getCountryFlag = (isoCode, size) => {
  return TWIRE_ASSETS_URL +
    `countries/${size || 'normal'}/` +
    isoCode.toLowerCase() +
    '.png'
}

/**
 * Returns url to the team logo picture by game
 *
 * @param {*} game
 * @param {*} teamName
 */
export const getMapByGame = (game, mapName) => {
  return TWIRE_ASSETS_URL +
    game.toLowerCase() + '/' +
    'maps/' +
    (mapName || 'default').replaceAll(' ', '-').toLowerCase()
}

/**
 * Returns url to the team logo picture
 *
 * @param {*} playerName
 */
export const getPlayerImage = (playerName) => {
  return playerName ? TWIRE_ASSETS_URL + 'pubg/players/' + playerName + '.png' : ''
}

/**
 * Returns url to the team logo picture
 *
 * @param {*} playerName
 */
export const getPlayerImageByGame = (game, playerName) => {
  return playerName ? TWIRE_ASSETS_URL + game.toLowerCase() + '/pubg/players/' + (playerName.replaceAll(' ', '-').toLowerCase() || 'default') + '.png' : ''
}

/**
 * Returns url to CSGO team logo picture
 *
 * @param {*} game
 * @param {*} teamName
 */
export const getCsgoTeamLogo = (teamName) => {
  return TWIRE_ASSETS_URL +
    'csgo/team-logos/' +
    (teamName || 'default').replaceAll(' ', '-').toLowerCase() +
    '.png'
}

/**
 * Returns url to the CSGO player picture
 *
 * @param {*} game
 * @param {*} playername
 */
export const getCsgoPlayerImage = (playerName) => {
  return TWIRE_ASSETS_URL +
    'csgo/players/' +
    (playerName || 'default').replaceAll(' ', '-').toLowerCase() +
    '.png'
}

/**
 * Returns url to the valorant agent icon
 *
 * @param {*} agent
 */
export const getValorantAgentIcon = (agent) => {
  return TWIRE_ASSETS_URL + `valorant/agents/${agent.toLowerCase()}/${agent.toLowerCase()}-icon.png`;
}

/**
 * Returns parameter value from URL if exists, else null
 *
 * @param {*} param parameter name
 */
export const getQueryParamFromUrl = (param) => {
  return new URL(window.location.href).searchParams.get(param)
}

export const roundNumber = (n, decimal, percentage) => {
  const rounded = !percentage ? Math.round(n * 10) / 10 : n * 100;
  if (decimal) {
    return (rounded).toFixed(1);
  }
  return rounded;
}

/**
 * Return csv
 *
 * @param {*} columns map of array property to column title
 * @param {*} array data
 */
export const arrayToCsv = (columns, array) => {
  let csv = `${Object.keys(columns)
    .map((k) => columns[k])
    .join(',')}\r\n`
  array.forEach((el) => {
    csv += `${Object.keys(columns)
      .map((k) => el[k])
      .join(',')}\r\n`
  })
  return csv
}

/**
 * Downloads give csv with given name
 *
 * @param {*} csv
 * @param {*} name
 */
export const downloadCsv = (csv, name) => {
  const hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = `${name}.csv`
  hiddenElement.click()
}

/**
 * Returns true if number is int
 */
export const isInt = (n) => {
  return n % 1 === 0;
}

/**
 * Converts number into displayable format
 * 
 * @param {*} n 
 */
export const numberForDisplay = (n) => {
  return isInt(n) ? n : parseFloat(n).toFixed(2);
}

/**
 * Returns the additional name of the filter (shardInfo - shard = additional name)
 * 
 * @param {*} tournamentFilters 
 * @param {*} tournamentFilterId 
 */
export const getAdditionalName = (tournamentFilters, tournamentFilterId) => {
  if (tournamentFilterId === null || !tournamentFilters.length || (tournamentFilters.length === 1 && tournamentFilters[0].type === STAGE_FILTER_TYPE_HIDDEN)) {
    return '';
  } else {
    const filter = tournamentFilters.find(f => f.id === tournamentFilterId);
    const parentAdditionalName = getAdditionalName(tournamentFilters, filter.parentId);
    return parentAdditionalName ? parentAdditionalName + '-' + (filter.value || '') : (filter.value || '');
  }
}

/**
 * Returns the child filter of the given additional name
 * 
 * @param {*} tournamentFilters 
 * @param {*} additionalName 
 */
export const getFilterByAdditionalName = (tournamentFilters, additionalName, acc = '', currentFilterId = null) => {
  const nextFilter = tournamentFilters
    .sort((a, b) => (a.value || '').length > (b.value || '').length ? -1 : 1)
    .find(f => additionalName.startsWith(acc ? acc + '-' + f.value : f.value) && f.parentId === currentFilterId);
  acc = acc ? acc + '-' + nextFilter.value : nextFilter ? nextFilter.value : '';
  if (acc === additionalName || acc + '-' === additionalName) {
    const overallFilter = tournamentFilters.find(f => f.parentId === nextFilter.id && (f.type === STAGE_FILTER_TYPE_OVERALL || f.type === STAGE_FILTER_TYPE_HIDDEN));
    return overallFilter ? overallFilter.id : nextFilter.id;
  } else {
    return getFilterByAdditionalName(tournamentFilters, additionalName, acc, nextFilter.id);
  }
}

/**
 * Returns the full shardInfo according to selected filter
 * 
 * @param {*} shard 
 * @param {*} tournamentFilters 
 * @param {*} tournamentFilterId 
 */
export const getShardInfo = (shard, tournamentFilters, tournamentFilterId) => {
  let additionalName = getAdditionalName(tournamentFilters, tournamentFilterId);
  if (additionalName.lastIndexOf('-') === additionalName.length - 1) additionalName = additionalName.slice(0, -1);
  return `${shard}${additionalName ? '-' + additionalName : ''}`;
}

/**
 * Returns true if first stage is also a group
 * 
 * @param {*} tournamentFilters 
 */
export const doesFirstStageHaveChildren = (tournamentFilters) => {
  const firstStage = (tournamentFilters.find(f => !f.parentId) || {});
  const firstStageChildren = tournamentFilters.filter(f => f.parentId === firstStage.id);
  return firstStageChildren.length;
}

/**
 * Returns true if first stage is also a group
 * 
 * @param {*} tournamentFilters 
 */
export const isFirstStageOverall = (tournamentFilters) => {
  const firstStage = (tournamentFilters.find(f => !f.parentId) || {});
  return tournamentFilters.filter(f => f.parentId === firstStage.id).find(f => f.type === STAGE_FILTER_TYPE_OVERALL);
}

/**
 * Sorts players by stats (first alive, then knocked, and last death)
 * 
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
export const sortByPlayerStatus = (a, b) => {
  if (a.status !== b.status) {
    if (a.status === 'alive') {
      return -1;
    } else if (b.status === 'alive') {
      return 1;
    } else if (a.status === 'knocked') {
      return -1;
    } else if (b.status === 'knocked') {
      return 1;
    }
  }
  return 0;
}

/**
 * Adds missing players to team (assums that 4 players need to be on team)
 * 
 * @param {*} players 
 */
export const addMissingPlayers = (players) => {
  const numberOfPlayer = players.length;
  if (players.length !== 4) {
    for (let i = 0; i < 4 - numberOfPlayer; i++) {
      players.push({ status: 'death', ign: 'NOT CONNECTED' });
    }
  }
}

/**
 * Returns true if the team is death
 * 
 * @param {*} team 
 * @returns 
 */
export const isTeamDeath = (team) => {
  return team.players.filter(p => p.status === 'death').length >= team.players.length;
}

/**
 * Returns game objects according to give component names and selected game
 * 
 * @param {*} game 
 * @param {*} availableComponents 
 */
export const getAvailableComponentObjects = (game, availableComponents) => {
  const filteredComponents = {};
  Object.keys(components[game]).forEach(k => {
    if (availableComponents.includes(k)) {
      filteredComponents[k] = components[game][k];
    }
  });
  return filteredComponents;
}

/**
 * Groups list by given attribute
 *
 * Example:
 * list = [ 'one', 'two', 'three' ]
 * key = 'length'
 * result = { 3: [ 'one', 'two' ], 5: [ 'three' ] }
 */
 export const groupBy = (list, key) => list.reduce((acc, currVal) => {
  (acc[currVal[key]] = acc[currVal[key]] || []).push(currVal);
  return acc;
}, {});

/**
 * Groups list by given two attribute
 *
 * Example:
 * list = [ { x: '1', y: '2', z: '4' }, { x: '1', y: '2', z: '3' }, { x: '1', y: '3', z: '4' } ]
 * key1 = 'x'
 * key2 = 'y'
 * result = { '1-2': [ { x: '1', y: '2', z: '4' }, { x: '1', y: '2', z: '3' } ], '1-3': [
 *  { x: '1', y: '3', z: '4' } ] }
 */
 export const groupByTwo = (list, key1, key2) => list.reduce((acc, currVal) => {
  (acc[String(currVal[key1]) + '-' + String(currVal[key2])] = acc[currVal[key1] + '-' + currVal[key2]] || [])
    .push(currVal);
  return acc;
}, {});