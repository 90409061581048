import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../../../scss/style.scss';
import { FormGroup, Label } from 'reactstrap';
import { requestChangeComponent } from '../../../../../../redux/dashboard/actions';
import { Select } from '../../../../../../components/StyledCoreUI';

function TeamSelect(props) {
  const { component, label, stateAttribute, childPlayerStateAttribute, stateToMergeOnChange, requestChangeComponent } = props;

  return (
    <FormGroup>
      <Label>{ label }</Label>
      <Select
        name={stateAttribute}
        color={styles.tertiaryDark}
        value={component.state[stateAttribute] ? component.state[stateAttribute] : 0}
        onChange={id => {
          const newState = {
            ...stateToMergeOnChange,
            [stateAttribute]: parseInt(id),
          };
          if (childPlayerStateAttribute) {
            newState[childPlayerStateAttribute] = null;
          }
          requestChangeComponent(component, { state: newState});
        }}
        disabledOptionText='-- select team --'
        options={component.teams.map(t => {
          return { key: t.id, value: t.name };
        })}
        warning={!component.state[stateAttribute] && component.state.showEmptyFieldWarning}
      />
    </FormGroup>
  );
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(TeamSelect);
