import React from 'react'
import { Col, Row } from 'reactstrap'
import ControllerBase from '../../../common/base/controller/ControllerBase'
import StatisticsSelect from '../../../common/base/controller/inputs/StatisticsSelect'
import MatchSelect from '../../../common/base/controller/inputs/MatchSelect'
import TeamSelect from '../../../common/base/controller/inputs/TeamSelect'
import {
  COMPONENT_STATE_TEAM,
} from '../../../constants'
import { VALORANT_GAME } from '../../../../../util/Games'

const ValorantTeamStatsController = (props) => {
  const { component } = props

  return (
    <ControllerBase
      game={VALORANT_GAME}
      {...props}
    >
      <Row>
        <Col xs="12">
          <StatisticsSelect component={component} />
        </Col>
        <Col xs="12">
          <MatchSelect component={component} />
        </Col>
        <Col xs="12">
          <TeamSelect
            component={component}
            label={'Team'}
            stateAttribute={COMPONENT_STATE_TEAM}
          />
        </Col>
      </Row>
    </ControllerBase>
  )
}

export default ValorantTeamStatsController
