import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import {  
  REQUEST_CREATE_TOURNAMENT,
  REQUEST_CREATE_TOURNAMENT_PENDING,
  REQUEST_CREATE_TOURNAMENT_ERROR,
  REQUEST_CREATE_TOURNAMENT_SUCCESS,
  REQUEST_EDIT_TOURNAMENT,
  REQUEST_EDIT_TOURNAMENT_PENDING,
  REQUEST_EDIT_TOURNAMENT_ERROR,
  REQUEST_EDIT_TOURNAMENT_SUCCESS,
  REQUEST_TOURNAMENT,
  REQUEST_TOURNAMENT_PENDING,
  REQUEST_TOURNAMENT_ERROR,
  REQUEST_TOURNAMENT_SUCCESS,
  REQUEST_CREATE_TOURNAMENT_FILTER,
  REQUEST_CREATE_TOURNAMENT_FILTER_SUCCESS,
  REQUEST_CREATE_TOURNAMENT_FILTER_PENDING,
  REQUEST_CREATE_TOURNAMENT_FILTER_ERROR,
  REQUEST_EDIT_TOURNAMENT_FILTER,
  REQUEST_EDIT_TOURNAMENT_FILTER_ERROR,
  REQUEST_EDIT_TOURNAMENT_FILTER_PENDING,
  REQUEST_EDIT_TOURNAMENT_FILTER_SUCCESS,
  REQUEST_DELETE_TOURNAMENT_FILTER,
  REQUEST_DELETE_TOURNAMENT_FILTER_ERROR,
  REQUEST_DELETE_TOURNAMENT_FILTER_PENDING,
  REQUEST_DELETE_TOURNAMENT_FILTER_SUCCESS,
  REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE,
  REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_ERROR,
  REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_PENDING,
  REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_SUCCESS,
  REQUEST_CHANGE_TRACKED_IGNS,
  REQUEST_CHANGE_TRACKED_IGNS_ERROR,
  REQUEST_CHANGE_TRACKED_IGNS_PENDING,
  REQUEST_CHANGE_TRACKED_IGNS_SUCCESS,
  REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS,
  REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_ERROR,
  REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_PENDING,
  REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_SUCCESS,
  REQUEST_ADD_TOURNAMENT_MATCH,
  REQUEST_ADD_TOURNAMENT_MATCH_ERROR,
  REQUEST_ADD_TOURNAMENT_MATCH_PENDING,
  REQUEST_ADD_TOURNAMENT_MATCH_SUCCESS,
  REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH,
  REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_PENDING,
  REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_SUCCESS,
  REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_ERROR,
  REQUEST_EDIT_TOURNAMENT_MATCH_PENDING,
  REQUEST_EDIT_TOURNAMENT_MATCH_SUCCESS,
  REQUEST_EDIT_TOURNAMENT_MATCH_ERROR,
  REQUEST_EDIT_TOURNAMENT_MATCH,
  REQUEST_TOURNAMENT_LEADERBOARD,
  REQUEST_TOURNAMENT_LEADERBOARD_ERROR,
  REQUEST_TOURNAMENT_LEADERBOARD_PENDING,
  REQUEST_TOURNAMENT_LEADERBOARD_SUCCESS,
  REQUEST_DELETE_TOURNAMENT_PHASE_PENDING,
  REQUEST_DELETE_TOURNAMENT_PHASE_SUCCESS,
  REQUEST_DELETE_TOURNAMENT_PHASE_ERROR,
  REQUEST_DELETE_TOURNAMENT_PHASE,
  REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_PENDING,
  REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_SUCCESS,
  REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_ERROR,
  REQUEST_ADD_TOURNAMENT_TEAM_SLOTS,
  REQUEST_ADD_TOURNAMENT_PRIZE_POOL_PENDING,
  REQUEST_ADD_TOURNAMENT_PRIZE_POOL_SUCCESS,
  REQUEST_ADD_TOURNAMENT_PRIZE_POOL_ERROR,
  REQUEST_ADD_TOURNAMENT_PRIZE_POOL,
  REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_PENDING,
  REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_SUCCESS,
  REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_ERROR,
  REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL,
  REQUEST_SAVE_TOURNAMENT_AWARD,
  REQUEST_SAVE_TOURNAMENT_AWARD_SUCCESS,
  REQUEST_SAVE_TOURNAMENT_AWARD_ROW,
  REQUEST_SAVE_TOURNAMENT_AWARD_ROW_SUCCESS,
  REQUEST_DELETE_TOURNAMENT_AWARD,
  REQUEST_DELETE_TOURNAMENT_AWARD_SUCCESS,
} from './types';
import { LOADING_ERROR, SAVING_ERROR } from '../../util/ErrorMessages';
import { SAVE_SUCCESS } from '../../util/SuccessMessages';
import {
  addTournamentMatch,
  changeTrackBy,
  createTournament,
  deleteTournamentPhase,
  editTournament,
  removeTournamentPrizePool,
  createTournamentAward,
  editTournamentAward,
  deleteTournamentAward,
  createTournamentAwardRow,
  editTournamentAwardRow,
} from '../../graphql/apollo/tournamentMutations';
import { getTournament } from '../../graphql/apollo/tournamentQueries';
import {
  createTournamentFilter,
  editTournamentFilter,
  deleteTournamentFilter,
  createTournamentPhase,
  editTournamentPhase,
  editTournamentMatch,
  addTournamentTeamSlots,
  addTournamentPrizePool,
} from '../../graphql/apollo/tournamentMutations';
import {
  getAdditionalTournamentInfo,
  tournamentById,
} from '../../graphql/public/queries';
import { displayErrorNotification, displaySuccessNotification } from '../notification/actions';
import { subscribeToTournamentMatch, subscribeToUsernameCheck } from '../../graphql/apollo/tournamentSubscriptions';
import { privateMutation, publicQuery } from '../../graphql/requestHelper';
import { productionLeaderboard } from '../../graphql/public/queries';
import { TOURNAMENT_TYPE_TOURNAMENT } from '../../util/Constants';
import { saveAdditionalTournamentInfo } from '../../graphql/private/mutations';

/**
 * Makes a request to create tournament
 * 
 * @param {*} action 
 */
function* requestCreateTournament(action) {
  yield put({ type: REQUEST_CREATE_TOURNAMENT_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const organizationId = yield select(state => state.organization.id);

    if (action.payload.tournament.eventType === TOURNAMENT_TYPE_TOURNAMENT && organizationId !== 1) {
      throw new Error();
    }

    const result = (yield call([client, 'mutate'], {
      mutation: createTournament,
      variables: {
        game: action.payload.game,
        tournament: action.payload.tournament,
      }
    })).data.createTournament;

    yield put({ type: REQUEST_CREATE_TOURNAMENT_SUCCESS, payload: { game: action.payload.game, ...result } });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
    if (action.payload.callback) action.payload.callback(result);
  } catch (err) {
    yield put({ type: REQUEST_CREATE_TOURNAMENT_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to edit tournament
 * 
 * @param {*} action 
 */
function* requestEditTournament(action) {
  yield put({ type: REQUEST_EDIT_TOURNAMENT_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const token = yield select(state => state.auth.token);
    const organizationId = yield select(state => state.organization.id);

    const result = (yield call([client, 'mutate'], {
      mutation: editTournament,
      variables: {
        game: action.payload.game,
        tournament: action.payload.tournament,
      }
    })).data.editTournament;

    if (organizationId === 1) {
      yield privateMutation(saveAdditionalTournamentInfo, {
        game: action.payload.game,
        tournamentId: action.payload.tournament.id,
        image: action.payload.additionalInfo.image,
        visible: action.payload.additionalInfo.visible,
        token: token,
      });
    }

    yield put({ type: REQUEST_EDIT_TOURNAMENT_SUCCESS, payload: result });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
  } catch (err) {
    yield put({ type: REQUEST_EDIT_TOURNAMENT_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server for all my tournaments
 * 
 * @param {*} action 
 */
function* requestTournament(action) {
  yield put({ type: REQUEST_TOURNAMENT_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const token = yield select(state => state.auth.token);
    const organizationId = yield select(state => state.organization.id);

    const result = (yield call([client, 'query'], {
      query: getTournament,
      variables: action.payload,
    })).data.getTournament;

    const tournament = (yield publicQuery(tournamentById, {
      game: action.payload.game,
      id: action.payload.tournament.id,
    })).data.tournamentById;

    let additionalInfo = {};

    if (organizationId === 1) {
      additionalInfo = (yield publicQuery(getAdditionalTournamentInfo, {
        game: action.payload.game,
        tournamentId: action.payload.tournament.id,
        token: token,
      })).data.getAdditionalTournamentInfo;
    }

    yield put({ type: REQUEST_TOURNAMENT_SUCCESS, payload: {
      game: action.payload.game,
      filters: tournament.tournamentFilters,
      ...additionalInfo,
      ...result,
    }});
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

/**
 * Makes a request to server to create tournament filter
 * 
 * @param {*} action 
 */
function* requestCreateTournamentFilter(action) {
  yield put({ type: REQUEST_CREATE_TOURNAMENT_FILTER_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: createTournamentFilter,
      variables: action.payload,
    })).data.createTournamentFilter;

    yield put({ type: REQUEST_CREATE_TOURNAMENT_FILTER_SUCCESS, payload: result });

    if (action.callback) action.callback(result.id);
  } catch (err) {
    yield put({ type: REQUEST_CREATE_TOURNAMENT_FILTER_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to upsert tournament filter
 * 
 * @param {*} action 
 */
function* requestEditTournamentFilter(action) {
  yield put({ type: REQUEST_EDIT_TOURNAMENT_FILTER_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const tournamentFilters = yield select(state => state.tournament.tournamentFilters);

    const filtersToCheck = action.payload.tournamentFilter.parentId ?
      tournamentFilters.filter(f => f.parentId === action.payload.tournamentFilter.parentId)
      :
      tournamentFilters.filter(f => !f.parentId);
    if (filtersToCheck.some(f => f.name === action.payload.tournamentFilter.name && f.id !== action.payload.tournamentFilter.id)) {
      yield put(displayErrorNotification("Name already exists"));
    } else {
      const result = (yield call([client, 'mutate'], {
        mutation: editTournamentFilter,
        variables: action.payload,
      })).data.editTournamentFilter;
  
      if (action.callback) action.callback(result);
  
      yield put({ type: REQUEST_EDIT_TOURNAMENT_FILTER_SUCCESS, payload: result });
    }
  } catch (err) {
    yield put({ type: REQUEST_EDIT_TOURNAMENT_FILTER_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to delete tournament filter
 * 
 * @param {*} action 
 */
function* requestDeleteTournamentFilter(action) {
  yield put({ type: REQUEST_DELETE_TOURNAMENT_FILTER_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    yield call([client, 'mutate'], {
      mutation: deleteTournamentFilter,
      variables: action.payload,
    });

    yield put({ type: REQUEST_DELETE_TOURNAMENT_FILTER_SUCCESS, payload: action.payload.id });
  } catch (err) {
    yield put({ type: REQUEST_DELETE_TOURNAMENT_FILTER_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to create or edit tournament phase
 * 
 * @param {*} action 
 */
function* requestCreateOrEditTournamentPhase(action) {
  yield put({ type: REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const tournamentPhases = yield select(state => state.tournament.tournamentPhases);

    // Getting tracking players from already existing phase and assigning them to create object
    const existingPhaseForThisGroup = tournamentPhases.find(p => p.id && p.tournamentFilterId === action.payload.phase.tournamentFilterId);
    action.payload.phase.trackBy = existingPhaseForThisGroup ? existingPhaseForThisGroup.trackBy.map(p => { return { username: p.username, platform: p.platform } }) : null;

    const result = (yield call([client, 'mutate'], {
      mutation: action.payload.phase.id ? editTournamentPhase : createTournamentPhase,
      variables: { game: action.payload.game, tournamentPhase: action.payload.phase },
    })).data[action.payload.phase.id ? 'editTournamentPhase' : 'createTournamentPhase'];

    yield put({ type: REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_SUCCESS, payload: result });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
  } catch (err) {
    yield put({ type: REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE_ERROR });
    yield put(displayErrorNotification(err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

/**
 * Makes a request to server to delete tournament phase
 * 
 * @param {*} action 
 */
function* requestDeleteTournamentPhase(action) {
  yield put({ type: REQUEST_DELETE_TOURNAMENT_PHASE_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    const tournamentPhases = yield select(state => state.tournament.tournamentPhases);

    yield call([client, 'mutate'], {
      mutation: deleteTournamentPhase,
      variables: { game: action.payload.game, id: action.payload.id },
    });

    tournamentPhases.splice(action.payload.index, 1);

    yield put({ type: REQUEST_DELETE_TOURNAMENT_PHASE_SUCCESS, payload: [...tournamentPhases] });
  } catch (err) {
    yield put({ type: REQUEST_DELETE_TOURNAMENT_PHASE_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to change tracked IGNs on phases that have give tournamentFilterId
 * 
 * @param {*} action 
 */
function* requestChangeTrackedIGNs(action) {
  yield put({ type: REQUEST_CHANGE_TRACKED_IGNS_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: changeTrackBy,
      variables: { 
        game: action.payload.game, 
        tournamentFilterId: action.payload.tournamentFilterId,
        tournamentId: action.payload.tournamentId,
        trackBy: action.payload.trackedIGNs,
      },
    })).data.changeTrackBy;

    yield put({ type: REQUEST_CHANGE_TRACKED_IGNS_SUCCESS, payload: { newPhases: result.filter(x => x), tournamentFilterId: action.payload.tournamentFilterId }});
    yield put(displaySuccessNotification(SAVE_SUCCESS));
  } catch (err) {
    yield put({ type: REQUEST_CHANGE_TRACKED_IGNS_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to subscribe to tournament matches
 * 
 * @param {*} action 
 */
function* requestSubscriptionToChangeTrackedIGNs(action) {
  yield put({ type: REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    
    const result = (yield call([client, 'subscribe'], {
      query: subscribeToUsernameCheck,
      variables: { shard: action.payload.shard },
    }));
    
    const subscription = result.subscribe({
      next: (result) => action.payload.callback(result.data.subscribeToUsernameCheck),
      error: () => {},
    });
    
    yield put({ type: REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_SUCCESS, payload: subscription });
  } catch (err) {
    yield put({ type: REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS_ERROR });
  }
}

/**
 * Makes a request to server to add match to the tournament
 * 
 * @param {*} action 
 */
function* requestAddTournamentMatch(action) {
  yield put({ type: REQUEST_ADD_TOURNAMENT_MATCH_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    yield call([client, 'mutate'], {
      mutation: addTournamentMatch,
      variables: action.payload,
    });

    yield put({ type: REQUEST_ADD_TOURNAMENT_MATCH_SUCCESS });
  } catch (err) {
    yield put({ type: REQUEST_ADD_TOURNAMENT_MATCH_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to edit tournament match
 * 
 * @param {*} action 
 */
function* requestEditTournamentMatch(action) {
  yield put({ type: REQUEST_EDIT_TOURNAMENT_MATCH_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: editTournamentMatch,
      variables: action.payload,
    })).data.editTournamentMatch;

    yield put({ type: REQUEST_EDIT_TOURNAMENT_MATCH_SUCCESS, payload: result });
  } catch (err) {
    yield put({ type: REQUEST_EDIT_TOURNAMENT_MATCH_ERROR });
    yield put(displayErrorNotification(SAVING_ERROR));
  }
}

/**
 * Makes a request to server to subscribe to tournament matches
 * 
 * @param {*} action 
 */
function* requestSubscriptionToTournamentMatch(action) {
  yield put({ type: REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    
    const result = (yield call([client, 'subscribe'], {
      query: subscribeToTournamentMatch,
      variables: { shard: action.payload.shard },
    }));
    
    const subscription = result.subscribe({
      next: (result) => action.payload.callback(result.data.subscribeToTournamentMatch),
      error: () => {},
    });
    
    yield put({ type: REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_SUCCESS, payload: subscription });
  } catch (err) {
    yield put({ type: REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH_ERROR });
  }
}

/**
 * Makes a request to server to get the tournament leaderboard
 * 
 * @param {*} action 
 */
function* requestTournamentLeaderboard(action) {
  yield put({ type: REQUEST_TOURNAMENT_LEADERBOARD_PENDING });
  try {
    const token = yield select(state => state.auth.token);
    const result = (yield publicQuery(productionLeaderboard, {
      game: action.payload.game,
      tournament: action.payload.shardInfo,
      token: token,
    })).data.productionLeaderboard.leaderboard;
    
    yield put({ type: REQUEST_TOURNAMENT_LEADERBOARD_SUCCESS, payload: result });
  } catch (err) {
    yield put({ type: REQUEST_TOURNAMENT_LEADERBOARD_ERROR });
  }
}

/**
 * Makes a request to server to get the tournament leaderboard
 * 
 * @param {*} action 
 */
function* requestAddTournamentTeamSlots(action) {
  yield put({ type: REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: addTournamentTeamSlots,
      variables: action.payload,
    })).data.addTournamentTeamSlots;
    
    yield put({ type: REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_SUCCESS, payload: { teams: result, tournamentFilterId: action.payload.tournamentFilterId }});
    yield put(displaySuccessNotification(SAVE_SUCCESS));
  } catch (err) {
    yield put({ type: REQUEST_ADD_TOURNAMENT_TEAM_SLOTS_ERROR });
    yield put(displayErrorNotification(err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

/**
 * Makes a request to server to add prize pool definitions
 * 
 * @param {*} action 
 */
function* requestAddTournamentPrizePool(action) {
  yield put({ type: REQUEST_ADD_TOURNAMENT_PRIZE_POOL_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    action.payload.tournamentPrizePoolDistributions.forEach(p => p.prize = parseInt(p.prize));

    const result = (yield call([client, 'mutate'], {
      mutation: addTournamentPrizePool,
      variables: action.payload,
    })).data.addTournamentPrizePool;
    
    yield put({ type: REQUEST_ADD_TOURNAMENT_PRIZE_POOL_SUCCESS, payload: { prizePoolDefinitions: result, tournamentFilterId: action.payload.tournamentFilterId }});
    yield put(displaySuccessNotification(SAVE_SUCCESS));
  } catch (err) {
    yield put({ type: REQUEST_ADD_TOURNAMENT_PRIZE_POOL_ERROR });
    yield put(displayErrorNotification(err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

/**
 * Makes a request to server to add prize pool definitions
 * 
 * @param {*} action 
 */
function* requestRemoveTournamentPrizePool(action) {
  yield put({ type: REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_PENDING });
  try {
    const client = yield select(state => state.apollo.client);
    action.payload.tournamentPrizePoolDistributions.prize = parseInt(action.payload.tournamentPrizePoolDistributions.prize);

    yield call([client, 'mutate'], {
      mutation: removeTournamentPrizePool,
      variables: action.payload,
    });
    
    yield put({ type: REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_SUCCESS });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
    if (action.callback) {
      action.callback();
    }
  } catch (err) {
    yield put({ type: REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL_ERROR });
    yield put(displayErrorNotification(err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

/**
 * Makes a request to server to add award
 * 
 * @param {*} action 
 */
function* requestSaveTournamentAward(action) {
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: action.payload.award.id ? editTournamentAward : createTournamentAward,
      variables: {
        game: action.payload.game,
        tournamentAward: {
          id: action.payload.award.id,
          title: action.payload.award.title,
          tournamentId: action.payload.award.tournamentId,
          description: action.payload.award.description,
          type: action.payload.award.type,
        },
      },
    })).data[action.payload.award.id ? 'editTournamentAward' : 'createTournamentAward'];
    
    yield put({ type: REQUEST_SAVE_TOURNAMENT_AWARD_SUCCESS, payload: {award: result, index: action.payload.index} });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
    if (action.callback) {
      action.callback();
    }
  } catch (err) {
    yield put(displayErrorNotification(err.graphQLErrors && err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

/**
 * Makes a request to server to add award column
 * 
 * @param {*} action 
 */
function* requestSaveTournamentAwardRow(action) {
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'mutate'], {
      mutation: action.payload.row.id ? editTournamentAwardRow : createTournamentAwardRow,
      variables: {
        game: action.payload.game,
        tournamentAwardRow: {
          ...action.payload.row,
          id: action.payload.row.id || null,
          teamId: action.payload.row.teamId || null,
          playerId: action.payload.row.playerId || null,
        },
      },
    })).data[action.payload.row.id ? 'editTournamentAwardRow' : 'createTournamentAwardRow'];
    
    yield put({ type: REQUEST_SAVE_TOURNAMENT_AWARD_ROW_SUCCESS, payload: result });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
    if (action.callback) {
      action.callback();
    }
  } catch (err) {
    yield put(displayErrorNotification(err.graphQLErrors && err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

/**
 * Makes a request to server to remove award
 * 
 * @param {*} action 
 */
function* requestDeleteTournamentAward(action) {
  try {
    const client = yield select(state => state.apollo.client);

    yield call([client, 'mutate'], {
      mutation: deleteTournamentAward,
      variables: {
        game: action.payload.game,
        tournamentAward: {
          id: action.payload.award.id,
          type: action.payload.award.type,
          title: action.payload.award.title,
          tournamentId: action.payload.award.tournamentId,
        },
      },
    });
    
    yield put({ type: REQUEST_DELETE_TOURNAMENT_AWARD_SUCCESS, payload: action.payload.award });
    yield put(displaySuccessNotification(SAVE_SUCCESS));
    if (action.callback) {
      action.callback();
    }
  } catch (err) {
    yield put(displayErrorNotification(err.graphQLErrors && err.graphQLErrors.length ? err.graphQLErrors[0].message : SAVING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_CREATE_TOURNAMENT, requestCreateTournament),
    takeLatest(REQUEST_EDIT_TOURNAMENT, requestEditTournament),
    takeLatest(REQUEST_TOURNAMENT, requestTournament),
    takeLatest(REQUEST_CREATE_TOURNAMENT_FILTER, requestCreateTournamentFilter),
    takeLatest(REQUEST_EDIT_TOURNAMENT_FILTER, requestEditTournamentFilter),
    takeLatest(REQUEST_DELETE_TOURNAMENT_FILTER, requestDeleteTournamentFilter),
    takeLatest(REQUEST_CREATE_OR_EDIT_TOURNAMENT_PHASE, requestCreateOrEditTournamentPhase),
    takeLatest(REQUEST_DELETE_TOURNAMENT_PHASE, requestDeleteTournamentPhase),
    takeLatest(REQUEST_CHANGE_TRACKED_IGNS, requestChangeTrackedIGNs),
    takeLatest(REQUEST_SUBSCRIPTION_TO_CHANGE_TRACKED_IGNS, requestSubscriptionToChangeTrackedIGNs),
    takeLatest(REQUEST_ADD_TOURNAMENT_MATCH, requestAddTournamentMatch),
    takeLatest(REQUEST_EDIT_TOURNAMENT_MATCH, requestEditTournamentMatch),
    takeLatest(REQUEST_SUBSCRIPTION_TO_TOURNAMENT_MATCH, requestSubscriptionToTournamentMatch),
    takeLatest(REQUEST_TOURNAMENT_LEADERBOARD, requestTournamentLeaderboard),
    takeLatest(REQUEST_ADD_TOURNAMENT_TEAM_SLOTS, requestAddTournamentTeamSlots),
    takeLatest(REQUEST_ADD_TOURNAMENT_PRIZE_POOL, requestAddTournamentPrizePool),
    takeLatest(REQUEST_REMOVE_TOURNAMENT_PRIZE_POOL, requestRemoveTournamentPrizePool),
    takeLatest(REQUEST_SAVE_TOURNAMENT_AWARD, requestSaveTournamentAward),
    takeLatest(REQUEST_SAVE_TOURNAMENT_AWARD_ROW, requestSaveTournamentAwardRow),
    takeLatest(REQUEST_DELETE_TOURNAMENT_AWARD, requestDeleteTournamentAward),
  ]);
}