import { 
  REQUEST_OVERLAY_DATA_SUCCESS,
  SET_RECEIVED_OVERLAY_DATA,
} from './types';
import {
  PUBG_GAME,
  CSGO_GAME,
  VALORANT_GAME,
} from '../../util/Games';
import { listToCamelCase } from '../../util/ObjectUtils';

const INITIAL_STATE = {
  loaded: false,
  opts: {},
  show: false,
  [PUBG_GAME]: null,
  [CSGO_GAME]: null,
  [VALORANT_GAME]: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On stats received
    case REQUEST_OVERLAY_DATA_SUCCESS:
    case SET_RECEIVED_OVERLAY_DATA:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        opts: parseOpts(action.payload.opts),
      };

    default:
      return state;
  }
}

export default reducer;

/**
 * Parses opts to JSON and converts all stats to camelcase
 * 
 * @param {*} opts 
 */
function parseOpts(opts) {
  let parsedOpts = {};
  if (opts) {
    parsedOpts = JSON.parse(opts);
    parsedOpts.stats = listToCamelCase(parsedOpts.stats || [], ['value']);
  }
  return parsedOpts;
}