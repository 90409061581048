import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';
import ControllerBase from '../../base/controller/ControllerBase';
import MapSelect from '../../base/controller/inputs/MapSelect';
import RuleSelect from '../../base/controller/inputs/RuleSelect';
import TeamSelectWithCopy from '../../base/controller/inputs/TeamSelectWithCopy';
import { Button } from '../../../../../components/StyledCoreUI';
import { requestChangeComponent, requestChangeComponentWithValidation } from '../../../../../redux/dashboard/actions';
import { displaySuccessNotification } from '../../../../../redux/notification/actions';
import { COMPONENT_STATE_LEFT_TEAM, COMPONENT_STATE_RIGHT_TEAM, MAP_VETO_VETO_STATUS } from '../../../constants';
import { VALORANT_GAME } from '../../../../../util/Games';

class MapVetoController extends Component {

  /**
   * Generated voting URL for teams
   * 
   * @param {*} token 
   */
  generateTeamLink = (token, teamToken) => {
    if (!token || !teamToken) return null;
    return `${window.location.protocol}//${window.location.host}/map-veto/${VALORANT_GAME}/${token}/${teamToken}`;
  }

  render() {
    const { component, requestChangeComponent, requestChangeComponentWithValidation } = this.props;

    return (
      <ControllerBase
        game={VALORANT_GAME}
        {...this.props}
      >
        <Row>
          <Col xs="12">
            <MapSelect component={component} />
          </Col>
          <Col xs="12">
            <RuleSelect component={component} />
          </Col>
          <Col xs="12">
            <TeamSelectWithCopy
              component={component}
              label={'Left Team'}
              stateAttribute={COMPONENT_STATE_LEFT_TEAM}
              copyText={this.generateTeamLink(component.token, component.leftToken)}
            />
          </Col>
          <Col xs="12">
            <TeamSelectWithCopy
              component={component}
              label={'Right Team'}
              stateAttribute={COMPONENT_STATE_RIGHT_TEAM}
              copyText={this.generateTeamLink(component.token, component.rightToken)}
            />
          </Col>
          <Col xs="6" className="text-center">
            <FormGroup>
              <p>Generate links for team captains</p>
              <Button
                color="primary"
                onClick={() => requestChangeComponentWithValidation(component, { [MAP_VETO_VETO_STATUS]: 'generate-url' })}
              >Generate Links</Button>
            </FormGroup>
          </Col>
          <Col xs="6" className="text-center">
            <FormGroup>
              <p>Reset map veto panels</p>
              <Button
                color="primary"
                onClick={() => requestChangeComponent(component, { [MAP_VETO_VETO_STATUS]: 'reset', state: {} })}
              >Reset</Button>
            </FormGroup>
          </Col>
        </Row>
      </ControllerBase>
    );
  }
}

const mapDispatchToProps = {
  requestChangeComponent,
  requestChangeComponentWithValidation,
  displaySuccessNotification,
};

export default connect(null, mapDispatchToProps)(MapVetoController);
