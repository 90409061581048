import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const SlideInAnimation = ({ children, show, direction, delay }) => {
  return (
    <Animation show={show} direction={direction} delay={delay}>
      {children}
    </Animation>
  )
}

SlideInAnimation.defaultProps = {
  delay: 0,
  direction: 'left',
}

SlideInAnimation.propTypes = {
  show: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  delay: PropTypes.number,
  children: PropTypes.object,
}

const Animation = styled.span`
  transform: ${({ direction, show }) => {
    if (!show) {
      return null
    }
    return `translateX(${direction === 'left' ? '-200vw' : '200vw'})`
  }};

  animation-name: animation-${({ show, direction }) => {
      return show ? `in-${direction}` : `out-${direction}`
    }};
  animation-delay: ${({ delay }) => delay}s;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.96, 0.05, 0.14, 0.92);

  @keyframes animation-in-left {
    0% {
      transform: translateX(-200vw);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes animation-out-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200vw);
    }
  }
  @keyframes animation-in-right {
    0% {
      transform: translateX(200vw);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes animation-out-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(200vw);
    }
  }
`
