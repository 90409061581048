import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardFooter from './LeaderboardFooter';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardSubHeader from './LeaderboardSubHeader';

const LeaderboardTable = styled.table`
  border-collapse:separate; 
  border-spacing: 0 2px;
  width: ${ props => props.overall ? 220 : 170 }px;

  text-align: center;
  vertical-align: middle;

  font-family: agency_fb;
  font-size: 1.4em;

  &.bc-show {
    margin-left: -230px;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -230px;}
      to {margin-left: 40px;}
    }
  }

  &.bc-hide {
    margin-left: 10;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 10px;}
      to {margin-left: -230px;}
    }
  }
`;

class PsjLiveTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;
    const top16 = leaderboard.slice(0, 16).sort((a, b) => {
      if (a.wins !== b.wins) {
        return a.wins > b.wins ? -1 : 1;
      } else if (a.totalKills !== b.totalKills) {
        return a.totalKills > b.totalKills ? -1 : 1;
      }
      return 0;
    })

    return (
      loaded ?
      <LeaderboardTable
        className={show ? 'bc-show' : 'bc-hide'}
        overall={opts.overall}
      >
        <tbody>
          <LeaderboardHeader/>
          <LeaderboardSubHeader overall={opts.overall}/>
          {
            top16.map((t, i) => {
              return (
                <LeaderboardBody
                  team={t}
                  rank={i+1}
                  overall={opts.overall}
                />
              );
            })
          }
        </tbody>
        <LeaderboardFooter />
      </LeaderboardTable>
      :
      null
    );
  }
}

export default PsjLiveTeamLeaderboard;