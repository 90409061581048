import lodash from 'lodash';

/**
 * Converts object's values to camelcase
 * 
 * @param {*} object 
 */
export const objectToCamelCase = (object) => {
  return lodash.mapValues(object, (v, k) => lodash.camelCase(v));
}

/**
 * Converts list of objects' values to camelcase
 * 
 * @param {*} list 
 * @param {*} exclude  
 */
export const listToCamelCase = (list, exclude) => {
  return list.map(object => lodash.mapValues(object, (v, k) => exclude.includes(k) ? v : lodash.camelCase(v)));
}