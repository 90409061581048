import React, { Component } from 'react';
import {
  Col,
  Form,
  FormGroup, 
  Label,
  ModalBody, 
  ModalFooter, 
  ModalHeader,
  Row
} from 'reactstrap';
import {
  Button,
  Input, 
  Modal
} from '../../StyledCoreUI';

class InputModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
  }

  /**
   * Handles form submit
   * It closes modal and continues the required action
   */
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.props.onConfirm(this.state.value);
    this.setState({ value: '' });
  }

  /**
   * Handles any form change
   */
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { title, value, placeholder, label = this.props.placeholder, onClose } = this.props;

    return (
      <Modal isOpen={this.props.open} toggle={onClose} className={this.props.className}>
        <ModalHeader toggle={onClose}>{ title }</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <Form onSubmit={this.handleFormSubmit}>
                <FormGroup row>
                  <Col xs="12">
                    <Label>{ label }</Label>
                  </Col>
                  <Col xs="12">
                    <Input 
                      type="text" 
                      name="value" 
                      value={this.state.value ? this.state.value : value} 
                      onChange={this.handleChange}
                      placeholder={placeholder}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => {
            this.handleFormSubmit(e);
          }}>OK</Button>{' '}
          <Button color="light" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default InputModal;