import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardFooter from './LeaderboardFooter';

const width = 1920;

const LeaderboardWrapper = styled.div`
  width: ${width}px;
  overflow: hidden;
  font-family: 'CenturyGothicBold';
  background: url('/assets/img/being/live_team_leaderboard_background.png');
  background-repeat: no-repeat;
  background-position: 60px 25px;
  
  .font-sony {
    font-family: 'SonySketchEF';
    font-size: 45px;
    line-height: 45px;
  }

  &.leaderboard-show {
    display: block;
    height: 0;
    animation: fadeIn 1s ease 0s forwards, slideDown 1.5s ease 0.6s forwards;
  
    @keyframes slideDown {
      from {height: 0;}
      to {height: calc(100vh);}
    }
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {}
    }
  }

  &.leaderboard-hide {
    height: calc(100vh);
    animation: slideUp 1s ease 0s forwards, fadeOut 0.3s ease 0.8s forwards;
  
    @keyframes slideUp {
      from {height: calc(100vh);}
      to {height: 0;}
    }
  
    @keyframes fadeOut {
      from {}
      to {opacity: 0;}
    }
  }

  .row {
    margin: 0;
  }
`;

class BeingTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, opts, show } = this.props;

    if (!loaded) return null;

    return (
      <>
        <LeaderboardHeader
          rowWidth={width}
          show={show}
          groupName={opts.groupName}
          matchName={opts.matchName}
        />
        <LeaderboardWrapper
          className={ show ? 'leaderboard-show' : 'leaderboard-hide leaderboard-fade-out' }
        >
          <LeaderboardBody
            leaderboard={leaderboard}
          />
          <LeaderboardFooter
            rowWidth={width}
            show={show}
          />
        </LeaderboardWrapper>
      </>
    );
  }
}

export default BeingTeamLeaderboard;
