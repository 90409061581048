import React, { Component } from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const width = 200;
const height = 50;
const leftSideWidth = 15;

const LastWinnersWrapper = styled.table`
  width: ${width}px;
  height: ${height}px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  font-size: 2em;
  margin: 5px;
  color: ${props => props.colors ? (props.colors.text || styles.secondary) : styles.secondary};

  &.bc-show tr {
    animation: fadeIn 0.6s ease 0s forwards;
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.bc-hide tr {
    animation: fadeOut 0.6s ease 0s forwards;
  
    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }

  tr td:first-child {
    background: ${props => props.colors ? (props.colors.primary || styles.primary) : styles.primaryLight};
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    width: ${leftSideWidth}px;
  }

  tr td:last-child {
    background: ${props => props.colors ? (props.colors.secondary || styles.tertiary) : styles.tertiary};
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
`;

class TwireLastWinners extends Component {
  render() {
    const { loaded, currentMatch, show, shard, overlayColor } = this.props;

    return (
      loaded ?
      <LastWinnersWrapper
        className={show ? 'bc-show' : 'bc-hide'}
        shard={shard.split('-')[0]}
        colors={overlayColor}
      >
        <tbody>
          <tr>
            <td></td>
            <td>Match { currentMatch }</td>
          </tr>
        </tbody>
      </LastWinnersWrapper>
      :
      null
    );
  }
}

export default TwireLastWinners;
