import { 
  SET_AUTH_USER,
  UNSET_AUTH_USER,
  REQUEST_LOGIN,
  REQUEST_LOGGED_IN_USER,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN,
  REQUEST_USER_SUBSCRIPTION,
} from './types';

/**
 * Action for setting authenticated user
 */
export const setAuthUser = (user) => ({
    type: SET_AUTH_USER,
    payload: {
      username: user.username,
      sub: user.attributes.sub,
      userGroups: user.signInUserSession.idToken.payload['cognito:groups'],
    }
});

/**
 * Action for setting authenticated user
 */
export const unsetAuthUser = () => ({
    type: UNSET_AUTH_USER,
});

/**
 * Action for login request
 * 
 * @param {*} username user's username
 * @param {*} password user's password
 */
export const requestLogin = (username, password) => ({
  type: REQUEST_LOGIN,
  payload: {
    username: username,
    password: password,
  }
});

/**
 * Requests logged in user if exists
 */
export const requestLoggedInUser = () => ({
  type: REQUEST_LOGGED_IN_USER,
});

/**
 * Requests tournament organizer token
 */
export const requestTournamentOrganizerToken = () => ({
  type: REQUEST_TOURNAMENT_ORGANIZER_TOKEN,
});

/**
 * Action for getting user subscription
 */
export const requestUserSubscription = () => ({
  type: REQUEST_USER_SUBSCRIPTION,
});