import { 
  REQUEST_HEAD_TO_HEAD_PLAYERS_SUCCESS,
  REQUEST_HEAD_TO_HEAD_PLAYERS_PENDING,
  REQUEST_HEAD_TO_HEAD_PLAYERS_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  leftPlayer: null,
  rightPlayer: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request head to head player pending or error
    case REQUEST_HEAD_TO_HEAD_PLAYERS_PENDING:
    case REQUEST_HEAD_TO_HEAD_PLAYERS_ERROR:
      return INITIAL_STATE;

    // On request head to head player success
    case REQUEST_HEAD_TO_HEAD_PLAYERS_SUCCESS:
      return {
        loaded: true,
        leftPlayer: action.payload.left,
        rightPlayer: action.payload.right,
      };

    default:
      return state;
  }
}

export default reducer;