import React from 'react';
import styled from 'styled-components';
import { addMissingPlayers, getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.table`
  width: 420px;
  position: fixed;
  top: ${props => props.index * 135}px;
  text-align: center;
  vertical-align: middle;
  height: 135px;
  border: 1px solid #808080;
  background: rgba(0, 0, 0, 0.93);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    z-index: -1;
    box-shadow: inset 0 8px 0 5px rgba(0, 0, 0, 0.5);
  }

  &.bc-show {
    left: ${props => props.side === 'left' ? '-420' : '1920'}px;
    animation: slideIn${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideIn${props => props.side} {
      from {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
      to {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
    }
  }

  &.bc-hide {
    left: ${props => props.side === 'left' ? '0' : '1500'}px;
    animation: slideOut${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideOut${props => props.side} {
      from {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
      to {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
    }
  }
  
  td.team-logo {
    width: 140px;

    img {
      height: 100px;
    }
  }

  tr.team-alive {
    font-family: 'BarlowCondensed-Light';
    font-size: 20px;
    line-height: 26px;
  
    td.player-name {
      text-align: ${props => props.side === 'left' ? 'right' : 'left'};
      padding: 0 8px;
      width: 265px;
      color: #fff;

      &.player-death {
        opacity: 0.5;
      }
    }
  }
`;

const PlayerIndicator = styled.td`
  div {
    min-width: 4px;
    max-width: 4px;
    height: 19px;
    ${props => props.color ? `background: ${props.color};` : ''}
  }
`;

const EmptyTd = styled.td`
  min-widht: 11px;
`;

function Team(props) {
  const { team, index, side, show } = props;
  addMissingPlayers(team.players);

  return (
    <Wrapper
      className={show ? 'bc-show' : 'bc-hide'}
      side={side}
      index={index}
    >
      {
        team.players.map((p, i) => {
          return (
            <tr className="team-alive">
              {
                i === 0 && side === 'left' ?
                <td
                  className="team-logo"
                  rowSpan="4"
                >
                  <img
                    src={getTeamLogo(`${team.name}`)}
                    onError={e => e.target.src = getTeamLogo('default')}
                    alt="team-logo"
                  />
                </td>
                :
                null
              }
              {
                side === 'right' ?
                <>
                  <EmptyTd></EmptyTd>
                  <PlayerIndicator
                    className="player-indicator"
                    color={p.status === 'alive' ? '#eff923' : p.status === 'knocked' ? '#00ffae' : null}
                  >
                    <div></div>
                  </PlayerIndicator>
                </>
                :
                null
              }
              <td
                className={ `player-name player-${p.status}` }
              ><span>{ p.ign }</span></td>
              {
                side === 'left' ?
                <>
                  <PlayerIndicator
                    className="player-indicator"
                    color={p.status === 'alive' ? '#eff923' : p.status === 'knocked' ? '#00ffae' : null}
                  >
                    <div></div>
                  </PlayerIndicator>
                  <EmptyTd></EmptyTd>
                </>
                :
                null
              }
              {
                i === 0 && side === 'right' ?
                <td
                  className="team-logo"
                  rowSpan="4"
                >
                  <img
                    src={getTeamLogo(`${team.name}`)}
                    onError={e => e.target.src = getTeamLogo('default')}
                    alt="team-logo"
                  />
                </td>
                :
                null
              }
            </tr>
          );
        })
      }
    </Wrapper>
  );
}

export default Team;