import React from 'react';
import styled from 'styled-components';
import { addMissingPlayers, getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.table`
  width: 420px;
  position: fixed;
  top: ${props => props.index * 135}px;
  text-align: center;
  vertical-align: middle;
  height: 135px;
  border-bottom: 1px solid black;

  &.bc-show {
    left: ${props => props.side === 'left' ? '-420' : '1920'}px;
    animation: slideIn${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideIn${props => props.side} {
      from {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
      to {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
    }
  }

  &.bc-hide {
    left: ${props => props.side === 'left' ? '0' : '1500'}px;
    animation: slideOut${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideOut${props => props.side} {
      from {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
      to {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
    }
  }
  
  td.team-logo {
    border-bottom: 1px solid white;
    width: 140px;
    background: #000;

    img {
      height: 100px;
    }
  }

  tr.team-alive {
    font-family: 'Calps Sans';
    font-weight: 400;
    font-size: 26px;
    line-height: 26px;
  
    td.player-name {
      text-align: ${props => props.side};
      padding: 0 15px;
      width: 280px;
      background: #ffff08;
      color: #000;

      &.player-death {
        background: #e4e4e4;

        span {
          opacity: 0.3;
        }
      }
    }
  }

  tr.team-death {
    font-family: 'ESL Legend';
    font-weight: 600;
    font-size: 60px;

    td.team-eliminated {
      width: 280px;
      color: #000;
      background: #e4e4e4;
    }
  }
`;

function Team(props) {
  const { team, index, side, show } = props;
  const teamDeath = team.players.every(p => p.status === 'death');
  addMissingPlayers(team.players);

  return (
    <Wrapper
      className={show ? 'bc-show' : 'bc-hide'}
      side={side}
      index={index}
    >
      {
        teamDeath ?
        <tr className="team-death">
          {
            side === 'left' ?
            <td className="team-logo">
              <img
                src={getTeamLogo(`${team.name}`)}
                onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                alt="team-logo"
              />
            </td>
            :
            null
          }
          <td className="team-eliminated">ELIMINATED</td>
          {
            side === 'right' ?
            <td className="team-logo">
              <img
                src={getTeamLogo(`${team.name}`)}
                onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                alt="team-logo"
              />
            </td>
            :
            null
          }
        </tr>
        :
        team.players.map((p, i) => {
          return (
            <tr className="team-alive">
              {
                i === 0 && side === 'left' ?
                <td
                  className="team-logo"
                  rowSpan="4"
                >
                  <img
                    src={getTeamLogo(`${team.name}`)}
                    onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                    alt="team-logo"
                  />
                </td>
                :
                null
              }
              <td
                className={ `player-name player-${p.status}` }
              ><span>{ p.ign }</span></td>
              {
                i === 0 && side === 'right' ?
                <td
                  className="team-logo"
                  rowSpan="4"
                >
                  <img
                    src={getTeamLogo(`${team.name}`)}
                    onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                    alt="team-logo"
                  />
                </td>
                :
                null
              }
            </tr>
          );
        })
      }
    </Wrapper>
  );
}

export default Team;