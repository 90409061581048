import React, { Component } from 'react';
import TodaysWinnersHeader from './TodaysWinnersHeader';
import TodaysWinnersBody from './TodaysWinnersBody';
import TodaysWinnersFooter from './TodaysWinnersFooter';

const width = 1600;

class TwireTodaysWinners extends Component {
  render() {
    const { loaded, currentMatchNumber, matches, mapRotation, show, shard } = this.props;
    const numOfMatches = (mapRotation || '').length;

    return (
      loaded ?
      <div>
        <TodaysWinnersHeader 
          shard={shard}
          rowWidth={width}
          show={show}
        />
        {
          Array.from(Array(numOfMatches).keys()).map(i => {
            return (
              <TodaysWinnersBody
                colWidth={width / numOfMatches}
                index={i}
                currentMatchNumber={currentMatchNumber}
                matches={matches}
                numOfMatches={numOfMatches}
                show={show}
                map={mapRotation.substring(i, i + 1)}
              />
            )
          })
        }
        <TodaysWinnersFooter
          rowWidth={width}
          show={show}
        />
      </div>
      :
      null
    );
  }
}

export default TwireTodaysWinners;
