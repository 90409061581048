import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Input } from 'reactstrap';

export default styled(Input)`
  background: ${props => props.color ? props.color : styles.tertiary};
  border-color: ${props => props.warning ? styles.primary : (props.color ? props.color : styles.tertiary)};
  color: ${styles.secondary};

  &:focus {
    background: ${props => props.color ? props.color : styles.tertiary};
    color: ${styles.secondary};
  }

  &[disabled] {
    background: ${props => props.color ? props.color : styles.tertiary};
    color: ${styles.info};
    cursor: not-allowed;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`;