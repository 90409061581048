import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardPlayer from './LeaderboardPlayer';
import LeaderboardFooter from './LeaderboardFooter';

const playerWidth = 300;
const playerMargin = 0;

const LeaderboardWrapper = styled.div`
  margin: 15px 30px;
  width: ${props => props.backgroundWidth}px;
  font-family: 'muli';
`;

class TwirePlayerLeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.numOfPlayers = 5;
  }

  render() {
    const { loaded, leaderboard, show, shard, opts, exportAsImage } = this.props;
    const stat = opts.stats ? opts.stats[0] : {};
    const topPlayers = leaderboard.slice(0, this.numOfPlayers);
    const width = playerWidth * topPlayers.length + playerMargin * (topPlayers.length - 1);

    return (
      loaded && topPlayers.length ?
      <LeaderboardWrapper 
        backgroundWidth={width}
      >
        <LeaderboardHeader
          shard={shard}
          backgroundWidth={width}
          title={stat.value}
          show={show}
          exportAsImage={exportAsImage}
        />
        {
          topPlayers.map((p, i) => {
            return (
              <LeaderboardPlayer
                shard={shard}
                rank={i + 1}
                player={p}
                stat={stat.key}
                backgroundWidth={playerWidth}
                margin={i !== topPlayers.length - 1}
                numOfPlayers={this.numOfPlayers}
                show={show}
                exportAsImage={exportAsImage}
              />
            );
          })
        }
        <LeaderboardFooter
          backgroundWidth={width}
          show={show}
          exportAsImage={exportAsImage}
        />
      </LeaderboardWrapper>
      :
      null
    );
  }
}

export default TwirePlayerLeaderBoard;
