import React, { Component } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import styles from '../../../scss/style.scss';

const WrappedToastContainer = styled.div`
  .Toastify__toast-container {}
  .Toastify__toast {
    background: ${styles.primary};
  }
  .Toastify__toast--error {
    background: ${styles.danger};
  }
  .Toastify__toast--warning {
    background: ${styles.warning};
  }
  .Toastify__toast--success {
    background: ${styles.success};
  }
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`;

class Toast extends Component {
  render() {
    return (
      <WrappedToastContainer>
        <ToastContainer
            position="bottom-right"
            hideProgressBar={false}
            draggable
        />
      </WrappedToastContainer>
    );
  }
}

export default Toast;