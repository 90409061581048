import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { AppSidebarNav } from '@coreui/react';

export default styled(AppSidebarNav)`
  .nav {
    background: ${styles.tertiaryDark};
  }

  .nav-link.active {
    background: ${styles.background};
  }

  .nav-link:hover {
    background: ${styles.primary};
  }

  .nav-dropdown .nav-dropdown-items .nav-link {
    color: ${styles.primary};
  }

  .nav-dropdown .nav-dropdown-items .nav-link:hover {
    color: ${styles.secondary};
  }
`;