import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { Nav } from 'reactstrap';

export default styled(Nav)`
  .nav-item {
    color: ${styles.secondary};
  }

  .nav-link {
    background: ${styles.tertiaryDark};
    border-color: ${styles.tertiary};

    &:hover {
      border-color: ${styles.tertiaryDark};
      color: ${styles.primary} !important;
      border-top: 2px solid ${styles.primary};
    }

    &.active {
      background: ${styles.primary};
      border-color: ${styles.tertiaryDark};
      color: ${styles.secondary} !important;
      border-top: 2px solid ${styles.tertiaryDark};

      &:hover {
        color: ${styles.secondary} !important;
      }
    }
    border-top: 2px solid ${styles.primary};
  }

  .tab-content {
    background: ${styles.tertiaryDark};
  }
`;