import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  color: ${ props => props.colors && props.colors.rankText ? props.colors.rankText : '#000'};
  
  td {
    background: ${ props => props.colors && props.colors.primary ? props.colors.primary : '#FFF'};
    text-transform: uppercase;
    padding: 1px;
  }
`;

function LeaderboardHeader(props) {

  return (
    <Wrapper
      colors={props.overlayColor}
    >
      <td colSpan="5"><strong>Leaderboard</strong></td>
    </Wrapper>
  );
}

export default LeaderboardHeader;