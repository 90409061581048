import { 
  SET_AUTH_USER,
  UNSET_AUTH_USER,
  REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS,
  REQUEST_USER_SUBSCRIPTION_SUCCESS,
} from './types';
import { isTournamentOrganizer } from '../../util/AuthUtil';

const INITIAL_STATE = {
  loaded: false,
  username: null,
  sub: null,
  userGroups: [],
  token: null,
  isTournamentOrganizer: false,
  premium: null,
  trial: null,
  premiumValidFrom: null,
  premiumValidTo: null,
  planId: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On set authenticated user
    case SET_AUTH_USER:
      return {
        ...state,
        loaded: true,
        username: action.payload.username,
        sub: action.payload.sub,
        userGroups: action.payload.userGroups,
        isTournamentOrganizer: isTournamentOrganizer(action.payload),
      };

    // On remove authenticated user
    case UNSET_AUTH_USER:
      return {
        loaded: true,
        username: null,
        sub: null,
        userGroups: [],
        token: null,
      };

    // On tournament organizer token success
    case REQUEST_TOURNAMENT_ORGANIZER_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };

    // On user subscription received
    case REQUEST_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        premium: action.payload.status,
        trial: action.payload.trial,
        premiumValidFrom: action.payload.validFrom,
        premiumValidTo: action.payload.validTo,
        planId: action.payload.planId,
      }

    default:
      return state;
  }
}

export default reducer;