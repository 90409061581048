import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
  &.component-show {
    animation: fadeIn 0.3s ease 0s forwards;
  
    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: fadeOut 0.3s ease ${props => (props.numberOfStats + 1) * 0.3}s forwards;
  
    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function HeadToHeadHeader(props) {
  const { show, numberOfStats } = props;

  return (
    <Wrapper
      className={ show ? 'component-show' : 'component-hide' }
      numberOfStats={numberOfStats}
    >
      <td>
        <h1>Team Head To Head</h1>
      </td>
    </Wrapper>
  );
}

export default HeadToHeadHeader;