import React, { Component } from 'react';
import styled from 'styled-components';
import Team from './Team';

const HeadToHeadWrapper = styled.table`
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
`;

class Pjc21TeamHeadToHead extends Component {

  render() {
    const { loaded, left, right, show } = this.props;

    if (!loaded || !left || !right) return null;

    return (
      <HeadToHeadWrapper
        className={ show ? 'bc-show' : 'bc-hide' }
      >
        <Team
          show={show}
          team={left}
          marginLeft={39}
          {...this.props}
        />
        <Team
          show={show}
          team={right}
          marginLeft={971}
          {...this.props}
        />
      </HeadToHeadWrapper>
    );
  }
}

export default Pjc21TeamHeadToHead;
