import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const TEAM_ROW_ANIMATION_DELAY = 0.1;
const TEAM_ROW_TEXT_ANIMATION_DELAY = 0.3;
const WIDTH = 1368;
const TEAM_ROW_WIDTH = 664;

const Wrapper = styled.div`
  font-family: 'ESL Legend';
  position: fixed;
  left: 472px;
  top: 60px;
  width: ${WIDTH}px;
  height: 859px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: ${TEAM_ROW_WIDTH}px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  div.header {
    width: ${WIDTH}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.header div {
    width: ${WIDTH}px;
    height: 140px;
    background-image: linear-gradient(135deg, transparent 2.3%, #1d1d1b 2.3%, #1d1d1b 100%);
    float: right;
    // Remove if subtitle is needed
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: 600;
      margin: 0;
      font-size: 80px;
      line-height: 85px;
    }
    p {
      font-family: 'Calps Sans';
      font-size: 40px;
      line-height: 45px;
    }
  }

  div.column-header {
    div.column-wrapper {
      font-weight: 600;
      color: #000; 
      font-size: 21px;
      line-height: 21px;
    }
  }

  div.team-row {
    div.column-wrapper {
      font-family: 'Calps Sans';
      font-size: 40px;
      line-height: 40px;
    }
  }

  div.column-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    float: right;

    p {
      margin: 0;
      padding: 0;
    }

    &.even {
      height: 75px;
      background: #1d1d1d;
    }

    &.odd {
      height: 75px;
      background: #323230;
    }

    div.arrow-column {
      width: 38px;

      img {
        margin-top: -5px;
      }
    }

    div.rank-column {
      width: 30px;

      &.single-match {
        width: 68px;
      }
    }

    div.logo-column {
      width: 65px;

      img {
        width: 45px;
      }
    }

    div.team-column {
      // width: 70%;
      width: 303px;
    }
  
    div.stat-column {
      // width: 15%;
      width: 114px;
    }
  }

  div.header-spacing {
    height: 67px;
  }

  div.column-header {
    height: 52px;
  }

  div.team-row {
    height: 75px;
  }
  
  &.bc-show {
    div.header div {
      width: 0;
      animation: slideInHeader 0.2s ease 0s forwards;
    
      @keyframes slideInHeader {
        from {width: 0px;}
        to {width: ${WIDTH}px;}
      }

      p, h1 {
        opacity: 0;
        animation: fadeIn 0.3s ease 0.2s forwards;
      }
    }
  }
  
  &.bc-hide {
    div.header div {
      width: ${WIDTH}px;
      animation: slideOut 0.2s ease 0.3s forwards;

      p, h1 {
        opacity: 0;
        animation: fadeOut 0.3s ease 0s forwards;
      }
    
      @keyframes slideOut {
        from {width: ${WIDTH}px;}
        to {width: 0px;}
      }
    }
  }
`;

const HeaderRow = styled.div`
  &.bc-show {
    .column-wrapper div p {
      margin-top: 52px;
      opacity: 0;
      animation: textFlyUp 0.3s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY}s forwards,
                fadeIn 0.01s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY}s forwards;
      
      @keyframes textFlyUp {
        from {margin-top: 52px;}
        to {margin-top: 0px;}
      }
    }
  }

  &.bc-hide {
    .column-wrapper div {
      animation: fadeOut 0.2s ease ${props => props.animationOutDelay}s forwards,
              textFlyOut 0.5s ease ${props => props.animationOutDelay}s forwards;
    }
  }
`;

const TeamRow = styled.div`
  &.bc-show {
    .column-wrapper {
      width: 0;
      animation: slideIn 0.3s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY}s forwards;
    
      @keyframes slideIn {
        from {width: 0px;}
        to {width: ${TEAM_ROW_WIDTH}px;}
      }

      div {
        opacity: 0;
        animation: fadeIn 0.5s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY + TEAM_ROW_TEXT_ANIMATION_DELAY}s forwards;
      
        @keyframes fadeIn {
          from {opacity: 0;}
          to {opacity: 1;}
        }

        &.text-fly-in p {
          margin-left: 100px;
          animation: textFlyIn 0.2s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY + TEAM_ROW_TEXT_ANIMATION_DELAY}s forwards;
        }

        &.text-fly-in.team-column p {
          margin-left: 200px;
          animation: textFlyIn 0.2s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY + TEAM_ROW_TEXT_ANIMATION_DELAY}s forwards;
        }
        
        @keyframes textFlyIn {
          from {margin-left: 100px;}
          to {margin-left: 0;}
        }
      }
    }
  }

  &.bc-hide {
    .column-wrapper {
      width: ${TEAM_ROW_WIDTH}px;

      animation: fadeOut 0.5s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY/4}s forwards,
              textFlyOut 0.5s ease ${props => props.animationDelay * TEAM_ROW_ANIMATION_DELAY/4}s forwards;
    
      @keyframes fadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
        
      @keyframes textFlyOut {
        from {margin-right: 0;}
        to {margin-right: 2000px;}
      }
    }

    div {
      opacity: 1;
    }
  }
`;

class EslLiveTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;
    const numberOfHalfTeams = leaderboard.length / 2;
    const firstHalfTeams = leaderboard.slice(0, numberOfHalfTeams);
    const secondHalfTeams = leaderboard.slice(numberOfHalfTeams, leaderboard.length);

    return (
      loaded ?
      <Wrapper className={show ? 'bc-show' : 'bc-hide'}>
        <div className="header text-center">
          <div>
            <h1>{ opts.overall ? 'Overall Standings' : 'Match Standings' }</h1>
            {/* <p>{ opts.overall ? opts.groupName : `Match 1-${ opts.numberOfMatches }` }</p> */}
          </div>
        </div>

        <div className="header-spacing"></div>
        <div className="header-spacing"></div>

        <HeaderRow
          className={`column-header ${show ? 'bc-show' : 'bc-hide'}`}
          animationDelay={numberOfHalfTeams/2}
          animationOutDelay={0.1}
        >
          <div className="column-wrapper">
            <div className="arrow-column"></div>
            <div className="rank-column"></div>
            <div className="logo-column"></div>
            <div className="team-column"><p>Team</p></div>
            <div className="stat-column text-center">{ opts.overall ? <p>Chicken<br/>Dinner</p> : null }</div>
            <div className="stat-column text-center"><p>Total<br/>Kills</p></div>
          </div>
        </HeaderRow>
        <HeaderRow
          className={`column-header ${show ? 'bc-show' : 'bc-hide'}`}
          animationDelay={numberOfHalfTeams}
          animationOutDelay={0.2}
        >
          <div className="column-wrapper">
            <div className="arrow-column"></div>
            <div className="rank-column"></div>
            <div className="logo-column"></div>
            <div className="team-column"><p>Team</p></div>
            <div className="stat-column text-center">{ opts.overall ? <p>Chicken<br/>Dinner</p> : null }</div>
            <div className="stat-column text-center"><p>Total<br/>Kills</p></div>
          </div>
        </HeaderRow>

        {
          firstHalfTeams.map((_, i) => {
            const parity = i % 2 === 0 ? 'even' : 'odd';
            const leftTeam = firstHalfTeams[i];
            const rightTeam = secondHalfTeams[i];
            return (
              <>
                <TeamRow
                  className={`team-row ${show ? 'bc-show' : 'bc-hide'}`}
                  animationDelay={i}
                >
                  <div className={`column-wrapper ${parity}`}>
                    {
                      opts.overall ?
                      <div className="arrow-column text-center">
                        <img
                          src={`/assets/img/esl/leaderboard-${leftTeam.previousPosition.toLowerCase()}.png`}
                          alt="previous position"
                        />
                      </div>
                      :
                      null
                    }
                    <div className={`rank-column text-center ${ opts.overall ? '' : 'single-match' }`}>{ i + 1 }</div>
                    <div className="logo-column text-center">
                      <img
                        src={getTeamLogo(`${leftTeam.name}`)}
                        onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                        alt="team logo"
                      />
                    </div>
                    <div className="team-column text-fly-in"><p>{ leftTeam.name }</p></div>
                    <div className="stat-column text-center text-fly-in"><p>{ opts.overall ? leftTeam.wins : '' }</p></div>
                    <div className="stat-column text-center text-fly-in"><p>{ leftTeam.totalKills }</p></div>
                  </div>
                </TeamRow>
                <TeamRow
                  className={`team-row ${show ? 'bc-show' : 'bc-hide'}`}
                  animationDelay={i + numberOfHalfTeams / 2}
                >
                  <div className={`column-wrapper ${parity}`}>
                    {
                      opts.overall ?
                      <div className="arrow-column text-center">
                        <img
                          src={`/assets/img/esl/leaderboard-${rightTeam.previousPosition.toLowerCase()}.png`}
                          alt="previous position"
                        />
                      </div>
                      :
                      null
                    }
                    <div className={`rank-column text-center ${ opts.overall ? '' : 'single-match' }`}>{ i + 1 + numberOfHalfTeams }</div>
                    <div className="logo-column text-center">
                      <img
                        src={getTeamLogo(`${rightTeam.name}`)}
                        onError={e => e.target.src = getTeamLogo('pubg_logo_masters-04')}
                        alt="team logo"
                      />
                    </div>
                    <div className="team-column text-fly-in"><p>{ rightTeam.name }</p></div>
                    <div className="stat-column text-center text-fly-in"><p>{ opts.overall ? rightTeam.wins : '' }</p></div>
                    <div className="stat-column text-center text-fly-in"><p>{ rightTeam.totalKills }</p></div>
                  </div>
                </TeamRow>
              </>
            );
          })
        }
      </Wrapper>
      :
      null
    );
  }
}

export default EslLiveTeamLeaderboard;