import styles from '../../scss/style.scss';
import styled from 'styled-components';
import { AppBreadcrumb2 } from '@coreui/react';

export default styled(AppBreadcrumb2)`
  .breadcrumb {
    background: ${styles.background};
    border-color: ${styles.background};

    .breadcrumb-item {
      color: ${styles.secondary};
    }
  }
`;