import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 25px;
  text-transform: uppercase;
  color: ${ props => props.colors && props.colors.headerText ? props.colors.headerText : '#FFF'};

  table {
    tr {
      &.component-show {
        animation: fadeIn 0.3s ease 0s forwards;
  
        @keyframes fadeIn {
          from {opacity: 0;}
          to {opacity: 1;}
        }
      }

      &.component-hide {
        animation: fadeOut 0.3s ease 0s forwards;
  
        @keyframes fadeOut {
          from {opacity: 1;}
          to {opacity: 0;}
        }
      }
    }
  }

  .team, .kills, .points {
    padding: 0;
  }

  .team {
    width: 470px;
  }

  .rank {
    width: 70px;
  }
  
  .kills, .points {
    width: 100px;
  }
`;

function LeaderboardSubHeader(props) {
  const { show, overlayColor, exportOverlay } = props;

  return (
    <td>
      <Wrapper
        colors={overlayColor}
      >
        <table>
          <tbody>
            <tr className={exportOverlay ? '' : show ? 'component-show' : 'component-hide'}>
              <td className="rank text-center"></td>
              <td className="team"></td>
              <td className="kills text-center">
                <div>KILLS</div>
              </td>
              <td className="points text-center">
                <div>PTS</div>
              </td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    </td>
  );
}

export default LeaderboardSubHeader;