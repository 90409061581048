import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getTeamLogoByGame, numberForDisplay } from '../../../../../../util/CommonUtil';

const PlayerHeadToHeadTable = styled.table`
  font-family: teko-regular;
  text-transform: uppercase;

  td {
    text-align: center; 
    vertical-align: middle;
    width: 300px;
    padding: 8px;
  }

  transition: opacity 0.5s ease-out;
  transition-delay: 0.5s;

  &.cg-show {
    opacity: 1;
  }

  &.cg-hide {
    opacity: 0;
  }
`;

const TeamLogos = styled.tr`
  img {
    height: 150px;
  }
`;

const HeaderRow = styled.tr`
  font-family: teko-bold;
  background: #5848f1;
  font-size: 2.3rem;
`;

const StatsRow = styled.tr`
  background: rgba(0,0,0,.80);
  font-size: 2rem;
`;

function PlayerHeadToHead(props) {
  const { left, right, opts, game, show } = props;
  
  return (
    <PlayerHeadToHeadTable
      className={!show ? 'cg-hide': 'cg-show'}
    >
      <tbody>
        <TeamLogos>
          <td>
            <img
              src={getTeamLogoByGame(game, left.teamName)}
              alt="team logo"
              onError={e => e.target.src = getTeamLogoByGame(game)}
            />
          </td>
          <td></td>
          <td>
            <img
              src={getTeamLogoByGame(game, right.teamName)}
              alt="team logo"
              onError={e => e.target.src = getTeamLogoByGame(game)}
            />
          </td>
        </TeamLogos>
        <HeaderRow>
          <td>
            {left.ign}
          </td>
          <td>
            VS
          </td>
          <td>
            {right.ign}
          </td>
        </HeaderRow>
        {
          (opts.stats || []).map(s => {
            return (
              <StatsRow>
                <td>
                  {numberForDisplay(left[s.key])}
                </td>
                <td>
                  {s.value}
                </td>
                <td>
                  {numberForDisplay(right[s.key])}
                </td>
              </StatsRow>
            );
          })
        }
      </tbody>
    </PlayerHeadToHeadTable>
  );
}

const mapStateToProps = state => ({
  left: state.overlay.left ? state.overlay.left.valorant : {},
  right: state.overlay.right ? state.overlay.right.valorant : {},
  opts: state.overlay.opts,
  show: state.overlay.show,
});

export default connect(mapStateToProps)(PlayerHeadToHead);