import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getTeamLogoByGame, numberForDisplay } from '../../../../../../util/CommonUtil';

const PlayerLeaderboardTable = styled.table`
  font-family: teko-regular;
  text-transform: uppercase;

  td {
    text-align: center; 
    vertical-align: middle;
    width: 300px;
    max-width: 300px;
    padding: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
  }

  transition: opacity 0.5s ease-out;
  transition-delay: 0.5s;

  &.cg-show {
    opacity: 1;
  }

  &.cg-hide {
    opacity: 0;
  }
`;

const TeamLogos = styled.tr`
  img {
    height: 150px;
  }
`;

const HeaderRow = styled.tr`
  font-family: teko-bold;
  font-size: 2.5rem;
`;

const PlayerNameRow = styled.tr`
  font-family: teko-bold;
  background: #5848f1;
  font-size: 2.3rem;
`;

const StatsRow = styled.tr`
  background: rgba(0,0,0,.80);
  font-size: 2rem;
`;

function PlayerLeaderboard(props) {
  const { players, opts, game, show } = props;

  return (
    <PlayerLeaderboardTable
      className={`text-center ${!show ? 'cg-hide': 'cg-show'}`}
    >
      <tbody>
        <HeaderRow>
          <td colSpan="5">
            {opts.stats ? opts.stats[0].value : ''}
          </td>
        </HeaderRow>
        <tr>
            {
              players.slice(0, 5).map(player => {
                return (
                  <td>
                    <PlayerLeaderboardTable>
                      <tbody>
                        <TeamLogos>
                          <td>
                            <img
                              src={getTeamLogoByGame(game, player.teamName)}
                              alt="team logo"
                              onError={e => e.target.src = getTeamLogoByGame(game)}
                            />
                          </td>
                        </TeamLogos>
                        <PlayerNameRow>
                          <td>
                            {player.ign}
                          </td>
                        </PlayerNameRow>
                        <StatsRow>
                          <td>
                            {numberForDisplay(player[opts.stats[0].key])}
                          </td>
                        </StatsRow>
                      </tbody>
                    </PlayerLeaderboardTable>
                  </td>
                );
              }) 
            }
        </tr>
      </tbody>
    </PlayerLeaderboardTable>
  );
}

const mapStateToProps = state => ({
  players: state.overlay.valorant || [],
  opts: state.overlay.opts,
  show: state.overlay.show,
});

export default connect(mapStateToProps)(PlayerLeaderboard);