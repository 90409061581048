import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.tr`
td {
  height: 82px;
  width: 224px;
  background: url('/assets/img/protality6/footer.png');
}
`;

function LeaderboardFooter(props) {

  return (
    <Wrapper>
      <td colSpan="4">
      </td>
    </Wrapper>
  );
}

export default LeaderboardFooter;