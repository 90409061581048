import React from 'react';
import { connect } from 'react-redux';
import PJC from './pjc/PjcLivePlayerStats';
import Protality from './protality/ProtalityLivePlayerStats';

const PUBGLiveTeamStatsOverlay = (props) => {
  const { overlayDesign } = props;

  return (
    overlayDesign === 'pjc' ?
    <PJC {...props} />
    :
    overlayDesign === 'protality' ?
    <Protality {...props} />
    :
    null
  );
}

const mapStateToProps = state => ({
  player: state.overlay.pubg || {},
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLiveTeamStatsOverlay);
