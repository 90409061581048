import { 
  REQUEST_PLAYER_LEADERBOARD_PENDING,
  REQUEST_PLAYER_LEADERBOARD_SUCCESS,
  REQUEST_PLAYER_LEADERBOARD_ERROR,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  leaderboard: [],
  matchName: null,
  lastMatchName: null,
  groupName: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request team leaderboard pending or error
    case REQUEST_PLAYER_LEADERBOARD_PENDING:
    case REQUEST_PLAYER_LEADERBOARD_ERROR:
      return INITIAL_STATE;

    // On request team leaderboard success
    case REQUEST_PLAYER_LEADERBOARD_SUCCESS:
      return {
        loaded: true,
        leaderboard: action.payload.leaderboard,
        matchName: action.payload.matchName,
        lastMatchName: action.payload.lastMatchName,
        groupName: action.payload.groupName,
      };

    default:
      return state;
  }
}

export default reducer;