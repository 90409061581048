import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLiveSurvivalLeaderboard';
import PSL from './psl/PslLiveTeamLeaderboard';
import PJC from './pjc/PjcLiveSurvivalLeaderboard';
import PJC21 from './pjc21/Pjc21LiveSurvivalLeaderboard';
import PJC221 from './pjc22-1/Pjc221LiveSurvivalLeaderboard';
import DNW from './dnw/DnwLiveSurvivalLeaderboard';
import Being from './being/BeingLiveSurvivalLeaderboard';
import Being2 from './being2/BeingLiveSurvivalLeaderboard';
import Protality from './protality/ProtalityLiveSurvivalLeaderboard';
import Protality6 from './protality6/ProtalityLiveSurvivalLeaderboard';
import PSJ from './psj/PsjLiveSurvivalLeaderboard';
import PAC from './pac/PacLiveSurvivalLeaderboard';
import Predator from './predator/PredatorLiveSurvivalLeaderboard';
import Atlantic from './atl/PacLiveSurvivalLeaderboard';
import Default from './default/DefaultLiveSurvivalLeaderboard';

const PUBGLiveSurvivalLeaderboardOverlay = (props) => {
  const { overlayDesign } = props;
  // if (props.leaderboard.length) {
  //   props.leaderboard[0].players = [ { status: 'alive' }, { status: 'alive' }, { status: 'knocked' }, { status: 'death' } ]
  //   props.leaderboard[1].players = [ { status: 'alive' }, { status: 'alive' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[2].players = [ { status: 'alive' }, { status: 'knocked' }, { status: 'knocked' }, { status: 'death' } ]
  //   props.leaderboard[3].players = [ { status: 'alive' }, { status: 'death' }, { status: 'knocked' }, { status: 'death' } ]
  //   props.leaderboard[4].players = [ { status: 'alive' }, { status: 'death' }, { status: 'knocked' }, { status: 'death' } ]
  //   props.leaderboard[5].players = [ { status: 'alive' }, { status: 'knocked' }, { status: 'knocked' }, { status: 'death' } ]
  //   props.leaderboard[6].players = [ { status: 'alive' }, { status: 'knocked' }, { status: 'knocked' }, { status: 'death' } ]
  //   props.leaderboard[7].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[8].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[9].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[10].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[11].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[12].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[13].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[14].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  //   props.leaderboard[15].players = [ { status: 'death' }, { status: 'death' }, { status: 'death' }, { status: 'death' } ]
  // }

  return (
    overlayDesign === 'psl' ?
    <PSL {...props} />
    :
    overlayDesign === 'pjc' ?
    <PJC {...props} />
    :
    overlayDesign === 'pjc21' ?
    <PJC21 {...props} />
    :
    overlayDesign === 'pjc22-1' ?
    <PJC221 {...props} />
    :
    overlayDesign === 'dnw' ?
    <DNW {...props} />
    :
    overlayDesign === 'esl' ?
    <ESL {...props} />
    :
    overlayDesign === 'being' ?
    <Being {...props} />
    :
    overlayDesign === 'being2' ?
    <Being2 {...props} />
    :
    overlayDesign === 'protality6' ?
    <Protality6 {...props} />
    :
    overlayDesign === 'protality' ?
    <Protality {...props} />
    :
    overlayDesign === 'psj' ?
    <PSJ {...props} />
    :
    overlayDesign === 'pac' ?
    <PAC {...props} />
    :
    overlayDesign === 'predator' ?
    <Predator {...props} />
    :
    overlayDesign === 'atl' ?
    <Atlantic {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  leaderboard: state.overlay.pubg || [],
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  overlayColor: (state.overlay.tournament || {}).overlayColor,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLiveSurvivalLeaderboardOverlay);
