import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import {  
  REQUEST_ALL_CUSTOM_TOURNAMENT,
  REQUEST_ALL_CUSTOM_TOURNAMENT_PENDING,
  REQUEST_ALL_CUSTOM_TOURNAMENT_ERROR,
  REQUEST_ALL_CUSTOM_TOURNAMENT_SUCCESS,
} from './types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import { getTournaments } from '../../graphql/apollo/tournamentQueries';
import { displayErrorNotification } from '../notification/actions';

/**
 * Makes a request to server for all my tournaments
 * 
 * @param {*} action 
 */
function* requestAllCustomTournaments(action) {
  yield put({ type: REQUEST_ALL_CUSTOM_TOURNAMENT_PENDING });
  try {
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'query'], {
      query: getTournaments,
    })).data;

    yield put({ type: REQUEST_ALL_CUSTOM_TOURNAMENT_SUCCESS, payload: result });
  } catch (err) {
    yield put({ type: REQUEST_ALL_CUSTOM_TOURNAMENT_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_ALL_CUSTOM_TOURNAMENT, requestAllCustomTournaments),
  ]);
}