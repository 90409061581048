import React, { Component } from 'react';
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody, 
  ModalFooter, 
  ModalHeader,
  Row
} from 'reactstrap';
import {
  Button,
  Modal
} from '../../StyledCoreUI';

class ConfirmModal extends Component {
  state = {
    dontShowAgain: false
  };

  render() {
    const { title, text, dontShowAgainText, onClose, onConfirm } = this.props;
    const { dontShowAgain } = this.state;

    return (
      <Modal isOpen={this.props.open} toggle={() => onClose(dontShowAgain)} className={this.props.className}>
        <ModalHeader toggle={onClose}>{ title }</ModalHeader>
        <ModalBody>
          <Row>
              <Col xs="12">
                <p>{ text }</p>
              </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FormGroup check style={{ position: 'absolute', left: '15px' }}>
            {
              dontShowAgainText ?
              <Label check>
                <Input type="checkbox" name="dontShowAgain" checked={dontShowAgain} onChange={() => this.setState({ dontShowAgain: !dontShowAgain})} />{' '}
                { dontShowAgainText }
              </Label>
              :
              null
            }
          </FormGroup>
          <Button color="primary" onClick={() => onConfirm(dontShowAgain)}>OK</Button>{' '}
          <Button color="light" onClick={() => onClose(dontShowAgain)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmModal;