import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import {  
  REQUEST_ALL_TOURNAMENT,
  REQUEST_ALL_TOURNAMENT_PENDING,
  REQUEST_ALL_TOURNAMENT_ERROR,
  REQUEST_ALL_TOURNAMENT_SUCCESS,
} from './types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import games from '../../util/Games';
import { getTournaments } from '../../graphql/apollo/tournamentQueries';
import { displayErrorNotification } from '../notification/actions';

/**
 * Makes a request to server for tournament by ID
 * 
 * @param {*} action 
 */
function* requestAllTournaments(action) {
  yield put({ type: REQUEST_ALL_TOURNAMENT_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);

    const tournamentResult = yield call([client, 'query'], {
      query: getTournaments,
    });

    Object.keys(tournamentResult.data.getTournaments).forEach(game => {
      if (Array.isArray(tournamentResult.data.getTournaments[game])) {
        tournamentResult.data.getTournaments[game].forEach(t => t.game = games.find(g => game.replace('Tournaments', '') === g));
      }
    });

    yield put({ type: REQUEST_ALL_TOURNAMENT_SUCCESS, payload: tournamentResult.data.getTournaments });
  } catch (err) {
    yield put({ type: REQUEST_ALL_TOURNAMENT_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_ALL_TOURNAMENT, requestAllTournaments),
  ]);
}