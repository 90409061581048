import {
  createComponent,
  createMapVetoComponent,
  changeComponent,
  changeMapVetoComponent,
  removeComponent,
  removeMapVetoComponent,
} from './componentMutations';
import {
  PLAYER_STATS_COMPONENT_TYPE,
  TEAM_STATS_COMPONENT_TYPE,
  PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
  TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  PLAYER_LEADERBOARD_COMPONENT_TYPE,
  TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_MAP_TEAMS_COMPONENT_TYPE,
  LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE,
  LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE,
  CURRENT_MATCH_COMPONENT_TYPE,
  TODAYS_WINNERS_COMPONENT_TYPE,
  SCOREBOARD_COMPONENT_TYPE,
  MAP_VETO_COMPONENT_TYPE,
} from '../../views/Components/constants';

export const createComponentMutations = {
  [PLAYER_STATS_COMPONENT_TYPE]: createComponent,
  [TEAM_STATS_COMPONENT_TYPE]: createComponent,
  [PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE]: createComponent,
  [TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: createComponent,
  [PLAYER_LEADERBOARD_COMPONENT_TYPE]: createComponent,
  [TEAM_LEADERBOARD_COMPONENT_TYPE]: createComponent,
  [LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE]: createComponent,
  [LIVE_MAP_TEAMS_COMPONENT_TYPE]: createComponent,
  [LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE]: createComponent,
  [LIVE_TEAM_STATS_COMPONENT_TYPE]: createComponent,
  [LIVE_PLAYER_STATS_COMPONENT_TYPE]: createComponent,
  [LIVE_PLAYER_KILLS_COMPONENT_TYPE]: createComponent,
  [LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE]: createComponent,
  [LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: createComponent,
  [LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE]: createComponent,
  [CURRENT_MATCH_COMPONENT_TYPE]: createComponent,
  [TODAYS_WINNERS_COMPONENT_TYPE]: createComponent,
  [SCOREBOARD_COMPONENT_TYPE]: createComponent,
  [MAP_VETO_COMPONENT_TYPE]: createMapVetoComponent,
}

export const changeComponentMutations = {
  [PLAYER_STATS_COMPONENT_TYPE]: changeComponent,
  [TEAM_STATS_COMPONENT_TYPE]: changeComponent,
  [PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE]: changeComponent,
  [TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: changeComponent,
  [PLAYER_LEADERBOARD_COMPONENT_TYPE]: changeComponent,
  [TEAM_LEADERBOARD_COMPONENT_TYPE]: changeComponent,
  [LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE]: changeComponent,
  [LIVE_MAP_TEAMS_COMPONENT_TYPE]: changeComponent,
  [LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE]: changeComponent,
  [LIVE_TEAM_STATS_COMPONENT_TYPE]: changeComponent,
  [LIVE_PLAYER_STATS_COMPONENT_TYPE]: changeComponent,
  [LIVE_PLAYER_KILLS_COMPONENT_TYPE]: changeComponent,
  [LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE]: changeComponent,
  [LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: changeComponent,
  [LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE]: changeComponent,
  [CURRENT_MATCH_COMPONENT_TYPE]: changeComponent,
  [TODAYS_WINNERS_COMPONENT_TYPE]: changeComponent,
  [SCOREBOARD_COMPONENT_TYPE]: changeComponent,
  [MAP_VETO_COMPONENT_TYPE]: changeMapVetoComponent,
}

export const removeComponentMutations = {
  [PLAYER_STATS_COMPONENT_TYPE]: removeComponent,
  [TEAM_STATS_COMPONENT_TYPE]: removeComponent,
  [PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE]: removeComponent,
  [TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: removeComponent,
  [PLAYER_LEADERBOARD_COMPONENT_TYPE]: removeComponent,
  [TEAM_LEADERBOARD_COMPONENT_TYPE]: removeComponent,
  [LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE]: removeComponent,
  [LIVE_MAP_TEAMS_COMPONENT_TYPE]: removeComponent,
  [LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE]: removeComponent,
  [LIVE_TEAM_STATS_COMPONENT_TYPE]: removeComponent,
  [LIVE_PLAYER_STATS_COMPONENT_TYPE]: removeComponent,
  [LIVE_PLAYER_KILLS_COMPONENT_TYPE]: removeComponent,
  [LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE]: removeComponent,
  [LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE]: removeComponent,
  [LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE]: removeComponent,
  [CURRENT_MATCH_COMPONENT_TYPE]: removeComponent,
  [TODAYS_WINNERS_COMPONENT_TYPE]: removeComponent,
  [SCOREBOARD_COMPONENT_TYPE]: removeComponent,
  [MAP_VETO_COMPONENT_TYPE]: removeMapVetoComponent,
}