export const getBackgroundColors = (i) => {
  switch (i) {
    case 0:
      return 'white'
    case 1:
      return '#ececec'
    case 2:
      return '#cccccc'
    default:
      return '#bbbbbb'
  }
}
