import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import {  
  REQUEST_ORGANIZATION,
  REQUEST_ORGANIZATION_ERROR,
  REQUEST_ORGANIZATION_PENDING,
  REQUEST_ORGANIZATION_SUCCESS,
} from './types';
import { LOADING_ERROR } from '../../util/ErrorMessages';
import { getOrganization } from '../../graphql/apollo/tournamentQueries';
import { displayErrorNotification } from '../notification/actions';

/**
 * Makes a request to server for logged in organization
 * 
 * @param {*} action 
 */
function* requestOrganization(action) {
  yield put({ type: REQUEST_ORGANIZATION_PENDING });
  try {
    // Calling the server
    const client = yield select(state => state.apollo.client);

    const result = (yield call([client, 'query'], {
      query: getOrganization,
    })).data.getOrganization;

    const availableComponents = result.availableComponents ? result.availableComponents.split(',') : [];
    yield put({ type: REQUEST_ORGANIZATION_SUCCESS, payload: { ...result, availableComponents: availableComponents }});
  } catch (err) {
    yield put({ type: REQUEST_ORGANIZATION_ERROR });
    yield put(displayErrorNotification(LOADING_ERROR));
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_ORGANIZATION, requestOrganization),
  ]);
}