import React from 'react';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';

const margin = 0;

const Wrapper = styled.div`
  overflow: hidden;
  width: ${props => props.backgroundWidth - margin}px;
  &.header-show {
    width: 0;
    animation: open 0.5s ease 0s forwards;
    
    @keyframes open {
      from {width: 0;}
      to {width: ${props => props.backgroundWidth - margin}px;}
    }
  }

  &.header-hide {
    width: ${props => props.backgroundWidth - margin}px;
    animation: close 0.5s ease 2s forwards;
    
    @keyframes close {
      from {width: ${props => props.backgroundWidth - margin}px;}
      to {width: 0;}
    }
  }

  &.no-animation {
    width: ${props => props.backgroundWidth - margin}px;
  }

  background: ${props => styles[props.shard + 'Color'] ? styles[props.shard + 'Color'] : styles.primaryLight};
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  h2 {
    margin-top: 5px;
    text-transform: uppercase;
    color: #FFF;
  }
`;

function LeaderboardHeader(props) {
  const { backgroundWidth, show, shard, exportAsImage } = props;

  return (
    <Wrapper
      backgroundWidth={backgroundWidth}
      className={ (exportAsImage ? 'no-animation' : show ? 'header-show' : 'header-hide') + ' text-center' }
      shard={shard.split('-')[0]}
    >
      <h2>{props.title}</h2>
    </Wrapper>
  );
}

export default LeaderboardHeader;