import styled from 'styled-components';
import styles from '../../../scss/style.scss';

/**
 * Short line colored in orange gradied
 */
export default styled.div`
  margin: 0 auto;
  background-image: -moz-linear-gradient( -180deg, ${styles.primaryGlow} 1%, ${styles.primary} 60%);
  background-image: -webkit-linear-gradient( -180deg, ${styles.primaryGlow} 1%, ${styles.primary} 60%);
  background-image: -ms-linear-gradient( -180deg, ${styles.primaryGlow} 1%, ${styles.primary} 60%);
  box-shadow: 0px 0px 22px 0px ${styles.primaryShadow};
  width: ${props => props.width ? props.width : '60px'};
  height: ${props => props.height ? props.height : '4px'};
  margin-bottom: 25px;
  ${props => props.float ? 'float: ' + props.float + ';': ''}
`;