import React from 'react';
import styled from 'styled-components';
import { addMissingPlayers, getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.table`
  font-family: machine, noto-bold;
  position: fixed;
  top: ${props => props.index * 135}px;
  text-align: center;
  vertical-align: middle;
  height: 135px;

  &.bc-show {
    left: ${props => props.side === 'left' ? '-420' : '1920'}px;
    animation: slideIn${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideIn${props => props.side} {
      from {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
      to {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
    }
  }

  &.bc-hide {
    left: ${props => props.side === 'left' ? '0' : '1500'}px;
    animation: slideOut${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideOut${props => props.side} {
      from {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
      to {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
    }
  }
  
  td.team-logo {
    border-bottom: 1px solid white;
    width: 140px;
    background: #020202;

    img {
      height: 100px;
    }
  }

  tr.team-alive {
    font-weight: 400;
    font-size: 26px;
    line-height: 26px;
  
    td.player-name {
      text-align: ${props => props.side};
      padding: 0 15px;
      width: 280px;
      color: #FFF;

      &.player-death {
        color: #FFF;
        background: #60606090;

        span {
          opacity: 0.3;
        }
      }

      &.player-knocked {
        color: #FFF;
        background: #682833;
      }
    }
  }
`;

const PlayerRow = styled.td`
  background-image: url('/assets/img/pjc/team-map.png');
  background-attachment: initial;
  background-position-x: ${props => props.side === 'left' ? -100 : -1500}px;
  background-position-y: -${props => props.index * 135 + props.playerIndex * (135/4)}px;
  background-repeat: no-repeat;
`;

function Team(props) {
  const { team, index, side, show } = props;
  addMissingPlayers(team.players);

  return (
    <Wrapper
      className={show ? 'bc-show' : 'bc-hide'}
      side={side}
      index={index}
    >
      {
        team.players.map((p, i) => {
          return (
            <tr className="team-alive">
              {
                i === 0 && side === 'left' ?
                <td
                  className="team-logo"
                  rowSpan="4"
                >
                  <img
                    src={getTeamLogo(`${team.name}`)}
                    onError={e => e.target.src = getTeamLogo('default')}
                    alt="team-logo"
                  />
                </td>
                :
                null
              }
              <PlayerRow
                className={ `player-name player-${p.status}` }
                index={index}
                playerIndex={i}
                side={side}
              ><span>{ p.ign }</span></PlayerRow>
              {
                i === 0 && side === 'right' ?
                <td
                  className="team-logo"
                  rowSpan="4"
                >
                  <img
                    src={getTeamLogo(`${team.name}`)}
                    onError={e => e.target.src = getTeamLogo('default')}
                    alt="team-logo"
                  />
                </td>
                :
                null
              }
            </tr>
          );
        })
      }
    </Wrapper>
  );
}

export default Team;