import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Default from './default/TwireTodaysWinners';
import PSL from './psl/PslTodaysWinners';
import PJC from './pjc/PjcTodaysWinners';

const PUBGTodaysWinnersOverlay = (props) => {
  const { shard, mapRotation, startTime, timeBetweenMatches } = props;
  const numOfMatches = (mapRotation || '').length;

  [...new Array(numOfMatches).keys()].forEach(i => {
    // Calculating match numbers
    if (!props.matches[i]) {
      props.matches.push({ matchNumber: props.currentMatchNumber + i });
    }
    props.matches[i].map = mapRotation.substring(i, i + 1);

    // Calculating times of each match
    let matchTime = moment.duration(startTime);
    [...new Array(i).keys()].forEach(() => {
      matchTime = matchTime.add(timeBetweenMatches);
    });
    props.matches[i].time = moment.utc(matchTime.asMilliseconds()).format("HH:mm");
  });

  return (
    shard.includes('psl') ? 
    <PSL {...props} />
    :
    shard.includes('test-pjc21') || shard.includes('pjc') ? 
    <PJC {...props} />
    :
    <Default {...props} />
  );
}

const mapStateToProps = state => ({
  currentMatchNumber: state.overlay.pubg ? state.overlay.pubg.currentMatchNumber : 1,
  matches: state.overlay.pubg ? state.overlay.pubg.matches : [],
  ...(state.overlay.opts || {}),
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGTodaysWinnersOverlay);
