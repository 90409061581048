import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getCountryFlag, getTeamLogoByGame } from '../../../../../../util/CommonUtil'
import { ShowInAnimation } from '../../../../animations/ShowInAnimation'
import { SlideInAnimation } from '../../../../animations/SlideInAnimation'
import { getBackgroundColors } from '../../../../helpers'
import { Image } from '../../../../helpers/Image'
import { VALORANT_GAME } from '../../../../../../util/Games'

function TeamLeaderboard({ show, teamLeaderboard }) {
  // Set up the table here
  const STANDARD_TEAM_SIZE = 16
  const HOW_MANY_PAGES = 1 // If pagination is needed: Math.ceil(teamLeaderboard.length / 8)
  const RANK_COL_LEN = 3
  const FLAG_COL_LEN = 6
  const LOGO_COL_LEN = 6
  const TEAM_COL_LEN = 25
  const PLAYED_COL_LEN = 5
  const WINS_COL_LEN = 5
  const DRAWS_COL_LEN = 5
  const LOSE_COL_LEN = 5
  const KD_COL_LEN = 5
  const POINTS_COL_LEN = 5
  const GAP_BETWEEN_PAGES = 1

  const numberOfExtraTeams =
    teamLeaderboard.length - STANDARD_TEAM_SIZE > 0 &&
    teamLeaderboard.length - STANDARD_TEAM_SIZE

  const howManyToDisplayPerPage = Math.ceil(
    teamLeaderboard.length / HOW_MANY_PAGES,
  )

  const shouldEvenPaginate =
    teamLeaderboard.length - howManyToDisplayPerPage > 0

  const mainContainerWidth =
    (RANK_COL_LEN +
      FLAG_COL_LEN +
      LOGO_COL_LEN +
      TEAM_COL_LEN +
      PLAYED_COL_LEN +
      WINS_COL_LEN +
      DRAWS_COL_LEN +
      LOSE_COL_LEN +
      KD_COL_LEN +
      POINTS_COL_LEN) *
    HOW_MANY_PAGES
  const tableHeight = howManyToDisplayPerPage * 4 + numberOfExtraTeams * 4

  return (
    <OverflowContainer>
      <FontWrapper>
        <ShowInAnimation show={show}>
          <Container width={mainContainerWidth}>
            <LegendRow>
              <TournamentLogo>
                <Image style={{ marginLeft: '-20px' }} size={'giant'} src={'/assets/img/aces/aces_logo.png'} />
              </TournamentLogo>
              {[...Array(HOW_MANY_PAGES)].map((e, i) => (
                <Legend
                  gap={GAP_BETWEEN_PAGES}
                  page={i}
                  shouldEvenPaginate={shouldEvenPaginate}
                >
                  <Cell textToBottom width={RANK_COL_LEN}>
                    &nbsp;
                  </Cell>
                  <Cell textToBottom width={FLAG_COL_LEN}>
                    &nbsp;
                  </Cell>
                  <Cell textToBottom width={LOGO_COL_LEN}>
                    &nbsp;
                  </Cell>
                  <Cell textToBottom width={TEAM_COL_LEN}>
                    &nbsp;
                  </Cell>
                  <Cell textToBottom width={PLAYED_COL_LEN}>
                    P
                  </Cell>
                  <Cell textToBottom width={WINS_COL_LEN}>
                    W
                  </Cell>
                  <Cell textToBottom width={DRAWS_COL_LEN}>
                    D
                  </Cell>
                  <Cell textToBottom width={LOSE_COL_LEN}>
                    L
                  </Cell>
                  <Cell textToBottom width={KD_COL_LEN}>
                    RD
                  </Cell>
                  <Cell textToBottom width={POINTS_COL_LEN}>
                    Pts
                  </Cell>
                </Legend>
              ))}
            </LegendRow>
            <Table height={tableHeight}>
              {teamLeaderboard.map((team, i) => {
                const page = Math.ceil((i + 1) / howManyToDisplayPerPage)
                const half = Math.ceil(teamLeaderboard.length / 2) === i + 1

                return (
                  <SlideInAnimation
                    show={show}
                    direction={'left'}
                    delay={i / 50}
                  >
                    <Team
                      shouldEvenPaginate={shouldEvenPaginate}
                      half={half}
                      backgroundColor={getBackgroundColors(i)}
                      page={page}
                      gap={GAP_BETWEEN_PAGES}
                    >
                      <Cell width={RANK_COL_LEN}>#{i + 1}</Cell>
                      <Cell width={FLAG_COL_LEN}>
                        <Image
                          size={'tiny'}
                          src={getCountryFlag(team.country.iso)}
                          onError={(e) =>
                            (e.target.src = getCountryFlag('si'))
                          }
                        />
                      </Cell>
                      <Cell width={LOGO_COL_LEN} textAlign={'center'}>
                        <Image
                          size={'tiny'}
                          src={getTeamLogoByGame(VALORANT_GAME, team.teamName)}
                          onError={(e) =>
                            (e.target.src = getTeamLogoByGame(VALORANT_GAME, 'default'))
                          }
                        />
                      </Cell>
                      <Cell width={TEAM_COL_LEN} uppercase textToLeft>
                        {team.teamName}
                      </Cell>
                      <Cell width={PLAYED_COL_LEN}>{team.played}</Cell>
                      <Cell width={WINS_COL_LEN}>{team.wins}</Cell>
                      <Cell borderLeft width={DRAWS_COL_LEN}>
                        {team.draws}
                      </Cell>
                      <Cell borderLeft width={LOSE_COL_LEN}>
                        {team.losses}
                      </Cell>
                      <Cell borderLeft width={KD_COL_LEN}>
                        {team.rd || 0}
                      </Cell>
                      <Cell borderLeft width={POINTS_COL_LEN}>
                        {team.points}
                      </Cell>
                    </Team>
                  </SlideInAnimation>
                )
              })}
            </Table>
          </Container>
        </ShowInAnimation>
      </FontWrapper>
    </OverflowContainer>
  )
}

const mapStateToProps = state => ({
  teamLeaderboard: state.overlay.valorant || [],
  show: state.overlay.show,
});

export default connect(mapStateToProps)(TeamLeaderboard);


const OverflowContainer = styled.div`
  overflow: hidden;
  background-color: transparent;
`

const FontWrapper = styled.div`
  font-size: 1.7rem;
  color: black;
  font-weight: 500;
  text-align: center;
`

const Container = styled.div`
  width: ${({ width }) => width}rem;
`

const TournamentLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LegendRow = styled.div`
  position: relative;
  width: 100%;
  background-color: #ed1c24;
  height: 6rem;

  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 0.2rem;

  background-image: url("/assets/img/aces/squares.png");
  background-position: left;
  background-repeat: no-repeat;
`

const Legend = styled.div`
  display: flex;
  margin${({ page, gap, shouldEvenPaginate }) => {
    if (shouldEvenPaginate) {
      return page % 2 === 0 ? `-right:${gap}rem` : `-left:${gap}rem`
    }
  }};
  font-weight: 900;
  font-size: 1.6rem;
`

const Cell = styled.div`
  width: ${({ width }) => width}rem;
  ${({ color }) => color && `color: ${color};`}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};

  border-left: ${({ borderLeft }) => borderLeft && '1px solid red'};

  display: flex;
  justify-content: ${({ textToLeft }) => (textToLeft ? 'start' : 'center')};
  align-items: ${({ textToBottom }) => (textToBottom ? 'flex-end' : 'center')};
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;

  width: 100%;
  height: ${({ height }) => height}rem;
`

const Team = styled.div`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: stretch;

  margin${({ page, gap, shouldEvenPaginate }) => {
    if (shouldEvenPaginate) {
      return page % 2 === 0 ? `-left:${gap}rem` : `-right:${gap}rem`
    }
  }};

  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${({ half }) => half && '2px solid #ed1c24'};
`
