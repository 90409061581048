/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const matchByUsername = /* GraphQL */ `
  query MatchByUsername($username: String!, $page: Int, $game: String!) {
    matchByUsername(username: $username, page: $page, game: $game) {
      isMember
      userMatches {
        id
        matchId
        telemetry
        duration
        gameMode
        shard
        map
        zoneProjection
        rank
        dbnos
        kills
        damageDealt
        gameCreatedAt
      }
    }
  }
`;
export const matchByTournament = /* GraphQL */ `
  query MatchByTournament(
    $shardInfo: String!
    $page: Int
    $game: String!
    $token: String
  ) {
    matchByTournament(
      shardInfo: $shardInfo
      page: $page
      game: $game
      token: $token
    ) {
      id
      matchId
      name
      telemetry
      duration
      gameMode
      shard
      shardInfo
      map
      zoneProjection
      gameCreatedAt
      tournamentName
      tournamentLogo
      zones
      winner
      winnerLogo
      teams {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
    }
  }
`;
export const matchById = /* GraphQL */ `
  query MatchById($id: String!, $game: String!) {
    matchById(id: $id, game: $game) {
      id
      matchId
      name
      telemetry
      duration
      gameMode
      shard
      shardInfo
      map
      zoneProjection
      gameCreatedAt
      tournamentName
      tournamentLogo
      zones
      winner
      winnerLogo
      teams {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
    }
  }
`;
export const tournamentsByEventType = /* GraphQL */ `
  query TournamentsByEventType($eventType: String!, $game: String!) {
    tournamentsByEventType(eventType: $eventType, game: $game) {
      id
      friendlyName
      shard
      eventType
      region
      imageName
      productionReady
      lastPlayedOn
      overlayDesign
      overlayColor
      prodTournamentId
      tier
      startDate
      stopDate
      prizePool
      currentlyActive
      tournamentSocials {
        id
        key
        type
        value
      }
      tournamentFilters {
        name
        value
        ruleset
        selected
        children {
          name
          value
          ruleset
        }
      }
      tournamentPrizePool {
        shardInfo
        shardName
        data {
          placement
          prize
          prizeCoefficient
          teamName
          teamLogo
        }
      }
    }
  }
`;
export const tournamentInitialData = /* GraphQL */ `
  query TournamentInitialData($id: Int!, $game: String!) {
    tournamentInitialData(id: $id, game: $game) {
      tournament {
        id
        friendlyName
        shard
        eventType
        region
        imageName
        productionReady
        lastPlayedOn
        overlayDesign
        overlayColor
        prodTournamentId
        tier
        startDate
        stopDate
        prizePool
        currentlyActive
        tournamentSocials {
          id
          key
          type
          value
        }
        tournamentFilters {
          name
          value
          ruleset
          selected
          children {
            name
            value
            ruleset
          }
        }
        tournamentPrizePool {
          shardInfo
          shardName
          data {
            placement
            prize
            prizeCoefficient
            teamName
            teamLogo
          }
        }
      }
      matches {
        id
        matchId
        name
        telemetry
        duration
        gameMode
        shard
        shardInfo
        map
        zoneProjection
        gameCreatedAt
        tournamentName
        tournamentLogo
        zones
        winner
        winnerLogo
        teams {
          id
          name
          ranking
          previousPosition
          previousPositionChange
          slot
          rosterId
          logo
        }
      }
      tournamentFilters {
        name
        value
        ruleset
        selected
        children {
          name
          value
          ruleset
        }
      }
    }
  }
`;
export const tournamentAll = /* GraphQL */ `
  query TournamentAll($game: String!) {
    tournamentAll(game: $game) {
      id
      friendlyName
      shard
      eventType
      region
      imageName
      productionReady
      lastPlayedOn
      overlayDesign
      overlayColor
      prodTournamentId
      tier
      startDate
      stopDate
      prizePool
      currentlyActive
      tournamentSocials {
        id
        key
        type
        value
      }
      tournamentFilters {
        name
        value
        ruleset
        selected
        children {
          name
          value
          ruleset
        }
      }
      tournamentPrizePool {
        shardInfo
        shardName
        data {
          placement
          prize
          prizeCoefficient
          teamName
          teamLogo
        }
      }
    }
  }
`;
export const tournamentById = /* GraphQL */ `
  query TournamentById($id: Int!, $game: String!) {
    tournamentById(id: $id, game: $game) {
      id
      friendlyName
      shard
      eventType
      region
      imageName
      productionReady
      lastPlayedOn
      overlayDesign
      overlayColor
      prodTournamentId
      tier
      startDate
      stopDate
      prizePool
      currentlyActive
      tournamentSocials {
        id
        key
        type
        value
      }
      tournamentFilters {
        name
        value
        ruleset
        selected
        children {
          name
          value
          ruleset
        }
      }
      tournamentPrizePool {
        shardInfo
        shardName
        data {
          placement
          prize
          prizeCoefficient
          teamName
          teamLogo
        }
      }
    }
  }
`;
export const getAdditionalTournamentInfo = /* GraphQL */ `
  query GetAdditionalTournamentInfo($game: String!, $token: String!, $tournamentId: Int!) {
    getAdditionalTournamentInfo(game: $game, token: $token, tournamentId: $tournamentId) {
      image
      visible
    }
  }
`;
export const searchMatchPlayer = /* GraphQL */ `
  query SearchMatchPlayer($matchId: Int!, $game: String!) {
    searchMatchPlayer(matchId: $matchId, game: $game) {
      id
      username
      rank
      dbnos
      assists
      damageDealt
      damageTaken
      revives
      kills
      killSteals
      killsStolenFrom
      rideDistance
      walkDistance
      timeSurvived
      killStreaks
      deathType
      heals
      boosts
      vehicleDestroys
      rosterId
    }
  }
`;
export const teamByName = /* GraphQL */ `
  query TeamByName($name: String!, $game: String!) {
    teamByName(name: $name, game: $game) {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
  }
`;
export const rotationByTeamId = /* GraphQL */ `
  query RotationByTeamId($teamId: Int!, $game: String!) {
    rotationByTeamId(teamId: $teamId, game: $game) {
      id
      gameCreatedAt
      map
      zones
      zoneProjection
      movement
      landingSpot
      planePath
      rank
      kills
      damageDealt
      damageTaken
      team {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
      matchName
      tournamentName
    }
  }
`;
export const rotationByTeams = /* GraphQL */ `
  query RotationByTeams($rotationRequest: RotationRequest!, $game: String!) {
    rotationByTeams(rotationRequest: $rotationRequest, game: $game) {
      id
      gameCreatedAt
      map
      zones
      zoneProjection
      movement
      landingSpot
      planePath
      rank
      kills
      damageDealt
      damageTaken
      team {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
      matchName
      tournamentName
    }
  }
`;
export const subscriptionPlans = /* GraphQL */ `
  query SubscriptionPlans {
    subscriptionPlans {
      name
      price
      months
      provider
      planId
      region
    }
  }
`;
export const tournamentFilters = /* GraphQL */ `
  query TournamentFilters($tournamentId: Int!, $game: String!) {
    tournamentFilters(tournamentId: $tournamentId, game: $game) {
      name
      value
      ruleset
      selected
      children {
        name
        value
        ruleset
      }
    }
  }
`;
export const tournamentStatsFilters = /* GraphQL */ `
  query TournamentStatsFilters($shardInfo: String!, $game: String!) {
    tournamentStatsFilters(shardInfo: $shardInfo, game: $game) {
      maps {
        key
        value
      }
      matches {
        key
        value
      }
    }
  }
`;
export const teamRanking = /* GraphQL */ `
  query TeamRanking($game: String!) {
    teamRanking(game: $game) {
      teams {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
      updatedAt
    }
  }
`;
export const productionLeaderboard = /* GraphQL */ `
  query ProductionLeaderboard(
    $tournament: String!
    $token: String!
    $filters: TournamentStatsFilterInput
    $game: String!
  ) {
    productionLeaderboard(
      tournament: $tournament
      token: $token
      filters: $filters
      game: $game
    ) {
      tournamentName
      groupName
      matchName
      lastMatchName
      leaderboard {
        team
        logo
        initial
        kills
        damageDealt
        points
        totalPoints
        wins
        bestMatchPoints
        bestKillPoints
        lastKills
        lastPoints
        numberOfMatches
        lastMatchPlayedAt
        previousPosition
        previousPositionChange
        players
        advance
        statsPerMatch {
          id
          name
          rank
          kills
          points
        }
      }
      overlayDesign
      overlayColor
      prodTournamentId
      numberOfMatches
      format
    }
  }
`;
export const platformLeaderboard = /* GraphQL */ `
  query PlatformLeaderboard(
    $tournament: String!
    $token: String
    $filters: TournamentStatsFilterInput
    $game: String!
  ) {
    platformLeaderboard(
      tournament: $tournament
      token: $token
      filters: $filters
      game: $game
    ) {
      tournamentName
      groupName
      matchName
      lastMatchName
      leaderboard {
        team
        logo
        initial
        kills
        damageDealt
        points
        totalPoints
        wins
        bestMatchPoints
        bestKillPoints
        lastKills
        lastPoints
        numberOfMatches
        lastMatchPlayedAt
        previousPosition
        previousPositionChange
        players
        advance
        statsPerMatch {
          id
          name
          rank
          kills
          points
        }
      }
      overlayDesign
      overlayColor
      prodTournamentId
      numberOfMatches
      format
    }
  }
`;
export const productionStats = /* GraphQL */ `
  query ProductionStats(
    $tournament: String!
    $token: String!
    $filters: TournamentStatsFilterInput
    $game: String!
  ) {
    productionStats(
      tournament: $tournament
      token: $token
      filters: $filters
      game: $game
    ) {
      tournamentName
      groupName
      matchName
      lastMatchName
      leaderboard {
        username
        teamName
        teamLogo
        kills
        assists
        kd
        kda
        kas
        killsKnocks
        deaths
        diedFirst
        diedSecond
        diedThird
        diedForth
        damageDealt
        arDamage
        dmrDamage
        srDamage
        smgDamage
        shotgunDamage
        lmgDamage
        pistolDamage
        avgDamageDealt
        damageTaken
        avgDamageTaken
        dbnos
        knocked
        revives
        revived
        headshotKills
        killSteals
        killsStolenFrom
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        avgTimeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroys
        healthRecovered
        grenadePickup
        grenadeDrop
        grenadeThrow
        grenadeDamage
        molotovPickup
        molotovDrop
        molotovThrow
        molotovDamage
        smokebombPickup
        smokebombDrop
        smokebombThrow
        flashbangPickup
        flashbangDrop
        flashbangThrow
        damageTakenFromBlueZone
        damageTakenFromEnemy
        damageDealtDamageTaken
        numOfMatches
        attacker
        finisher
        utility
        survivor
        teammate
        stealer
        twr
      }
      overlayDesign
      overlayColor
      prodTournamentId
    }
  }
`;
export const platformStats = /* GraphQL */ `
  query PlatformStats(
    $tournament: String!
    $token: String
    $filters: TournamentStatsFilterInput
    $game: String!
  ) {
    platformStats(
      tournament: $tournament
      token: $token
      filters: $filters
      game: $game
    ) {
      tournamentName
      groupName
      matchName
      lastMatchName
      leaderboard {
        username
        teamName
        teamLogo
        kills
        assists
        kd
        kda
        kas
        killsKnocks
        deaths
        diedFirst
        diedSecond
        diedThird
        diedForth
        damageDealt
        arDamage
        dmrDamage
        srDamage
        smgDamage
        shotgunDamage
        lmgDamage
        pistolDamage
        avgDamageDealt
        damageTaken
        avgDamageTaken
        dbnos
        knocked
        revives
        revived
        headshotKills
        killSteals
        killsStolenFrom
        swimDistance
        walkDistance
        rideDistance
        longestKill
        timeSurvived
        avgTimeSurvived
        killStreaks
        heals
        boosts
        vehicleDestroys
        healthRecovered
        grenadePickup
        grenadeDrop
        grenadeThrow
        grenadeDamage
        molotovPickup
        molotovDrop
        molotovThrow
        molotovDamage
        smokebombPickup
        smokebombDrop
        smokebombThrow
        flashbangPickup
        flashbangDrop
        flashbangThrow
        damageTakenFromBlueZone
        damageTakenFromEnemy
        damageDealtDamageTaken
        numOfMatches
        attacker
        finisher
        utility
        survivor
        teammate
        stealer
        twr
      }
      overlayDesign
      overlayColor
      prodTournamentId
    }
  }
`;
export const teamStats = /* GraphQL */ `
  query TeamStats(
    $shardInfo: String!
    $token: String
    $filters: TournamentStatsFilterInput
    $game: String!
  ) {
    teamStats(
      shardInfo: $shardInfo
      token: $token
      filters: $filters
      game: $game
    ) {
      tournamentName
      groupName
      matchName
      lastMatchName
      teamStats {
        teamName
        teamLogo
        stats {
          map
          rank
          kills
          assists
          damageDealt
          damageTaken
          dbnos
          revives
          headshotKills
          killSteals
          killsStolenFrom
          swimDistance
          walkDistance
          rideDistance
          timeSurvived
          killStreaks
          heals
          boosts
          vehicleDestroys
          healthRecovered
          grenadePickup
          grenadeDrop
          grenadeThrow
          grenadeDamage
          molotovPickup
          molotovDrop
          molotovThrow
          molotovDamage
          smokebombPickup
          smokebombDrop
          smokebombThrow
          flashbangPickup
          flashbangDrop
          flashbangThrow
          numOfMatches
          points
          wins
          totalPoints
          avgTotalPoints
          avgPoints
          avgRank
          avgKills
          avgDamageDealt
          avgDamageTaken
          avgTimeSurvived
        }
      }
      overlayDesign
      overlayColor
      prodTournamentId
    }
  }
`;
export const todaysWinners = /* GraphQL */ `
  query TodaysWinners(
    $tournament: String!
    $numOfMatches: Int!
    $token: String
    $game: String!
  ) {
    todaysWinners(
      tournament: $tournament
      numOfMatches: $numOfMatches
      token: $token
      game: $game
    ) {
      tournamentName
      groupName
      lastMatchNumber
      winners {
        teamName
        matchNumber
        map
      }
      overlayDesign
      overlayColor
      prodTournamentId
    }
  }
`;
export const currentMatch = /* GraphQL */ `
  query CurrentMatch($tournament: String!, $game: String!) {
    currentMatch(tournament: $tournament, game: $game)
  }
`;
export const teamNamesByTournament = /* GraphQL */ `
  query TeamNamesByTournament($tournament: String!, $game: String!) {
    teamNamesByTournament(tournament: $tournament, game: $game) {
      id
      name
      ranking
      previousPosition
      previousPositionChange
      slot
      rosterId
      logo
    }
  }
`;
export const playerNamesByTournament = /* GraphQL */ `
  query PlayerNamesByTournament($tournament: String!, $game: String!) {
    playerNamesByTournament(tournament: $tournament, game: $game) {
      id
      username
      team {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
    }
  }
`;
export const headToHeadTeams = /* GraphQL */ `
  query HeadToHeadTeams(
    $tournament: String!
    $leftId: Int!
    $rightId: Int!
    $token: String
    $game: String!
  ) {
    headToHeadTeams(
      tournament: $tournament
      leftId: $leftId
      rightId: $rightId
      token: $token
      game: $game
    ) {
      left {
        name
        kills
        damageDealt
        avgRank
        avgPoints
      }
      right {
        name
        kills
        damageDealt
        avgRank
        avgPoints
      }
      overlayDesign
      overlayColor
      prodTournamentId
    }
  }
`;
export const headToHeadPlayers = /* GraphQL */ `
  query HeadToHeadPlayers(
    $tournament: String!
    $leftId: Int!
    $rightId: Int!
    $token: String
    $game: String!
  ) {
    headToHeadPlayers(
      tournament: $tournament
      leftId: $leftId
      rightId: $rightId
      token: $token
      game: $game
    ) {
      left {
        username
        teamName
        kills
        damageDealt
        assists
        dbnos
      }
      right {
        username
        teamName
        kills
        damageDealt
        assists
        dbnos
      }
      overlayDesign
      overlayColor
      prodTournamentId
    }
  }
`;
export const getArticles = /* GraphQL */ `
  query GetArticles($game: String!, $page: Int) {
    getArticles(game: $game, page: $page) {
      author
      comments {
        content
        id
        insertedAt
        parentId
        updatedAt
        username
      }
      content
      id
      insertedAt
      summary
      summaryImg
      title
      updatedAt
      numberOfComments
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($game: String!, $articleId: Int!) {
    getArticle(game: $game, articleId: $articleId) {
      author
      comments {
        content
        id
        insertedAt
        parentId
        updatedAt
        username
      }
      content
      id
      insertedAt
      summary
      summaryImg
      title
      updatedAt
      numberOfComments
    }
  }
`;
export const getTournaments = /* GraphQL */ `
  query GetTournaments(
    $game: String!
    $type: String!
    $timeType: String
    $tier: String
    $region: String
    $page: Int
  ) {
    getTournaments(
      game: $game
      type: $type
      timeType: $timeType
      tier: $tier
      region: $region
      page: $page
    ) {
      id
      friendlyName
      shard
      eventType
      region
      imageName
      productionReady
      lastPlayedOn
      overlayDesign
      overlayColor
      prodTournamentId
      tier
      startDate
      stopDate
      prizePool
      currentlyActive
      tournamentSocials {
        id
        key
        type
        value
      }
      tournamentFilters {
        name
        value
        ruleset
        selected
        children {
          name
          value
          ruleset
        }
      }
      tournamentPrizePool {
        shardInfo
        shardName
        data {
          placement
          prize
          prizeCoefficient
          teamName
          teamLogo
        }
      }
    }
  }
`;
export const getLastestMatches = /* GraphQL */ `
  query GetLastestMatches($game: String!, $tournamentId: Int, $page: Int) {
    getLastestMatches(game: $game, tournamentId: $tournamentId, page: $page) {
      id
      matchId
      name
      telemetry
      duration
      gameMode
      shard
      shardInfo
      map
      zoneProjection
      gameCreatedAt
      tournamentName
      tournamentLogo
      zones
      winner
      winnerLogo
      teams {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
    }
  }
`;
export const getFeatured = /* GraphQL */ `
  query GetFeatured($game: String!) {
    getFeatured(game: $game) {
      type
      data
    }
  }
`;
export const getTournamentPrizeInfo = /* GraphQL */ `
  query GetTournamentPrizeInfo($game: String!, $shard: String!) {
    getTournamentPrizeInfo(game: $game, shard: $shard) {
      shardInfo
      shardName
      data {
        placement
        prize
        prizeCoefficient
        teamName
        teamLogo
      }
    }
  }
`;
export const generalSearch = /* GraphQL */ `
  query GeneralSearch($game: String!, $searchString: String!) {
    generalSearch(game: $game, searchString: $searchString) {
      tournaments {
        id
        friendlyName
        shard
        eventType
        region
        imageName
        productionReady
        lastPlayedOn
        overlayDesign
        overlayColor
        prodTournamentId
        tier
        startDate
        stopDate
        prizePool
        currentlyActive
        tournamentSocials {
          id
          key
          type
          value
        }
        tournamentFilters {
          name
          value
          ruleset
          selected
          children {
            name
            value
            ruleset
          }
        }
        tournamentPrizePool {
          shardInfo
          shardName
          data {
            placement
            prize
            prizeCoefficient
            teamName
            teamLogo
          }
        }
      }
      teams {
        id
        name
        ranking
        previousPosition
        previousPositionChange
        slot
        rosterId
        logo
      }
      players {
        id
        username
        team {
          id
          name
          ranking
          previousPosition
          previousPositionChange
          slot
          rosterId
          logo
        }
      }
      news {
        author
        comments {
          content
          id
          insertedAt
          parentId
          updatedAt
          username
        }
        content
        id
        insertedAt
        summary
        summaryImg
        title
        updatedAt
        numberOfComments
      }
    }
  }
`;
