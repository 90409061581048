import React, { Component } from 'react';
import { Multiselect as ReactMultiSelect } from 'multiselect-react-dropdown';
import styled from 'styled-components';
import styles from '../../../scss/style.scss';

const SelectWrapper = styled.div`
  #multiselectContainerReact div {
    background: ${props => props.color ? props.color : styles.tertiary} !important;
    border-color: ${props => props.warning ? styles.primary : (props.color ? props.color : styles.tertiary)} !important;
    color: ${styles.secondary};

    &:focus {
      background: ${props => props.color ? props.color : styles.tertiary};
      color: ${styles.secondary};
    }

    #search_input::placeholder {
      color: #fff;
    }

    span, li:hover, li.highlight {
      background: ${styles.primary}
    }
  }
`;

class Multiselect extends Component {

  render() {
    const { options, selectedValues, selectionLimit, onSelect, onRemove, color, displayValue, warning } = this.props;
    
    return (
      <SelectWrapper
        color={color}
        warning={warning}
      >
        <ReactMultiSelect
          options={options}
          selectedValues={selectedValues}
          selectionLimit={selectionLimit}
          onSelect={onSelect}
          onRemove={onRemove}
          displayValue={displayValue}
        />
      </SelectWrapper>
    );
  }
}

export default Multiselect;