import {
  REQUEST_TOURNAMENT_TEAMS_PENDING,
  REQUEST_TOURNAMENT_TEAMS_SUCCESS,
  REQUEST_TOURNAMENT_TEAMS_ERROR,
} from './types';

const INITIAL_STATE = {
  tournamentTeamsLoaded: false,
  tournamentTeams: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On request tournament teams pending
    case REQUEST_TOURNAMENT_TEAMS_PENDING:
      return {
        ...state,
        tournamentTeamsLoaded: false,
        tournamentTeams: [],
      }

    // On request tournament teams success
    case REQUEST_TOURNAMENT_TEAMS_SUCCESS:
      return {
        ...state,
        tournamentTeamsLoaded: true,
        tournamentTeams: action.payload,
      }

    // On request tournament teams success
    case REQUEST_TOURNAMENT_TEAMS_ERROR:
      return {
        ...state,
        tournamentTeamsLoaded: true,
        tournamentTeams: [],
      }

    default:
      return state;
  }
}

export default reducer;