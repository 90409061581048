import React, { Component } from 'react';
import styled from 'styled-components';
import { getTeamLogo, numberForDisplay } from '../../../../../../util/CommonUtil';

const WIDTH = 358;
const HEIGHT = 806;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;

  .overlay {
    width: ${WIDTH}px;
    height: ${HEIGHT}px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;

    font-family: noto-black;

    position: fixed;
    top: 135px;
    left: 43px;

    border-top: 2px solid red;
    border-bottom: 2px solid red;
    background: url('/assets/img/pjc21/team-stats-background.png');

    >div {
      width: 290px;

      font-size: 45px;
      line-height: 45px;

      .small-font {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 10px;

        span {
          border-bottom: 2px solid red;
        }
      }
    }

    .team-logo {
      height: 290px;
      padding: 40px 0px;
      display: flex;
      justify-content: center;
      border-bottom: 2px solid red;
    }

    .team-name {
      height: 136px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;

      p {
        vertical-align: text-top;
      }

      ${props => props.longName ? 'font-size: 40px;' : ''}
      ${props => props.longName ? 'line-height: 40px;' : ''}
    }

    .team-kills, .team-damage, .team-points {
      margin-bottom: 35px;
    }
  
    &.bc-show {
      height: 0px;
      animation: slideUp 0.3s ease 0.1s forwards,
        fadeIn 0.1s ease 0s forwards;
    
      @keyframes slideUp {
        from {height: 0px;}
        to {height: ${HEIGHT}px;}
      }
      
      @keyframes fadeIn {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  
    &.bc-hide {
      height: ${HEIGHT}px;
      animation: slideDown 0.3s ease 0s forwards,
        fadeOut 0.1s ease 0.3s forwards;
    
      @keyframes slideDown {
        from {height: ${HEIGHT}px;}
        to {height: 0px;}
      }
      
      @keyframes fadeOut {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }
`;

const TEAM_NAME_MAP = {
  'Zeta Division': 'ZETA DIVISION',
  'Matsui Gaming Club': '松井GAMING CLUB'
}

class Pjc21LiveTeamStats extends Component {
  render() {
    const { team, show } = this.props;

    return (
      team ?
      <Wrapper longName={team.name.length > 10}>
        <div className={`overlay ${show ? 'bc-show' : 'bc-hide'}`}>
          <div className="team-logo">
            <img
              src={getTeamLogo(`pjc-${team.name}`)}
              onError={e => e.target.src = getTeamLogo('default')}
              alt="team logo"
            />
          </div>
          <div className="team-name text-center">
            <p>{ TEAM_NAME_MAP[team.name] || team.name }</p>
          </div>
          <div className="team-kills">
            <p className="small-font"><span>AVERAGE KILLS</span></p>
            <p>{ numberForDisplay(team.avgKills) }</p>
          </div>
          <div className="team-damage">
            <p className="small-font"><span>AVERAGE DAMAGE</span></p>
            <p>{ numberForDisplay(team.avgDamageDealt) }</p>
          </div>
          <div className="team-points">
            <p className="small-font"><span>POINT</span></p>
            <p>{ team.totalPoints }</p>
          </div>
        </div>
      </Wrapper>
      :
      null
    );
  }
}

export default Pjc21LiveTeamStats;