import React from 'react';
import OSL from './onslaughtLeague/PlayerHeadToHead';
import SDP from './sdp/PlayerHeadToHead';

const ValorantPlayerHeadToHeadOverlay = (props) => {
  const { shard } = props;

  return (
    shard.includes('sdp') ? 
    <SDP {...props} />
    :
    shard.includes('ol') ? 
    <OSL {...props} />
    :
    null
  );
}

export default ValorantPlayerHeadToHeadOverlay;
