import {
  // Component Names
  PLAYER_STATS_COMPONENT_NAME,
  PLAYER_LEADERBOARD_COMPONENT_NAME,
  PLAYER_HEAD_TO_HEAD_COMPONENT_NAME,
  TEAM_STATS_COMPONENT_NAME,
  TEAM_LEADERBOARD_COMPONENT_NAME,
  LIVE_TEAM_LEADERBOARD_COMPONENT_NAME,
  LIVE_SURVIVAL_LEADERBOARD_COMPONENT_NAME,
  LIVE_MAP_TEAMS_COMPONENT_NAME,
  LIVE_TEAM_STATS_COMPONENT_NAME,
  LIVE_PLAYER_STATS_COMPONENT_NAME,
  LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_NAME,
  LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_NAME,
  LIVE_PLAYER_LEADERBOARD_COMPONENT_NAME,
  TEAM_HEAD_TO_HEAD_COMPONENT_NAME,
  CURRENT_MATCH_COMPONENT_NAME,
  TODAYS_WINNERS_COMPONENT_NAME,
  // Component Types
  PLAYER_STATS_COMPONENT_TYPE,
  PLAYER_LEADERBOARD_COMPONENT_TYPE,
  PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
  TEAM_STATS_COMPONENT_TYPE,
  TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE,
  LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE,
  LIVE_MAP_TEAMS_COMPONENT_TYPE,
  LIVE_TEAM_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_STATS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE,
  LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE,
  TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
  CURRENT_MATCH_COMPONENT_TYPE,
  TODAYS_WINNERS_COMPONENT_TYPE,
  // State to opts mappings
  PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  PLAYER_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_SURVIVAL_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_MAP_TEAMS_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_PLAYER_KILLS_COMPONENT_NAME,
  LIVE_PLAYER_KILLS_COMPONENT_TYPE,
  LIVE_PLAYER_KILLS_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  LIVE_PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  CURRENT_MATCH_COMPONENT_STATE_TO_OPTS_MAPPING,
  TODAYS_WINNERS_COMPONENT_STATE_TO_OPTS_MAPPING,
} from '../constants';

import PlayerStatsController from './playerStats/controller/PUBGPlayerStatsController';
import PlayerStatsOverlay from './playerStats/overlay/PUBGPlayerStatsOverlay';
import TeamStatsController from './teamStats/controller/PUBGTeamStatsController';
import TeamStatsOverlay from './teamStats/overlay/PUBGTeamStatsOverlay';
import PlayerHeadToHeadController from './playerHeadToHead/controller/PUBGPlayerHeadToHeadController';
import PlayerHeadToHeadOverlay from './playerHeadToHead/overlay/PUBGPlayerHeadToHeadOverlay';
import TeamHeadToHeadController from './teamHeadToHead/controller/PUBGTeamHeadToHeadController';
import TeamHeadToHeadOverlay from './teamHeadToHead/overlay/PUBGTeamHeadToHeadOverlay';
import PlayerLeaderboardController from './playerLeaderboard/controller/PUBGPlayerLeaderboardController';
import PlayerLeaderboardOverlay from './playerLeaderboard/overlay/PUBGPlayerLeaderboardOverlay';
import TeamLeaderboardController from './teamLeaderboard/controller/PUBGTeamLeaderboardController';
import TeamLeaderboardOverlay from './teamLeaderboard/overlay/PUBGTeamLeaderboardOverlay';
import LiveTeamLeaderboardController from './liveTeamLeaderboard/controller/PUBGLiveTeamLeaderboardController';
import LiveTeamLeaderboardOverlay from './liveTeamLeaderboard/overlay/PUBGLiveTeamLeaderboardOverlay';
import LiveSurvivalLeaderboardController from './liveSurvivalLeaderboard/controller/PUBGLiveSurvivalLeaderboardController';
import LiveSurvivalLeaderboardOverlay from './liveSurvivalLeaderboard/overlay/PUBGLiveSurvivalLeaderboardOverlay';
import LiveMapTeamsController from './liveMapTeams/controller/PUBGLiveMapTeamsController';
import LiveMapTeamsOverlay from './liveMapTeams/overlay/PUBGLiveMapTeamsOverlay';
import LiveTeamStatsController from './liveTeamStats/controller/PUBGLiveTeamStatsController';
import LiveTeamStatsOverlay from './liveTeamStats/overlay/PUBGLiveTeamStatsOverlay';
import LivePlayerStatsController from './livePlayerStats/controller/PUBGLivePlayerStatsController';
import LivePlayerStatsOverlay from './livePlayerStats/overlay/PUBGLivePlayerStatsOverlay';
import LivePlayerKillsController from './livePlayerKills/controller/PUBGLivePlayerKillsController';
import LivePlayerKillsOverlay from './livePlayerKills/overlay/PUBGLivePlayerKillsOverlay';
import LivePlayerKillsDamageController from './livePlayerKillsDamage/controller/PUBGLivePlayerKillsDamageController';
import LivePlayerKillsDamageOverlay from './livePlayerKillsDamage/overlay/PUBGLivePlayerKillsDamageOverlay';
import LiveTeamHeadToHeadController from './liveTeamHeadToHead/controller/PUBGLiveTeamHeadToHeadController';
import LiveTeamHeadToHeadOverlay from './liveTeamHeadToHead/overlay/PUBGLiveTeamHeadToHeadOverlay';
import LivePlayerLeaderboardController from './livePlayerLeaderboard/controller/PUBGLivePlayerLeaderboardController';
import LivePlayerLeaderboardOverlay from './livePlayerLeaderboard/overlay/PUBGLivePlayerLeaderboardOverlay';
import CurrentMatchController from './currentMatch/controller/PUBGCurrentMatchController';
import CurrentMatchOverlay from './currentMatch/overlay/PUBGCurrentMatchOverlay';
import TodaysWinnersController from './todaysWinners/controller/PUBGTodaysWinnersController';
import TodaysWinnersOverlay from './todaysWinners/overlay/PUBGTodaysWinnersOverlay';

export default {
  PlayerStats: {
    width: 4,
    name: PLAYER_STATS_COMPONENT_NAME,
    type: PLAYER_STATS_COMPONENT_TYPE,
    controller: PlayerStatsController,
    overlay: PlayerStatsOverlay,
    maxStatistics: 5,
    stateOptsMapping: PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  TeamStats: {
    width: 4,
    name: TEAM_STATS_COMPONENT_NAME,
    type: TEAM_STATS_COMPONENT_TYPE,
    controller: TeamStatsController,
    overlay: TeamStatsOverlay,
    maxStatistics: 5,
    stateOptsMapping: TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  PlayerHeadToHead: {
    width: 4,
    name: PLAYER_HEAD_TO_HEAD_COMPONENT_NAME,
    type: PLAYER_HEAD_TO_HEAD_COMPONENT_TYPE,
    controller: PlayerHeadToHeadController,
    overlay: PlayerHeadToHeadOverlay,
    maxStatistics: 4,
    stateOptsMapping: PLAYER_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  TeamHeadToHead: {
    width: 4,
    name: TEAM_HEAD_TO_HEAD_COMPONENT_NAME,
    type: TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
    controller: TeamHeadToHeadController,
    overlay: TeamHeadToHeadOverlay,
    maxStatistics: 4,
    stateOptsMapping: TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  PlayerLeaderboard: {
    width: 4,
    name: PLAYER_LEADERBOARD_COMPONENT_NAME,
    type: PLAYER_LEADERBOARD_COMPONENT_TYPE,
    controller: PlayerLeaderboardController,
    overlay: PlayerLeaderboardOverlay,
    maxStatistics: 1,
    stateOptsMapping: PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
    exportOverlay: {
      width: 1800,
      height: 700,
      teamNameProperty: 'teamName',
    },
  },
  TeamLeaderboard: {
    width: 4,
    name: TEAM_LEADERBOARD_COMPONENT_NAME,
    type: TEAM_LEADERBOARD_COMPONENT_TYPE,
    controller: TeamLeaderboardController,
    overlay: TeamLeaderboardOverlay,
    maxStatistics: null,
    stateOptsMapping: TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
    exportOverlay: {
      width: 1800,
      height: 800,
      teamNameProperty: 'team',
    },
  },
  LiveTeamLeaderboard: {
    width: 4,
    name: LIVE_TEAM_LEADERBOARD_COMPONENT_NAME,
    type: LIVE_TEAM_LEADERBOARD_COMPONENT_TYPE,
    controller: LiveTeamLeaderboardController,
    overlay: LiveTeamLeaderboardOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_TEAM_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LiveSurvivalLeaderboard: {
    width: 4,
    name: LIVE_SURVIVAL_LEADERBOARD_COMPONENT_NAME,
    type: LIVE_SURVIVAL_LEADERBOARD_COMPONENT_TYPE,
    controller: LiveSurvivalLeaderboardController,
    overlay: LiveSurvivalLeaderboardOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_SURVIVAL_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LiveMapTeams: {
    width: 4,
    name: LIVE_MAP_TEAMS_COMPONENT_NAME,
    type: LIVE_MAP_TEAMS_COMPONENT_TYPE,
    controller: LiveMapTeamsController,
    overlay: LiveMapTeamsOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_MAP_TEAMS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LiveTeamStats: {
    width: 4,
    name: LIVE_TEAM_STATS_COMPONENT_NAME,
    type: LIVE_TEAM_STATS_COMPONENT_TYPE,
    controller: LiveTeamStatsController,
    overlay: LiveTeamStatsOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_TEAM_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LivePlayerStats: {
    width: 4,
    name: LIVE_PLAYER_STATS_COMPONENT_NAME,
    type: LIVE_PLAYER_STATS_COMPONENT_TYPE,
    controller: LivePlayerStatsController,
    overlay: LivePlayerStatsOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_PLAYER_STATS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LivePlayerKills: {
    width: 4,
    name: LIVE_PLAYER_KILLS_COMPONENT_NAME,
    type: LIVE_PLAYER_KILLS_COMPONENT_TYPE,
    controller: LivePlayerKillsController,
    overlay: LivePlayerKillsOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_PLAYER_KILLS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LiveTeamKillsDamage: {
    width: 4,
    name: LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_NAME,
    type: LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_TYPE,
    controller: LivePlayerKillsDamageController,
    overlay: LivePlayerKillsDamageOverlay,
    maxStatistics: null,
    stateOptsMapping: LIVE_PLAYER_KILLS_DAMAGE_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LiveTeamHeadToHead: {
    width: 4,
    name: LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_NAME,
    type: LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_TYPE,
    controller: LiveTeamHeadToHeadController,
    overlay: LiveTeamHeadToHeadOverlay,
    maxStatistics: 4,
    stateOptsMapping: LIVE_TEAM_HEAD_TO_HEAD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  LivePlayerLeaderboard: {
    width: 4,
    name: LIVE_PLAYER_LEADERBOARD_COMPONENT_NAME,
    type: LIVE_PLAYER_LEADERBOARD_COMPONENT_TYPE,
    controller: LivePlayerLeaderboardController,
    overlay: LivePlayerLeaderboardOverlay,
    maxStatistics: 1,
    stateOptsMapping: LIVE_PLAYER_LEADERBOARD_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  CurrentMatch: {
    width: 4,
    name: CURRENT_MATCH_COMPONENT_NAME,
    type: CURRENT_MATCH_COMPONENT_TYPE,
    controller: CurrentMatchController,
    overlay: CurrentMatchOverlay,
    maxStatistics: null,
    stateOptsMapping: CURRENT_MATCH_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
  TodaysWinners: {
    width: 4,
    name: TODAYS_WINNERS_COMPONENT_NAME,
    type: TODAYS_WINNERS_COMPONENT_TYPE,
    controller: TodaysWinnersController,
    overlay: TodaysWinnersOverlay,
    maxStatistics: null,
    stateOptsMapping: TODAYS_WINNERS_COMPONENT_STATE_TO_OPTS_MAPPING,
  },
};