import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardFooter from './LeaderboardFooter';

const LeaderboardTable = styled.table`
  border-collapse:separate; 
  border-spacing: 0 2px;

  text-align: center;
  vertical-align: middle;

  font-family: 'CenturyGothicBold';
  font-size: 1.4em;

  &.bc-show {
    margin-left: -210;
    animation: slideRight 0.3s ease 0s forwards;
  
    @keyframes slideRight {
      from {margin-left: -210px;}
      to {margin-left: 40px;}
    }
  }

  &.bc-hide {
    margin-left: 10;
    animation: slideLeft 0.3s ease 0s forwards;
  
    @keyframes slideLeft {
      from {margin-left: 10px;}
      to {margin-left: -210px;}
    }
  }
`;

class BeingLiveSurvivalLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, overlayColor } = this.props;

    return (
      loaded ?
      <LeaderboardTable className={show ? 'bc-show' : 'bc-hide'}>
        <tbody>
          <LeaderboardHeader
            overlayColor={overlayColor}
          />
          {
            leaderboard.map((t, i) => {
              return (
                <LeaderboardBody
                  team={t}
                  rank={i+1}
                />
              );
            })
          }
          <LeaderboardFooter />
        </tbody>
      </LeaderboardTable>
      :
      null
    );
  }
}

export default BeingLiveSurvivalLeaderboard;