import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ControllerBase from '../../../common/base/controller/ControllerBase';
import { CSGO_GAME } from '../../../../../util/Games';
import StatisticsSelect from '../../../common/base/controller/inputs/StatisticsSelect';
import MatchSelect from '../../../common/base/controller/inputs/MatchSelect';
import { requestChangeComponent } from '../../../../../redux/dashboard/actions';

class CSGOPlayerLeaderboardController extends Component {
  render() {
    const { component } = this.props;

    return (
      <ControllerBase
        game={CSGO_GAME}
        {...this.props}
      >
      <Row>
        <Col xs="12">
          <StatisticsSelect component={component} />
        </Col>
        <Col xs="12">
          <MatchSelect component={component} />
        </Col>
      </Row>
      </ControllerBase>
    );
  }
}

const mapDispatchToProps = {
  requestChangeComponent,
};

export default connect(null, mapDispatchToProps)(CSGOPlayerLeaderboardController);
