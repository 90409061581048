import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import styles from '../../../../../../scss/style.scss';
import { getTeamLogo } from '../../../../../../util/CommonUtil';
import { TWIRE_ASSETS_URL } from '../../../../../../util/Constants';

const PlayerWrapper = styled(Row)`
  background: ${styles.tertiary};
  text-transform: uppercase;

  img {
    height: 220px;
  }

  h1 {
    font-size: 70px;
  }

  h4 {
    font-size: 30px;
  }

  span {
    color: ${styles.info};
    font-size: 20px;
  }
`;

const VsCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function HeadToHeadBody(props) {
  const { left, right, stats } = props;

  const PlayerNames = ({ leftPlayer, rightPlayer }) => {
    return (
      <Row>
        <Col xs="5">
          <h3>{leftPlayer.username}</h3>
        </Col>
        <Col xs="5" className="offset-2">
          <h3>{rightPlayer.username}</h3>
        </Col>
      </Row>
    );
  }

  const PlayerLogos = ({ leftPlayer, rightPlayer }) => {
    return (
      <Row>
        <Col xs="5">
          <Row>
            <Col xs="12" className="mt-4">
              <img
                src={
                  leftPlayer.logo ?
                    leftPlayer.logo
                    :
                    getTeamLogo(leftPlayer.teamName)
                }
                alt="team logo"
                onError={(e) => e.target.src = TWIRE_ASSETS_URL + "pubg/team-logos/default.png"}
              />
            </Col>
          </Row>
        </Col>
        <VsCol xs="2">
          <h1>VS</h1>
        </VsCol>
        <Col xs="5">
          <Row>
            <Col xs="12" className="mt-4">
              <img
                src={
                  rightPlayer.logo ?
                    rightPlayer.logo
                    :
                    getTeamLogo(rightPlayer.teamName)
                }
                alt="team logo"
                onError={(e) => e.target.src = TWIRE_ASSETS_URL + "pubg/team-logos/default.png"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  const PlayerDetails = ({ leftPlayer, rightPlayer, stats }) => {

    return (
      <Row className="mb-4">
        <Col xs="12">
          {
            (stats || []).map(s => {
              return (
                <Row className="mt-4">
                  <Col xs="3" className="offset-1">
                    <h4>{Math.round(leftPlayer[s.key])}</h4>
                  </Col>
                  <VsCol xs="4">
                    <span>{s.value}</span>
                  </VsCol>
                  <Col xs="3">
                    <h4>{Math.round(rightPlayer[s.key])}</h4>
                  </Col>
                </Row>
              );
            })
          }
        </Col>
      </Row>
    );
  }

  return (
    <PlayerWrapper
      className="text-center"
    >
      <Col xs="12" className="mt-4">
        <PlayerNames
          leftPlayer={left}
          rightPlayer={right}
        />
        <PlayerLogos
          leftPlayer={left}
          rightPlayer={right}
        />
        <PlayerDetails
          leftPlayer={left}
          rightPlayer={right}
          stats={stats}
        />
      </Col>
    </PlayerWrapper>
  );
}

const mapStateToProps = state => ({
  leftPlayer: state.headToHeadPlayers.leftPlayer,
  rightPlayer: state.headToHeadPlayers.rightPlayer,
});

export default connect(mapStateToProps)(HeadToHeadBody);