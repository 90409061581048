import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';
import styles from '../../../../../../scss/style.scss';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  // background: url(/assets/img/pjc21/leaderboard-background.png);

  .overlay {
    position: fixed;
    top: 340px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
    color: ${styles.secondary};
  
    font-family: machine;
  
    &.bc-show {
      opacity: 0;
      animation: fade-in 0.4s ease 0s forwards;

      @keyframes fade-in {
        from {opacity: 0;}
        to {opacity: 1;}
      }
    }
  
    &.bc-hide {
      opacity: 1;
      animation: fade-out 0.4s ease 0s forwards;
    
      @keyframes fade-out {
        from {opacity: 1;}
        to {opacity: 0;}
      }
    }
  }
`;

class Pjc21LiveTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, show, opts } = this.props;
    const left = leaderboard.slice(0, 8);
    const right = leaderboard.slice(8, 16);

    return (
      loaded ?
      <Wrapper
      >
        <div className={`overlay ${show ? 'bc-show' : 'bc-hide'}`}>
          <LeaderboardHeader
            numberOfMatches={opts.numberOfMatches}
            overall={opts.overall}
          />
          {
            left.map((_, i) => {
              return (
                <>
                  <LeaderboardBody
                    team={left[i]}
                    rank={i+1}
                  />
                  <LeaderboardBody
                    team={right[i]}
                    rank={i+9}
                  />
                </>
              );
            })
          }
        </div>
      </Wrapper>
      :
      null
    );
  }
}

export default Pjc21LiveTeamLeaderboard;