import React from 'react';
import styled from 'styled-components';
import { addMissingPlayers, getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.table`
  position: fixed;
  top: ${props => props.index * 135}px;
  text-align: center;
  vertical-align: middle;
  height: 135px;
  width: 420px;
  background: url('/assets/img/being/live_team_map_${props => props.corner}_background.png');

  &.bc-show {
    left: ${props => props.side === 'left' ? '-420' : '1920'}px;
    animation: slideIn${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideIn${props => props.side} {
      from {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
      to {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
    }
  }

  &.bc-hide {
    left: ${props => props.side === 'left' ? '0' : '1500'}px;
    animation: slideOut${props => props.side} 0.5s ease 0s forwards;
  
    @keyframes slideOut${props => props.side} {
      from {left: ${props => props.side === 'left' ? '0' : '1500'}px;}
      to {left: ${props => props.side === 'left' ? '-420' : '1920'}px;}
    }
  }

  .team-logo {
    height: 110px;
    position: absolute;
    top: 12px;
    ${props => props.side}: 17px;
  }

  .eliminated {
    color: #ff0050;
    font-family: 'CenturyGothicBold';
    font-size: 40px;
    position: absolute;
    top: 35px;
    ${props => props.side}: 165px;
    text-shadow: 0 0 20px #ff0050;
  }

  .players {
    color: #fff;
    font-family: 'CenturyGothicBold';
    font-size: 18px;
    line-height: 25px;
    position: absolute;
    top: 13px;
    ${props => props.side}: 165px;

    .death {
      color: #808080;
    }

    .knocked {
      color: #ff0050;
    }
  }
`;

function Team(props) {
  const { team, index, side, show } = props;
  const teamDeath = team.players.every(p => p.status === 'death');
  addMissingPlayers(team.players);

  const corner = `${side}_${ index % 8 < 4 ? 'top' : 'bottom' }`;

  return (
    <Wrapper
      className={show ? 'bc-show' : 'bc-hide'}
      side={side}
      corner={corner}
      index={index}
    >
      <img
        className="team-logo"
        src={getTeamLogo(team.name)}
        onError={e => e.target.src = getTeamLogo('default')}
        alt="team-logo"
      />
      {
        teamDeath ?
        <p className="eliminated">ELIMINATED</p>
        :
        <table className={`players text-${side}`}>
          <tbody>
            {
              team.players.map(p => {
                return (
                  <tr>
                    <td className={p.status}>{ p.ign }</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      }
    </Wrapper>
  );
}

export default Team;