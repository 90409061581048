// Action for requesting new component
export const REQUEST_CREATE_COMPONENT = 'REQUEST_CREATE_COMPONENT';
export const REQUEST_CREATE_COMPONENT_PENDING = 'REQUEST_CREATE_COMPONENT_PENDING';
export const REQUEST_CREATE_COMPONENT_SUCCESS = 'REQUEST_CREATE_COMPONENT_SUCCESS';
export const REQUEST_CREATE_COMPONENT_ERROR = 'REQUEST_CREATE_COMPONENT_ERROR';
// Action for requesting new components in bulk
export const REQUEST_CREATE_COMPONENTS_BULK = 'REQUEST_CREATE_COMPONENTS_BULK';
export const REQUEST_CREATE_COMPONENTS_BULK_PENDING = 'REQUEST_CREATE_COMPONENTS_BULK_PENDING';
export const REQUEST_CREATE_COMPONENTS_BULK_SUCCESS = 'REQUEST_CREATE_COMPONENTS_BULK_SUCCESS';
export const REQUEST_CREATE_COMPONENTS_BULK_ERROR = 'REQUEST_CREATE_COMPONENTS_BULK_ERROR';
// Action for requesting change component
export const REQUEST_CHANGE_COMPONENT = 'REQUEST_CHANGE_COMPONENT';
export const REQUEST_CHANGE_COMPONENT_PENDING = 'REQUEST_CHANGE_COMPONENT_PENDING';
export const REQUEST_CHANGE_COMPONENT_SUCCESS = 'REQUEST_CHANGE_COMPONENT_SUCCESS';
export const REQUEST_CHANGE_COMPONENT_ERROR = 'REQUEST_CHANGE_COMPONENT_ERROR';
// Action for requesting change component wiht state validation
export const REQUEST_CHANGE_COMPONENT_WITH_VALIDATION = 'REQUEST_CHANGE_COMPONENT_WITH_STATE_VALIDATION';
// Action for requesting remove of the component
export const REQUEST_REMOVE_COMPONENT = 'REQUEST_REMOVE_COMPONENT';
export const REQUEST_REMOVE_COMPONENT_PENDING = 'REQUEST_REMOVE_COMPONENT_PENDING';
export const REQUEST_REMOVE_COMPONENT_SUCCESS = 'REQUEST_REMOVE_COMPONENT_SUCCESS';
export const REQUEST_REMOVE_COMPONENT_ERROR = 'REQUEST_REMOVE_COMPONENT_ERROR';
// Action for requesting remove of all components
export const REQUEST_REMOVE_ALL_COMPONENT = 'REQUEST_REMOVE_ALL_COMPONENT';
export const REQUEST_REMOVE_ALL_COMPONENT_PENDING = 'REQUEST_REMOVE_ALL_COMPONENT_PENDING';
export const REQUEST_REMOVE_ALL_COMPONENT_SUCCESS = 'REQUEST_REMOVE_ALL_COMPONENT_SUCCESS';
export const REQUEST_REMOVE_ALL_COMPONENT_ERROR = 'REQUEST_REMOVE_ALL_COMPONENT_ERROR';
// Action for requesting change of the shard info
export const REQUEST_SHARD_INFO_CHANGE_COMPONENT = 'REQUEST_SHARD_INFO_CHANGE_COMPONENT';
export const REQUEST_SHARD_INFO_CHANGE_COMPONENT_PENDING = 'REQUEST_SHARD_INFO_CHANGE_COMPONENT_PENDING';
export const REQUEST_SHARD_INFO_CHANGE_COMPONENT_SUCCESS = 'REQUEST_SHARD_INFO_CHANGE_COMPONENT_SUCCESS';
export const REQUEST_SHARD_INFO_CHANGE_COMPONENT_ERROR = 'REQUEST_SHARD_INFO_CHANGE_COMPONENT_ERROR';
// Action for subscribing to component changes
export const SUBSCRIBE_TO_CHANGE_COMPONENT = 'SUBSCRIBE_TO_CHANGE_COMPONENT';
export const SUBSCRIBE_TO_CHANGE_COMPONENT_PENDING = 'SUBSCRIBE_TO_CHANGE_COMPONENT_PENDING';
export const SUBSCRIBE_TO_CHANGE_COMPONENT_SUCCESS = 'SUBSCRIBE_TO_CHANGE_COMPONENT_SUCCESS';
export const SUBSCRIBE_TO_CHANGE_COMPONENT_ERROR = 'SUBSCRIBE_TO_CHANGE_COMPONENT_ERROR';
// Action for setting the component state
export const SET_COMPONENT_STATE = 'SET_COMPONENT_STATE';
// Action for setting the component data
export const SET_COMPONENT_DATA = 'SET_COMPONENT_DATA';