import React, { Component } from 'react';
import styled from 'styled-components';
import LeaderboardTitle from './LeaderboardTitle';
import LeaderboardBody from './LeaderboardBody';
import LeaderboardHeader from './LeaderboardHeader';

const WIDTH = 1682;
const HEIGHT = 897;

const LeaderboardWrapper = styled.table`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  font-family: Purista-Medium;
  text-transform: uppercase;
  font-size: 25px;
  border-collapse: separate; 
  border-spacing: 10px 0;
  
  .text-bold {
    font-family: Purista-Bold;
  }
  
  .text-semibold {
    font-family: Purista-SemiBold;
  }
  
  .text-semibold-italic {
    font-family: Purista-SemiBoldItalic;
  }
  
  .td-rank, .td-wins, .td-kills {
    width: 120px;
  }

  .td-team {
    481px;
  }

  .tr-title tr {
    height: 150px;
  }

  .tr-header tr {
    height: 90px;
  }

  .tr-body tr {
    height: 77px;
  }

  .twire-logo img {
    margin-top: 10px;
    height: 50px;
    background: #1f1f1f;
  }

  &.component-show .twire-logo img {
    opacity: 0;
    animation: fadeIn 0.3s ease 1.4s forwards;
  }

  &.component-hide .twire-logo img {
    animation: fadeOut 0.3s ease 1.0s forwards;
  }
`;

class DnwTeamLeaderboard extends Component {
  render() {
    const { loaded, leaderboard, opts, show, exportOverlay } = this.props;
    if (leaderboard.length % 2 !== 0) leaderboard.push({ team: '', kills: '', points: '' });
    const numberOfHalfTeams = leaderboard.length / 2;
    const firstHalfTeams = leaderboard.slice(0, numberOfHalfTeams);
    const secondHalfTeams = leaderboard.slice(numberOfHalfTeams, leaderboard.length);

    if (!loaded) return null;

    return (
      <LeaderboardWrapper
        className={ exportOverlay ? '' : (show ? 'component-show' : 'component-hide') }
      >
        <tbody>
          <tr className="tr-title">
            <td colSpan="2">
              <LeaderboardTitle
                rowWidth={WIDTH}
                numberOfMatches={opts.numberOfMatches}
                show={show} 
                exportOverlay={exportOverlay} 
                overall={opts.overall}
              />
            </td>
          </tr>
          <tr className="tr-header">
            <td>
              <LeaderboardHeader
                rowWidth={WIDTH}
                show={show} 
                exportOverlay={exportOverlay} 
              />
            </td>
            <td>
              <LeaderboardHeader
                rowWidth={WIDTH}
                show={show} 
                exportOverlay={exportOverlay} 
              />
            </td>
          </tr>
          <tr className="tr-body">
            <td>
              <LeaderboardBody 
                teams={firstHalfTeams} 
                startingRank={1} 
                rowWidth={WIDTH/2} 
                show={show} 
                exportOverlay={exportOverlay} 
              />
            </td>
            <td>
              <LeaderboardBody
                teams={secondHalfTeams} 
                startingRank={firstHalfTeams.length + 1} 
                rowWidth={WIDTH/2} 
                show={show} 
                exportOverlay={exportOverlay} 
              />
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="twire-logo text-center"
            >
              <img
                src="/assets/img/twire/powered-by-twire-no-background.png"
                alt="twire-logo"
              />
            </td>
          </tr>
        </tbody>
      </LeaderboardWrapper>
    );
  }
}

export default DnwTeamLeaderboard;
