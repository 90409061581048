import React from 'react';
import styled from 'styled-components';
import { getTeamLogo } from '../../../../../../util/CommonUtil';

const Wrapper = styled.div`
  font-size: 35px;

  table {
    border-collapse:separate;
    border-spacing: 0px 10px;
  }

  td {
    height: 65px;
    position: relative;

    img {
      height: 180px;
  
      &:first-child {
        z-index: -1;
        position: absolute;
        filter: blur(15px);
      }
    }
  }

  .stats-value {
    width: 100px;
    background: #ff8317;
  }

  .stats-name {
    padding: 0;
    width: 500px;

    div {
      background: #404040;
      position: relative;
      overflow: hidden;
    }
  }

  .stats {
    font-size: 50px;
  }
`;

const TableRow = styled.tr`
  &.component-show {
    transform: rotate3d(1, 0, 0, 90deg);
    animation: 
      rotateIn 0.5s ease ${props => ((props.rowNumber > 5 ? props.rowNumber - 5 : props.rowNumber)) * 0.3}s forwards,
      fadeIn 1s ease ${props => ((props.rowNumber > 5 ? props.rowNumber - 5 : props.rowNumber)) * 0.3}s forwards;

    .team-logo {
      opacity: 0;
      animation: fadeIn 0.3s ease ${props => (props.numberOfStats + 1) * 0.3}s forwards;
    }

    @keyframes rotateIn {
      from {transform: rotate3d(1, 0, 0, 90deg);}
      to {transform: rotate3d(1, 0, 0, 0deg);}
    }

    @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }

  &.component-hide {
    animation: 
      rotateOut 0.5s ease ${props => ((props.rowNumber > 5 ? props.rowNumber - 5 : props.rowNumber) + 1) * 0.3}s forwards,
      fadeOut 1s ease ${props => ((props.rowNumber > 5 ? props.rowNumber - 5 : props.rowNumber) + 1) * 0.3}s forwards;

    .team-logo {
      animation: fadeOut 0.3s ease 0s forwards;
    }

    @keyframes rotateOut {
      from {transform: rotate3d(1, 0, 0, 0deg);}
      to {transform: rotate3d(1, 0, 0, 90deg);}
    }

    @keyframes fadeOut {
      from {opacity: 1;}
      to {opacity: 0;}
    }
  }
`;

function HeadToHeadBody(props) {
  const { left, right, opts,show } = props;

  return (
    <tr>
      <Wrapper className={ show ? 'component-show' : 'component-hide' }>
        <table>
          <tbody>
            {
              (opts.stats || []).map((s, i) => {
                return (
                  <TableRow
                    className={ show ? 'component-show' : 'component-hide' }
                    rowNumber={i+1}
                    numberOfStats={opts.stats.length}
                  >
                    {
                      i === 0 ?
                      <td rowSpan={opts.stats.length} className="team-logo pr-3">
                        <img
                          src={getTeamLogo(left.teamName)}
                          onError={(e) => e.target.src = getTeamLogo('default')}
                          alt="team logo"
                        />
                        <img
                          src={getTeamLogo(left.teamName)}
                          onError={(e) => e.target.src = getTeamLogo('default')}
                          alt="team logo"
                        />
                        <p>{ left.ign }</p>
                      </td>
                      :
                      null
                    }
                    <td className="stats-value">{ parseInt(left[s.key]) }</td>
                    <td className="stats-name">
                      <div>
                        { s.value }
                      </div>
                    </td>
                    <td className="stats-value">{ parseInt(right[s.key]) }</td>
                    {
                      i === 0 ?
                      <td rowSpan={opts.stats.length} className="team-logo pl-3">
                        <img
                          src={getTeamLogo(right.teamName)}
                          onError={(e) => e.target.src = getTeamLogo('default')}
                          alt="team logo"
                        />
                        <img
                          src={getTeamLogo(right.teamName)}
                          onError={(e) => e.target.src = getTeamLogo('default')}
                          alt="team logo"
                        />
                        <p>{ right.ign }</p>
                      </td>
                      :
                      null
                    }
                  </TableRow>
                );
              })
            }
          </tbody>
        </table>
      </Wrapper>
    </tr>
  );
}

export default HeadToHeadBody;