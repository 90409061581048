import styles from '../../scss/style.scss';
import styled from 'styled-components';

export default styled.textarea`
  width: 100%;
  background: ${styles.tertiary};
  border-color: ${styles.tertiary};
  color: ${styles.secondary};

  &:focus {
    background: ${styles.tertiary};
    color: ${styles.secondary};
  }

  &[disabled] {
    background: ${styles.tertiary};
    color: ${styles.info};
    cursor: not-allowed;
  }
`;