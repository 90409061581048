import React from 'react';
import { CardBody } from 'reactstrap';
import {
  Card,
  CardHeader,
} from '../../StyledCoreUI';

function CustomCard(props) {
  return (
    <Card>
      <CardHeader>
        <strong>{props.title}</strong>
      </CardHeader>
      <CardBody>
        {props.children}
      </CardBody>
    </Card>
  );
}

export default CustomCard;