import {
  REQUEST_TEAM_SEARCH_SUCCESS,
  REQUEST_TEAM_SEARCH_PENDING,
  REQUEST_TEAM_SEARCH_ERROR,
  CLEAR_TEAM_SEARCH_STATE,
} from './types';

const INITIAL_STATE = {
  loaded: false,
  teams: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On team search success
    case REQUEST_TEAM_SEARCH_SUCCESS:
      return {
        ...state,
        loaded: true,
        teams: action.payload,
      }

    // On team search pending
    case REQUEST_TEAM_SEARCH_PENDING:
      return {
        ...state,
        loaded: false,
        teams: [],
      }

    // On team search error
    case REQUEST_TEAM_SEARCH_ERROR:
      return {
        ...state,
        loaded: true,
        teams: [],
      }

    // On team search error
    case CLEAR_TEAM_SEARCH_STATE:
      return INITIAL_STATE

    default:
      return state;
  }
}

export default reducer;