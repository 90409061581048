import React from 'react'
import AEL from './acesEsports/PlayerLeaderboard'
import OSL from './onslaughtLeague/PlayerLeaderboard'
import SDP from './sdp/PlayerLeaderboard'

const ValorantPlayerLeaderboardOverlay = (props) => {
  const { shard } = props;

  return (
    shard.includes('ael-conq') || shard.includes('test-att') ? 
    <AEL {...props} />
    :
    shard.includes('ol') ? 
    <OSL {...props} />
    :
    shard.includes('sdp') ? 
    <SDP {...props} />
    :
    null
  );
}

export default ValorantPlayerLeaderboardOverlay
