import React from 'react';
import { connect } from 'react-redux';
import ESL from './esl/EslLiveTeamHeadToHead';
const PUBGLiveTeamHeadToHeadOverlay = (props) => {
  const { overlayDesign } = props;

  return (
    overlayDesign === 'esl' ?
    <ESL {...props} />
    :
    null
  );
}

const mapStateToProps = state => ({
  left: state.overlay.left ? state.overlay.left.pubg : {},
  right: state.overlay.right ? state.overlay.right.pubg : {},
  overlayDesign: (state.overlay.tournament || {}).overlayDesign,
  opts: state.overlay.opts,
  show: state.overlay.show,
  loaded: state.overlay.loaded,
});

export default connect(mapStateToProps)(PUBGLiveTeamHeadToHeadOverlay);